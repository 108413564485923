import * as React from 'react';
import { StyleRules, withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import { ListItemSecondaryAction, ListItemText, Theme, WithStyles } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import ChevronIcon from '@material-ui/icons/ChevronLeft';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import { ListCategoriesDrawerContainerProps } from './Container';
import IconButton from '@material-ui/core/IconButton';
import { NewCategoryDialog } from './NewCategoryDialog';
import { CollapsableItem } from '../../../utils/components/collapsableitem/CollapsableItem';

const EmptyImg = require('react-icons/lib/fa/sun-o');

const style: (theme: Theme) => StyleRules = theme => ({
  list: {
    width: '100%'
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  drawerPaper: {
    width: '100%'
  },
  deleteIcon: {
    color: theme.palette.grey.A700
  },
  emptyState: {
    height: '100%',
    textAlign: 'center',
    paddingTop: '70px'
  }
});

export type ListCategoriesProps = ListCategoriesDrawerContainerProps & WithStyles;

const ListCategoriesDrawerStyled = (props: ListCategoriesProps) => {
  const { t, classes, open, closeClicked, newCategoryDialogOpen, showNewCategoryDialog, ...rest } = props;
  const items =
    props.listCategories.categories &&
    props.listCategories.categories.map(category => {
      const catIdx: string = category.toLowerCase().replace(' ', '_');
      return (
        <CollapsableItem
          // tslint:disable-next-line jsx-no-lambda
          onCollapsed={() => ({})}
          key={catIdx}>
          <ListItemText primary={category} />
          <ListItemSecondaryAction>
            <IconButton
              aria-haspopup="true"
              color="inherit"
              // tslint:disable-next-line jsx-no-lambda
              onClick={() => props.removeCategory(props.selectedList, category)}>
              <DeleteIcon className={props.classes.deleteIcon} />
            </IconButton>
          </ListItemSecondaryAction>
        </CollapsableItem>
      );
    });

  let component;
  if (props.listCategories && props.listCategories.categories && props.listCategories.categories.length > 0) {
    component = (
      <div className={classes.list}>
        <List>{items}</List>
      </div>
    );
  } else {
    component = (
      <div className={classes.emptyState}>
        <EmptyImg size={200} color="rgba(97, 97, 97, 0.5)" />
        <Typography variant="h5" style={{ paddingTop: '30px' }}>
          You don't have categories
        </Typography>
        <Typography variant="subtitle1">Click '+' in your top left corner to crate a new Category.</Typography>
      </div>
    );
  }

  return (
    <div>
      <Drawer
        anchor="right"
        open={open}
        onClose={closeClicked}
        classes={{
          paper: classes.drawerPaper
        }}>
        <AppBar position="sticky">
          <Toolbar>
            <div className={classes.drawerHeader} onClick={closeClicked}>
              <ChevronIcon />
              {t('List categories')}
            </div>
            <Typography variant="subtitle1" color="inherit" style={{ flex: 1 }} />
            <div
              // tslint:disable-next-line jsx-no-lambda
              onClick={() => props.showNewCategoryDialog(true)}>
              <AddIcon />
            </div>
          </Toolbar>
        </AppBar>
        <Divider />
        <div tabIndex={0} role="button">
          {component}
        </div>
      </Drawer>
      <NewCategoryDialog
        open={newCategoryDialogOpen}
        // tslint:disable-next-line jsx-no-lambda
        closeClicked={() => showNewCategoryDialog(false)}
        {...rest}
      />
    </div>
  );
};

export const ListCategoriesDrawerComponent = withStyles(style)(ListCategoriesDrawerStyled);
