import { logger } from "./LoggingUtils";

const log = logger('debounce');

// inspired on: https://github.com/chodorowicz/ts-debounce/blob/master/src/index.ts
/**
 * A function that emits a side effect and does not return anything.
 */
export type Procedure = (...args: any[]) => void;

export type Options = {
  isImmediate: boolean,
}

export function debounce<F extends Procedure>(
  func: F,
  waitMilliseconds = 50,
  options: Options = {
    isImmediate: false
  },
): (this: ThisParameterType<F>, ...args: Parameters<F>) => void {
  let timeoutId: NodeJS.Timeout | undefined;

  return function(this: ThisParameterType<F>, ...args: Parameters<F>) {
    const context = this;
    const doLater = function() {
      timeoutId = undefined;
      if (!options.isImmediate) {
        func.apply(context, args);
        log.debug(`Function executed with: ${args}`);
      }
    }

    const shouldCallNow = options.isImmediate && timeoutId === undefined;

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(doLater, waitMilliseconds);
    
    if (shouldCallNow) {
      func.apply(context, args);
    }
  }
}