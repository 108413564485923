import * as React from 'react';
import {
  ChangeEvent,
  InputProps,
  RenderSuggestionsContainerParams,
  SuggestionSelectedEventData,
  SuggestionsFetchRequestedParams
} from 'react-autosuggest';
import { AutoCompleteProps } from './Container';
import { AutoCompleteActions } from './Actions';
import { AutoCompleteState } from './Reducers';
import TextField from '@material-ui/core/TextField';
import { MenuItem, Theme, WithStyles } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import { RenderSuggestionParams } from 'react-autosuggest';
import * as AutoS from 'react-autosuggest'
//Check https://github.com/DefinitelyTyped/DefinitelyTyped/issues/14224
const Autosuggest = (AutoS as unknown ) as { new (): AutoS<string> }

const match = require('autosuggest-highlight/match');
const parse = require('autosuggest-highlight/parse');

const styles: (theme: Theme) => StyleRules = theme => ({
  container: {
    flexGrow: 1,
    position: 'relative'
  },
  suggestionsContainerOpenUpwards: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(),
    left: 0,
    right: 0,
    top: 'auto',
    bottom: '100%'
  },
  suggestionsContainerOpenDownwards: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(),
    left: 0,
    right: 0,
    top: '100%',
    bottom: 'auto',
    maxHeight: '170px',
    overflowY: 'auto'
  },
  suggestion: {
    display: 'block'
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none'
  }
});

class AutoCompleteComponentStyled extends React.Component<
  AutoCompleteActions & AutoCompleteState & AutoCompleteProps & WithStyles,
  {}
> {
  private textInput?: HTMLInputElement;

  componentDidMount() {
    if (this.props.requestFocusWhenShown && this.textInput) {
      this.textInput.focus();
    }
  }

  updateInputRef = (inputProps: InputProps<{}>) => (elem: HTMLInputElement) => {
    if (elem) {
      this.textInput = elem;
      if (this.props.inputRef) {
        this.props.inputRef(elem);
      }

      if (inputProps.ref) {
        inputProps.ref(elem);
      }
    }
  };

  renderSuggestionsContainer = (options: RenderSuggestionsContainerParams) => {
    const { containerProps, children } = options;

    return (
      <Paper {...containerProps} square={true}>
        {children}
      </Paper>
    );
  };

  renderSuggestion = (suggestion: string, params: RenderSuggestionParams) => {
    const matches = match(suggestion, params.query);
    const parts: Array<{ text: string; highlight: boolean }> = parse(suggestion, matches);
    return (
      <MenuItem selected={params.isHighlighted} component="div">
        {parts.map((part, index) => {
          let txt = part.text.startsWith(' ') ? part.text.replace(' ', '\u00A0') : part.text;
          txt = txt.endsWith(' ') ? txt.substring(0, txt.length - 1) + '\u00A0' : txt;
          return part.highlight ? (
            <span key={String(index)} style={{ fontWeight: 500 }}>
              {txt}
            </span>
          ) : (
            <span key={String(index)} style={{ fontWeight: 300 }}>
              {txt}
            </span>
          );
        })}
      </MenuItem>
    );
  };

  getValue = (str: string) => str;

  renderInputComponent = (inputProps: InputProps<{}>) => {
    const { classes, ref, defaultValue, onChange, ...other } = inputProps;
    return (
      <TextField
        inputRef={this.updateInputRef(inputProps)}
        inputProps={{
          ...other
        }}
        fullWidth={true}
        onChange={(event) => inputProps.onChange(event, {newValue: event.target.value, method: 'enter'})}
        value={inputProps.value}
      />
    );
  };

  shouldRenderSuggestions = (txt: string) => (!!this.props.showSuggestionsOnFocus ? true : txt.length > 0);

  onChange = (el: React.FormEvent<{}>, params?: ChangeEvent) => {
    if (params != null) {
      this.props.onTextChange(params.newValue);
    }
  };

  clientMangedSuggestions = () => !!this.props.onSuggestionsRequested
  
  onSuggestionRequested = (p: SuggestionsFetchRequestedParams) => {
    if (this.clientMangedSuggestions()) {
      if (this.props.onSuggestionsRequested) {
        this.props.onSuggestionsRequested(p.value);
      }
    } else {
      this.props.newSuggestionsRequested(p, this.props.name, this.props.suggestions);
    } 
  }
    
  onSuggestionCleared = () => {
    this.props.suggestionClearRequested(this.props.name, this.props.suggestions)
  };

  onSuggestionSelected = (e: React.FormEvent<{}>, params: SuggestionSelectedEventData<{}>) => {
    if (this.props.onSuggestionSelected) {
      this.props.onSuggestionSelected(params.suggestionValue);
    }
  };

  render() {
    let props = this.props;
    return (
      <Autosuggest
        theme={{
          container: this.props.classes.container,
          suggestionsContainerOpen: !!this.props.openDownwards
            ? this.props.classes.suggestionsContainerOpenDownwards
            : this.props.classes.suggestionsContainerOpenUpwards,
          suggestionsList: this.props.classes.suggestionsList,
          suggestion: this.props.classes.suggestion
        }}
        shouldRenderSuggestions={this.shouldRenderSuggestions}
        suggestions={this.clientMangedSuggestions() ? props.suggestions : props.activeSuggestions || [] }
        onSuggestionsFetchRequested={this.onSuggestionRequested}
        onSuggestionsClearRequested={this.onSuggestionCleared}
        getSuggestionValue={this.getValue}
        renderSuggestion={this.renderSuggestion}
        renderSuggestionsContainer={this.renderSuggestionsContainer}
        onSuggestionSelected={this.onSuggestionSelected}
        renderInputComponent={this.renderInputComponent}
        focusInputOnSuggestionClick={!props.showSuggestionsOnFocus}
        inputProps={{
          ...props.inputProps,
          onChange: this.onChange,
          value: props.searchText
        }}
      />
    );
  }
}

export const AutoCompleteComponent = withStyles(styles)(AutoCompleteComponentStyled);
