import i18n from 'i18next';
import { TFunction } from 'i18next';
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

export type I18nProps = { t: TFunction }

const init = () => {
    i18n
        .use(initReactI18next)
        .use(LanguageDetector)
        .init({
            resources: {
                es: {
                    translation: {
                        'Welcome back': 'Bienvenido',
                        'Sign in with Google': 'Ingresar con Google',
                        'Something went wrong': 'Algo ha salido mal',
                        'Please restart Trolley and try again': 'Por favor reinicia trolley e intenta de nuevo',
                        'If the problem persist, please contact the author': 'Si el problema persiste, por favor contacta al autor',
                        'My first list': 'Mi primera lista',
                        'My lists': 'Mis Listas',
                        'New list': 'Nueva lista',
                        'Notifications': 'Notificaciones',
                        'Recipe ingredients': 'Ingredientes de recetas',
                        'About': 'Acerca de',
                        'Log out': 'Salir',
                        'Filter list by recipe': 'Filtra por receta',
                        'You don\'t have recipes': 'No tienes recetas',
                        'Tap': 'Presiona',
                        'here': 'aquí',
                        'to start creating your recipes': 'para crear tus recetas',
                        'Clear': 'Quitar filtro',
                        'Recent items': 'Compras recientes',
                        'Items for later': 'Compras aplazadas',
                        'List categories': 'Categorías de la lista',
                        'Show categories': 'Mostrar categorías',
                        'Share list': 'Compartir lista',
                        'This list is not shared yet': 'Esta lista no ha sido compartida',
                        'Add user': 'Adicionar usuario',
                        'Search users by typing the first letters of their name': 'Busca usuarios ingresando las primeras letras del nombre',
                        'User name': 'Nombre',
                        'Select a user': 'Seleccione un usuario',
                        'Couldn\'t find any user': 'No encontré ningún usuario',
                        'Do you want to share this list with': 'Quieres compartir esta lista con',
                        'Back': 'Regresar',
                        'Rename list': 'Renombrar lista',
                        'Remove list': 'Eliminar lista',
                        'Do you want to remove this list': 'Quieres eliminar esta lista',
                        'Cancel': 'Cancelar',
                        'Item categories': 'Categorías',
                        'Complete item': 'Comprado',
                        'Buy later': 'Comprar después',
                        'Remove item': 'Eliminar',
                        'Rename item': 'Cambiar nombre',
                        'Completed': 'Comprado',
                        'Choose': 'Seleccione',
                        'Smart sort': 'Inteligente',
                        'Most recent last': 'Menos reciente',
                        'Most recent first': 'Mas reciente',
                        'Most frequent first': 'Frecuencia',
                        'Hasn\'t been bought recently': 'No ha sido comprado recientemente',
                        'Bought every': 'Comprado cada',
                        'minutes': 'minutos',
                        'hours': 'horas',
                        'days': 'días',
                        'years': 'años',
                        'Yay, no recent items': 'No hay compras recientes',
                        'Buy some items from your list to see them here': 'Compre algunos elementos de su lista para que aparezcan aquí',
                        'Your shopping list is empty': 'Tu lista esta vacía',
                        'Tap the button below to add an item': 'Presiona el botón de abajo para adicionar elementos',
                        'Select or create new item': 'Ingrese un nuevo elemento',
                        'Add': 'Adicionar',
                        'You don\'t have items for later': 'No tienes compras aplazadas',
                        'Swipe left some items in your list to see them here': 'Desliza a la izquierda algunos elementos en tu lista para adicionarlos aquí',
                        'User email': 'Email del usuario',
                        'Shared with': 'Compartida con',
                        'New list name': 'Nuevo nombre',
                        'Developed by': 'Desarrollado por',
                        'Close': 'Cerrar',
                        'New version available': 'Hay una nueva versión',
                        'Do you want to update': 'Quieres actualizar',
                        'Yes': 'Si',
                        'Selected list': 'Lista seleccionada',
                        'completed recently': 'completado recientemente',
                        'You don\'t have recipes for this list': 'No tienes recetas en esta lista',
                        'Tap the + button on the corner to add a Recipe': 'Presiona + para crear una receta',
                        'Do you want to add this recipe items to the current list': 'Quieres adicionar los ingredientes a la lista',
                        'Do you want delete this recipe': 'Quieres borrar esta receta',
                        'Recipe name': 'Nombre de la receta',
                        'Item name': 'Nombre del ingrediente',
                        'Add to list': 'Adicionar a la lista',
                        'This item is new': 'Nuevo ingrediente',
                        'Currently in the list': 'Actualmente en la lista',
                        'Bought': 'Comprado hace',
                        ' ago': '',
                        'Edit': 'Editar',
                        'Delete recipe': 'Borrar receta',
                        'Press back again to exit Trolley': 'Presione hacia atras para salir de Trolley',
                        'Syncing with server': 'Sincronizando',
                        'Press back to exit and sync later': 'Presione hacia atras para salir y sincronizar después',
                        'This web app can be installed': 'Esta aplicación puede ser instalada',
                        'Trolley is a shopping list optimised for user experience': 'Trolley es una lista de compras optimizada para una buena experiencia al usarla',
                        'To install': 'Para instalar presiona',
                        'Tap the share button': 'Compartir',
                        'Tap add to home screen': 'Adicionar al escritorio',
                        'Continue without installing': 'Continúa sin instalar'
                    }
                }
            },
            detection: {
                order: ["navigator"],
                lookupQuerystring: "lng",
                lookupLocalStorage: 'i18nextLng',
                checkForSimilarInWhitelist: true
            },
            load: 'languageOnly',
            cleanCode: true,
            // allow keys to be phrases having `:`, `.`
            nsSeparator: false,
            keySeparator: false,

            // do not load a fallback
            fallbackLng: false,
            interpolation: {
                escapeValue: false
            }
        });
}

export const TrolleyI18n = {
    init
}