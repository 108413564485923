import moment, { Duration, Moment, duration } from 'moment';
import { listUtils } from '../../../utils/ListUtils';
import { RecentItem } from './Types';
import { logger } from '../../../utils/LoggingUtils';
import { timeUtils } from '../../../utils/TimeUtils';

const log = logger('RecentItems.sortingService');

export const BUY_PERIOD_EVALUATION_TIMEFRAME = duration(3, 'months');
export const HALF_EVALUATION_PERIOD = duration (45, 'days');

export const calculateDefaultBuyPeriod: (dates: Date[]) => Duration | undefined =
  (dates) => {
    return calculateBuyPeriod(dates, BUY_PERIOD_EVALUATION_TIMEFRAME, new Date());
  }

/**
 * Removes the dates outside the evaluation period.
 * @param buyDates dates the item has been bought.
 * @param evaluationPeriod in which the calculation will take place. Default 3 months
 * @param today =)
 */
const getBuyDatesInPeriod: (buyDates: Date[], evaluationPeriod: Duration, today: Date) => Date[]
  = (buyDates, evaluationPeriod, today) => {
    if (!buyDates) {
      return [];
    }

    const periodStartDate: Moment = moment(today).subtract(evaluationPeriod);
    const truncatedDates: Date[] = buyDates.filter(date => periodStartDate.isBefore(date));
    return truncatedDates;
  }

/**
 * Buy period represent how many days between each buy in certain amount of time. It is a Duration.
 * 
 * It is a naive approach. Calculate the duration between the first and last time the item was boguth
 * within the period and average that between the number of times the item has been bought in the period.
 * i.e. The item was bougth the 1st, 2nd, 7rd and 8th of the month. Today is 12.
 * 
 * The buy period for the last month is 12/4 = 3 days. 
 * 
 * The buy period for the last week is 7 / 2 = 3.5 days.
 * @param dates dates in which the item has been bought.
 * @param evaluationPeriod period in which the buy period will be calculated. i.e. 2 Month
 * @param today today's date.
 */
export const calculateBuyPeriod: (dates: Date[], evaluationPeriod: Duration, today: Date) => Duration | undefined =
  (dates, evaluationPeriod, today) => {

    const truncatedDates = getBuyDatesInPeriod(dates, evaluationPeriod, today);
    log.trace(`Truncated: ${truncatedDates}`);
    const sortedDates: Moment[] = listUtils.sortDateArray(truncatedDates).map(date => moment(date));

    if (sortedDates.length === 0) {
      // if the item hasn't been bought, the period is unknown
      return undefined;
    }

    const numberOfPeriodsBetweenBuys: number = sortedDates.length;

    const totalDuration: number = moment(today).diff(sortedDates[0], 'seconds');

    const period: number = totalDuration / numberOfPeriodsBetweenBuys;

    return moment.duration(period, 'seconds');

  };

/**
 * Next buy date is using a naive approach. 
 * If the item hasn't been bought during the evaluation period, then we don't know when will be bought next.
 * default 100 years.
 * 
 * If the item has been bought 3 times or less in this period, then the next buy date would be the next period.
 * 
 * If the item has been bought more than 3 times, then the next date would be the last time it was bought plus the period.
 * 
 * Rule added on 28/05/2020
 * If the item has been bought more than 3 times, but the last date is more than half the evaluation period, then 
 * the next buy date would be: Last bought date + period + half evaluation period.
 * @param buyPeriod 
 * @param lastCompletedDate 
 * @param numberOfBuysInPeriod 
 */
export const estimateNextBuyDate = (
  today: Date,
  buyPeriod?: Duration,
  lastCompletedDate?: Date,
  numberOfBuysInPeriod?: number
  ): Date => {

  // if the item hasn't been bought in the evaluation period, then we can't calculate date.
  // default to a big one.
  if (!lastCompletedDate || !buyPeriod) {
    return moment()
      .add(moment.duration(100, 'years'))
      .toDate();
  }

  //Can't be undefined because we check for empty before.
  let nextDate = moment(lastCompletedDate).add(buyPeriod);


  const halfEvaluationPeriodDate: Moment = moment(today).subtract(HALF_EVALUATION_PERIOD);
  if (halfEvaluationPeriodDate.isAfter(lastCompletedDate)) {
    nextDate = nextDate.add(HALF_EVALUATION_PERIOD);

  } else if (!numberOfBuysInPeriod || numberOfBuysInPeriod <= 3) {
    nextDate = nextDate.add(BUY_PERIOD_EVALUATION_TIMEFRAME);

  }

  return nextDate.toDate();
};

const compareByNextBuyDate = (a: RecentItem, b: RecentItem, today: Date) => {
  const prevBuysA = getBuyDatesInPeriod(a.completedOn, BUY_PERIOD_EVALUATION_TIMEFRAME, today).length;
  const prevBuysB = getBuyDatesInPeriod(b.completedOn, BUY_PERIOD_EVALUATION_TIMEFRAME, today).length;
  const estimatedNexBuyDateA = estimateNextBuyDate(today, a.frequency, a.lastCompletedDate, prevBuysA);
  const estimatedNexBuyDateB = estimateNextBuyDate(today, b.frequency, b.lastCompletedDate, prevBuysB);
  return moment(estimatedNexBuyDateA).diff(moment(estimatedNexBuyDateB));
}

export const sortItems: (items: RecentItem[], sortType: string) => RecentItem[] = (items, sortType) => {
  const today = new Date();
  const newItems = items
    .sort((a, b) => {
      switch (sortType) {
        case 'NEWEST_FIRST':
          return moment(b.lastCompletedDate).diff(moment(a.lastCompletedDate));
        case 'FREQUENCY':
          return timeUtils.compareDuration(a.frequency, b.frequency);
        case 'A-Z':
          return a.itemName.toLowerCase().localeCompare(b.itemName.toLowerCase());
        case 'Z-A':
          return b.itemName.toLowerCase().localeCompare(a.itemName.toLowerCase());
        case 'SMART':
          // TODO Change the algorithm to use a likehood score instead of a date.
          // Sort by that score.
          return compareByNextBuyDate(a, b, today);
        case 'OLDEST_FIRST':
        default:
          return moment(a.lastCompletedDate).diff(moment(b.lastCompletedDate));
      }
    });
  return newItems;
};
