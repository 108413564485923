import * as React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { getMenuDrawerState, getNavState, State } from '../../reducer';
import { MenuDrawerActions } from './Actions';
import { MenuDrawerState } from './Reducers';
import { MenuDrawerComponent } from './Components';
import { CommonActions } from '../../utils/actions/Actions';
import { Unsubscribe } from '../../domain/SubsciptionTypes';
import { domainApi } from '../../domain/DomainApi';
import { RouteComponentProps } from 'react-router-dom';
import { AppProps } from '../../router/PrivateRouter';
import { ShoppingListsNavigationState } from '../shoppinglists/router/NavigationReducer';
import { ShoppingListsNavActions } from '../shoppinglists/router/NavigationActions';
import { getShoppingListNavState } from '../shoppinglists/ShoppingListsPageReducer';
import { logger } from '../../utils/LoggingUtils';
import { ActionProps } from '../../utils/Actions';

const log = logger('MenuDrawerContainer');

export type MenuDrawerProps = ActionProps<
  typeof MenuDrawerActions &
  typeof CommonActions & 
  typeof ShoppingListsNavActions
  > &
  AppProps &
  MenuDrawerState &
  RouteComponentProps<{}> & 
  { open: boolean; closeClicked: () => void } & 
  ShoppingListsNavigationState;

class MenuDrawerContainer extends Component<MenuDrawerProps> {
  userListsUnsubscribe: Unsubscribe = ()=>{};
  userSelectedListUnsubscribe: Unsubscribe = ()=>{};

  componentDidMount() {
    this.userListsUnsubscribe = domainApi.subscribeToUserLists(this.props.userId, this.props.onUserListChanged);
    log.debug(`Subscribed to user lists for user ${this.props.userId}`);
    this.userSelectedListUnsubscribe = domainApi.subscribeToUserDetails(
      this.props.userId,
      this.props.onUserSelectedListChanged
    );
  }

  componentWillUnmount() {
    log.debug(`Unsubscribed to user lists for user ${this.props.userId}`);
    this.userListsUnsubscribe && this.userListsUnsubscribe();
    this.userSelectedListUnsubscribe && this.userSelectedListUnsubscribe();
  }

  componentDidUpdate(prevProps: Readonly<MenuDrawerProps>) {
    const oldProps = prevProps;
    // Resubscribe and subscribe if the user has changed
    if (this.props.loggedInUser !== oldProps.userId) {
      log.debug(`Updating subscriptions for ${this.props.loggedInUser} - ${oldProps.userId}`);
      this.userListsUnsubscribe();
      this.userSelectedListUnsubscribe();
      this.userListsUnsubscribe = domainApi.subscribeToUserLists(this.props.loggedInUser, this.props.onUserListChanged);
      this.userSelectedListUnsubscribe = domainApi.subscribeToUserDetails(
        this.props.loggedInUser,
        this.props.onUserSelectedListChanged
      );
    }
  }
  render() {
    return <MenuDrawerComponent {...this.props} />;
  }
}

const mapDispatchToProps = {
  ...MenuDrawerActions,
  ...CommonActions,
  ...ShoppingListsNavActions
};

const mapStateToProps = (state: State) => {
  return {
    ...getMenuDrawerState(state),
    ...getNavState(state),
    ...getShoppingListNavState(state)
  };
};

export const MenuDrawer = connect(mapStateToProps, mapDispatchToProps)(MenuDrawerContainer);
