import { SyncAction, SyncActionClass } from '../../../utils/Actions';
import { ListItem, ShoppingList } from './Types';
import { domainApi } from '../../../domain/DomainApi';

export class CompleteListItemAction extends SyncActionClass<{ listId: string; itemId: string }> {
  constructor(listId: string, itemId: string) {
    super('CompleteListItemAction', { listId, itemId });
  }
}

export class BuyItemLaterAction extends SyncActionClass<{ listId: string; itemId: string }> {
  constructor(listId: string, itemId: string) {
    super('BuyItemLaterAction', { listId, itemId });
  }
}

export class DragStartedAction extends SyncActionClass<string> {
  constructor(dragItemId: string) {
    super('DragStartedAction', dragItemId);
  }
}

export class ToggleExpandCategory extends SyncActionClass<{ cat: string; expand: boolean }> {
  constructor(cat: string, expand: boolean) {
    super('ToggleExpandCategory', { cat, expand });
  }
}

export class DragEndedAction extends SyncActionClass<{ prevCat: string; newCat: string; idx: number; itemId: string }> {
  constructor(prevCat: string, newCat: string, idx: number, itemId: string) {
    super('DragEndedAction', { prevCat, newCat, idx, itemId });
  }
}

export class UpdateItemCategoriesAction extends SyncActionClass<{
  listId: string;
  itemId: string;
  categories: string[];
  newCategory: string;
}> {
  constructor(listId: string, itemId: string, categories: string[], newCategory: string) {
    super('UpdateItemCategoriesAction', { listId, itemId, categories, newCategory });
  }
}

export class RenameItemAction extends SyncActionClass<{
  listId: string;
  itemId: string;
  newName: string;
}> {
  constructor(listId: string, itemId: string, newName: string) {
    super('RenameItemAction', { listId, itemId, newName });
  }
}

export class RemoveItemAction extends SyncActionClass<{ listId: string; itemId: string }> {
  constructor(listId: string, itemId: string) {
    super('RemoveItemAction', { listId, itemId });
  }
}

export class ListItemChangedAction extends SyncActionClass<ListItem[]> {
  constructor(listItems: ListItem[]) {
    super('ListItemChangedAction', listItems);
  }
}

export class ShoppingListChangedAction extends SyncActionClass<ShoppingList> {
  constructor(sl: ShoppingList) {
    super('ShoppingListChangedAction', sl);
  }
}

const completeItem: (listId: string, itemId: string) => SyncAction = (listId, itemId) => {
  domainApi.completeShoppingListItem(listId, itemId);
  return new CompleteListItemAction(listId, itemId).action();
};

const removeItemAction: (listId: string, itemId: string) => SyncAction = (listId, itemId) => {
  domainApi.removeItem(listId, itemId);
  return new RemoveItemAction(listId, itemId).action();
};

const buyItemLater: (listId: string, itemId: string) => SyncAction = (listId, itemId) => {
  domainApi.buyItemLater(listId, itemId);
  return new BuyItemLaterAction(listId, itemId).action();
};

const updateItemCategories: (
  listId: string,
  itemId: string,
  categories: string[],
  newCategory: string
) => SyncAction = (listId, itemId, categories, newCategory) => {
  domainApi.updateItemCategories(listId, itemId, [...categories, newCategory]);

  if (newCategory) {
    domainApi.addCategoryToList(listId, newCategory);
  }

  return new UpdateItemCategoriesAction(listId, itemId, categories, newCategory).action();
};

const renameItem: (listId: string, itemId: string, newName: string) => SyncAction = (listId, itemId, newName) => {
  if (newName) {
    domainApi.renameItem(listId, itemId, newName);
  }

  return new RenameItemAction(listId, itemId, newName).action();
};

const onListItemChanged: (listItems: ListItem[]) => SyncAction = listItems =>
  new ListItemChangedAction(listItems).action();

const onShoppingListChanged: (sl: ShoppingList) => SyncAction = sl => new ShoppingListChangedAction(sl).action();

const onDragStarted = (id: string) => new DragStartedAction(id).action();
const onDragEnded = (prevCat: string, newCat: string, idx: number, itemId: string) =>
  new DragEndedAction(prevCat, newCat, idx, itemId).action();

const expandCategory = (cat: string) => new ToggleExpandCategory(cat, true).action();

const collapseCategory = (cat: string) => new ToggleExpandCategory(cat, false).action();

export const ShoppingListActions = {
  completeItem,
  removeItemAction,
  onListItemChanged,
  onShoppingListChanged,
  buyItemLater,
  updateItemCategories,
  onDragStarted,
  renameItem,
  onDragEnded,
  expandCategory,
  collapseCategory
};
