import { Duration } from 'moment';

export interface RecentItemsList {
  listId: string;
  sortType: string;
  items: RecentItem[];
}

/**
 * Recent item optimised for presentation speed.
 */
export interface RecentItem {
  id: string;
  itemName: string;
  completedOn: Date[];
  addedOn: Date[];
  lastCompletedDate?: Date;
  lastAddedDate?: Date;
  frequency?: Duration;
  name_idx: string;
}

export const emptyRecentItemsList = {
  listId: '',
  listName: '',
  sortType: 'OLDEST_FIRST',
  items: []
};