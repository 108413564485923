import React, { ReactNode } from "react";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";

export interface SimpleDialogProps {
    open: boolean;
    title?: ReactNode;
    content?: ReactNode;
    onClose?: () => void;
  }

export const SimpleDialog = (props: SimpleDialogProps) => {
    const { open, title, content, onClose } = props;
    return (
      <Dialog
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        fullWidth={true}
        onClose={onClose}
        aria-labelledby="confirmation-dialog-title"
        open={open}>
        { title && <DialogTitle id="simple-dialog-title">{title}</DialogTitle> }
        <DialogContent>{content}</DialogContent>
      </Dialog>
    );
  };