import * as React from 'react';
import { Theme, WithStyles, Button, LinearProgress } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';

import CancelIcon from '@material-ui/icons/Cancel';
import Drawer from '@material-ui/core/Drawer';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { AddListDrawerActions } from './Actions';
import { AddListDrawerState } from './Reducers';
import IconButton from '@material-ui/core/IconButton';
import { AuthState } from '../../../utils/components/auth/Reducers';
import { logger } from '../../../utils/LoggingUtils';
import { ActionProps } from '../../../utils/Actions';
import { trimAndLower } from '../../../utils/StringUtils';

const log = logger('AddListDrawerComponent');

const style: (theme: Theme) => StyleRules = theme => ({
  flex: {
    flex: 1
  },
  drawerHeader: {
    alignItems: 'center',
    padding: '0 8px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    ...theme.mixins.toolbar
  },
  headerIcon: {
    color: theme.palette.primary.contrastText
  },
  drawerContent: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4)
  },
  drawerPaper: {
    width: '100%'
  }
});

type AddListDrawerProps = ActionProps<typeof AddListDrawerActions> &
  AddListDrawerState &
  AuthState &
  WithStyles & { open: boolean; closeClicked: (newListId?: string) => void };

class AddListDrawer extends React.Component<AddListDrawerProps> {
  inputRef?: HTMLElement;

  componentDidMount() {
    setTimeout(() => {
      if (this.inputRef) {
        this.inputRef.focus();
      }
    }, 500);
  }

  componentDidUpdate(prevProps: Readonly<AddListDrawerProps>) {
    if (!prevProps.open && this.props.open) {
      setTimeout(() => {
        if (this.inputRef) {
          this.inputRef.focus();
        }
      }, 500);
      log.debug('Add list opened');
    }
    if (prevProps.creationState !== 'Created' && this.props.creationState === 'Created') {
      this.onCloseClicked();
    }
  }

  setInputRef = (elem: HTMLElement) => {
    if (elem) {
      this.inputRef = elem;
    }
  };

  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.onAddListTextChange(e.currentTarget.value);
  };
  submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.props.addList(this.props.addListBoxText, this.props.userId);
  };

  onCloseClicked = () => {
    this.props.cleanNewListDialog();
    this.props.closeClicked(this.props.newListId ? this.props.newListId : undefined);
  };

  onAddClicked = () => {
    if (trimAndLower(this.props.addListBoxText)) {
      this.props.addList(this.props.addListBoxText, this.props.userId);
    }
    
    this.props.closeClicked();
  };
  
  render() {
    const { classes, open } = this.props;
    
    const progress = this.props.creationState === 'InProgress' ? <LinearProgress /> : null;
    
    return (
      <div>
        <Drawer
          anchor="right"
          open={open}
          onClose={this.onCloseClicked}
          classes={{
            paper: classes.drawerPaper
          }}>
          <div className={classes.drawerHeader}>
            <Toolbar>
              <IconButton onClick={this.onCloseClicked} data-testid="cancel-new-list">
                <CancelIcon className={classes.headerIcon} />
              </IconButton>
              <Typography variant="subtitle1" color="inherit" className={classes.flex} />
              <Button onClick={this.onAddClicked} color="inherit" variant="outlined" data-testid="ok-new-list">Ok</Button>
            </Toolbar>
          </div>
          <div className={classes.drawerContent}>
            <form onSubmit={this.submitForm}>
              <TextField
                margin="normal"
                id="name"
                label="List name"
                type="text"
                fullWidth={true}
                value={this.props.addListBoxText}
                onChange={this.onChange}
                data-testid="new-list-tf"
                inputRef={this.setInputRef}
              />
              {progress}
            </form>
          </div>
        </Drawer>
      </div>
    );
  }
}

export default withStyles(style)(AddListDrawer);
