import { makeStyles } from '@material-ui/styles';
import { RecipeItemUI } from './Reducers';
import { RecipeDetailsProps } from './Container';
import * as React from 'react';
import SwipeableViews from 'react-swipeable-views';
import {
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Button,
  Collapse,
  Theme,
  Menu,
  MenuItem,
  ListItemIcon,
  Divider
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/HighlightOff';
import RemoveIcon from '@material-ui/icons/Delete';
import { waitMs } from '../../../utils/PromiseDelay';
import TimeUtils from '../../../utils/TimeUtils';

const useRecipeItemStyles = makeStyles((theme: Theme) => ({
  mainContainer: {
    '& li': {
      display: 'flex'
    },
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
  },
  chooseAction: {
    backgroundColor: '#FFAB00',
    color: theme.palette.error.contrastText,
    height: '100%',
    display: 'flex',
    alignItems: 'Center',
    justifyContent: 'Center',
    paddingLeft: '20px'
  }
}));

type RecipeItemProps = {
  toggleExpand: (itemId: string) => void;
  expanded: boolean;
  item: RecipeItemUI;
} & RecipeDetailsProps;

const SwipeMenu = React.memo(
  (props: {
    open: boolean;
    itemId: string;
    swipeAnchorEl: HTMLElement | null;
    onCancel: () => void;
    onRemove: () => void;
  }) => {
    return (
      <Menu
        id={`swipe-left-menu-${props.itemId}`}
        open={props.open}
        anchorEl={props.swipeAnchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        onClose={props.onCancel}>
        <MenuItem onClick={props.onRemove}>
          <ListItemIcon>
            <RemoveIcon />
          </ListItemIcon>
          <ListItemText primary={'Remove item'} disableTypography={true} />
        </MenuItem>
        <Divider />
        <MenuItem onClick={props.onCancel}>
          <ListItemIcon>
            <CancelIcon />
          </ListItemIcon>
          <ListItemText primary={'Cancel'} disableTypography={true} />
        </MenuItem>
      </Menu>
    );
  }
);

export const RecipeItem = React.memo((props: RecipeItemProps) => {
  const [showing, setShowing] = React.useState(true);
  const [swipeIdx, setSwipeIdx] = React.useState(0);
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [anchor, setAnchor] = React.useState<HTMLElement | null>(null);
  const classes = useRecipeItemStyles();

  const recipeId = props.recipe ? props.recipe.id : '';
  const item = props.item;
  const listId = props.selectedList;

  const t = props.t;

  const addRecipeItemToList = () => {
    props.addRecipeItemToList(item.id, listId, recipeId);
  };

  const onSwitching = (idx: number, type: string) => {
    if (type === 'end') {
      setSwipeIdx(idx);
    }
  };

  const onEndSwipping = () => {
    // Don't do anything if the item remains in the middle.
    if (swipeIdx === 0) {
      return;
    }

    if (swipeIdx === 1) {
      setMenuOpen(true);
    }
  };

  const cancelSwipe = () => {
    setMenuOpen(false);
    setSwipeIdx(0);
  };

  const removeItem = () => {
    setShowing(false);
    waitMs(250).then(() => {
      props.deleteRecipeItem(item.id, listId, recipeId);
    });
  };

  const toggleExpand = () => {
    props.toggleExpand(item.id);
  };

  const canAdd = !item.inList;
  const secondaryText = item.inList
    ? t('Currently in the list') + '.'
    : item.boughtAgo
    ? `${t('Bought')} ${TimeUtils.humanizeDuration(item.boughtAgo, t)}${t(' ago')}.`
    : t('This item is new') + '.';

  const swipeMenu = (
    <SwipeMenu open={menuOpen} itemId={item.id} swipeAnchorEl={anchor} onCancel={cancelSwipe} onRemove={removeItem} />
  );

  return (
    <Collapse in={showing} timeout={200} unmountOnExit>
      <SwipeableViews
        index={swipeIdx}
        onSwitching={onSwitching}
        onTransitionEnd={onEndSwipping}
        className={classes.mainContainer}>
        <div>
          <ListItem button={true} onClick={toggleExpand}>
            <ListItemText primary={item.itemName} secondary={secondaryText} />
            {canAdd && (
              <ListItemSecondaryAction>
                <Button size="small" color="primary" onClick={addRecipeItemToList}>
                  {t('Add to list')}
                </Button>
              </ListItemSecondaryAction>
            )}
          </ListItem>
          <Collapse in={props.expanded} timeout="auto" unmountOnExit>
            <ListItem button={false} divider={false}>
              <ListItemIcon onClick={removeItem}>
                <RemoveIcon />
              </ListItemIcon>
            </ListItem>
          </Collapse>
        </div>
        <div
          ref={node => {
            setAnchor(node);
          }}
          className={classes.chooseAction}>
          <ListItem divider={false}>
            <ListItemText primary={'Choose'} disableTypography={true} />
          </ListItem>
        </div>
        {swipeMenu}
      </SwipeableViews>
    </Collapse>
  );
});
