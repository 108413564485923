import { IO, _do } from './io';
import { waitMs } from '../PromiseDelay';

export const timeoutIO: (cb: () => IO<void>, ms: number) => IO<void> = (cb, ms) =>
    new IO(effects =>
        waitMs(ms)
            .then(
                () => cb().eval(effects)
            )
    );

export const liftFromList: (list: IO<any>[]) => IO<any[]> = _do(function* (list) {
    const ret: any[] = [];
    for (let i = 0; i < list.length; i++) {
        const val = yield list[i];
        ret.push(val);
    }
    return ret;
});