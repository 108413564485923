import { SyncAction, SyncActionClass, AsyncActionClass, AsyncAction } from '../../utils/Actions';
import { UserDetails } from '../../domain/userdetals/Types';
import { domainApi } from '../../domain/DomainApi';

export class UsersDetailsUpdated extends SyncActionClass<UserDetails[]> {
  static TYPE: string = 'UsersDetailsUpdated';
  constructor(userDetailsList: UserDetails[]) {
    super(UsersDetailsUpdated.TYPE, userDetailsList);
  }
}

export class FetchIsAdmin extends AsyncActionClass<string, boolean> {
  static TYPE = 'FetchIsAdmin';
  constructor(userId: string) {
    super(FetchIsAdmin.TYPE, domainApi.isAdmin, userId);
  }
}

const onUserDetailsListUpdated: (userDetailsList: UserDetails[]) => SyncAction
  = userDetailsList => {
    return new UsersDetailsUpdated(userDetailsList).action();
  };

const fetchIsAdmin: (userId: string) => AsyncAction<boolean>
  = userId => new FetchIsAdmin(userId).action();

/**
 * Actions related to the admin page and dashboards.
 *
 */
export const AdminPageActions = {
  onUserDetailsListUpdated,
  fetchIsAdmin
};
