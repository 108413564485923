import * as React from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { AuthState } from './Reducers';
import Typography from '@material-ui/core/Typography';
import versionUtils from '../../VersionUtils';
import { I18nProps } from '../../../i18n';
const TrolleySplash = require('react-icons/lib/ti/shopping-cart');

export const renderSecure = (
  authState: AuthState,
  i18n: I18nProps,
  Component: React.ComponentType<RouteComponentProps<{}> & AuthState & I18nProps>
) => {
  return (props: RouteComponentProps<{}>) => {
    if (authState.loggedIn === 'false') {
      return <Redirect to="/public/login" />;
    }

    if (authState.loggedInUser) {
      return <Component {...props} {...authState} {...i18n} />;
    }

    return (
      <div
        style={{
          height: '100%',
          textAlign: 'center',
          paddingTop: '70px'
        }}>
        <TrolleySplash size={250} />
        <Typography variant="h5">{versionUtils.getClientVersion()}</Typography>
      </div>
    );
  };
};
