import { firebaseService } from '../../../firebase/FirestoreConfig';
import { CollectionFetchOptions, DocumentData, Query, QuerySnapshot, CollectionReference, FirestoreEffects, FirestoreDocument } from './FirestoreEffects';
import { logger } from '../../../LoggingUtils';

const log = logger('FirestoreEffectsImpl');

type Firestore = firebase.firestore.Firestore;

const getCollectionRef: (path: string[], firestore: Firestore) => CollectionReference<DocumentData> =
    (path, firestore) => {
        let colRef: CollectionReference<DocumentData> = firestore.collection(path[0]);
        for (let i = 1; i < path.length; i++) {
            colRef = colRef.doc(path[i]).collection(path[i + 1]);
            i++;
        }
        log.debug(`Built reference ${colRef.path}`);
        return colRef;
    };

const saveBatch: <T extends FirestoreDocument>(path: string[], elements: T[]) => Promise<void> =
    async (path, elements) => {
        const firestore = await firebaseService.firestore;
        const colRef = getCollectionRef(path, firestore);
        const batch = firestore.batch();
        for (const elm of elements) {
            batch.set(colRef.doc(elm.id), elm, { merge: true });
        }
        await batch.commit();
        log.debug(`Batch commited`);
    };


const saveDocument: (path: string[], docId: string, document: any) => Promise<void>
    = async (path, docId, document) => {
        const firestore = await firebaseService.firestore;
        const colRef = getCollectionRef(path, firestore);
        log.debug(`Saving ${path} / ${docId}`);
        await colRef.doc(docId).set(document);
        log.debug(`${docId} saved`);
    };

const updateDocument: <T>(path: string[], documentId: string, val: T) => Promise<void>
    = async (path, documentId, val) => {
        const firestore = await firebaseService.firestore;
        const colRef = getCollectionRef(path, firestore);
        log.debug(`Updating ${path} / ${documentId}`);
        await colRef.doc(documentId).set(val, { merge: true });
        log.debug(`${documentId} updated`);
    };

const deleteDocument: (path: string[], documentId: string) => Promise<void>
    = async (path, documentId) => {
        const firestore = await firebaseService.firestore;
        const colRef = getCollectionRef(path, firestore);
        log.debug(`Deleting ${path} / ${documentId}`);
        await colRef.doc(documentId).delete();
        log.debug(`${documentId} deleted`);
    };

const findDocument: (path: string[], docId: string) => Promise<DocumentData | undefined>
    = async (path, docId) => {
        const firestore = await firebaseService.firestore;
        let colRef = getCollectionRef(path, firestore);
        const docData = await colRef.doc(docId).get();
        if (!docData.exists) {
            return;
        }

        return docData.data();
    };

const fetchCollection: (path: string[], options?: CollectionFetchOptions) => Promise<DocumentData[]>
    = async (path, options) => {
        //TODO check path should be length 3
        const firestore = await firebaseService.firestore;
        let colRef = getCollectionRef(path, firestore) as Query<DocumentData>;

        if (options) {
            if (options.where) {
                options.where.forEach(queryWhere =>
                    colRef = colRef.where(queryWhere.field, queryWhere.op, queryWhere.value)
                );
            }

            if (options.limit) {
                colRef.limit(options.limit);
            }
        }

        const collection: QuerySnapshot<DocumentData> = await colRef.get();

        if (collection.empty) {
            return [];
        }

        return collection.docs.filter(doc => doc.exists).map(doc => {
            return doc.data();
        });

    };

export const FirestoreEffectsImpl: FirestoreEffects = {
    fetchCollection,
    findDocument,
    saveBatch,
    saveDocument,
    updateDocument,
    deleteDocument
};
