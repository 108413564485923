import { connect } from 'react-redux';
import { State } from '../../../reducer';
import * as React from 'react';
import { AdminPageProps } from '../AdminPage';
import { UsersComponent } from './Component';
import { ActionProps } from '../../../utils/Actions';
import { UsersListActions } from './Actions';
import { getUsersListState } from '../AdminPageReducer';
import { UsersListState } from './Reducer';

export type UsersContainerProps = AdminPageProps 
  & ActionProps<typeof UsersListActions> & UsersListState;

const mapDispatchToProps = {
  ...UsersListActions
};

const mapStateToProps = (state: State) => {
  return getUsersListState(state);
};

class UsersContainer extends React.Component<UsersContainerProps> {
  render() {
    return <UsersComponent {...this.props} />
  }
}

export const Users = connect(mapStateToProps, mapDispatchToProps)(UsersContainer);
