import * as React from 'react';
import firebaseui from 'firebaseui';

// Global ID for the element.
const ELEMENT_ID = 'firebaseui_container';

// Promise that resolves unless the FirebaseUI instance is currently being deleted.
let firebaseUiDeletion = Promise.resolve();

/**
 * Properties types.
 */
interface FirebaseAuthProps {
  // The Firebase UI Web UI Config object.
  // See: https://github.com/firebase/firebaseui-web#configuration
  uiConfig: firebaseui.auth.Config;
  // The Firebase App auth instance to use.
  firebaseAuth: firebase.auth.Auth;
  // Callback that will be passed the FirebaseUi instance before it is
  // started. This allows access to certain configuration options such as
  // disableAutoSignIn().
  uiCallback?: (auth: firebaseui.auth.AuthUI) => {};
  className?: string;
}

/**
 * React Component wrapper for the FirebaseUI Auth widget.
 */
export default class FirebaseAuthComp extends React.Component<FirebaseAuthProps> {
  uiConfig: firebaseui.auth.Config;
  firebaseAuth: firebase.auth.Auth;
  className?: string;
  uiCallback?: (auth: firebaseui.auth.AuthUI) => {};
  firebaseUiWidget?: firebaseui.auth.AuthUI;
  userSignedIn?: boolean;
  unregisterAuthObserver?: firebase.Unsubscribe;

  constructor(props: FirebaseAuthProps) {
    super(props);

    this.uiConfig = props.uiConfig;
    this.firebaseAuth = props.firebaseAuth;
    this.className = props.className;
    this.uiCallback = props.uiCallback;
  }

  /**
   * @inheritDoc
   */
  componentDidMount() {
    // Import the css only on the client.
    require('firebaseui/dist/firebaseui.css');

    // Firebase UI only works on the Client. So we're loading the package in `componentDidMount`
    // So that this works when doing server-side rendering.
    const firebaseui = require('firebaseui');

    // Wait in case the firebase UI instance is being deleted.
    // This can happen if you unmount/remount the element quickly.
    firebaseUiDeletion.then(() => {
      // Get or Create a firebaseUI instance.
      this.firebaseUiWidget = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(this.firebaseAuth);
      if (this.uiConfig.signInFlow === 'popup') {
        this.firebaseUiWidget && this.firebaseUiWidget.reset();
      }

      // We track the auth state to reset firebaseUi if the user signs out.
      this.userSignedIn = false;
      this.unregisterAuthObserver = this.firebaseAuth.onAuthStateChanged(user => {
        if (!user && this.userSignedIn) {
          this.firebaseUiWidget && this.firebaseUiWidget.reset();
        }
        this.userSignedIn = !!user;
      });

      // Trigger the callback if any was set.
      if (this.firebaseUiWidget && this.uiCallback) {
        this.uiCallback(this.firebaseUiWidget);
      }

      // Render the firebaseUi Widget.
      this.firebaseUiWidget && this.firebaseUiWidget.start('#' + ELEMENT_ID, this.uiConfig);
    });
  }

  /**
   * @inheritDoc
   */
  componentWillUnmount() {
    if (this.unregisterAuthObserver && this.firebaseUiWidget) {
      this.unregisterAuthObserver();
      firebaseUiDeletion = this.firebaseUiWidget.delete();
    }
  }

  /**
   * @inheritDoc
   */
  render() {
    return <div className={this.className} id={ELEMENT_ID} />;
  }
}
