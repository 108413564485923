import { SyncAction, SyncActionClass } from '../Actions';
import { domainApi } from '../../domain/DomainApi';
import { RecipesList } from '../../domain/recipes/Types';
import { effectsImpl } from '../fp/effects/EffectsImpl';

// TODO-AC move this to outside utils. This is business logic.
export class SelectUserListAction extends SyncActionClass<{ userId: string; listId: string }> {
  constructor(userId: string, listId: string) {
    super('SelectUserListAction', { userId, listId });
  }
}

export class OnRecipesListUpdatedAction extends SyncActionClass<RecipesList> {
  constructor(recipes: RecipesList) {
    super('OnRecipesListUpdatedAction', recipes);
  }
}

const onRecipesListUpdated = (recipes: RecipesList) => new OnRecipesListUpdatedAction(recipes).action();

export const selectUserList: (userId: string, listId: string) => SyncAction = (userId, listId) => {
  domainApi.setSelectedList(userId, listId).eval(effectsImpl);
  return new SelectUserListAction(userId, listId).action();
};

export const CommonActions = {
  selectUserList,
  onRecipesListUpdated
};
