import { NavAction } from '../../../router/NavReducer';
import { Action } from 'redux';
import { RECIPES_PATH } from './RecipesRouter';
import { logger } from '../../../utils/LoggingUtils';

const log = logger('RecipesNavReducer');

export interface RecipesNavState {
  isRecipeTitleDialogOpen: boolean;
  isAddRecipeItemDialogOpen: boolean;
  isRecipeDetailsOpen: boolean;
  menuOpen: boolean;
}

const emptyState = {
  isRecipeTitleDialogOpen: false,
  isAddRecipeItemDialogOpen: false,
  isRecipeDetailsOpen: false,
  menuOpen: false
};

export const isRecipesPageShown = (path: string) => path.startsWith(RECIPES_PATH);

export const recipesNavReducer: (state: RecipesNavState | undefined, action: Action) => RecipesNavState = (
  state = emptyState,
  action
) => {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE': {
      const navAction = action as NavAction;
      const pathname = navAction.payload.location.pathname;
      log.debug(`Route navigated: ${pathname}`);
      
      return {
        ...state,
        isRecipeTitleDialogOpen: pathname.endsWith('/edit-recipe-title'),
        isAddRecipeItemDialogOpen: pathname.endsWith('/add-recipe-item'),
        isRecipeDetailsOpen: pathname.startsWith(`${RECIPES_PATH}/recipe-details`),
        menuOpen: pathname.endsWith('/menu')
      };
    }
    default:
      return state;
  }
};
