import { Recipe, RecipeItem } from '../../../domain/recipes/Types';
import { Action } from 'redux';
import { ConfirmAddItemsToList, ConfirmDeleteRecipe } from './Actions';
import { OnRecipesListUpdatedAction } from '../../../utils/actions/Actions';
import moment from 'moment';

export interface RecipesListState {
  recipesList: RecipeUI[];
  confirmAddDialog: {
    open: boolean;
    recipeId?: string;
  };
  confirmDeleteDialog: {
    open: boolean;
    recipeId?: string;
  };
}

export interface RecipeUI {
  recipe: Recipe;
  percentageCompletedRecently: number;
}

const emptyState = {
  recipesList: [],
  confirmAddDialog: {
    open: false
  },
  confirmDeleteDialog: {
    open: false
  }
};

export const daysSinceCompleted = (item: RecipeItem, now: moment.Moment) => {
  if (!item.completedOn) {
    return 10000; // Simulate long time since completed.
  }

  const completedMoment = moment(item.completedOn);

  if (!item.addedOn) {
    return now.diff(completedMoment, 'days');
  }

  const addedMoment = moment(item.addedOn);

  if (addedMoment.isAfter(completedMoment)) {
    // is currently in list.
    return 10000;
  }

  return now.diff(completedMoment, 'days');
};

export const calculatePercentageCompletedRecently = (items: RecipeItem[], now: moment.Moment) => {
  const totalItems = items.length;

  if (totalItems === 0) {
    return 0;
  }

  const itemsCompletedRecently = items.filter(item =>  daysSinceCompleted(item, now) < 5).length;
  return round5(itemsCompletedRecently * 100 / totalItems);
};

const round5 = (x: number) => {
  return Math.ceil(x / 5) * 5;
};

const toRecipeUI: (recipe: Recipe) => RecipeUI = recipe => {
  return {
    recipe,
    percentageCompletedRecently: calculatePercentageCompletedRecently(recipe.items, moment())
  };
};

export const recipesListReducer: (state: RecipesListState | undefined, action: Action) => RecipesListState = (
  state = emptyState,
  action
) => {
  switch (action.type) {
    case 'OnRecipesListUpdatedAction': {
      const recipesListAction = action as OnRecipesListUpdatedAction;
      return {
        ...state,
        recipesList: recipesListAction.payload.recipes.map(toRecipeUI)
      };
    }
    case 'ConfirmAddItemsToList': {
      const confirmAction = action as ConfirmAddItemsToList;
      return {
        ...state,
        confirmAddDialog: {
          open: true,
          recipeId: confirmAction.payload
        }
      };
    }
    case 'ConfirmDeleteRecipe': {
      const confirmAction = action as ConfirmDeleteRecipe;
      return {
        ...state,
        confirmDeleteDialog: {
          open: true,
          recipeId: confirmAction.payload
        }
      };
    }
    case 'CancelAddItemsToList': {
      return {
        ...state,
        confirmAddDialog: {
          open: false,
          recipeId: undefined
        }
      };
    }
    case 'CancelDeleteRecipe': {
      return {
        ...state,
        confirmDeleteDialog: {
          open: false,
          recipeId: undefined
        }
      };
    }
    case 'DeleteRecipe': {
      return {
        ...state,
        confirmDeleteDialog: {
          open: false,
          recipeId: undefined
        }
      };
    }
    case 'AddRecipeItemsToList': {
      return {
        ...state,
        confirmAddDialog: {
          open: false,
          recipeId: undefined
        }
      };
    }
    default:
      return state;
  }
};
