import { randomId } from '../../utils/IdUtils';

export interface UserLists {
  userId: string;
  selectedList: string;
  lists: UserList[];
}

export const emptyUserLists = {
  userId: '',
  selectedList: '',
  lists: []
};

export interface UserList {
  id: string;
  name: string;
  totalItems: number;
  sharedWith: string[];
}

export const newUserList = (name: string): UserList => {
  return {
    id: randomId(),
    name: name,
    totalItems: 0,
    sharedWith: []
  };
};
