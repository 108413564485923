import { Action, Dispatch } from 'redux';
import { State } from '../reducer';
import { logger } from './LoggingUtils';

const log = logger('Actions');

type StringAction = Action<string>;

/**
 * @template A Action to be dispatched.
 * @template R Async return type.
 */
export type AsyncAction<R> = (dispatch: Dispatch<StringAction>, getState: () => State) => Promise<R>;

export type SyncAction = (dispatch: Dispatch<StringAction>, getState: () => State) => void;

/**
 * Mapped type that transforms an ActionObj to Properties for React.
 * The action object is an object with functions that returns sync or async actions.
 */
export type ActionProps<T extends ActionObj> = {
  [P in keyof T]: (...args: Parameters<T[P]>) => void
}

/**
 * The action object is an object with functions that returns sync or async actions.
 */
type ActionObj = {
  [key: string]: (...args: any[]) => any
}

type SyncActionType<P> = StringAction & {
  payload: P;
};

export interface AsyncActionOptions<R> {
  actionsOnSuccess?: (res: R) => void;
  runAfterInProgress?: () => void;
  pendingActionMsg?: string;
}

/**
 * Represents an Async action.
 * @template P represents the parameters for the async job
 * @template R represents the response type.
 */
export class AsyncActionClass<P, R> implements StringAction {
  response?: R;
  public options?: AsyncActionOptions<R>;
  errorMessage?: string;

  constructor(public type: string, public promiseCreator: (params: P) => Promise<R>, public params: P) {}

  action: () => AsyncAction<R> = () => {
    return createAsyncAction(this.type, this.promiseCreator, this.params, this.options);
  };
}

/**
 * Represents a sync action
 * @template R represents the payload of the action.
 */
export class SyncActionClass<R> implements SyncActionType<R> {
  type: string;
  payload: R;

  constructor(type: string, payload: R) {
    this.payload = payload;
    this.type = type;
  }

  action = () => newSyncAction(this);
}

export const voidAsyncAction: AsyncAction<{}> = (dispatch, getState) => {
  return Promise.resolve({});
};

export const invalidAsyncAction: <R>(reason: string) => AsyncAction<R> = reason => (dispatch, getState) => {
  return Promise.reject(reason);
};

const newSyncAction: <R>(action: SyncActionType<R>) => SyncAction = action => {
  return (dispatch, getState) => {
    log.trace(action.type);
    dispatch({ payload: action.payload, type: action.type });
  };
}

export const actionSuccessType = (type: string) => `${type}_SUCCESS`;
export const actionInProgressType = (type: string) => `${type}_IN_PROGRESS`;
export const actionFailedType = (type: string) => `${type}_FAILED`;

/**
 * Creates a redux async action
 * @param prefix action name
 * @param promiseCreator creates a promise that will resolve the payload
 * @param params Parameters that will be used in the promise
 * @param options Async options
 */
const createAsyncAction: <P, R>(
  prefix: string,
  promiseCreator: (params: P) => Promise<R>,
  params: P,
  options?: AsyncActionOptions<R>
) => AsyncAction<R> = (prefix, promiseCreator, params, options = {}) => (dispatch, getState) => {
  log.debug(`${prefix}_IN_PROGRESS`);
  dispatch({
    type: actionInProgressType(prefix),
    params: params
  });
  if (options && options.runAfterInProgress) {
    options.runAfterInProgress();
  }
  return promiseCreator(params)
    .then(
      res => {
        log.debug(`${prefix}_SUCCESS`);
        dispatch({
          type: actionSuccessType(prefix),
          response: res,
          params: params
        });

        if (options.actionsOnSuccess) {
          log.debug('Dispatching actionsOnSuccess');
          options.actionsOnSuccess(res);
        }
        return res;
      },
      error => {
        log.error(`Async request error: ${error}`);
        dispatch({
          type: actionFailedType(prefix),
          errorMessage: error.message,
          params: params
        });
        return Promise.reject(error.message);
      }
    )
    .catch(error => {
      log.error(`Unhandled error: ${error}`);
      return Promise.reject(error.message);
    });
};
