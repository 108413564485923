import { SyncAction, SyncActionClass } from '../../../utils/Actions';
import { domainApi } from '../../../domain/DomainApi';
import { ListItem } from '../currentshoppinglist/Types';

export class ItemsForLaterChanged extends SyncActionClass<ListItem[]> {
  constructor(listItems: ListItem[]) {
    super('ItemsForLaterChanged', listItems);
  }
}

export class AddItemForLaterToList extends SyncActionClass<{ listId: string; itemId: string }> {
  constructor(listId: string, itemId: string) {
    super('AddItemForLaterToList', { listId, itemId });
  }
}

const onItemsForLaterChanged: (items: ListItem[]) => SyncAction = items => {
  return new ItemsForLaterChanged(items).action();
};

const addToList: (listId: string, itemId: string) => SyncAction = (listId, itemId) => {
  domainApi.addLaterItemToList(listId, itemId);
  return new AddItemForLaterToList(listId, itemId).action();
};

export const ItemsForLaterActions = {
  onItemsForLaterChanged,
  addToList
};
