import { SyncAction, SyncActionClass } from '../../Actions';
import { SuggestionsFetchRequestedParams } from 'react-autosuggest';
import { SuggestionsResult } from './Types';

export class NewSuggestionsRequestedAction extends SyncActionClass<SuggestionsResult> {
  constructor(name: string, selected:string, suggestionList: string[]) {
    super(name + '@@NEW_SUGGESTIONS_REQUESTED', { searchTerm: selected, suggestionList });
  }
}

export class ClearSuggestionsAction extends SyncActionClass<string[]> {
  constructor(name: string, suggestionList: string[]) {
    super(name + '@@CLEAR_SUGGESTIONS', suggestionList)
  }
}

export const newSuggestionsRequested: (
  params: SuggestionsFetchRequestedParams,
  name: string,
  suggestionList: string[]
) => SyncAction = (params, name, suggestionList) => {
  return new NewSuggestionsRequestedAction(name, params.value, suggestionList).action();
};

export const suggestionClearRequested: (name: string, suggestionList: string[]) => SyncAction = (
  name,
  suggestionList
) => {
  if (suggestionList) {
    return new ClearSuggestionsAction(name, suggestionList).action();
  } else {
    return new ClearSuggestionsAction(name, []).action();
  }
}

export interface AutoCompleteActions {
  newSuggestionsRequested: (
    params: SuggestionsFetchRequestedParams,
    name: string,
    suggestionList: string[]
  ) => void;
  suggestionClearRequested: (name: string, suggestionList: string[]) => void;
}
