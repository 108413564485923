import { EventBusEffects } from './EventBusEffects';
import { Effects } from '../Effects';
import { CallbackIO, eventBusIO } from '../../../eventbus/EventBusIO';

const emit: <T>(effects: Effects, name: string, ...args: T[]) => Promise<void>
    = (effects, name, args) => eventBusIO.emit(effects, name, args);

const register: <T>(name: string, callback: CallbackIO<T>) => Promise<void>
    = (name, callback) => eventBusIO.on(name, callback);

export const EventBusEffectsImpl: EventBusEffects = {
    emit,
    register
};