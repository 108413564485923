import { createStore, applyMiddleware, Store } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { State , default as createRootReducer } from './reducer';
import { History, createBrowserHistory } from 'history';
import { persistStore, persistReducer, createTransform } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import hardSet from 'redux-persist/es/stateReconciler/hardSet';
import { routerMiddleware } from 'connected-react-router';

// Transform date for redux-persistr
// https://github.com/rt2zz/redux-persist/issues/82
const dateTransform = () => {
  const replacer = (key:string , value:object ) => value instanceof Date ? value.toISOString() : value

  const reviver = (key:string, value:object) => 
    (typeof value === 'string' && (value as string).match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/))
      ? new Date(value)
      : value

  const encode = (toDehydrate:any) => JSON.stringify(toDehydrate, replacer)

  const decode = (toRehydrate:string) => JSON.parse(toRehydrate, reviver)
  return createTransform(encode,decode);
}

export const persistConfig = {
  key: 'root',
  storage: storage,
  stateReconciler: hardSet,
  blacklist: ['router'],
  transforms: [dateTransform()]
};

const combinedReducer = (history: History) =>
  persistReducer(persistConfig, createRootReducer(history));

export const history = createBrowserHistory();

const middleware = [routerMiddleware(history), thunk];

const store: Store<State> = createStore(
  combinedReducer(history),
  composeWithDevTools(applyMiddleware(...middleware))
);

export const persistor = persistStore(store);

export default store;
