import { State } from '../../../reducer';
import { connect } from 'react-redux';
import { ShareListDrawerActions } from './Actions';
import { ShareListDrawerComponent } from './Component';
import { getShareListDrawerState } from '../ShoppingListsPageReducer';
import { ActionProps } from '../../../utils/Actions';
import { ShareListDrawerState } from './Reducers';
import { AuthState } from '../../../utils/components/auth/Reducers';
import React from 'react';
import { Unsubscribe } from 'firebase';
import { domainApi } from '../../../domain/DomainApi';
import { ShoppingListPageProps } from '../ShoppingListsPage';
import { logger } from '../../../utils/LoggingUtils';

const log = logger('ShareListDrawerContainer');

const mapDispatchToProps = {
  ...ShareListDrawerActions
};

const mapStateToProps = (state: State) => {
  return getShareListDrawerState(state);
};

export type ShareListDrawerProps = ShoppingListPageProps & ActionProps<typeof ShareListDrawerActions> &
  ShareListDrawerState &
  AuthState & { open: boolean; closeClicked: () => void };

class ShareListDrawerContainer extends React.Component<ShareListDrawerProps> {
  unsubscribe: Unsubscribe = ()=>{};

  componentDidMount() {
    if (this.props.selectedList) {
      log.debug(`Subscribing to list ${this.props.selectedList}`);
      this.unsubscribe = domainApi.subscribeToUsersSharingList(this.props.selectedList,
        users => {
          this.props.onUsersSharingListUpdated(users);
        });
    }
  }

  componentDidUpdate(prevProps: Readonly<ShareListDrawerProps>) {
    if (this.props.selectedList !== prevProps.selectedList) {
      log.debug(`Lists has changed. Old ${prevProps.selectedList}, new: ${this.props.selectedList}`);
      this.unsubscribe && this.unsubscribe();

      this.props.onUsersSharingListUpdated([]); //Clear users
      this.unsubscribe = domainApi.subscribeToUsersSharingList(this.props.selectedList,
        users => {
          this.props.onUsersSharingListUpdated(users);
        });
    }
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      log.debug(`Unsubscribing to list ${this.props.selectedList}`);
      this.unsubscribe();
    }
  }

  render() {
    const props = this.props;
    return <ShareListDrawerComponent {...props} />;
  }
}


export const ShareListDrawer = connect(mapStateToProps, mapDispatchToProps)(ShareListDrawerContainer);
