import { Action } from 'redux';
import { RenameListTextChanged } from './Actions';

export type RenameListDrawerState = Readonly<{
  renameListBoxText: string;
}>;

const emptyRenameListState = {
  renameListBoxText: ''
};

export const renameListDrawerReducer: (state: RenameListDrawerState | undefined, action: Action) => RenameListDrawerState = (
  state = emptyRenameListState,
  action
) => {
  switch (action.type) {
    case 'RenameListTextChanged':
      const searchBoxChanged = action as RenameListTextChanged;
      return { ...emptyRenameListState, renameListBoxText: searchBoxChanged.payload };
    default:
      return state;
  }
};
