import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import { isArray } from 'util';
import { Unsubscribe } from '../../domain/SubsciptionTypes';
import * as qs from 'querystring';
import { history } from '../../store';
import { logger } from '../LoggingUtils';
import { NavUtils } from '../NavigatorUtils';

const log = logger('FirestoreConfig');

type Firestore = firebase.firestore.Firestore;

type FirestoreFunc = (fs: Firestore) => Unsubscribe;

// TODO-AC: use nulls is a hack!!! for lists you can not do safe empty, for objects, you can.
export const safeEmpty: <R>(val?: R, useNulls?: boolean) => R | firebase.firestore.FieldValue | null = (val, useNulls) => {
  if (val) {
    return val;
  }

  if (useNulls) {
    return null;
  }

  return firebase.firestore.FieldValue.delete();
};

export const getUserId = (user: firebase.User) => {
  if (user.providerData) {
    const userInfo = user.providerData[0];
    if (userInfo) {
      // If it contains @ then its an email, the user id will be the email name.
      if (userInfo.uid.indexOf('@') < 0) {
        return `google-oauth2|${userInfo.uid}`;
      } else {
        return `email|${userInfo.uid.substring(0, userInfo.uid.indexOf('@'))}`;
      }
    }
  }

  return user.uid;
};

interface CredentialsFromUrl {
  accessToken?: string;
  idToken?: string;
  mobile?: string;
  testing?: string;
  email?: string;
  password?: string;
}

const getCredentialsFromUrl: () => CredentialsFromUrl = () => {
  const parsed = qs.parse(history.location.search.slice(1));
  const accessTokens = parsed.accessToken;
  const idTokens = parsed.idToken;
  const mobiles = parsed.mobile;
  const testings = parsed.testing;
  const emails = parsed.email;
  const passwords = parsed.password;
  const accessToken = !accessTokens ? undefined : isArray(accessTokens) ? accessTokens[0] : accessTokens;
  const idToken = !idTokens ? undefined : isArray(idTokens) ? idTokens[0] : idTokens;
  const mobile = !mobiles ? undefined : isArray(mobiles) ? mobiles[0] : mobiles;
  const testing = !testings ? undefined : isArray(testings) ? testings[0] : testings;
  const email = !emails ? undefined : isArray(emails) ? emails[0] : emails;
  const password = !passwords ? undefined : isArray(passwords) ? passwords[0] : passwords;

  return {
    accessToken,
    idToken,
    mobile,
    testing,
    email,
    password
  };
};

export const isDev = () => process.env.NODE_ENV === 'development' || process.env.REACT_APP_ENV === 'dev';

class FirebaseService {
  firebaseConfig = {};

  /**
   * Promise returning the firestore instance after initialization.
   * It could have the network disabled until auth happens, but it can 
   * be used and the operations will be queued.
   */
  firestore!: Promise<Firestore>;

  /**
   * Promise with the firestore instance AND the network enabled.
   * This will mean that it wont resolve when offline.
   */
  ensureNetwork: Promise<Firestore>;

  firebaseAuth!: firebase.auth.Auth;

  /**
   * Represents the login action from url parameters (mobile)
   * This is needed to monitor the error code on mobile sign ons.
   * TODO HACK: the whole auth should be extracted from here and simplified.
   */
  mobileLoginAction?: Promise<firebase.auth.UserCredential>

  fromMobile: boolean;

  mobileLoginInProgress: boolean;

  private initialiseFirestore: (fstore: Firestore) => Promise<Firestore>
    = (fstore) => {
      log.debug('Initializing firestore');
      const settings = {};
      fstore.settings(settings);
      return new Promise<Firestore>(resolve => {
        this.enablePersistence(fstore)
          .then(() => {
            const instance = firebase.firestore();
            log.debug('Firestore initialized');
            resolve(instance);
          })
          .catch(err => {
            log.error(`Firestore err: ${JSON.stringify(err)}`);
            resolve(fstore);
          });
        // Make firestore offline first. Enable once auth has happened.
        // This is to accept user actions before auth. After network is enabled,
        // the queued actions will be sent to server.  
        fstore.disableNetwork();
      });
    }

  constructor() {
    log.debug(`All envs: ${JSON.stringify(process.env)}`);
    if (isDev()) {
      log.debug('Using development Firebase');
      this.firebaseConfig = require('./config-dev.json');
    } else {
      log.debug('Using prod Firebase');
      this.firebaseConfig = require('./config-prod.json');
    }

    // Uncomment for local mobile app testing and migrations.
    // this.firebaseConfig = require('./config-prod.json');

    const urlCredentials = getCredentialsFromUrl();
    this.fromMobile = !!urlCredentials.mobile;
    this.mobileLoginInProgress = !!urlCredentials.idToken || !!urlCredentials.testing;
    // initialize firebase instance

    firebase.initializeApp(this.firebaseConfig);

    this.firebaseAuth = this.initializeAuth(urlCredentials);
    this.firestore = this.initialiseFirestore(firebase.firestore());

    
    let networkEnabled: () => void = () => {
      log.warn('NetworkEnabled before ensureNetwork constructor, ensureNetwork will never resolve');
    };

    this.ensureNetwork = new Promise<Firestore>(resolve => {
      networkEnabled = () => this.firestore.then(resolve);
    });
    
    // Make firestore offline first. Enable once auth has happened.
    // This is to accept user actions before auth. After network is enabled,
    // the queued actions will be sent to server.
    log.debug('Network disabled. Waiting for auth...');
    firebase.firestore().disableNetwork();
    this.firestore
      .then(() => {
        // Make firestore online once the auth as happened.
        const unsubs = this.firebaseAuth.onAuthStateChanged(user => {
          if (user) {
            log.debug(`Authenticated user ${user.email}`);
            log.debug('Enabling network');
            this.firestore
              .then(fstore => fstore.enableNetwork())
              .then(networkEnabled);
            unsubs();
          }
        });
      })

  }

  waitForPendingOperations: (cb: (allCompleted: boolean) => void ) => void = (cb) => {
    this.firestore.then(fstore => {
      log.debug('Checking pending operations');
      fstore.waitForPendingWrites().then(() => {
        log.debug('All operations have finished...');
        clearTimeout(timeout);
        cb(true);
      });
      const timeout = setTimeout(() => cb(false), 300);
    });
  }

  firestoreSubscription: (cb: FirestoreFunc) => Unsubscribe = cb => {
    const unsubscribeProm = this.firestore.then(fstore => {
      return cb(fstore);
    });

    return () => unsubscribeProm.then(unsubs => unsubs());
  };

  private async enablePersistence(fstore: Firestore) {
    if (
      NavUtils.isIOS()
      && (!NavUtils.isValidIOSVersion() || !NavUtils.isPwaInstalled())
      ) {
      log.warn('IOS web detected, offline mode disabled!');
      return Promise.resolve();
    }
    await fstore.enablePersistence();
    log.debug('Persistance enabled!!');
  }

  private initializeAuth(urlCredentials: CredentialsFromUrl): firebase.auth.Auth {
    const auth = firebase.auth();
    if (urlCredentials.idToken) {
      log.debug('Initializing from url');
      const credential = firebase.auth.GoogleAuthProvider.credential(urlCredentials.idToken, urlCredentials.accessToken);
      this.mobileLoginAction = auth.signInWithCredential(credential)
        .then(args => {
          if (args.user) {
            log.debug(`Auth initialized successfully!`);
            this.mobileLoginInProgress = false;
            return args;
          } else {
            class MobileLoginError extends Error implements firebase.auth.Error {
              code: string;
              constructor(m: string, code: string) {
                super(m);
                this.code = code;
                // Set the prototype explicitly.
                Object.setPrototypeOf(this, MobileLoginError.prototype);
              }
            }
            
            throw new MobileLoginError(
              `Couldn't get a user from mobile login`,
              'mobile/user-undefined'
            );
        }
      });
    } else if (urlCredentials.testing) {
      log.debug('Initializing for testing purposes...');
      if (!urlCredentials.email || !urlCredentials.password) {
        log.error('Testing credentials not properly set...');
        throw Error('Testing credentials not properly set...');
      }
      auth.signInWithEmailAndPassword(urlCredentials.email, urlCredentials.password).then(args => {
        log.debug(`Auth initialized for testing successfully ${JSON.stringify(args)}`);
      });
    } else {
      log.debug('Web auth');
    }

    return auth;
  }
}

export const firebaseService = new FirebaseService();
