import { ListItem } from '../currentshoppinglist/Types';
import * as React from 'react';
import { ItemCategoriesComponent } from './ItemCategoriesComponent';

export interface ItemCategoriesContainerProps {
  item?: ListItem;
  open: boolean;
  onClose: () => void;
  listCategories: string[];
  onCategoriesUpdated: (itemId: string, categories: string[], newCat: string) => void;
}

export interface ItemCategoriesContainerState {
  selectedCategories: string[];
  newCatTxt: string;
}

export class ItemCategories extends React.Component<ItemCategoriesContainerProps, ItemCategoriesContainerState> {
  state: ItemCategoriesContainerState;

  constructor(props: ItemCategoriesContainerProps) {
    super(props);
    this.state = {
      selectedCategories: props.item ? props.item.tags.Category : [],
      newCatTxt: ''
    };
  }

  onCategoryRemoved = (cat: string) => {
    const newCat = this.state.selectedCategories.indexOf(cat) === -1;
    if (!newCat) {
      this.setState({
        selectedCategories: this.state.selectedCategories.filter(val => val !== cat)
      });
    }
  };

  onCategorySelected = (cat: string) => {
    const newCat = this.state.selectedCategories.indexOf(cat) === -1;
    if (newCat) {
      this.setState({
        selectedCategories: this.state.selectedCategories.concat(cat),
        newCatTxt: ''
      });
    } else {
      this.setState({
        newCatTxt: ''
      });
    }
  };

  closeDialog = () => {
    this.setState({
      selectedCategories: [],
      newCatTxt: ''
    });
    this.props.onClose();
  };

  componentDidMount() {
    this.setState({
      selectedCategories: this.getCategories(),
      newCatTxt: ''
    });
  }

  getCategories = () => {
    return this.props.item && this.props.item.tags && this.props.item.tags.Category
      ? this.props.item.tags.Category
      : [];
  };

  componentDidUpdate(prevProps: ItemCategoriesContainerProps) {
    if (!this.props.item) {
      return;
    }

    if (prevProps.item && prevProps.item.id === this.props.item.id) {
      return;
    }

    this.setState({
      selectedCategories: this.getCategories(),
      newCatTxt: ''
    });
  }

  onTextChange = (txt: string) => this.setState({ newCatTxt: txt });

  render() {
    return (
      <ItemCategoriesComponent
        {...this.props}
        onClose={this.closeDialog}
        onTextChange={this.onTextChange}
        onCategorySelected={this.onCategorySelected}
        onCategoryRemoved={this.onCategoryRemoved}
        {...this.state}
      />
    );
  }
}
