import { Action } from 'redux';
import { NewSuggestionsRequestedAction, ClearSuggestionsAction } from './Actions';
import { makeIndexable } from '../../StringUtils';

export type AutoCompleteState = Readonly<{
  activeSuggestions?: string[];
}>;

export const emptyAutoCompleteState: AutoCompleteState = {
  activeSuggestions: []
};

const filterSuggestions: (suggestionsList: string[], filterValue: string) => string[] = (
  suggestionsList,
  filterValue
) => {
  if (!suggestionsList) {
    return [];
  }

  return suggestionsList.filter((value: string) =>
    makeIndexable(value, true).includes(makeIndexable(filterValue, true))
  );
};

export const autoCompleteReducer: (
  name: string
) => (state: AutoCompleteState, action: Action) => AutoCompleteState = name => (
  state = emptyAutoCompleteState,
  action
) => {
  switch (action.type) {
    case name + '@@NEW_SUGGESTIONS_REQUESTED':
      const suggestionRequest = action as NewSuggestionsRequestedAction;
      return {
        ...state,
        activeSuggestions: filterSuggestions(
          suggestionRequest.payload.suggestionList,
          suggestionRequest.payload.searchTerm
        )
      };
    case name + '@@CLEAR_SUGGESTIONS':
      const clearRequest = action as ClearSuggestionsAction;
      return {
        ...state,
        activeSuggestions: clearRequest.payload
      };
    default:
      return state;
  }
};
