import { ListItem } from '../components/shoppinglists/currentshoppinglist/Types';
import { eventBus } from '../utils/eventbus/EventBus';
import { RecentItem } from '../components/shoppinglists/recentitemsdrawer/Types';
import { Recipe, RecipeItem } from './recipes/Types';
import { UserDetails } from './userdetals/Types';
import { IO } from '../utils/fp/io';
import { CallbackIO, EventBusIO } from '../utils/eventbus/EventBusIO';
import { UserList } from '../components/menudrawer/Types';
import { RemoveFromListReason } from './userlists/io/UserListsServiceIO';

const ShoppingListChanged = 'SHOPING_LIST_CHANGED';
const ShoppingListShared = 'SHOPING_LIST_SHARED';
const ShoppingListItemsChanged = 'SHOPING_LIST_ITEMS_CHANGED';
const ShoppingListItemRemoved = 'ShoppingListItemRemoved';
const NewUserDetailsCreated = 'NewUserCreated';

export interface ShoppingListSharedEvent {
  sharedFromId: string;
  sharedToId: string;
  shoppingListId: string;
  shoppingListName: string;
}

export interface UserDetailsDeletedEvent {
  userId: string;
}

export interface NewUserCreatedEvent {
  userId: string;
  selectedList: string;
  user: UserDetails;
}

export type RecentItemUpdateAction = 'Add' | 'Update' | 'Restore';

export interface RecentItemUpdatedEvent {
  action: RecentItemUpdateAction;
  listId: string;
  item: RecentItem;
}

export class UserDetailsDeletedEvent {
  public static onEvent(cb: (event: UserDetailsDeletedEvent) => void) {
    eventBus.on('UserDetailsDeletedEvent', cb);
  }

  constructor(public userId: string) { }

  emit() {
    eventBus.emit('UserDetailsDeletedEvent', this);
  }
}

export class ItemAddedToRecipeEvent {
  public static onEvent(cb: (event: ItemAddedToRecipeEvent) => void) {
    eventBus.on('ItemAddedToRecipeEvent', cb);
  }

  constructor(public recipe: Recipe, public recipeItem: RecipeItem) { }

  emit() {
    eventBus.emit('ItemAddedToRecipeEvent', this);
  }
}

export class ItemRemovedFromRecipeEvent {
  public static onEvent(cb: (event: ItemRemovedFromRecipeEvent) => void) {
    eventBus.on('ItemRemovedFromRecipeEvent', cb);
  }

  constructor(public recipe: Recipe, public itemId: string) { }

  emit() {
    eventBus.emit('ItemRemovedFromRecipeEvent', this);
  }
}

export class RecipeDeletedEvent {
  public static onEvent(cb: (event: ItemAddedToRecipeEvent) => void) {
    eventBus.on('RecipeDeletedEvent', cb);
  }

  constructor(public recipe: Recipe) { }

  emit() {
    eventBus.emit('RecipeDeletedEvent', this);
  }
}

export class RecipeItemsAddedToList {
  public static onEvent(cb: (event: RecipeItemsAddedToList) => void) {
    eventBus.on('RecipeItemsAddedToList', cb);
  }

  constructor(public recipe: Recipe, public idsToAdd: string[] = []) { }

  emit() {
    eventBus.emit('RecipeItemsAddedToList', this);
  }
}

// TODO Make domain events easier to write.
class DomainEvent {
  id: string;

  constructor(id: string) {
    this.id = id;
  }

  emit() {
    eventBus.emit(this.id, this);
  }
}

class DomainEventIO {
  id: string;

  constructor(id: string) {
    this.id = id;
  }

  emit(): IO<void> {
    return new IO(effects => effects.eventbus.emit(effects, this.id, this));
  }
}

export class ShoppingListItemAddedEvent extends DomainEvent {
  items: ListItem[];

  public static onEvent(cb: (event: ShoppingListItemAddedEvent) => void) {
    eventBus.on('ShoppingListItemAddedEvent', cb);
  }

  constructor(public listId: string, ...items: ListItem[]) {
    super('ShoppingListItemAddedEvent');
    this.items = items;
  }
}

export class ShoppingListItemCompletedEvent extends DomainEvent {
  public static onEvent(cb: (event: ShoppingListItemCompletedEvent) => void) {
    eventBus.on('ShoppingListItemCompletedEvent', cb);
  }

  constructor(public listId: string, public item: ListItem) {
    super('ShoppingListItemCompletedEvent');
  }
}

export class ShoppingListItemRenamedEvent extends DomainEvent {
  public static onEvent(cb: (event: ShoppingListItemCompletedEvent) => void) {
    eventBus.on('ShoppingListItemRenamedEvent', cb);
  }

  constructor(public listId: string, public item: ListItem) {
    super('ShoppingListItemRenamedEvent');
  }
}

export class UserRemovedFromListEventIO extends DomainEventIO {
  public static onEvent(cb: CallbackIO<UserRemovedFromListEventIO>): IO<void> {
    return EventBusIO.register('UserRemovedFromListEventIO', cb);
  }

  constructor(public userList: UserList, public removedUserId: string, public reason: RemoveFromListReason, public removingUserId?: string) {
    super('UserRemovedFromListEventIO');
  }
}

export class UserListCreatedEventIO extends DomainEventIO {
  public static onEvent(cb: CallbackIO<UserListCreatedEventIO>): IO<void> {
    return EventBusIO.register('UserListCreatedEventIO', cb);
  }

  constructor(public userId: string, public listId: string, public listName: string) {
    super('UserListCreatedEventIO');
  }
}

export class UserDetailsUpdatedEventIO extends DomainEventIO {
  public static onEvent(cb: CallbackIO<UserDetailsUpdatedEventIO>): IO<void> {
    return EventBusIO.register('UserDetailsUpdatedEventIO', cb);
  }

  constructor(public userDetails: UserDetails) {
    super('UserDetailsUpdatedEventIO');
  }
}

export class ShoppingListCreatedEvent extends DomainEvent {
  static NAME = 'ShoppingListCreatedEvent';
  public static onEvent(cb: (event: ShoppingListCreatedEvent) => void) {
    eventBus.on(ShoppingListCreatedEvent.NAME, cb);
  }

  constructor(public listId: string, public listName: string) {
    super(ShoppingListCreatedEvent.NAME);
  }
}

export class ListCategoriesCreatedEvent extends DomainEvent {
  static NAME = 'ListCategoriesCreatedEvent';
  public static onEvent(cb: (event: ListCategoriesCreatedEvent) => void) {
    eventBus.on(ListCategoriesCreatedEvent.NAME, cb);
  }

  constructor(public listId: string) {
    super(ListCategoriesCreatedEvent.NAME);
  }
}

export class ListUserHardDeletedEventIO extends DomainEventIO {
  public static onEvent(cb: CallbackIO<ListUserHardDeletedEventIO>): IO<void> {
    return EventBusIO.register('ListUserHardDeletedEventIO', cb);
  }

  constructor(public listId: string) {
    super('ListUserHardDeletedEventIO');
  }
}

const onRecentItemUpdated = (cb: (event: RecentItemUpdatedEvent) => void) => {
  eventBus.on('RecentItemUpdatedEvent', cb);
};

const emitRecentItemUpdated = (event: RecentItemUpdatedEvent) => {
  eventBus.emit('RecentItemUpdatedEvent', event);
};

export default {
  ShoppingListChanged,
  ShoppingListItemsChanged,
  ShoppingListItemRemoved,
  UserListCreatedEventIO,
  NewUserDetailsCreated,
  ShoppingListItemCompletedEvent,
  ShoppingListShared,
  UserDetailsUpdatedEventIO,
  RecipeDeletedEvent,
  ShoppingListItemAddedEvent,
  onRecentItemUpdated,
  emitRecentItemUpdated,
  ItemAddedToRecipeEvent,
  UserDetailsDeletedEvent,
  ListUserHardDeletedEventIO
};
