import { logger } from './LoggingUtils';

const log = logger('PromiseRetry');

export const retry: <P>(retries: number, fn: () => Promise<P>) => Promise<P> = (retries, fn) =>
  fn().catch(err => (retries > 1 ? retry(retries - 1, fn) : Promise.reject(err)));

export const pause: (duration: number) => Promise<{}> = (duration: number) =>
  new Promise(res => setTimeout(res, duration));

/**
 * Runs the function (Promise creator) and if there is an error, it exponentially
 * increase the timeout and runs it again for a number of retries.
 * @param retries 
 * @param fn 
 * @param delay 
 */
export const backoff: <P>(retries: number, fn: () => Promise<P>, delay?: number) => Promise<P> = (
  retries,
  fn,
  delay = 500
) =>
  fn().catch(err => {
    log.debug(`Error: ${err} - Retrying ${retries} times more...`);
    return retries > 1 ? pause(delay).then(() => backoff(retries - 1, fn, delay * 2)) : Promise.reject(err);
  });
