import { logger } from '../LoggingUtils';
import { IO } from '../fp/io';
import { timeoutIO, liftFromList } from '../fp/ioutils';
import { Effects } from '../fp/effects/Effects';

export type CallbackIO<T> = (...args: T[]) => IO<void>;

type ListenerMap = Map<CallbackIO<any>, boolean>;

type EventMap = { [name: string]: ListenerMap; };

const log = logger('EventBusIO');

export class EventBusIO {
  events: EventMap = {};

  constructor() {
    log.debug('Event bus initialized');
  }

  public static register<T>(name: string, callback: CallbackIO<T>): IO<void> {
    return new IO(effects => effects.eventbus.register(name, callback));
  }

  on<T>(name: string, callback: CallbackIO<T>): Promise<void> {
    this.events[name] = this.events[name] || new Map();
    this.events[name].set(callback, false);
    return Promise.resolve();
  }

  emit<T>(effects: Effects, name: string, ...args: T[]): Promise<void> {
    log.debug(`Emmitting ${name}`);
    const event = this.events[name];
    if (event) {
      let callbacks: CallbackIO<T>[] = Array.from(event.keys());
      log.debug(`Mapping ${callbacks.length} callbacks`);
      let ios: IO<void>[] = callbacks.map(callback =>
        timeoutIO(() => {
          log.debug('Running callback IO');
          return callback(...args);
        }, 1)
      );

      let ioList: IO<void[]> = liftFromList(ios);
      let ioReturn: IO<void> = ioList.flatMap(() => IO.void());
      return ioReturn.eval(effects);
    }

    return Promise.resolve();
  }
}

export const eventBusIO = new EventBusIO();
