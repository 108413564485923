import { SyncAction, SyncActionClass } from '../../utils/Actions';
import { ListCategories } from '../../domain/listcategories/Types';

export class ListCategoriesUpdatedAction extends SyncActionClass<ListCategories> {
  constructor(listCategories: ListCategories) {
    super('ListCategoriesUpdatedAction', listCategories);
  }
}

const onListCategoriesUpdated: (listCategories: ListCategories) => SyncAction = listCategories => {
  return new ListCategoriesUpdatedAction(listCategories).action();
};

/**
 * Actions related to shopping lists management.
 *
 */
export const ShoppingListsPageActions = {
  onListCategoriesUpdated
};
