import { SyncAction, SyncActionClass } from '../../../utils/Actions';
import { Recipe } from '../../../domain/recipes/Types';

export class UpdateRecipeFilterAction extends SyncActionClass<{ listId: string; recipe: Recipe; add: boolean }> {
  constructor(listId: string, recipe: Recipe, add: boolean) {
    super('UpdateRecipeFilterAction', { listId, recipe, add });
  }
}

const updateRecipeFilterAction: (listId: string, recipe: Recipe, add: boolean) => SyncAction = (
  listId,
  recipe,
  add
) => {
  return new UpdateRecipeFilterAction(listId, recipe, add).action();
};

export const RecipesFilterActions = {
  updateRecipeFilterAction
};
