import { Action } from 'redux';
import { ShoppingListChangedAction } from '../../shoppinglists/currentshoppinglist/Actions';
import { RenameListAction } from '../../shoppinglists/renamelistdrawer/Actions';

export type TopBarState = Readonly<{
  shoppingListName: string;
}>;

export const emptyTopBarState = {
  shoppingListName: ''
};

export const topBarReducer: (state: TopBarState | undefined, action: Action) => TopBarState = (
  state = emptyTopBarState,
  action
) => {
  switch (action.type) {
    case 'ShoppingListChangedAction': {
      const fetchAction = action as ShoppingListChangedAction;
      const shoppingList = fetchAction.payload;
      return { ...state, shoppingListName: shoppingList.name };
    }
    case 'RenameListAction': {
      const inProgress = action as RenameListAction;
      return { ...state, shoppingListName: inProgress.payload.name };
    }
    default:
      return state;
  }
};
