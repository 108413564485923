import * as React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { ListCategoriesDrawerActions } from './Actions';
import { AuthState } from '../../../utils/components/auth/Reducers';
import { ActionProps } from '../../../utils/Actions';

type NewCategoryDialogProps = AuthState &
  ActionProps<typeof ListCategoriesDrawerActions> & {
    open: boolean;
    closeClicked: () => void;
  };

interface LocalState {
  txt: string;
}

class NewCategoryDialogContainer extends React.Component<NewCategoryDialogProps, LocalState> {
  state: LocalState;
  constructor(props: NewCategoryDialogProps) {
    super(props);
    this.state = {
      txt: ''
    };
  }

  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      txt: e.currentTarget.value
    });
  };

  onOk = () => {
    this.props.addCategory(this.props.selectedList, this.state.txt);
    this.setState({ txt: '' });
    this.props.closeClicked();
  };

  render() {
    return (
      <Dialog open={this.props.open} onClose={this.props.closeClicked} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">New Category</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus={true}
            margin="dense"
            id="name"
            label="Name"
            fullWidth={true}
            value={this.state.txt}
            onChange={this.onChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.closeClicked} color="primary">
            Cancel
          </Button>
          <Button onClick={this.onOk} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export const NewCategoryDialog = connect(null, {})(NewCategoryDialogContainer);
