import * as React from 'react';
import { Component } from 'react';
import Drawer from '@material-ui/core/Drawer';
import { StyleRules } from '@material-ui/core/styles';
import { Theme, WithStyles } from '@material-ui/core';
import { RecipeDetailsProps } from './Container';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import SendIcon from '@material-ui/icons/Send';
import BackIcon from '@material-ui/icons/ArrowBack';
import Toolbar from '@material-ui/core/Toolbar';
import withStyles from '@material-ui/core/styles/withStyles';

const style: (theme: Theme) => StyleRules = theme => ({
  drawerContent: {
    margin: theme.spacing(2)
  },
  drawerPaper: {
    overflowY: 'visible'
  },
  drawer: {
    overflowY: 'visible'
  },
  drawerToolbar: {
    justifyContent: 'space-between',
    paddingRight: 0,
    paddingLeft: 0
  },
  addItem: {
    color: theme.palette.secondary.main
  }
});

type RecipeTitleDialogProps = RecipeDetailsProps & WithStyles;

class RecipeTitleDialogStyled extends Component<RecipeTitleDialogProps> {
  closeNewRecipe = () => {
    if (!!this.props.recipe) {
      this.props.openRecipeTitleDialog(false, this.props.recipe.id);
    } else {
      this.props.openRecipeDetails(false);
    }
  };

  onChange = (e: React.ChangeEvent<HTMLInputElement>) => this.props.onRecipeTitleTextChange(e.currentTarget.value);
  submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    this.editRecipeTitle();
    e.preventDefault();
  };

  navigateToRecipe = (recipeId: string) => {
    this.props.openRecipeDetails(true, recipeId);
  };

  editRecipeTitle = () => {
    if (this.props.newRecipeTitleText) {
      if (this.props.recipe) {
        this.props.updateRecipeName(this.props.selectedList, this.props.recipe.id, this.props.newRecipeTitleText);
        this.navigateToRecipe(this.props.recipe.id);
      } else {
        this.props.createNewRecipe(this.props.selectedList, this.props.newRecipeTitleText, recipe =>
          this.navigateToRecipe(recipe.id)
        );
      }
      this.props.resetRecipeData();
    }
  };

  render() {
    const { t, classes } = this.props;
    return (
      <Drawer
        anchor="bottom"
        open={this.props.isRecipeTitleDialogOpen}
        onClose={this.closeNewRecipe}
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper
        }}>
        <div className={classes.drawerContent}>
          <form onSubmit={this.submitForm}>
            <TextField
              autoFocus={true}
              margin="normal"
              id="shareList"
              label={t("Recipe name")}
              type="text"
              fullWidth={true}
              value={this.props.newRecipeTitleText}
              onChange={this.onChange}
            />
            <Toolbar className={classes.drawerToolbar}>
              <IconButton onClick={this.closeNewRecipe}>
                <BackIcon />
              </IconButton>
              <div>
                <IconButton onClick={this.editRecipeTitle}>
                  <SendIcon className={classes.addItem} />
                </IconButton>
              </div>
            </Toolbar>
          </form>
        </div>
      </Drawer>
    );
  }
}

export const RecipeTitleDialog = withStyles(style)(RecipeTitleDialogStyled);
