import { firebaseService } from '../../utils/firebase/FirestoreConfig';
import { logger } from '../../utils/LoggingUtils';

const log = logger('PermissionsService');

const initialize = () => {
  log.debug('Initialized.');
};

const isAdmin: (userId: string) => Promise<boolean> = async (userId) => {
  const db = await firebaseService.ensureNetwork;
  
  log.debug(`Checking if user ${userId} is admin...`);
  const snapshot = await db.collection('Permissions').doc(userId).get({source: 'server'});
  const data = snapshot.exists ? snapshot.data() : undefined;
  const admin =data ? data.admin : false;
  log.debug(`admin ${admin}`);
  return admin;
};

export const PermissionsDomainApi = {
  isAdmin
};

export default {
  initialize
};
