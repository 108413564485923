import * as React from 'react';
import { Theme, WithStyles, List, ListItem, ListItemAvatar, Avatar, ListItemText, Button } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';

import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { debounce } from '../../../utils/Debounce';
import { ShareListDrawerProps } from './Container';
import { UserDetails } from '../../../domain/userdetals/Types';
import { listUtils } from '../../../utils/ListUtils';
import { SearchUsersDialog } from './SearchUsersDialog';
import AddIcon from '@material-ui/icons/Add';
import { SimpleDialog } from '../../../utils/components/dialogs/SimpleDialog';

const style: (theme: Theme) => StyleRules = theme => ({
  flex: {
    flex: 1
  },
  drawerHeader: {
    alignItems: 'center',
    padding: '0 8px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    ...theme.mixins.toolbar
  },
  headerIcon: {
    color: theme.palette.primary.contrastText
  },
  drawerContent: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4)
  },
  addUserActions: {
    marginTop: theme.spacing(4)
  },
  addUserButton: {
    marginTop: theme.spacing(4)
  },
  drawerPaper: {
    width: '100%'
  },
  sharedWithTitle: {
    marginTop: '20px',
    marginBottom: '20px'

  },
  sharedWithItem: {
    paddingLeft: theme.spacing(0)
  }
});

interface ShareListComponentState {
  searchOpen: boolean;
  userMenuOpen: boolean;
  selectedUser?: UserDetails;
}

type ShareListDrawerComponentProps = ShareListDrawerProps &
  WithStyles;

class ShareListDrawerStyled extends React.Component<ShareListDrawerComponentProps, ShareListComponentState> {
  debouncedFetch?: (searchTerm: string) => void;
  state: ShareListComponentState;

  constructor(props: ShareListDrawerComponentProps) {
    super(props);
    this.state = { searchOpen: false, userMenuOpen: false };
  }

  componentDidMount() {
    this.debouncedFetch = debounce(this.props.fetchSuggestions, 400);
  }

  closeDrawer = () => {
    this.props.closeClicked();
  };

  openSearch = () => {
    this.setState({ searchOpen: true });
  };

  closeSearch = () => {
    this.setState({ searchOpen: false });
  };

  getFirstName = (user?: UserDetails) => {
    if (user) {
      return user.name.split(" ")[0];
    } else {
      return "";
    }
  };

  onRemoveClicked = () => {
    this.props.removeUser(this.state.selectedUser!, this.props.selectedList, this.props.loggedInUser);
    this.setState({ selectedUser: undefined });
  };

  // Remove users already sharing the list
  getFilteredUsersSharing = () => {
    if (this.props.filteredUsers) {
      const alreadySharingIds = (this.props.usersSharingList || [])
        .map(user => user.userId);
      return this.props.filteredUsers
        .filter(current => !listUtils.includes(alreadySharingIds, current.userId));
    } else {
      return [];
    }
  };

  getSharedUserListItem = (user: UserDetails) => {
    const labelId = `checkbox-list-secondary-label-${user.userId}`;
    return (
      <ListItem
        key={user.userId}
        className={this.props.classes.sharedWithItem}
        onClick={() => this.setState({ selectedUser: user })}
      >
        <ListItemAvatar>
          <Avatar
            src={user.photoURL}
            imgProps={{
              onError: (e) => {
                e.currentTarget.src = `https://ui-avatars.com/api/?name=${user.name || 'A user'}`;
              }
            }}
          />
        </ListItemAvatar>
        <ListItemText id={labelId} primary={user.name || 'A user'} />
      </ListItem>
    );
  };

  shareList = (toUserEmail: string) => {
    return this.props.shareListWithUser(this.props.userId, toUserEmail, this.props.selectedList);
  };

  sharedWithComponent = () => {
    const { t, classes } = this.props;
    const users = (this.props.usersSharingList || [])
      .filter(user => user.userId !== this.props.loggedInUser);
    if (users.length > 0) {
      return (
        <>
          <Typography variant="subtitle1" className={classes.sharedWithTitle}>
            {t('Shared with') + ':'}
          </Typography>
          <List dense={false}>
            {users.map(this.getSharedUserListItem)}
            <ListItem button onClick={this.openSearch} className={this.props.classes.sharedWithItem}>
              <ListItemAvatar>
                <Avatar>
                  <AddIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={this.props.t('Add user')} />
            </ListItem>
          </List>
        </>
      );
    } else {
      return (
        <>
          <Typography variant="subtitle1" className={classes.sharedWithTitle}>
            {t('This list is not shared yet') + '.'}
          </Typography>
          <List dense={false}>
            <ListItem button onClick={this.openSearch} className={this.props.classes.sharedWithItem}>
              <ListItemAvatar>
                <Avatar>
                  <AddIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={this.props.t('Add user')} />
            </ListItem>
          </List>
        </>
      );
    }

  };

  render() {
    const { t, classes, open } = this.props;

    return (
      <div>
        <Drawer
          anchor="right"
          open={open}
          onClose={this.closeDrawer}
          classes={{
            paper: classes.drawerPaper
          }}>
          <div className={classes.drawerHeader}>
            <Toolbar>
              <Button onClick={this.closeDrawer} color="inherit" variant="outlined" data-testid="close-share-list">{t('Back')}</Button>
            </Toolbar>
          </div>
          <div className={classes.drawerContent}>
            {this.sharedWithComponent()}
          </div>
          <SearchUsersDialog open={this.state.searchOpen} onClose={this.closeSearch}
            fetchUsers={this.debouncedFetch}
            filteredUsers={this.getFilteredUsersSharing()}
            clearUserSuggestions={this.props.clearUserSuggestions}
            shareList={this.shareList}
            sharingStatus={this.props.sharingStatus}
            fetchUsersStatus={this.props.fetchUsersStatus}
            t={t}
          />
        </Drawer>
        <SimpleDialog
          open={!!this.state.selectedUser}
          onClose={() => this.setState({ selectedUser: undefined })}
          content={(
            <List component="nav">
              <ListItem button onClick={this.onRemoveClicked}>
                <ListItemText primary={`Remove ${this.getFirstName(this.state.selectedUser)} from list`} />
              </ListItem>
              <ListItem button onClick={() => this.setState({ selectedUser: undefined })}>
                <ListItemText primary={`Close`} />
              </ListItem>
            </List>
          )}
        />
      </div>
    );
  }
}

export const ShareListDrawerComponent = withStyles(style)(ShareListDrawerStyled);
