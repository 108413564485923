import { firebaseService } from '../../utils/firebase/FirestoreConfig';
import { SubscriptionCallback, Unsubscribe } from '../SubsciptionTypes';
import { ListItem, ShoppingList } from '../../components/shoppinglists/currentshoppinglist/Types';
import { eventBus } from '../../utils/eventbus/EventBus';
import DomainEvents, {
  ItemAddedToRecipeEvent,
  ShoppingListItemAddedEvent,
  RecipeDeletedEvent,
  RecipeItemsAddedToList,
  ShoppingListItemCompletedEvent,
  ShoppingListItemRenamedEvent,
  ItemRemovedFromRecipeEvent,
  ListUserHardDeletedEventIO,
  UserListCreatedEventIO,
  ShoppingListCreatedEvent
} from '../DomainEvents';
import moment from 'moment';
import { dedupe, listUtils, removeEmpty } from '../../utils/ListUtils';
import { logger } from '../../utils/LoggingUtils';
import { ShoppingListRepo } from './ShoppingListRepository';
import { RecentItem } from '../../components/shoppinglists/recentitemsdrawer/Types';
import { RecipeItem } from '../recipes/Types';
import { nameIdx } from '../../utils/IdUtils';
import * as firebase from 'firebase/app';
import i18n from 'i18next';
import { PureShoppingListRepo } from './fp/PureShoppingListRepository';
import { effectsImpl } from '../../utils/fp/effects/EffectsImpl';
import PureShoppingListService from './fp/PureShoppingListService';
import { IO } from '../../utils/fp/io';

const log = logger('ShoppingListService');

const initialize = () => {
  UserListCreatedEventIO.onEvent(event =>
    new IO(() => createShoppingList(event.listId, event.listName))
  ).eval(effectsImpl);

  ShoppingListCreatedEvent.onEvent(event =>
    addDefaultItemsToShoppingList(event.listId)
  );

  DomainEvents.onRecentItemUpdated(event => {
    switch (event.action) {
      case 'Restore':
        addFromRecentItems(event.listId, event.item);
        break;
      default:
        return;
    }
  });

  ItemAddedToRecipeEvent.onEvent(event => {
    addRecipeTagToItem(event.recipe.listId, event.recipeItem, event.recipe.name);
  });

  RecipeItemsAddedToList.onEvent(event => {
    addRecipeItemToList(event.recipe.listId, event.recipe.name, event.recipe.items, event.idsToAdd);
  });

  RecipeDeletedEvent.onEvent(event => {
    removeRecipeTagFromItems(event.recipe.listId, event.recipe.name);
  });

  ItemRemovedFromRecipeEvent.onEvent(event => {
    removeRecipeTagFromItem(event.recipe.listId, event.itemId, event.recipe.name);
  });

  ListUserHardDeletedEventIO.onEvent(event =>
    new IO(() => hardDeleteShoppingList(event.listId))
  ).eval(effectsImpl);

  log.debug('ShoppingListService initialized.');
};

const hardDeleteShoppingList: (listId: string) => Promise<void> = async (listId) => {
  log.debug(`Hard deleting shoppinglist ${listId}`);
  const firestore = await firebaseService.firestore;
  await ShoppingListRepo.deleteShoppingList(listId, firestore);
  return;
};

const addRecipeItemToList = async (
  listId: string,
  recipeName: string,
  recipeItems: RecipeItem[],
  idsToAdd: string[] = []
) => {
  if (idsToAdd.length > 0) {
    log.debug(`Adding ${idsToAdd.length} items to list ${listId}`);
  } else {
    log.debug(`Adding ${recipeItems.length} items to list ${listId}`);
  }

  let itemsToAdd = recipeItems;

  if (!listUtils.isEmpty(idsToAdd)) {
    itemsToAdd = itemsToAdd.filter(item => listUtils.includes(idsToAdd, item.id));
  }

  const firestore = await firebaseService.firestore;
  const listItems = await recipeItemToListItem(listId, recipeName, itemsToAdd, firestore);
  await PureShoppingListRepo.saveItems(listId, listItems).eval(effectsImpl);
  new ShoppingListItemAddedEvent(listId, ...listItems).emit();
  log.debug(`Items added to list ${listId}`);
};

const recipeItemToListItem: (
  listId: string,
  recipeName: string,
  recipeItems: RecipeItem[],
  db: firebase.firestore.Firestore
) => Promise<ListItem[]> = (listId, recipeName, recipeItems, db) => {
  const searchPromises = recipeItems.map(recipeItem => {
    return findOrCreateListItem(listId, recipeItem.itemName, { id: recipeItem.id, recipe: recipeName }, db);
  });

  return Promise.all(searchPromises);
};

const createShoppingList = async (listId: string, listName: string) => {
  const firestore = await firebaseService.firestore;
  await firestore
    .collection('ShoppingLists')
    .doc(listId)
    .set({
      id: listId,
      name: listName,
      showCategories: true
    });
  new ShoppingListCreatedEvent(listId, listName).emit();
};

//TODO - AC: Move this method to FP folder. For this:
// - Find a way to subscribe to events in a FP way.
// - Create effect for i18n.
const addDefaultItemsToShoppingList = async (listId: string) => {
  const lang = i18n.languages[0];
  await PureShoppingListService.addDefaultItemsToShoppingList(listId, lang).eval(effectsImpl);
};

const isItemAddedToList: (item: ListItem) => boolean = item => {
  return !!item.addedOn && (!item.completedOn || moment(item.addedOn).isAfter(moment(item.completedOn)));
};

const isItemCompleted: (item: ListItem) => boolean = item => {
  return !isItemAddedToList(item);
};

const isItemToBuyLater: (item: ListItem) => boolean = item => {
  const now = moment();
  return !!item.hideUntil && now.isBefore(moment(item.hideUntil));
};

const isItemNotToBuyLater: (item: ListItem) => boolean = item => {
  return !isItemToBuyLater(item);
};

const subscribeToShoppingListItems: (
  listId: string,
  cb: SubscriptionCallback<ListItem[]>,
  fetchingCb?: () => void
) => Unsubscribe = (listId, cb, fetchingCb) => {
  if (fetchingCb) {
    fetchingCb();
  }
  return firebaseService.firestoreSubscription(firestore => {
    if (!listId) {
      log.warn('Cant subscribe to shopping list items because there is not list.');
      return () => {
        log.warn('Cant subscribe to list items because there is not list.');
      };
    }

    return ShoppingListRepo.itemsDB(listId, firestore)
      .orderBy('addedOn', 'desc')
      .onSnapshot(snapshot => {
        log.debug(`Shopping list items Snapshot received ${snapshot.docs.length} from cache: ${snapshot.metadata.fromCache}`);
        let docs: ListItem[] = ShoppingListRepo.queryToListItems(snapshot)
          .filter(isItemAddedToList)
          .filter(isItemNotToBuyLater);
        cb(docs);
        eventBus.emit(DomainEvents.ShoppingListItemsChanged, {
          listId,
          items: docs
        });
      });
  });
};

const subscribeToShoppingListItemsForLater: (listId: string, cb: SubscriptionCallback<ListItem[]>) => Unsubscribe = (
  listId,
  cb
) => {
  return firebaseService.firestoreSubscription(firestore => {
    if (!listId) {
      log.warn('Cant subscribe to items for later because there is not list.');
      return () => {
        log.warn('There wasnt a subscription');
      };
    }
    return ShoppingListRepo.itemsDB(listId, firestore)
      .orderBy('addedOn', 'desc')
      .onSnapshot(snapshot => {
        let docs: ListItem[] = ShoppingListRepo.queryToListItems(snapshot).filter(isItemToBuyLater);
        cb(docs);
      });
  });
};

const subscribeToCompletedItems: (listId: string, cb: SubscriptionCallback<ListItem[]>) => Unsubscribe = (
  listId,
  cb
) => {
  return firebaseService.firestoreSubscription(firestore => {
    return ShoppingListRepo.itemsDB(listId, firestore)
      .orderBy('addedOn', 'desc')
      .onSnapshot(snapshot => {
        let docs: ListItem[] = ShoppingListRepo.queryToListItems(snapshot).filter(isItemCompleted);
        cb(docs);
      });
  });
};

const subscribeToAllItems: (listId: string, cb: SubscriptionCallback<ListItem[]>) => Unsubscribe = (listId, cb) => {
  return firebaseService.firestoreSubscription(firestore => {
    return ShoppingListRepo.itemsDB(listId, firestore)
      .orderBy('addedOn', 'desc')
      .onSnapshot(snapshot => {
        let docs: ListItem[] = ShoppingListRepo.queryToListItems(snapshot);
        cb(docs);
      });
  });
};

const subscribeToShoppingList: (listId: string, cb: SubscriptionCallback<ShoppingList>) => Unsubscribe = (
  listId,
  cb
) => {
  return firebaseService.firestoreSubscription(firestore => {
    if (!listId) {
      log.warn('Cant subscribe to items for later because there is not list.');
      return () => {
        log.warn('There wasnt a subscription');
      };
    }
    return firestore
      .collection('ShoppingLists')
      .doc(listId)
      .onSnapshot(snapshot => {
        if (!snapshot.exists) {
          return;
        }

        const data = snapshot.data();

        if (!data) {
          return;
        }

        const shoppingList: ShoppingList = {
          id: data.id,
          name: data.name,
          showCategories: data.showCategories,
          items: []
        };

        cb(shoppingList);
        eventBus.emit(DomainEvents.ShoppingListChanged, shoppingList);
      });
  });
};

const removeItem = (listId: string, itemId: string) => {
  firebaseService.firestore.then(db => {
    log.debug(`Removing item ${itemId} from list ${listId}`);
    ShoppingListRepo.findItemById(listId, itemId, db).then(item => {
      if (item) {
        log.debug('Existing shopping list item found');

        if (!item.completedOn) {
          // if it doesn't have completed on, it means that is brand new.
          log.debug(`Item ${itemId} its a new item and will be removed.`);
          PureShoppingListRepo.deleteItem(listId, itemId).eval(effectsImpl);
        } else {
          log.debug(`Item has history and so, it wont be removed`);
          const updatedItem = {
            ...item,
            addedOn: undefined
          };
          ShoppingListRepo.saveItem(listId, updatedItem, db);
        }
        eventBus.emit(DomainEvents.ShoppingListItemRemoved, { listId, itemId });
      }
    });
  });
};

const completeShoppingListItem: (listId: string, itemId: string) => void = (listId, itemId) => {
  firebaseService.firestore.then(firebase => {
    ShoppingListRepo.findItemById(listId, itemId, firebase)
      .then(item => {
        if (item) {
          log.debug('Existing shopping list item found');
          const updatedItem = {
            ...item,
            completedOn: new Date()
          };
          ShoppingListRepo.saveItem(listId, updatedItem, firebase);
          return updatedItem;
        } else {
          return undefined;
        }
      })
      .then(completedItem => {
        if (completedItem) {
          new ShoppingListItemCompletedEvent(listId, completedItem).emit();
        }
      });
  });
};

const buyItemLater = (listId: string, itemId: string) => {
  const hideUntil = moment()
    .add(2, 'hours')
    .toDate();
  firebaseService.firestore.then(firestore => {
    ShoppingListRepo.itemDB(listId, itemId, firestore).update({ hideUntil });
  });
};

const removeRecipeTagFromItems = (listId: string, name: string) => {
  firebaseService.firestore.then(firestore => {
    log.debug(`Removing tag ${name} from list ${listId}`);
    ShoppingListRepo.findItemsByRecipeTag(listId, name, firestore)
      .then(items => {
        return items.map(item => {
          const currentRecipes = item.tags.Recipe;
          item.tags.Recipe = listUtils.removeItem(currentRecipes, name);
          return item;
        });
      })
      .then(updatedItems => {
        log.debug(`Found ${updatedItems.length} items for recipe ${name}`);
        PureShoppingListRepo.saveItems(listId, updatedItems).eval(effectsImpl);
      });
  });
};

const removeRecipeTagFromItem = (listId: string, itemId: string, recipeName: string) => {
  firebaseService.firestore.then(firestore => {
    log.debug(`Removing tag ${recipeName} from itemId ${itemId} on list ${listId}`);
    ShoppingListRepo.findItemById(listId, itemId, firestore)
      .then(foundItem => {
        if (!foundItem) {
          log.debug(`Item ${itemId} not found on list ${listId}. Can't remove recipe tag`);
          return;
        }
        const currentRecipes = foundItem.tags.Recipe;
        foundItem.tags.Recipe = listUtils.removeItem(currentRecipes, recipeName);
        return foundItem;
      })
      .then(updatedItem => {
        if (updatedItem) {
          log.debug(`Updating item ${updatedItem.name}: Removed recipe: ${recipeName}`);
          ShoppingListRepo.saveItem(listId, updatedItem, firestore);
        }
      });
  });
};

const addRecipeTagToItem = async (listId: string, recipeItem: RecipeItem, recipeName: string) => {
  const firestore = await firebaseService.firestore;
  log.debug(`Adding recipe ${recipeName} to item ${recipeItem.itemName}`);
  const foundItem = await ShoppingListRepo.findItemById(listId, recipeItem.id, firestore);
  if (!foundItem) {
    log.debug(`Item ${recipeItem.id}:${recipeItem.itemName} not found in list ${listId}`);
    return;
  }

  const newRecipes = foundItem.tags.Recipe || [];
  newRecipes.push(recipeName);

  ShoppingListRepo.itemDB(listId, foundItem.id, firestore).update({
    tags: {
      ...foundItem.tags,
      Recipe: removeEmpty(dedupe(newRecipes))
    }
  });
};

const updateItemCategories = (listId: string, itemId: string, categories: string[]) => {
  firebaseService.firestore.then(firestore => {
    log.debug(`Update item categoreis: ${categories}`);
    ShoppingListRepo.findItemById(listId, itemId, firestore).then(foundItem => {
      if (!foundItem) {
        return;
      }
      ShoppingListRepo.itemDB(listId, itemId, firestore).update({
        tags: {
          ...foundItem.tags,
          Category: removeEmpty(dedupe(categories))
        }
      });
    });
  });
};

const renameItem = async (listId: string, itemId: string, newName: string) => {
  const firestore = await firebaseService.firestore;
  if (!newName) {
    return;
  }
  log.debug(`Rename item: ${listId}-${itemId}`);
  await ShoppingListRepo.itemDB(listId, itemId, firestore).set({
    name: newName.trim(),
    name_idx: nameIdx(newName)
  }, { merge: true });
  const foundItem = await ShoppingListRepo.findItemById(listId, itemId, firestore);
  if (foundItem) {
    log.debug(`Updated item found ${foundItem.name}`);
    new ShoppingListItemRenamedEvent(listId, foundItem).emit();
  } else {
    log.warn(`Couldn't find recently updated item ${itemId} in ist ${listId}`);
  }
};

const addLaterItemToList = (listId: string, itemId: string) => {
  firebaseService.firestore.then(firestore => {
    ShoppingListRepo.itemDB(listId, itemId, firestore).update({ hideUntil: null });
  });
};

const renameList = (listId: string, name: string) => {
  firebaseService.firestore.then(firestore => {
    firestore
      .collection('ShoppingLists')
      .doc(listId)
      .update({ name });
  });
};

const showCategories = (listId: string, show: boolean) => {
  firebaseService.firestore.then(firestore => {
    firestore
      .collection('ShoppingLists')
      .doc(listId)
      .update({
        showCategories: show
      });
  });
};

// Private function
const findOrCreateListItem: (
  listId: string, name: string, defaults: { id?: string; recipe?: string; }, db: firebase.firestore.Firestore
) => Promise<ListItem> = async (listId, name, defaults, db) => {

  const foundItem = await ShoppingListRepo.findItemByName(listId, name, db);
  if (!foundItem) {
    log.debug(`Item ${name} does not exist in list ${listId}, will create a new one`);
    return ShoppingListRepo.newItem(name, defaults);

  } else {
    log.debug(`Item ${name} found in list ${listId}, will update`);
    return {
      ...foundItem,
      addedOn: new Date(),
      hideUntil: undefined
    };
  }
};

const addFromRecentItems = (listId: string, item: RecentItem) => {
  firebaseService.firestore.then(firestore => {
    log.debug(`Adding item ${item.itemName} to list ${listId} from recent items`);
    findOrCreateListItem(listId, item.itemName, {}, firestore).then(updatedItem => {
      ShoppingListRepo.saveItem(listId, updatedItem, firestore);
    });
  });
};

const addShoppingListItem = async (listId: string, name: string, recipe?: string) => {
  log.debug('Add shopping list item...');
  const firestore = await firebaseService.firestore;
  const updatedItem = await findOrCreateListItem(listId, name, { recipe }, firestore);
  await ShoppingListRepo.saveItem(listId, updatedItem, firestore);
  new ShoppingListItemAddedEvent(listId, updatedItem).emit();
};

const saveShoppingListItems: (listId: string, items: ListItem[]) => Promise<void> =
  async (listId, items) => {
    await PureShoppingListRepo.saveItems(listId, items).eval(effectsImpl);
  };

/**
 * API to be exposed outside the domain
 */
export const ShoppingListDomainApi = {
  subscribeToShoppingListItems,
  subscribeToShoppingListItemsForLater,
  subscribeToCompletedItems,
  subscribeToAllItems,
  subscribeToShoppingList,
  removeItem,
  renameList,
  buyItemLater,
  addLaterItemToList,
  showCategories,
  updateItemCategories,
  completeShoppingListItem,
  renameItem,
  addShoppingListItem
};

/**
 * API to be used by domain classes
 */
export default {
  initialize,
  saveShoppingListItems,
};
