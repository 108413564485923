import { dedupeBy } from '../../utils/ListUtils';
import { Recipe } from '../../domain/recipes/Types';

export const updateRecipeFilters = (filters: Recipe[], newFilter: Recipe, add: boolean) => {
  let newRecipes = filters || [];
  if (add) {
    newRecipes.push(newFilter);
  } else {
    newRecipes = newRecipes.filter(filter => filter.id !== newFilter.id);
  }

  return dedupeBy(newRecipes, thisRecipe => thisRecipe.id);
};

export const removeRecipeFiltersNotInRecipeList = (filters: Recipe[], recipes: Recipe[]) => {
  return filters.filter(filter => recipes.map(recipe => recipe.id).indexOf(filter.id) >= 0);
};
