import { firebaseService } from '../../utils/firebase/FirestoreConfig';

const getServerVersion: () => Promise<string> = async () => {
  const firestore = await firebaseService.ensureNetwork;
  const ref = firestore.collection('Version');
  const snapshot = await ref.get();
  const docs = snapshot.docs
    .map(doc => doc.data())
    .map(data => ({
      version: data.version,
      date: data.date.toDate()
    })).sort((v1, v2) => v2.date - v1.date);
  if (docs && docs.length > 0) {
    return docs[0].version;
  }

  return 'error';
};

const addServerVersionId = (version: string) => {
  firebaseService.firestore.then(firestore => {
    const ref = firestore.collection('Version').doc(replaceAll(version, '.', '-'));
    ref.set({
      version,
      date: new Date()
    });
  });
};

const replaceAll = (str: string, find: string, replace: string) => str.split(find).join(replace);

export default {};

export const VersionDomainApi = {
  getServerVersion,
  addServerVersionId
};
