import { Action } from 'redux';
import {
  AddItemTextChanged,
  SuggestionsUpdatedAction
} from './Actions';
import {
  autoCompleteReducer,
  AutoCompleteState,
  emptyAutoCompleteState
} from '../../../utils/components/autocomplete/Reducers';
import { SelectUserListAction } from '../../../utils/actions/Actions';
import { ShoppingListChangedAction } from '../currentshoppinglist/Actions';

export type AddItemButtonState = Readonly<{
  addItemBoxText: string;
  matchingSuggestion: string | null;
  shoppingListId: string;
  autoComplete: AutoCompleteState;
  suggestions: string[];
}>;

const emptySearchBarState = {
  addItemBoxText: '',
  matchingSuggestion: null,
  shoppingListId: '',
  autoComplete: emptyAutoCompleteState,
  suggestions: []
};

export const getAutoCompleteState = (state: AddItemButtonState) => state.autoComplete;

export const addItemButtonReducer: (state: AddItemButtonState | undefined, action: Action) => AddItemButtonState = (
  state = emptySearchBarState,
  action
) => {
  switch (action.type) {
    case 'AddItemAction':
      return {
        ...state,
        addItemBoxText: '',
        matchingSuggestion: null
      };
    case 'AddMoreItemsAction':
      return {
        ...state,
        addItemBoxText: '',
        matchingSuggestion: null
      };
    case 'AddItemTextChanged':
      const searchBoxChanged = action as AddItemTextChanged;
      const addItemBoxText = searchBoxChanged.payload;
      const currentActiveSgstns = state.autoComplete.activeSuggestions;
      let matchingSuggestion = null;
      
      if (currentActiveSgstns && currentActiveSgstns.length === 1) {
        matchingSuggestion = currentActiveSgstns[0];
      }

      return { ...state, addItemBoxText, matchingSuggestion };

    case 'ShoppingListChangedAction':
      const fetchAction = action as ShoppingListChangedAction;
      return { ...state, shoppingListId: fetchAction.payload.id };

    case 'SuggestionsUpdatedAction':
      const fetchSuggestionAction = action as SuggestionsUpdatedAction;
      return {
        ...state,
        suggestions: fetchSuggestionAction.payload.items.map(item => item.name),
        shoppingListId: fetchSuggestionAction.payload.listId
      };

    case 'SelectUserListAction':
      const selectListAction = action as SelectUserListAction;
      if (selectListAction.payload.listId !== state.shoppingListId) {
        return { ...state, shoppingListId: selectListAction.payload.listId };
      }
      return state;

    case 'CancelItemAction':
      return {
        ...state,
        addItemBoxText: '',
        matchingSuggestion: null
      };

    default:
      return { ...state, autoComplete: autoCompleteReducer('addItem')(state.autoComplete, action) };
  }
};
