import { Action } from 'redux';
import { AddListTextChanged, NewListAction, CleanNewListAction } from './Actions';
import { actionInProgressType, actionSuccessType } from '../../../utils/Actions';
import { logger } from '../../../utils/LoggingUtils';

const log = logger('AddListDrawerReducer');

type ListCreationState = 'New' | 'InProgress' | 'Created';

export type AddListDrawerState = Readonly<{
  addListBoxText: string;
  creationState: ListCreationState;
  newListId: string;
}>;

const emptyAddListState: AddListDrawerState = {
  addListBoxText: '',
  creationState: 'New',
  newListId: ''
};

export const addListDrawerReducer: (state: AddListDrawerState | undefined, action: Action) => AddListDrawerState = (
  state = emptyAddListState,
  action
) => {
  switch (action.type) {
    case AddListTextChanged.TYPE:
      const searchBoxChanged = action as AddListTextChanged;
      return { ...state, addListBoxText: searchBoxChanged.payload };
    case CleanNewListAction.TYPE: 
      return emptyAddListState;
    case actionInProgressType('NewListAction'):
      return { ...state, creationState: 'InProgress' };
    case actionSuccessType('NewListAction'):
      const newListAction = action as NewListAction;
      if (!newListAction.response) {
        log.warn('Didn`t get an Id after newListAction');
        return emptyAddListState;
      }
      return { ...emptyAddListState, creationState: 'Created', newListId: newListAction.response};
    default:
      return state;
  }
};
