import { formatPath, navigate } from '../../../utils/RouterUtils';
import { LISTS_PATH } from './ShoppingListRouter';

export const ShoppingListsNavActions = {
  openMenu: (open: boolean) => navigate(formatPath(LISTS_PATH, 'menu', open)),
  showRecentItems: (open: boolean) => navigate(formatPath(LISTS_PATH, 'recent-items', open)),
  showItemsForLater: (open: boolean) => navigate(formatPath(LISTS_PATH, 'items-for-later', open)),
  showListCategories: (open: boolean) => navigate(formatPath(LISTS_PATH, 'list-categories', open)),
  showNewCategoryDialog: (open: boolean) => navigate(formatPath(`${LISTS_PATH}/list-categories`, 'new-category', open)),
  showItemCategoriesDialog: (open: boolean, selectedItem: string) =>
    navigate(formatPath(LISTS_PATH, `item-categories/${selectedItem}`, open), true),
  showRenameItemDialog: (open: boolean, selectedItem: string) =>
    navigate(formatPath(LISTS_PATH, `rename-item/${selectedItem}`, open), true),
  showShareListDrawer: (open: boolean) => navigate(formatPath(LISTS_PATH, 'share-list', open)),
  showRenameListDrawer: (open: boolean) => navigate(formatPath(LISTS_PATH, 'rename-list', open)),
  showNotificationListDrawer: (open: boolean) => navigate(formatPath(LISTS_PATH, 'notifications', open)),
  showAboutDialog: (open: boolean) => navigate(formatPath(LISTS_PATH, 'about', open)),
  showVersionUpdateDialog: (open: boolean) => navigate(formatPath(LISTS_PATH, 'version-update', open)),
  showAddListForm: (open: boolean) => navigate(formatPath(`${LISTS_PATH}/menu`, 'add-list', open)),
  showRecipesFilter: (open: boolean) => navigate(formatPath(LISTS_PATH, 'filter-recipes', open)),
  openAddListItemDialog: (open: boolean) =>
    navigate(formatPath(LISTS_PATH, 'add-list-item', open)),
  gotoShoppingListPage: () => navigate(LISTS_PATH)
};
