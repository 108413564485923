import { IO, _do } from '../../../utils/fp/io'
import { ListCategories } from '../Types';
const path = ['ListCategories'];
const updateListCategories: (listId: string, categories: string[]) => IO<void> =
    (listId, categories) => {
        return new IO(
            effects => effects.firestore.updateDocument(path, listId, { categories })
        );
    }

const fetchListCategories: (listId: string) => IO<ListCategories> =
    _do(function* (listId) {
        const emptyListCategory = {
            listId,
            categories: []
        };
        const foundDoc = yield new IO(effects => effects.firestore.findDocument(path, listId));
        if (!foundDoc) {
            return emptyListCategory
        }

        emptyListCategory.categories = foundDoc.categories;

        return emptyListCategory;
    });

const saveListCategories: (listId: string, categories: string[]) => IO<void> =
    (listId, categories) => new IO(effects => effects.firestore.saveDocument(path, listId, { listId, categories }));

export const ListCategoriesRepo = {
    saveListCategories,
    updateListCategories,
    fetchListCategories
}