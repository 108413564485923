import * as React from 'react';
import { StyleRules, withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { Theme, WithStyles } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import AddIcon from '@material-ui/icons/Add';
import { TopBarProps } from './Container';
import * as log from 'loglevel';

const style: (theme: Theme) => StyleRules = theme => ({
  flex: {
    flex: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 5
  },
  recentItems: {
    paddingRight: 5
  },
  appBar: {
    backgroundColor: theme.palette.background.paper
  },
  titleBar: {
    backgroundColor: theme.palette.primary.main
  },
  subtitleBar: {
    color: theme.palette.text.primary
  },
  shoppingListTitle: {
    marginLeft: 'auto'
  },
  shoppingListValue: {
    marginLeft: '10px',
    marginRight: '5px'
  }
});

type TopBarComponentProps = TopBarProps & WithStyles;

class TopAppBarStyled extends React.Component<TopBarComponentProps> {
  openMainMenu = () => this.props.openMenu(true);
  goToNewRecipe = () => {
    log.debug('Open recipes...');
    this.props.openRecipeDetails(true, 'new');
  };
  goToLists = () => this.props.goToListsPage();

  render() {
    const { t, classes } = this.props;

    return (
      <React.Fragment>
        <AppBar position="sticky" className={classes.appBar}>
          <Toolbar className={classes.titleBar}>
            <IconButton
              className={classes.menuButton}
              color="inherit"
              aria-label="Menu"
              onClick={this.openMainMenu}
              data-testid="recipes-main-menu">
              <MenuIcon />
            </IconButton>
            <Typography variant="subtitle1" color="inherit" className={classes.flex} data-testid="recipes-header">
              {t('Recipe ingredients')}
            </Typography>
            <div onClick={this.goToNewRecipe}>
              <AddIcon />
            </div>
          </Toolbar>
          <Toolbar className={classes.subtitleBar}>
            <Typography
              variant="subtitle2"
              color="inherit"
              data-testid="recipes-subheader"
              className={classes.shoppingListTitle}>
              {t('Selected list')}:
            </Typography>
            <Typography
              variant="subtitle1"
              color="secondary"
              data-testid="recipes-subheader"
              className={classes.shoppingListValue}
              onClick={this.goToLists}>
              {this.props.shoppingListName}
            </Typography>
          </Toolbar>
        </AppBar>
      </React.Fragment>
    );
  }
}

export const TopBarComponent = withStyles(style)(TopAppBarStyled);
