import { logger } from '../LoggingUtils';

export type Callback<T> = (...args: T[]) => void;

export type ListenerMap = Map<Callback<any>, boolean>;

export type EventMap = { [name: string]: ListenerMap };

const log = logger('EventBus');

class EventBus {
  events: EventMap = {};

  constructor() {
    log.debug('Event bus initialized');
  }

  on<T>(name: string, callback: Callback<T>) {
    this.events[name] = this.events[name] || new Map();
    this.events[name].set(callback, false);

    return this;
  }

  once<T>(name: string, callback: Callback<T>) {
    this.events[name] = this.events[name] || new Map();
    this.events[name].set(callback, true);

    return this;
  }

  off<T>(name: string, callback?: Callback<T>) {
    const event = this.events[name];

    if (event) {
      if (callback) {
        event.delete(callback);
      } else {
        delete this.events[name];
      }
    }

    return this;
  }

  emit<T>(name: string, ...args: T[]) {
    const event = this.events[name];
    if (event) {
      event.forEach((once, callback) => {
        setTimeout(() => {
          callback(...args);
          if (once) {
            event.delete(callback);
          }
        }, 1);
      });
    }

    return this;
  }
}

export const eventBus = new EventBus();
