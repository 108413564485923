import * as React from 'react';
import { State } from '../../../reducer';
import { connect } from 'react-redux';
import { TopBarComponent } from './Components';
import { RouteComponentProps } from 'react-router-dom';
import { RecipesPageProps } from '../RecipesPage';
import { TopBarState } from './Reducers';
import { getTopBarState } from '../RecipesPageReducer';

export type TopBarProps = RecipesPageProps & TopBarState;

const mapDispatchToProps = {};

const mapStateToProps = (state: State, props: RouteComponentProps<{}>) => {
  return getTopBarState(state);
};

class TopBarContainer extends React.Component<TopBarProps> {
  render() {
    const props = this.props;
    return <TopBarComponent {...props} />;
  }
}

export const TopBar = connect(mapStateToProps, mapDispatchToProps)(TopBarContainer);
