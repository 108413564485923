import { RecentItemsList, emptyRecentItemsList, RecentItem } from './Types';
import { Action } from 'redux';
import { RecentItemsChanged, RecentItemsListChanged } from './Actions';
import { sortItems, calculateDefaultBuyPeriod } from './RecentItemsSortingService';
import { logger } from '../../../utils/LoggingUtils';
import { Duration } from 'moment';
import { lastDate } from '../../../utils/TimeUtils';

const log = logger('RecentItems.reducer');

export type RecentItemsDrawerState = Readonly<{
  recentItemsList: RecentItemsList;
  loading: boolean;
}>;

const emptyRecentItemsState = {
  recentItemsList: emptyRecentItemsList,
  loading: false
};

const updateBuyPeriod: (items: RecentItem[]) => RecentItem[] = (items) => {
  return items.map(item => {
    const buyPeriod: Duration | undefined = calculateDefaultBuyPeriod(item.completedOn);
    return { ...item, frequency: buyPeriod, lastAddedDate: lastDate(item.addedOn), lastCompletedDate: lastDate(item.completedOn) };
  });
};

const sortRecentItemsList: (recentItemsList: RecentItemsList) => RecentItemsList = (recentItemsList) => {
  const unsortedItems: RecentItem[] = recentItemsList.items;
  const sortType: string = recentItemsList.sortType;

  return { ...recentItemsList, items: sortItems(unsortedItems, sortType) }
};

export const recentItemsDrawerReducer: (state: RecentItemsDrawerState | undefined, action: Action) => RecentItemsDrawerState = (
  state = emptyRecentItemsState,
  action
) => {
  switch (action.type) {
    case 'RecentItemsChanged': { // TODO: Update action to ChangeRecentItemsCommand
      const changeRecentItemsCommand = action as RecentItemsChanged;
      log.debug(`Recent items changed... Payload size: ${changeRecentItemsCommand.payload.length}`);

      log.debug(`Calculating frequency for ${changeRecentItemsCommand.payload.length} items`);
      const updatedItems = updateBuyPeriod(changeRecentItemsCommand.payload);
      log.debug(`Done`);

      const newRecentItemsList: RecentItemsList = {
        ...state.recentItemsList,
        items: updatedItems
      }
      log.debug(`Sorting recent items...`);
      const newState = { ...state, recentItemsList: sortRecentItemsList(newRecentItemsList) };
      log.debug(`Done`);
      return newState;
    }

    case 'RecentItemsListChanged': { // TODO: Update command name to ChangeSortTypeCommand
      const changeSortTypeCommand = action as RecentItemsListChanged;
      log.debug(`Recent items sort type changed... ${changeSortTypeCommand.payload.sortType}`);

      const newRecentItemsList: RecentItemsList = {
        ...state.recentItemsList,
        sortType: changeSortTypeCommand.payload.sortType
      }

      log.debug(`Sorting recent items...`);
      const newState = { ...state, recentItemsList: sortRecentItemsList(newRecentItemsList) };
      log.debug(`Done`);

      return newState;
    }

    default:
      return state;
  }
};
