import { newSuggestionsRequested, suggestionClearRequested } from './Actions';
import { AutoCompleteState } from './Reducers';
import { State } from '../../../reducer';
import { connect } from 'react-redux';
import { AutoCompleteComponent } from './Component';

export type AutoCompleteProps = {
  name: string;
  onTextChange: (val: string) => void;
  searchText: string;
  getState?: (state: State) => AutoCompleteState;
  suggestions: string[];
  onSuggestionsRequested?: (searchText: string) => void;
  onSuggestionSelected?: (suggestionVal: string) => void;
  inputProps: {
    [key: string]: {};
  };
  requestFocusWhenShown?: boolean;
  inputRef?: (input?: HTMLInputElement) => void;
  openDownwards?: boolean;
  showSuggestionsOnFocus?: boolean;
};

const mapDispatchToProps = {
  newSuggestionsRequested,
  suggestionClearRequested
};

const mapStateToProps = (state: State, props: AutoCompleteProps) => {
  return props.getState ? props.getState(state) : {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AutoCompleteComponent);
