import { Action, combineReducers } from 'redux';
import { actionSuccessType } from '../../utils/Actions';
import { State, getAdminPageState } from '../../reducer';
import { UsersListState, usersListReducer } from './users/Reducer';
import { FetchIsAdmin } from './AdminPageActions';

export type AllUsersState = Readonly<{
  isAdmin: boolean;
}>;

const emptyAllUsersState = {
  isAdmin: false
};

const allUsersReducer: (state: AllUsersState | undefined, action: Action) => AllUsersState = (
  state = emptyAllUsersState,
  action
) => {
  switch (action.type) {
    case actionSuccessType(FetchIsAdmin.TYPE): {
      const myAction = action as FetchIsAdmin;
      return { ...state, isAdmin: myAction.response || false }
    }
    default:
      return state;
  }
};

export const getAllUsersState: (state: State) => AllUsersState = state =>
  getAdminPageState(state).allUsersState;

export const getUsersListState: (state: State) => UsersListState = state =>
  getAdminPageState(state).usersListState

export const adminPageReducer = combineReducers({
  allUsersState: allUsersReducer,
  usersListState: usersListReducer,
});

export type AdminPageState = {
  allUsersState: AllUsersState;
  usersListState: UsersListState;
}