import { connect } from 'react-redux';
import { State } from '../../../reducer';
import { RecipeDetailsActions } from './Actions';
import * as React from 'react';
import { RecipesPageProps } from '../RecipesPage';
import { Unsubscribe } from '../../../domain/SubsciptionTypes';
import { getPathParam } from '../../../utils/RouterUtils';
import { RECIPE_DETAILS_PATH } from '../router/RecipesRouter';
import { domainApi } from '../../../domain/DomainApi';
import { RecipeDetailsState } from './Reducers';
import { getRecipeDetailsState, getRecipesNavState } from '../RecipesPageReducer';
import { RecipeDetailsDrawerComponent } from './Component';
import { logger } from '../../../utils/LoggingUtils';
import { ActionProps } from '../../../utils/Actions';

const log = logger('RecipeDetails.container');

export type RecipeDetailsProps = 
  RecipesPageProps & 
  RecipeDetailsState & 
  ActionProps<typeof RecipeDetailsActions>;

class RecipeDetailsDrawerContainer extends React.Component<RecipeDetailsProps> {
  unsuscribe?: Unsubscribe;

  componentDidMount() {
    this.suscribeToRecipeDetailsChanges();
  }

  componentDidUpdate(prev: RecipeDetailsProps) {
    const recipeId = getPathParam(this.props.location.pathname, RECIPE_DETAILS_PATH, 'selectedRecipe');
    const prevRecipeId = getPathParam(prev.location.pathname, RECIPE_DETAILS_PATH, 'selectedRecipe');
    // Subscribe only if the recipe is different, otherwise don't bother
    if (prevRecipeId !== recipeId) {
      log.debug(`prev: ${prevRecipeId} ; curr ${recipeId}`);
      this.unsuscribeFromDetails();
      this.suscribeToRecipeDetailsChanges();
    }
  }

  componentWillUnmount() {
    this.unsuscribeFromDetails();
  }

  render() {
    return <RecipeDetailsDrawerComponent {...this.props} />;
  }

  private suscribeToRecipeDetailsChanges() {
    const recipeId = getPathParam(this.props.location.pathname, RECIPE_DETAILS_PATH, 'selectedRecipe');
    if (recipeId && recipeId !== 'new') {
      log.debug(`Subscribing to recipe details ${recipeId}`);

      const detailsUnsub = domainApi.subscribeToRecipeDetails(
        this.props.selectedList,
        recipeId,
        this.props.onRecipeDetailsChanged
      );

      this.unsuscribe = () => {
        detailsUnsub();
      };
    }
  }

  private unsuscribeFromDetails() {
    if (this.unsuscribe) {
      log.debug('Unsubscribing from recipe details');
      this.unsuscribe();
      this.unsuscribe = undefined;
    }
  }
}

const mapDispatchToProps = {
  ...RecipeDetailsActions
};

const mapStateToProps = (state: State) => {
  return {
    ...getRecipeDetailsState(state),
    ...getRecipesNavState(state) // TODO-AC: Why do I have to do this instead of passing it from above?
  };
};

export const RecipeDetailsDrawer = connect(mapStateToProps, mapDispatchToProps)(RecipeDetailsDrawerContainer);
