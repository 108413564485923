import RecentItemsService, { RecentItemsDomainApi } from './recentitems/RecentItemsService';
import ShoppingListService, { ShoppingListDomainApi } from './shoppinglist/ShoppingListService';
import { UserListsDomainApi, default as UserListsService } from './userlists/UserListsService';
import UserDetailsService, { UserDetailsDomainApi } from './userdetals/UserDetailsService';
import { VersionDomainApi } from './version/VersionService';
import ListCategoriesService, { ListCategoriesDomainApi } from './listcategories/ListCategoriesService';
import UserNotificationsService, { UserNotificationsDomainApi } from './notifications/UserNotificationsService';
import RecipesService, { RecipesDomainApi } from './recipes/RecipesService';
import PermissionsService, { PermissionsDomainApi } from './permissions/PermissionsService';
import { AdminApi } from './admin/AdminService';

UserListsService.initialize();
RecentItemsService.initialize();
ShoppingListService.initialize();
ListCategoriesService.initialize();
UserNotificationsService.initialize();
RecipesService.initialize();
PermissionsService.initialize();
UserDetailsService.initialize();

export const domainApi = {
  ...VersionDomainApi,
  ...UserListsDomainApi,
  ...ShoppingListDomainApi,
  ...RecentItemsDomainApi,
  ...UserDetailsDomainApi,
  ...ListCategoriesDomainApi,
  ...UserNotificationsDomainApi,
  ...RecipesDomainApi,
  ...PermissionsDomainApi,
  ...AdminApi
};
