// TEST
// Platform: IPhone
// version: 5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X)
export const getIOSversion: (platform: string, appVersion: string) => Number[] =
  (platform, appVersion) => {
    if (/iP(hone|od|ad)/.test(platform)) {
      // supports iOS 2.0 and later: <http://bit.ly/TJjs1V>
      const v = (appVersion || '').match(/OS (\d+)_(\d+)_?(\d+)?/);
      if (v) {
        return [parseInt(v[1] || '0', 10), parseInt(v[2] || '0', 10), parseInt(v[3] || '0', 10)];
      }
    }

    return [];
  }

export const isValidVersion: (version: Number[]) => Boolean
  = (version) => {
    return version[0] > 12 || 
    (version[0] == 12 && version[1] >= 2);
  }

export const isInStandaloneMode = () => ('standalone' in navigator) && (navigator['standalone']);

export const getInstallType: (fromMobile: boolean) => 'Pwa' | 'Android' | 'Web' =
  (fromMobile) => {
    if (fromMobile) {
      return 'Android';
    }
    return NavUtils.isPwaInstalled() ? 'Pwa' : 'Web';
  }

export const NavUtils = {
  isIOS: () => {
    return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
  },
  isValidIOSVersion: () => {
    const version = getIOSversion(navigator.platform, navigator.appVersion);
    return isValidVersion(version);
  },
  isPwaInstalled: isInStandaloneMode,
  getVersion: () => {
    const version = getIOSversion(navigator.platform, navigator.appVersion);
    if (version && version.length > 0) {
      return version.join('.');
    }
    return 'android';
  },
  getResolution: () => {
    if (window.screen) {
      return `${window.screen.width}x${window.screen.height}`;
    }
    return '';
  },
  getInstallType
};
