import { combineReducers } from 'redux';
import { itemsForLaterDrawerReducer, ItemsForLaterDrawerState } from './itemsforlaterdrawer/Reducers';
import {
  addItemButtonReducer,
  AddItemButtonState,
  getAutoCompleteState as getItemAutoCompleteState
} from './additembutton/Reducers';
import { recentItemsDrawerReducer, RecentItemsDrawerState } from './recentitemsdrawer/Reducers';
import { renameListDrawerReducer, RenameListDrawerState } from './renamelistdrawer/Reducers';
import { topBarReducer, TopBarState } from './topbar/Reducers';
import { listCategoriesDrawerReducer, ListCategoriesDrawerState } from './listcategoriesdrawer/Reducers';
import { shareListDrawerReducer, ShareListDrawerState } from './sharelistdrawer/Reducers';
import { getCategoriesAutoCompleteState, shoppingListReducer, ShoppingListState } from './currentshoppinglist/Reducers';
import { addListDrawerReducer, AddListDrawerState } from './addlistdrawer/Reducers';
import { getShoppingListsState, State } from '../../reducer';
import { AutoCompleteState } from '../../utils/components/autocomplete/Reducers';
import { ShoppingListsNavigationState, shoppingListsNavReducer } from './router/NavigationReducer';
import { RecipesFilterDrawerState, recipesFilterReducer } from './recipesfilterdrawer/Reducers';

export const shoppingListsReducer = combineReducers({
  addItemButtonState: addItemButtonReducer,
  topBarState: topBarReducer,
  shoppingListState: shoppingListReducer,
  itemsForLaterState: itemsForLaterDrawerReducer,
  addListDrawerState: addListDrawerReducer,
  recentItemsDrawerState: recentItemsDrawerReducer,
  shareListDrawerState: shareListDrawerReducer,
  renameListDrawerState: renameListDrawerReducer,
  listCategoriesDrawerState: listCategoriesDrawerReducer,
  shoppingListNavigationState: shoppingListsNavReducer,
  recipesFilterDrawerState: recipesFilterReducer
});

export type ShoppingListsState = {
  addItemButtonState: AddItemButtonState;
  topBarState: TopBarState;
  shoppingListState: ShoppingListState;
  itemsForLaterState: ItemsForLaterDrawerState;
  addListDrawerState: AddListDrawerState;
  recentItemsDrawerState: RecentItemsDrawerState;
  shareListDrawerState: ShareListDrawerState;
  renameListDrawerState: RenameListDrawerState;
  listCategoriesDrawerState: ListCategoriesDrawerState;
  shoppingListNavigationState: ShoppingListsNavigationState;
  recipesFilterDrawerState: RecipesFilterDrawerState;
};

export const getShoppingListNavState: (state: State) => ShoppingListsNavigationState = state =>
  getShoppingListsState(state).shoppingListNavigationState;

export const getTopBarState: (state: State) => TopBarState = state => getShoppingListsState(state).topBarState;

export const getShoppingListState: (state: State) => ShoppingListState = state =>
  getShoppingListsState(state).shoppingListState;

export const getAddItemButtonState: (state: State) => AddItemButtonState = state =>
  getShoppingListsState(state).addItemButtonState;

export const getAddListDrawerState: (state: State) => AddListDrawerState = state =>
  getShoppingListsState(state).addListDrawerState;

export const getShareListDrawerState: (state: State) => ShareListDrawerState = state =>
  getShoppingListsState(state).shareListDrawerState;

export const getRenameListDrawerState: (state: State) => RenameListDrawerState = state =>
  getShoppingListsState(state).renameListDrawerState;

export const getAddItemAutoCompleteState: (state: State) => AutoCompleteState = state =>
  getItemAutoCompleteState(getShoppingListsState(state).addItemButtonState);

export const getItemCategoriesAutoCompleteState: (state: State) => AutoCompleteState = state =>
  getCategoriesAutoCompleteState(getShoppingListsState(state).shoppingListState);

export const getRecentItemsDrawerState: (state: State) => RecentItemsDrawerState = state =>
  getShoppingListsState(state).recentItemsDrawerState;

export const getItemsForLaterDrawerState: (state: State) => ItemsForLaterDrawerState = state =>
  getShoppingListsState(state).itemsForLaterState;

export const getListCategoriesDrawerState: (state: State) => ListCategoriesDrawerState = state =>
  getShoppingListsState(state).listCategoriesDrawerState;

export const getRecipesFilterDrawerState: (state: State) => RecipesFilterDrawerState = state =>
  getShoppingListsState(state).recipesFilterDrawerState;
