import * as React from 'react';
import * as log from 'loglevel';
import { CardContent, Theme, WithStyles } from '@material-ui/core';
import { StyleRules, withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import { StyledComponentProps } from '@material-ui/core/styles/withStyles';

const style: (theme: Theme) => StyleRules = theme => ({
  root: {
    height: '100%',
  },
  card: {
    textAlign: 'center',
    height: '100%',
    width: '100%',
  },
  cardContent: {
    paddingTop: 30
  },
  trolley: {
    fontSize: '3em'
  },
  controls: {
    bottom: theme.spacing(2),
    top: 'auto',
    left: 'auto',
    position: 'fixed',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: theme.spacing(),
    paddingBottom: theme.spacing()
  },
  button: {
    width: 300,
    backgroundColor: theme.palette.primary.main
  }
});

class OfflineStyled extends React.Component<WithStyles> {
  render() {
    const props = this.props;
    log.debug('LOGIN PAGE');
    const { classes } = props;

    return (
      <div className={`${classes.root} landing`}>
        <Card className={`${classes.card}`} raised={false}>
          <CardContent className={classes.cardContent}>
            <h1 className={classes.trolley}>Trolley</h1>
            <div>Trolley can't work on your version of IOS</div>
            <div>Please upgrade to the latest version and try again</div>
          </CardContent>
        </Card>
      </div>
    );
  }
}

const UnsupportedOSComponent: React.ComponentType<StyledComponentProps<'default'>> = withStyles(style)(OfflineStyled);

export class UnsupportedOSContainer extends React.Component<{}> {
  render() {
    return <UnsupportedOSComponent {...this.props} />;
  }
}
