import { State } from '../../../reducer';
import { connect } from 'react-redux';
import { RenameListDrawerComponent } from './Component';
import { RenameListDrawerActions } from './Actions';
import { getRenameListDrawerState } from '../ShoppingListsPageReducer';

const mapDispatchToProps = {
  ...RenameListDrawerActions
};

const mapStateToProps = (state: State) => {
  return getRenameListDrawerState(state);
};

export const RenameListDrawer = connect(mapStateToProps, mapDispatchToProps)(RenameListDrawerComponent);
