import * as React from 'react';
import { StyleRules, withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import { Theme, WithStyles } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import ChevronIcon from '@material-ui/icons/ChevronLeft';
import EditIcon from '@material-ui/icons/Edit';
import { RecipeDetailsProps } from './Container';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Loading from '../../../utils/components/loading/Loading';
import { RECIPE_DETAILS_PATH } from '../router/RecipesRouter';
import { getPathParam } from '../../../utils/RouterUtils';
import { waitMs } from '../../../utils/PromiseDelay';
import { RecipeTitleDialog } from './RecipeTitleDialog';
import { AddRecipeItemButton } from './additem/Container';
import { RecipeItem } from './RecipeItem';

const style: (theme: Theme) => StyleRules = theme => ({
  list: {
    width: '100%'
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  drawerPaper: {
    width: '100%'
  },
  emptyState: {
    height: '100%',
    textAlign: 'center',
    paddingTop: '70px'
  }
});

interface DrawerState {
  expandedItem?: String;
}

class RecipeDetailsDrawerStyled extends React.Component<RecipeDetailsProps & WithStyles, DrawerState> {
  state: DrawerState;

  constructor(props: RecipeDetailsProps & WithStyles) {
    super(props);
    this.state = {};
  }

  closeDrawer = () => {
    this.props.resetRecipeDetailsData();
    this.props.openRecipeDetails(false);
  };

  expandItem = (itemId: string) => {
    this.setState({
      expandedItem: this.state.expandedItem === itemId ? undefined : itemId
    });
  };

  componentDidMount() {
    this.openTitleDialogIfNeeded();
  }

  componentDidUpdate() {
    this.openTitleDialogIfNeeded();
  }

  render() {
    const { classes, ...rest } = this.props;
    let component;
    if (this.props.recipe && this.props.recipe.items) {
      const items = this.props.recipe.items.map(item => {
        return (
          <RecipeItem
            {...this.props}
            item={item}
            toggleExpand={this.expandItem}
            expanded={item.id === this.state.expandedItem}
            key={item.id}
          />
        );
      });
      component = <div className={classes.list}>{items}</div>;
    } else {
      component = <Loading />;
    }

    return (
      <React.Fragment>
        <Drawer
          anchor="right"
          open={this.props.isRecipeDetailsOpen}
          onClose={this.closeDrawer}
          classes={{
            paper: classes.drawerPaper
          }}>
          <AppBar position="sticky">
            <Toolbar>
              <div className={classes.drawerHeader} onClick={this.closeDrawer}>
                <ChevronIcon />
                {this.props.recipe ? this.props.recipe.name : '...'}
              </div>
              <Typography variant="subtitle1" color="inherit" style={{ flex: 1 }} />

              <div onClick={this.openRecipeTitle}>
                <EditIcon />
              </div>
            </Toolbar>
          </AppBar>
          <Divider />
          <div tabIndex={0} role="button">
            {component}
          </div>
          <RecipeTitleDialog {...rest} />
        </Drawer>
        {this.props.isRecipeDetailsOpen && !this.props.isRecipeTitleDialogOpen && <AddRecipeItemButton {...rest} />}
      </React.Fragment>
    );
  }

  private openRecipeTitle = () => {
    const recipeId = this.getRecipeId();
    this.props.openRecipeTitleDialog(true, recipeId);
  };

  // TODO: Upgrade to material ui 3.0 and use onEntered event.
  private openTitleDialogIfNeeded() {
    if (this.props.isRecipeDetailsOpen) {
      const recipeId = this.getRecipeId();
      if (recipeId && recipeId === 'new' && !this.props.isRecipeTitleDialogOpen) {
        this.props.resetRecipeDetailsData();
        waitMs(300).then(() => {
          if (this.getRecipeId() === 'new') {
            this.openRecipeTitle();
          }
        });
      }
    }
  }

  private getRecipeId() {
    return getPathParam(this.props.location.pathname, RECIPE_DETAILS_PATH, 'selectedRecipe');
  }
}

export const RecipeDetailsDrawerComponent = withStyles(style)(RecipeDetailsDrawerStyled);
