import { push } from 'connected-react-router';
import { SyncAction, SyncActionClass } from '../../utils/Actions';
import { UserList } from './Types';
import { UserDetails } from '../../domain/userdetals/Types';

export class ExpandUserListAction extends SyncActionClass<boolean> {
  static TYPE = 'EXPAND_USER_LIST_ACTION';
  constructor(expand: boolean) {
    super(ExpandUserListAction.TYPE, expand);
  }
}

export class UserListsChangedAction extends SyncActionClass<UserList[]> {
  constructor(userLists: UserList[]) {
    super('UserListsChangedAction', userLists);
  }
}

export class UserSelectedListChangedAction extends SyncActionClass<UserDetails> {
  constructor(userLists: UserDetails) {
    super('UserSelectedListChangedAction', userLists);
  }
}

export const expandUserLists: (expand: boolean) => SyncAction = expand =>
  new ExpandUserListAction(expand).action();

export const onUserListChanged: (userLists: UserList[]) => SyncAction = userLists =>
  new UserListsChangedAction(userLists).action();

export const onUserSelectedListChanged: (userLists: UserDetails) => SyncAction = userLists =>
  new UserSelectedListChangedAction(userLists).action();

export const logout: () => SyncAction = () => {
  return dispatch => dispatch(push('/public/logout'));
};

export const MenuDrawerActions = {
  onUserListChanged,
  onUserSelectedListChanged,
  expandUserLists,
  logout
};
