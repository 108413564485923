const post: (msg: string) => void = msg => {
  window.postMessage(msg, '*');

  // eslint-disable-next-line
  if ((<any> window).ReactNativeWebView) {
  // eslint-disable-next-line
      (<any> window).ReactNativeWebView.postMessage(msg);
  }
};

export default {
  post
};
