import * as React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { RecipesPage } from '../RecipesPage';
import { AuthState } from '../../../utils/components/auth/Reducers';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import { connect } from 'react-redux';
import { State } from '../../../reducer';
import { AppProps } from '../../../router/PrivateRouter';
export const RECIPES_PATH = '/recipes';
export const RECIPE_DETAILS_PATH = `${RECIPES_PATH}/recipe-details/:selectedRecipe`;

export const recipesPageTheme = createMuiTheme({
  palette: {
    primary: {
      main: 'rgba(211, 47, 47, 0.90)',
      contrastText: '#ffffff'
    },
    secondary: {
      main: 'rgba(255, 109, 0, 0.88)',
      contrastText: '#ffffff'
    }
  }
});

export class RecipesRouterContainer extends React.Component<RouteComponentProps<{}> & AuthState & AppProps> {
  renderRecipes = (props: RouteComponentProps<{}>) => <RecipesPage {...props} {...this.props} />;

  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route path={`${RECIPES_PATH}:path(|/|/menu)`} exact={true} render={this.renderRecipes} />
          <Route path={RECIPE_DETAILS_PATH} exact={true} render={this.renderRecipes} />
          <Route path={`${RECIPE_DETAILS_PATH}/edit-recipe-title`} exact={true} render={this.renderRecipes} />
          <Route path={`${RECIPE_DETAILS_PATH}/add-recipe-item`} exact={true} render={this.renderRecipes} />
        </Switch>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: State, props: RouteComponentProps<{}>) => {
  return {};
};

export const RecipesRouter = connect(mapStateToProps, {})(RecipesRouterContainer);
