import { SyncAction, SyncActionClass, voidAsyncAction } from '../../../utils/Actions';
import { domainApi } from '../../../domain/DomainApi';
import { ListItem } from '../currentshoppinglist/Types';

export class AddItemAction extends SyncActionClass<{ itemName: string; listId: string }> {
  constructor(itemName: string, listId: string) {
    super('AddItemAction', { itemName, listId });
  }
}

export class AddMoreItemsAction extends SyncActionClass<{ itemName: string; listId: string }> {
  constructor(itemName: string, listId: string) {
    super('AddMoreItemsAction', { itemName, listId });
  }
}

export class CancelItemAction extends SyncActionClass<{}> {
  constructor() {
    super('CancelItemAction', {});
  }
}

export class AddItemTextChanged extends SyncActionClass<string> {
  constructor(txt: string) {
    super('AddItemTextChanged', txt);
  }
}

export class SuggestionsUpdatedAction extends SyncActionClass<{ listId: string; items: ListItem[] }> {
  constructor(listId: string, items: ListItem[]) {
    super('SuggestionsUpdatedAction', { listId, items });
  }
}

export const cancelAddingItem: () => SyncAction = () => new CancelItemAction().action();

export const addItem: (listId: string, itemName: string) => SyncAction = (listId, itemName) => {
  if (!itemName) {
    return voidAsyncAction;
  }
  domainApi.addShoppingListItem(listId, itemName);
  return new AddItemAction(itemName, listId).action();
};

export const addMoreItems: (listId: string, itemName: string) => SyncAction = (listId, itemName) => {
  if (!itemName) {
    return voidAsyncAction;
  }
  domainApi.addShoppingListItem(listId, itemName.trim());
  return new AddMoreItemsAction(itemName, listId).action();
};

export const onAddItemTextChange: (txt: string) => SyncAction = txt => new AddItemTextChanged(txt).action();

export const onSuggestionsUpdated: (listId: string, items: ListItem[]) => SyncAction = (listId, items) =>
  new SuggestionsUpdatedAction(listId, items).action();

export const AddItemButtonActions = {
  addItem,
  addMoreItems,
  onAddItemTextChange,
  cancelAddingItem,
  onSuggestionsUpdated
};
