export type WhereFilterOp = firebase.firestore.WhereFilterOp;
export type QuerySnapshot<T> = firebase.firestore.QuerySnapshot;
export type Query<T> = firebase.firestore.Query;
export type CollectionReference<T> = firebase.firestore.CollectionReference<T>;
export type DocumentData = firebase.firestore.DocumentData;

export interface FirestoreDocument {
    id: string;
}

export interface QueryWhere {
    field: string;
    op: WhereFilterOp;
    value: any
}

export const queryWhere: (field: string, op: WhereFilterOp, value: any) => QueryWhere = (field, op, value) => {
    return {
        field,
        op,
        value
    }
}

export interface CollectionFetchOptions {
    where?: QueryWhere[];
    limit?: number;
}

export interface FirestoreEffects {
    fetchCollection: (path: string[], options?: CollectionFetchOptions) => Promise<DocumentData[]>
    saveBatch: <T extends FirestoreDocument>(path: string[], elements: T[]) => Promise<void>
    saveDocument: (path: string[], docId: string, document: any) => Promise<void>
    updateDocument: <T>(path: string[], documentId: string, val: T) => Promise<void>
    deleteDocument: (path: string[], documentId: string) => Promise<void>
    findDocument: (path: string[], docId: string) => Promise<DocumentData | undefined>
}