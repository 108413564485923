import * as React from 'react';
import { AddItemButtonActions } from './Actions';
import { State } from '../../../reducer';
import { connect } from 'react-redux';
import { AddItemButton } from './Component';
import { Component } from 'react';
import { AddItemButtonState } from './Reducers';
import { domainApi } from '../../../domain/DomainApi';
import { Unsubscribe } from '../../../domain/SubsciptionTypes';
import { getAddItemButtonState } from '../ShoppingListsPageReducer';
import { ShoppingListPageProps } from '../ShoppingListsPage';
import { ActionProps } from '../../../utils/Actions';
import { logger } from '../../../utils/LoggingUtils';

const log = logger('AddItemButtonContainer');

export type AddItemButtonProps = 
  ActionProps<typeof AddItemButtonActions> & AddItemButtonState & ShoppingListPageProps;

class AddItemContainer extends Component<AddItemButtonProps> {
  unsubs: Unsubscribe = ()=>{};

  componentDidMount() {
    if (this.props.selectedList) {
      log.info('Subscribing to completed items...');
      this.unsubs = domainApi.subscribeToCompletedItems(this.props.selectedList, items =>
        this.props.onSuggestionsUpdated(this.props.selectedList, items)
      );
    }
  }

  componentWillUnmount() {
      if (this.unsubs) {
          this.unsubs();
      }
  }

  componentDidUpdate(prevProps: Readonly<AddItemButtonProps>) {
    if (this.props.selectedList && this.props.selectedList !== prevProps.selectedList) {
      log.debug(`Add item button selected list changed, updating subscriptions to list ${this.props.selectedList}`);

      if (this.unsubs) {
        this.unsubs();
      }

      this.unsubs = domainApi.subscribeToCompletedItems(this.props.selectedList, items =>
        this.props.onSuggestionsUpdated(this.props.selectedList, items)
      );
    }
  }

  render() {
    return <AddItemButton {...this.props} />;
  }
}

const mapDispatchToProps = {
  ...AddItemButtonActions
};

const mapStateToProps = (state: State) => {
  return getAddItemButtonState(state);
};

export default connect(mapStateToProps, mapDispatchToProps)(AddItemContainer);
