import { Action } from 'redux';
import { emptyListCategories, ListCategories } from '../../../domain/listcategories/Types';
import { ListCategoriesUpdatedAction } from '../ShoppingListsPageActions';

export type ListCategoriesDrawerState = Readonly<{
  listCategories: ListCategories;
}>;

const emptyState = {
  listCategories: emptyListCategories
};

export const listCategoriesDrawerReducer: (
  state: ListCategoriesDrawerState | undefined,
  action: Action
) => ListCategoriesDrawerState = (state = emptyState, action) => {
  switch (action.type) {
    case 'ListCategoriesUpdatedAction': {
      const updateAction = action as ListCategoriesUpdatedAction;
      return { ...state, listCategories: updateAction.payload };
    }

    default:
      return state;
  }
};
