import * as React from 'react';
import { firebaseService } from '../../utils/firebase/FirestoreConfig';
import { AuthActions, AuthProps } from '../../utils/components/auth/Actions';
import { connect } from 'react-redux';
import { logger } from '../../utils/LoggingUtils';

const log = logger('LogoutContainer');

type LogoutContainerProps = AuthProps;

class LogoutContainer extends React.Component<LogoutContainerProps> {
  componentDidMount() {
    log.debug('Logging out... deleting local storage...');
    localStorage.removeItem('persist:root');
    log.debug('Signing out of app');
    firebaseService.firebaseAuth.signOut().then(() => {
      this.props.onLogout();
      const redirectUrl = firebaseService.fromMobile ? '/?mobile=true' : '/';
      window.location.href = redirectUrl;
    });
  }

  render() {
    return <div>Logging out</div>;
  }
}

const mapDispatchToProps = {
  ...AuthActions
};

export const Logout = connect(null, mapDispatchToProps)(LogoutContainer);
