import { SyncAction, SyncActionClass } from '../../../utils/Actions';
import { domainApi } from '../../../domain/DomainApi';
import { RecentItem, RecentItemsList } from './Types';
import * as ReactGA from 'react-ga';
import { logger } from '../../../utils/LoggingUtils';

const log = logger('RecentItems.actions');

export class AddRecentItemToListAction extends SyncActionClass<{listId: string, itemId: string}> {
  constructor(listId: string, itemId: string) {
    super('ADD_RECENT_ITEM_TO_LIST', {listId, itemId});
  }
}

export class SortRecentItemsAction extends SyncActionClass<{sortType: string, listId: string}> {
  constructor(sortType: string, listId: string) {
    super('SORT_RECENT_ITEMS', {sortType, listId});
  }
}

export class RecentItemsChanged extends SyncActionClass<RecentItem[]> {
  constructor(recentItems: RecentItem[]) {
    super('RecentItemsChanged', recentItems);
  }
}

export class RecentItemsListChanged extends SyncActionClass<RecentItemsList> {
  constructor(itemList: RecentItemsList) {
    super('RecentItemsListChanged', itemList);
  }
}

const recentItemsChanged: (items: RecentItem[]) => SyncAction = items => {
  log.trace(`Recent items changed fired with: ${items}`);
  return new RecentItemsChanged(items).action();
};

const recentItemListChanged: (itemList: RecentItemsList) => SyncAction = itemList => {
  return new RecentItemsListChanged(itemList).action();
};

const addItemFromHistory: (listId: string, itemId: string) => SyncAction = (listId, itemId) => {
  domainApi.restoreRecentItem(listId, itemId);
  return new AddRecentItemToListAction(listId, itemId).action();
};

const sortRecentItems: (sortType: string, listId: string) => SyncAction = (sortType, listId) => {
  ReactGA.event({
    category: 'User Action',
    action: 'Sort Recent Items',
    label: sortType
  });
  domainApi.changeSortType(listId, sortType);
  return new SortRecentItemsAction(listId, sortType).action();
};

export const RecentItemsActions = {
  recentItemsChanged,
  recentItemListChanged,
  addItemFromHistory,
  sortRecentItems
};
