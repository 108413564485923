import { SubscriptionCallback, Unsubscribe } from '../SubsciptionTypes';
import { firebaseService, getUserId, isDev } from '../../utils/firebase/FirestoreConfig';
import { UserDetails } from './Types';
import { eventBus } from '../../utils/eventbus/EventBus';
import DomainEvents, { NewUserCreatedEvent, UserDetailsUpdatedEventIO, UserDetailsDeletedEvent, UserRemovedFromListEventIO } from '../DomainEvents';
import { logger } from '../../utils/LoggingUtils';
import { NavUtils } from '../../utils/NavigatorUtils';
import { UserDetailsRepositoryIO } from './io/UserDetailsRepositoryIO';
import { UserDetailsServiceIO, UpdateUserDetailsResponse } from './io/UserDetailsServiceIO';
import { effectsImpl } from '../../utils/fp/effects/EffectsImpl';
import { IO } from '../../utils/fp/io';

const log = logger('UserDetailsServce');

const initialize = () => {
  UserRemovedFromListEventIO.onEvent(event => {
    if (event.reason === 'USER_DELETED') {
      log.debug(`User ${event.removedUserId} is being deleted, won't select default list`);
      return IO.void();
    } else {
      return UserDetailsServiceIO.selectDefaultList(event.removedUserId);
    }
  }
  ).eval(effectsImpl);
  log.debug('Initialized.');
};

const getUsersDetails: (usersId: string[], db: firebase.firestore.Firestore) => Promise<UserDetails[]>
  = async (usersId, db) => {

    if (!usersId || usersId.length === 0) {
      return [];
    }

    const snapShot = await db.collection('UserDetails').where('userId', 'in', usersId).get();
    const users: UserDetails[] = snapShot.docs.map(doc => doc.data() as UserDetails);
    return users;
  };

const subscribeToUserDetailsList: (cb: SubscriptionCallback<UserDetails[]>) => Unsubscribe = (cb) => {
  return firebaseService.firestoreSubscription(firestore => {
    const ref = firestore.collection('UserDetails');
    return ref.onSnapshot(snapshot => {
      if (snapshot.empty) {
        log.debug(`User list is empty`);
        return [];
      }

      log.debug(`User list updated, size ${snapshot.docs.length}`);
      const users = snapshot.docs
        .map(doc => doc.data())
        .filter(data => !!data)
        .map(UserDetailsRepositoryIO.toUserDetails);

      cb(users);
    });
  });
};

const subscribeToUserDetails: (userId: string, cb: SubscriptionCallback<UserDetails>) => Unsubscribe = (userId, cb) => {
  return firebaseService.firestoreSubscription(firestore => {
    if (!userId) {
      log.warn('Cant subscribe to user details because there is no user.');
      return () => {
        log.warn('There wasnt subscriptiopn');
      };
    }
    log.debug(`Subscribing to user details ${userId}`);
    const ref = firestore.collection('UserDetails').doc(userId);
    return ref.onSnapshot(snapshot => {
      if (!snapshot.exists) {
        return;
      }

      const data = snapshot.data();

      if (!data) {
        return;
      }

      cb(UserDetailsRepositoryIO.toUserDetails(data));
    });
  });
};

const deleteUser: (userToDelete: string) => void = async (userToDelete) => {
  if (!isDev()) {
    log.debug(`Can not delete users in production. UserId ${userToDelete}`);
    return;
  }
  const firestore = await firebaseService.firestore;
  const ref = firestore.collection('UserDetails').doc(userToDelete);
  const snapshot = await ref.get();

  if (!snapshot.exists) {
    return;
  }

  log.debug(`Deleting user ${userToDelete}`);
  await ref.delete();
  new UserDetailsDeletedEvent(userToDelete).emit();
};

const updateUserDetails: (user: firebase.User) => void
  = async (user: firebase.User) => {
    const userId = getUserId(user);

    // This method can't resolve while offline since the
    // events won't be triggered.
    await firebaseService.ensureNetwork;
    const navProps = {
      platform: `${NavUtils.getInstallType(firebaseService.fromMobile)} ${navigator.platform}`,
      appVersion: NavUtils.getVersion(),
      resolution: NavUtils.getResolution(),
      lang: navigator.language || 'ukn'
    };

    const response: UpdateUserDetailsResponse =
      await UserDetailsServiceIO.updateUserDetails(userId, user, navProps, new Date())
        .eval(effectsImpl);

    if (!response.newUser) {
      log.debug(`User exists, updating data: ${userId}`);
      new UserDetailsUpdatedEventIO(response.user).emit().eval(effectsImpl);

    } else {
      const event: NewUserCreatedEvent = {
        userId,
        selectedList: response.user.selectedList,
        user: response.user
      };

      eventBus.emit(DomainEvents.NewUserDetailsCreated, event);
    }

  };

const findByEmail: (userEmail: string) => Promise<UserDetails> = async (userEmail: string) => {
  const firestore = await firebaseService.firestore;
  const snapShot = await firestore
    .collection('UserDetails')
    .where('email_idx', '==', userEmail.toLowerCase())
    .get();

  if (snapShot.empty) {
    throw new Error(`User does not exist ${userEmail}`);
  }

  return UserDetailsRepositoryIO.toUserDetails(snapShot.docs[0].data());
};

const searchUsersByEmail: (searchTerm: string) => Promise<UserDetails[]> = async (searchTerm) => {
  const firestore = await firebaseService.firestore;
  const snapShot = await firestore
    .collection('UserDetails')
    .where('email_idx', '>=', searchTerm.toLowerCase())
    .where('email_idx', '<', searchTerm.toLowerCase() + '\uf8ff')
    .limit(100)
    .get();
  if (snapShot.empty) {
    return [];
  }

  return snapShot.docs.map(doc => UserDetailsRepositoryIO.toUserDetails(doc.data()));
};

export default {
  findByEmail,
  getUsersDetails,
  initialize
};

export const UserDetailsDomainApi = {
  subscribeToUserDetails,
  subscribeToUserDetailsList,
  setSelectedList: UserDetailsRepositoryIO.updateSelectedList,
  updateUserDetails,
  searchUsersByEmail,
  searchUsersByName: UserDetailsServiceIO.searchUsersByName,
  deleteUser
};
