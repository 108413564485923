import { SyncActionClass, SyncAction } from '../../utils/Actions';
import { ListItem } from '../shoppinglists/currentshoppinglist/Types';

export class RecipeItemsHistoryUpdatedAction extends SyncActionClass<{ listId: string; items: ListItem[] }> {
    constructor(listId: string, items: ListItem[]) {
        super('RecipeItemsHistoryUpdatedAction', { listId, items });
    }
}

const onRecipeItemsHistoryUpdatedAction: (listId: string, items: ListItem[]) => SyncAction = (listId, items) =>
    new RecipeItemsHistoryUpdatedAction(listId, items).action();


export const RecipesPageActions = {
    onRecipeItemsHistoryUpdatedAction
};
