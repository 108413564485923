import * as React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { Theme, WithStyles, CardHeader, CardMedia, CardContent, CardActions } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import { StyleRules } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { firebaseService } from '../../utils/firebase/FirestoreConfig';
import versionUtils from '../../utils/VersionUtils';
import ReactNativeBridge from '../../utils/ReactNativeBridge';
import { NavUtils } from '../../utils/NavigatorUtils';
import { logger } from '../../utils/LoggingUtils';
import { TFunction } from 'i18next';

const log = logger('AboutDialogContainer');

const TrolleyIcon = require('react-icons/lib/ti/shopping-cart');

const styles: (theme: Theme) => StyleRules = theme => ({
  card: {
    width: 250
  },
  media: {
    height: 0,
    paddingTop: '56.25%' // 16:9
  },
  actions: {
    display: 'flex'
  },
  icon: {
    fontSize: 40,
    color: 'black'
  },
  close: {
    marginLeft: 'auto',
    marginRight: '35px',
    paddingRight: 0
  }
});

export type AboutDialogContainerProps = WithStyles & {
  open: boolean;
  closeClicked: () => void;
  t: TFunction
};

interface AboutState {
  updateAvailable: boolean;
  isIOS: boolean;
}

class AboutDialogContainer extends React.Component<AboutDialogContainerProps, AboutState> {
  state: AboutState;
  url = 'https://andrescastano.dev';

  constructor(props: AboutDialogContainerProps) {
    super(props);
    this.state = {
      updateAvailable: false,
      isIOS: NavUtils.isIOS()
    };
  }

  async updateClicked() {
    window.location.reload(true);
  }

  openLinkNative = () => {
    ReactNativeBridge.post(JSON.stringify({ type: 'NAVIGATE_TO_URL', url: this.url }));
  };

  async componentDidUpdate(prevProps: Readonly<AboutDialogContainerProps>) {
    if(!prevProps.open && this.props.open) {
      log.debug('Dialog opened, checking for updates');
      const version = await versionUtils.checkForUpdates();
      if(version) {
        log.debug('Update found');
        this.setState({
          updateAvailable: true
        });
      }else {
        log.debug('Update not found');
      }
    }
  }

  render() {
    let version = versionUtils.getClientVersion();
    const { t, classes } = this.props;
    const afcastano = firebaseService.fromMobile ? (
      // eslint-disable-next-line
      <a href="javascript:void(0)" onClick={this.openLinkNative}>
        {this.url}
      </a>
    ) : (
        // eslint-disable-next-line
        <a href={this.url} target="_blank">
          {this.url}
        </a>
      );

    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.closeClicked}
        aria-labelledby="about-dialog-title"
        aria-describedby="about-dialog-description">
        <Card className={classes.card}>
          <CardHeader
            avatar={<TrolleyIcon className={classes.icon} />}
            title="Trolley"
            subheader={<Typography variant="caption" component="small">{`${NavUtils.getInstallType(firebaseService.fromMobile)} v. ${version}`}</Typography>}
          />
          <CardMedia className={classes.media} image="/bg-trolley-dark.jpg" title="Trolley" />
          <CardContent>
            <Typography component="span">
              {t('Developed by')}:<br />
              {afcastano}
            </Typography>
          </CardContent>
          <CardActions className={classes.actions} disableSpacing={true}>
            {
              this.state.isIOS && this.state.updateAvailable && (
                <Button onClick={this.updateClicked} color="primary" className={classes.close}>
                  {t('Update')}
                </Button>
              )
            }
            <Button onClick={this.props.closeClicked} color="primary" className={classes.close}>
              {t('Close')}
            </Button>
          </CardActions>
        </Card>
      </Dialog>
    );
  }
}

export const AboutDialog = withStyles(styles)(AboutDialogContainer);
