import * as React from 'react';
import { Theme, WithStyles, IconButton, ListItem, ListItemText } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import withStyles from '@material-ui/core/styles/withStyles';
import Toolbar from '@material-ui/core/Toolbar';
import Drawer from '@material-ui/core/Drawer';
import ChevronIcon from '@material-ui/icons/ChevronLeft';
import AddIcon from '@material-ui/icons/Send';
import { ItemCategoriesContainerProps, ItemCategoriesContainerState } from './ItemCategoriesContainer';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import AutoComplete from '../../../utils/components/autocomplete/Container';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import { getItemCategoriesAutoCompleteState } from '../ShoppingListsPageReducer';

const style: (theme: Theme) => StyleRules = theme => ({
  flex: {
    flex: 1
  },
  drawerHeader: {
    alignItems: 'center',
    padding: '0 8px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    ...theme.mixins.toolbar
  },
  headerIcon: {
    color: theme.palette.primary.contrastText
  },
  drawerContent: {},
  drawerPaper: {
    width: '100%'
  },
  searchPanel: {
    width: '100%',
    marginBottom: '15px',
    paddingBottom: '10px',
    paddingLeft: '10px'
  },
  paper: {
    overflowY: 'visible',
    position: 'fixed',
    top: '0px',
    left: '0px',
    width: '82%'
  },
  panelCategories: {
    display: 'flex',
    justifyContent: 'start',
    flexWrap: 'wrap',
    width: '100%',
    padding: '0'
  },
  chip: {
    margin: '2px'
  },
  catList: {
    maxHeight: '300px',
    overflow: 'auto'
  }
});

interface ItemCategoriesComponent extends ItemCategoriesContainerProps, ItemCategoriesContainerState {
  onTextChange: (txt: string) => void;
  onCategorySelected: (catName: string) => void;
  onCategoryRemoved: (catName: string) => void;
}

const StyledItemCategoriesComponent = (props: ItemCategoriesComponent & WithStyles) => {
  const { classes } = props;

  const selectedCategories = props.selectedCategories;
  return (
    <Drawer
      anchor="right"
      open={props.open}
      style={{ overflow: 'hidden' }}
      classes={{ paper: classes.drawerPaper }}
      onClose={props.onClose}>
      <div className={classes.drawerHeader}>
        <Toolbar>
          <IconButton
            // tslint:disable-next-line jsx-no-lambda
            onClick={() => {
              props.onCategoriesUpdated(props.item ? props.item.id : '', props.selectedCategories, props.newCatTxt);
              props.onClose();
            }}>
            <ChevronIcon className={classes.headerIcon} />
          </IconButton>
          <Typography variant="subtitle1" color="inherit" className={classes.flex}>
            {props.item ? props.item.name : ''}
          </Typography>
          <IconButton
            // tslint:disable-next-line jsx-no-lambda
            onClick={() => {
              props.onCategoriesUpdated(props.item ? props.item.id : '', props.selectedCategories, props.newCatTxt);
              props.onClose();
            }}>
            <AddIcon style={{ float: 'right' }} className={classes.headerIcon} />
          </IconButton>
        </Toolbar>
      </div>
      <div className={classes.drawerContent}>
        <Paper className={classes.searchPanel} elevation={4}>
          <Paper className={classes.panelCategories} elevation={0}>
            {selectedCategories.map(cat => {
              return (
                <Chip
                  key={cat}
                  label={cat}
                  // tslint:disable-next-line jsx-no-lambda
                  onDelete={() => props.onCategoryRemoved(cat)}
                  className={classes.chip}
                />
              );
            })}
          </Paper>
          <form
            // tslint:disable-next-line jsx-no-lambda
            onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
              e.preventDefault();
              props.onCategoriesUpdated(props.item ? props.item.id : '', props.selectedCategories, props.newCatTxt);
              props.onClose();
            }}>
            <AutoComplete
              name="searchCategory"
              onTextChange={props.onTextChange}
              searchText={props.newCatTxt}
              getState={getItemCategoriesAutoCompleteState}
              suggestions={props.listCategories ? props.listCategories.sort((a, b) => a.localeCompare(b)): []}
              requestFocusWhenShown={true}
              onSuggestionSelected={props.onCategorySelected}
              openDownwards={true}
              inputProps={{
                name: 'searchCategory',
                placeholder: '  Search / Create new category'
              }}
              showSuggestionsOnFocus={false}
            />
          </form>
        </Paper>
        <List component="nav" className={classes.catList}>
          {props.listCategories
            .filter(cat => props.selectedCategories.indexOf(cat) === -1)
            .sort((a, b) => a.localeCompare(b))
            .map(cat => {
              return (
                <React.Fragment key={cat}>
                  <ListItem
                    button={true}
                    // tslint:disable-next-line jsx-no-lambda
                    onClick={() => props.onCategorySelected(cat)}>
                    <ListItemText primary={cat} />
                  </ListItem>
                  <Divider />
                </React.Fragment>
              );
            })}
        </List>
      </div>
    </Drawer>
  );
};

export const ItemCategoriesComponent = withStyles(style)(StyledItemCategoriesComponent);
