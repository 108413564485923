import { IO, _do } from '../../../utils/fp/io'
import { logger } from '../../../utils/LoggingUtils';
import { ListCategoriesRepo } from './ListCategoriesRepo';

const log = logger('PureListCategoriesService');

/** CORE */
export type DefaultCategories = {
  categories: string[];
}

const importDefaultCategories: (lang: string) => IO<DefaultCategories> =
  (lang) =>
    lang === 'es'
      ? new IO(effects => effects.imports.importDefaultCategoriesEs())
      : new IO(effects => effects.imports.importDefaultCategoriesEn())

const createDefaultCategories: (listId: string, lang: string) => IO<void> = _do(function* (listId, lang) {
  const fileImported: DefaultCategories = yield importDefaultCategories(lang);
  log.debug(`File loaded for locale "${lang}". Categoires imported: ${fileImported.categories.length}`);
  yield ListCategoriesRepo.updateListCategories(listId, fileImported.categories);
});

const createNewListCategories: (listId: string) => IO<void> = listId => ListCategoriesRepo.saveListCategories(listId, []);

export const PureListCategoriesService = {
  importDefaultCategories,
  createDefaultCategories,
  createNewListCategories
}