import { AsyncAction, AsyncActionClass, invalidAsyncAction, SyncAction, SyncActionClass } from '../../../utils/Actions';
import { Recipe } from '../../../domain/recipes/Types';
import { domainApi } from '../../../domain/DomainApi';

export class RecipeDetailsChanged extends SyncActionClass<Recipe> {
  constructor(recipe: Recipe) {
    super('RecipeDetailsChanged', recipe);
  }
}

export class NewRecipeTitleTextChanged extends SyncActionClass<string> {
  constructor(txt: string) {
    super('NewRecipeTitleTextChanged', txt);
  }
}

export class CreateNewRecipeAction extends AsyncActionClass<{ name: string; listId: string }, Recipe> {
  constructor(name: string, listId: string, onSuccess: (r: Recipe) => void) {
    super('CreateNewRecipeAction', domainApi.createNewRecipe, { name, listId });
    this.options = {
      actionsOnSuccess: onSuccess
    };
  }
}

export class DeleteRecipeItemAction extends AsyncActionClass<
  { itemId: string; listId: string; recipeId: string },
  void
> {
  constructor(itemId: string, listId: string, recipeId: string) {
    super('DeleteRecipeItemAction', domainApi.removeRecipeItem, { itemId, listId, recipeId });
  }
}

export class AddRecipeItemToList extends SyncActionClass<{ itemId: string; listId: string; recipeId: string }> {
  constructor(itemId: string, listId: string, recipeId: string) {
    super('AddRecipeItemToList', { itemId, listId, recipeId });
  }
}

export class UpdateRecipeName extends SyncActionClass<{ listId: string; recipeId: string; name: string }> {
  constructor(listId: string, recipeId: string, name: string) {
    super('UpdateRecipeName', { listId, recipeId, name });
  }
}

export class ResetNewRecipeData extends SyncActionClass<{}> {
  constructor() {
    super('ResetNewRecipeData', {});
  }
}

export class ResetRecipeDetailsData extends SyncActionClass<{}> {
  constructor() {
    super('ResetRecipeDetailsData', {});
  }
}
export const onRecipeTitleTextChange: (txt: string) => SyncAction = txt => new NewRecipeTitleTextChanged(txt).action();

const onRecipeDetailsChanged = (recipe: Recipe) => new RecipeDetailsChanged(recipe).action();

export const createNewRecipe: (
  listId: string,
  recipeName: string,
  onSuccess: (r: Recipe) => void
) => AsyncAction<Recipe> = (listId, recipeName, onSuccess) => {
  if (!recipeName) {
    return invalidAsyncAction('Empty recipe name');
  }

  return new CreateNewRecipeAction(recipeName, listId, onSuccess).action();
};

const resetRecipeData: () => SyncAction = () => new ResetNewRecipeData().action();

const updateRecipeName: (listId: string, recipeId: string, name: string) => SyncAction = (listId, recipeId, name) => {
  domainApi.updateRecipeName(listId, recipeId, name);
  return new UpdateRecipeName(listId, recipeId, name).action();
};

const resetRecipeDetailsData: () => SyncAction = () => new ResetRecipeDetailsData().action();

const addRecipeItemToList: (itemId: string, listId: string, recipeId: string) => SyncAction = (
  itemId,
  listId,
  recipeId
) => {
  domainApi.addRecipeItemsToList(recipeId, listId, [itemId]);
  return new AddRecipeItemToList(itemId, listId, recipeId).action();
};

const deleteRecipeItem: (itemId: string, listId: string, recipeId: string) => AsyncAction<void> = (
  itemId,
  listId,
  recipeId
) => {
  return new DeleteRecipeItemAction(itemId, listId, recipeId).action();
};

export const RecipeDetailsActions = {
  onRecipeDetailsChanged,
  onRecipeTitleTextChange,
  createNewRecipe,
  resetRecipeData,
  resetRecipeDetailsData,
  updateRecipeName,
  addRecipeItemToList,
  deleteRecipeItem
};
