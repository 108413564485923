import { RECIPES_PATH } from './RecipesRouter';
import { formatPath, navigate } from '../../../utils/RouterUtils';
import { LISTS_PATH } from '../../shoppinglists/router/ShoppingListRouter';

export const RecipesNavigationActions = {
  goToRecipesPage: () => navigate(RECIPES_PATH),
  goToListsPage: () => navigate(LISTS_PATH),
  openRecipeDetails: (open: boolean, selectedRecipe?: string) =>
    navigate(formatPath(RECIPES_PATH, `recipe-details/${selectedRecipe}`, open), true),
  openMenu: (open: boolean) => navigate(formatPath(RECIPES_PATH, 'menu', open)),
  openRecipeTitleDialog: (open: boolean, selectedRecipe?: string) =>
    navigate(formatPath(`${RECIPES_PATH}/recipe-details/${selectedRecipe}`, 'edit-recipe-title', open)),
  openAddRecipeItemDialog: (open: boolean, selectedRecipe?: string) =>
    navigate(formatPath(`${RECIPES_PATH}/recipe-details/${selectedRecipe}`, 'add-recipe-item', open))
};
