import * as React from 'react';
import { StyleRules, withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import { ListItem, ListItemText, Theme, WithStyles } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import ChevronIcon from '@material-ui/icons/ChevronLeft';

import { NotificationsDrawerContainerProps } from './Container';
import AppBar from '@material-ui/core/AppBar';
import moment from 'moment';
import Toolbar from '@material-ui/core/Toolbar';

const style: (theme: Theme) => StyleRules = theme => ({
  list: {
    width: '100%'
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  drawerPaper: {
    width: '100%'
  },
  emptyState: {
    height: '100%',
    textAlign: 'center',
    paddingTop: '70px'
  }
});

export type NotificationsDrawerProps = NotificationsDrawerContainerProps & WithStyles;

const RecentItemsDrawerStyled = (props: NotificationsDrawerProps) => {
  const { classes, open, closeClicked } = props;

  const items = props.notifications.map(notification => {
    const secondaryText = moment(notification.createdOn).fromNow();

    return (
      <ListItem button={false} divider={true} key={notification.id}>
        <ListItemText primary={notification.text} secondary={secondaryText} />
      </ListItem>
    );
  });

  return (
    <div>
      <Drawer
        anchor="right"
        open={open}
        // tslint:disable-next-line jsx-no-lambda
        onClose={() => {
          props.clearUserListNotification(props.userId);
          closeClicked();
        }}
        classes={{
          paper: classes.drawerPaper
        }}>
        <AppBar position="sticky">
          <Toolbar>
            <div
              className={classes.drawerHeader}
              // tslint:disable-next-line jsx-no-lambda
              onClick={() => {
                props.clearUserListNotification(props.userId);
                closeClicked();
              }}>
              <ChevronIcon />
              Notifications
            </div>
          </Toolbar>
        </AppBar>
        <Divider />
        <div tabIndex={0} role="button">
          <div className={classes.list}>
            <List>{items}</List>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export const NotificationsDrawerComponent = withStyles(style)(RecentItemsDrawerStyled);
