import { makeIndexable } from './StringUtils';

export const randomId: () => string = () => {
  let result = '';
  let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  for (let i = 7; i > 0; --i) {
    result += chars[Math.floor(Math.random() * chars.length)];
  }
  return result;
};

export const nameIdx: (name: string, includeTildeInIndex?: boolean) => string = (name, includeTildeInIndex) => (name ? makeIndexable(name, includeTildeInIndex) : '');
