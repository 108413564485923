import * as React from 'react';
import { Component } from 'react';
import { AuthState } from '../../utils/components/auth/Reducers';
import { logger } from '../../utils/LoggingUtils';
import { ActionProps } from '../../utils/Actions';
import { AdminPageActions } from './AdminPageActions';
import { Unsubscribe } from 'firebase';
import { State } from '../../reducer';
import { getAllUsersState, AllUsersState } from './AdminPageReducer';
import { connect } from 'react-redux';
import { AppProps } from '../../router/PrivateRouter';
import { RouteComponentProps } from 'react-router-dom';
import { domainApi } from '../../domain/DomainApi';
import { Users } from './users/Container';

const log = logger('AdminPage');

export type AdminPageProps = AuthState &
  ActionProps<typeof AdminPageActions> &
  AppProps &
  RouteComponentProps<{ [key: string]: string }> &
  AllUsersState;

class AdminPageContainer extends Component<AdminPageProps> {
  unsubscribeToUsersDetails?: Unsubscribe;

  componentDidMount() {
    log.debug('Mounting AdminPage App');
    this.props.fetchIsAdmin(this.props.userId);
    this.unsubscribeToUsersDetails =
      domainApi.subscribeToUserDetailsList(
        this.props.onUserDetailsListUpdated
      );
  }

  componentWillUnmount() {
    log.debug('Unmounting Admin page');
    this.unsubscribeToUsersDetails && this.unsubscribeToUsersDetails();
  }

  render() {
    const component = this.props.isAdmin ? 
      <Users {...this.props}/> :
      <div>You don't have permissions</div>;
    return component;
  }
}

const mapDispatchToProps = {
  ...AdminPageActions
};

const mapStateToProps: (state: State) => AllUsersState = (state: State) => {
  return getAllUsersState(state);
};

export const AdminPage = connect(mapStateToProps, mapDispatchToProps)(AdminPageContainer);
