import * as React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { StyleRules } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';
import { AddItemButtonProps } from './Container';
import AutoComplete from '../../../utils/components/autocomplete/Container';
import { Theme, WithStyles, Fab, Button } from '@material-ui/core';
import { getAddItemAutoCompleteState } from '../ShoppingListsPageReducer';
import { logger } from '../../../utils/LoggingUtils';
import { makeIndexable } from '../../../utils/StringUtils';

const log = logger('AddItemComponent');

const style: (theme: Theme) => StyleRules = theme => ({
  fab: {
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    position: 'fixed'
  },
  drawerContent: {
    margin: theme.spacing(2)
  },
  drawerPaper: {
    overflowY: 'visible'
  },
  drawer: {
    overflowY: 'visible'
  },
  drawerToolbar: {
    justifyContent: 'space-between',
    paddingRight: 0,
    paddingLeft: 0
  },
  addItem: {
    color: theme.palette.secondary.main
  }
});

type AddItemComponentProps = AddItemButtonProps & WithStyles;

class StyledAddItemButton extends React.Component<AddItemComponentProps> {
  private textInput?: HTMLInputElement;

  submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.addMore(this.props.addItemBoxText);
  };

  addMore = (text: string) => {
    if (text) {
      this.props.addMoreItems(this.props.selectedList, text);
    }

    if (this.textInput) {
      this.textInput.focus();
    }
  };

  addItemFromList = (text: string) => {
    if (text) {
      this.addMore(text);
    }
  };

  addItemClick = () => {
    if (!this.props.addItemBoxText) {
      this.closeAddItemDialog();
      return;
    }
    log.debug(`Matching suggestion: ${this.props.matchingSuggestion}`);
    const currentItemBoxText = this.props.addItemBoxText;
    const matchingSuggestion = this.props.matchingSuggestion || '';
    
    if (makeIndexable(currentItemBoxText) === makeIndexable(matchingSuggestion)) {
      log.debug('Adding the matching suggestion as it is equal to the text box');
      this.addMore(matchingSuggestion);
    } else {
      this.addMore(currentItemBoxText);
    }
  };

  focusTextInput = (inputRef?: HTMLInputElement) => {
    if (inputRef) {
      this.textInput = inputRef;
    }
  };

  render() {
    const props = this.props;
    const { t, classes } = props;

    return (
      <React.Fragment>
        {!this.props.isAddListItemOpen && (
          <Fab
            color="secondary"
            aria-label="add"
            className={classes.fab}
            data-testid="add"
            onClick={this.openAddItemDialog}>
            <AddIcon />
          </Fab>)
        }
        <Drawer
          anchor="bottom"
          open={props.isAddListItemOpen}
          onClose={this.closeAddItemDialog}
          className={classes.drawer}
          classes={{
            paper: classes.drawerPaper
          }}>
          <div className={classes.drawerContent}>
            <form onSubmit={this.submitForm}>
              <AutoComplete
                name="addItem"
                onTextChange={props.onAddItemTextChange}
                searchText={props.addItemBoxText}
                getState={getAddItemAutoCompleteState}
                suggestions={props.suggestions}
                requestFocusWhenShown={true}
                onSuggestionSelected={this.onSuggestionSelected}
                inputProps={{
                  name: 'searchBoxText',
                  placeholder: t('Select or create new item')
                }}
                inputRef={this.focusTextInput}
              />
              <Toolbar className={classes.drawerToolbar}>
                <IconButton onClick={this.closeAddItemDialog} data-testid="cancelItem">
                  <CancelIcon />
                </IconButton>
                <div>
                  <Button onClick={this.addItemClick} color="secondary" data-testid="addItem">{t('Add')}</Button>
                </div>
              </Toolbar>
            </form>
          </div>
        </Drawer>
      </React.Fragment>
    );
  }

  private openAddItemDialog = () => {
    this.props.openAddListItemDialog(true);
  };

  private closeAddItemDialog = () => {
    this.props.cancelAddingItem();
    this.props.openAddListItemDialog(false);
  };

  private onSuggestionSelected = (suggestion: string) => this.addItemFromList(suggestion);

}

export const AddItemButton = withStyles(style)(StyledAddItemButton);
