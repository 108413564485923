import { firebaseService } from '../../utils/firebase/FirestoreConfig';
import { RecentItem, RecentItemsList } from '../../components/shoppinglists/recentitemsdrawer/Types';
import moment from 'moment';
import * as firebase from 'firebase/app';
import { SubscriptionCallback, Unsubscribe } from '../SubsciptionTypes';
import { ListItem } from '../../components/shoppinglists/currentshoppinglist/Types';
import { eventBus } from '../../utils/eventbus/EventBus';
import DomainEvents, {
  RecentItemUpdateAction,
  ShoppingListItemAddedEvent,
  ShoppingListItemCompletedEvent,
  ShoppingListItemRenamedEvent,
  ListUserHardDeletedEventIO,
  UserListCreatedEventIO
} from '../DomainEvents';
import { waitMs } from '../../utils/PromiseDelay';
import { logger } from '../../utils/LoggingUtils';
import { recentItemsRepo } from './RecentItemsRepo';
import { nameIdx } from '../../utils/IdUtils';
import { IO } from '../../utils/fp/io';
import { effectsImpl } from '../../utils/fp/effects/EffectsImpl';

const log = logger('RecentItemsService');

const initialize = () => {
  UserListCreatedEventIO.onEvent(
    event => new IO(() => createRecentItems(event.listId))
  ).eval(effectsImpl);

  ShoppingListItemAddedEvent.onEvent(event => onShoppingListItemAdded(event.listId, event.items));

  ShoppingListItemCompletedEvent.onEvent(props => {
    log.debug('Item completed! Adding it to recent items...');
    onListItemCompleted(props.listId, props.item);
  });

  eventBus.on(DomainEvents.ShoppingListItemRemoved, (cb: { listId: string; itemId: string; }) => {
    onItemRemoved(cb.listId, cb.itemId);
  });

  ShoppingListItemRenamedEvent.onEvent(event => onItemRenamed(event.listId, event.item.id, event.item.name));

  ListUserHardDeletedEventIO.onEvent(event =>
    new IO(() => hardDeleteRecentItems(event.listId))
  ).eval(effectsImpl);

  log.debug('Initialized.');
};

const createRecentItems = async (listId: string) => {
  await firebaseService.firestore.then(firestore => {
    recentItemsRepo.newRecentItemCollection(listId, 'OLDEST_FIRST', firestore);
  });
};

const hardDeleteRecentItems: (listId: string) => Promise<void> = async (listId) => {
  log.debug(`Hard deleting recent items ${listId}`);
  const firestore = await firebaseService.firestore;
  await recentItemsRepo.deleteRecentItems(listId, firestore);
  return;
};

const subscribeToRecentItems: (listId: string, cb: SubscriptionCallback<RecentItem[]>) => Unsubscribe = (
  listId,
  cb
) => {
  return firebaseService.firestoreSubscription(firestore => {
    return recentItemsRepo.subscribeToItems(
      listId,
      items => {
        const recentItems = items.filter((recentItem: RecentItem) => {
          const lastCompleted = recentItem.lastCompletedDate;
          const lastAdded = recentItem.lastAddedDate;

          if (!lastCompleted && lastAdded) {
            return false;
          }

          return lastCompleted && lastAdded ? moment(lastCompleted).isAfter(moment(lastAdded)) : true;
        });
        cb(recentItems);
      },
      firestore
    );
  });
};

const subscribeToRecentItemList: (listId: string, cb: SubscriptionCallback<RecentItemsList>) => Unsubscribe = (
  listId,
  cb
) => {
  return firebaseService.firestoreSubscription(firestore => {
    if (!listId) {
      log.warn('Cant subscribe because there is not list.');
      return () => {
        log.warn('Cant subscribe because there is not list.');
      };
    }
    const recentItemsListRef = firestore.collection('RecentItems').doc(listId);
    return recentItemsListRef.onSnapshot(snapshot => {
      const data = snapshot.data();

      if (data) {
        const recentItemsList: RecentItemsList = {
          listId: data.listId,
          sortType: data.sortType,
          items: []
        };

        cb(recentItemsList);
      }
    });
  });
};

const changeSortType = (listId: string, sortType: string) => {
  return firebaseService.firestore.then(firestore => {
    recentItemsRepo.recentItemsCollectionDB(listId, firestore).update({
      sortType
    });
  });
};

const onItemRemoved = (listId: string, itemId: string) => {
  firebaseService.firestore.then(db => {
    recentItemsRepo.findItemById(listId, itemId, db).then(foundItem => {
      if (!foundItem) {
        log.debug('removing an item not yet in recent items ' + itemId);
        return;
      }
      const newAddedOn = foundItem.addedOn;
      newAddedOn.pop();
      let lastAddedOn;
      if (newAddedOn.length > 0) {
        [lastAddedOn] = newAddedOn.slice(-1);
      }

      recentItemsRepo.itemDB(listId, itemId, db).update({
        addedOn: newAddedOn,
        lastAddedDate: lastAddedOn ? lastAddedOn : null
      });
      // Can't use `then` because it won't work when offline.
      emitRecentItemEvent(listId, itemId, db, 'Update');
    });
  });
};

const emitRecentItemEvent = (
  listId: string,
  itemId: string,
  firestore: firebase.firestore.Firestore,
  action: RecentItemUpdateAction
) => {
  // Wait for local cache to be updated.
  waitMs(100).then(() => {
    recentItemsRepo.findItemById(listId, itemId, firestore).then(foundItem => {
      if (!foundItem) {
        log.debug(`Item not yet in recent items, won't emit recent item update`);
        return;
      }
      DomainEvents.emitRecentItemUpdated({
        action,
        listId,
        item: foundItem
      });
    });
  });
};

const onItemRenamed = (listId: string, itemId: string, newName: string) => {
  firebaseService.firestore.then(db => {
    if (!newName) {
      return;
    }
    recentItemsRepo
      .itemDB(listId, itemId, db)
      .update({
        itemName: newName.trim(),
        name_idx: nameIdx(newName)
      })
      .catch(() => {
        log.debug('Updating an item not yet in recent items ' + itemId);
      });
    emitRecentItemEvent(listId, itemId, db, 'Update');
  });
};

const restoreRecentItem = (listId: string, itemId: string) => {
  firebaseService.firestore.then(firestore => {
    recentItemsRepo.findItemById(listId, itemId, firestore).then(foundItem => {
      if (!foundItem) {
        return;
      }
      const addedOn = new Date();
      recentItemsRepo.itemDB(listId, itemId, firestore).update({
        addedOn: [...foundItem.addedOn, addedOn],
        lastAddedDate: addedOn
      });
      // Can't use `then` because it won't work when offline.
      emitRecentItemEvent(listId, itemId, firestore, 'Restore');
    });
  });
};

const onShoppingListItemAdded = async (listId: string, items: ListItem[]) => {
  const firestore = await firebaseService.firestore;
  items.forEach(async (item) => {
    log.debug(`Adding completed item ${item.name_idx} to recent items ${listId}`);
    const foundItem = await recentItemsRepo.findItemById(listId, item.id, firestore);
    log.debug(`Trying to update existing item ${item.id}`);
    if (foundItem) {
      log.debug(`Updating existing item ${item.name_idx}`);
      recentItemsRepo.itemDB(listId, item.id, firestore).set({
        addedOn: [...foundItem.addedOn, item.addedOn],
        lastAddedDate: item.addedOn,
        itemName: item.name,
        name_idx: item.name_idx,
      }, { merge: true });
      // Won't wait for the promise because it won't work offline.
      emitRecentItemEvent(listId, item.id, firestore, 'Update');
    } else {
      log.debug('Item not found in cache, wont update');
    }
  });
};

const onListItemCompleted = async (listId: string, completedItem: ListItem) => {
  if (!completedItem) {
    log.debug(`Completed item is empty for listId: ${listId}`);
    return;
  }
  const firestore = await firebaseService.firestore;
  const foundItem = await recentItemsRepo.findItemById(listId, completedItem.id, firestore);
  log.debug(`Trying to update existing item ${completedItem.id}`);
  if (foundItem) {
    log.debug(`Updating existing item ${completedItem.id}`);
    recentItemsRepo.itemDB(listId, completedItem.id, firestore).set({
      completedOn: [...foundItem.completedOn, completedItem.completedOn],
      lastCompletedDate: completedItem.completedOn,
      itemName: completedItem.name,
      name_idx: completedItem.name_idx
    }, { merge: true });
    // Won't wait for the promise because it won't work offline.
    emitRecentItemEvent(listId, completedItem.id, firestore, 'Update');
  } else {
    log.debug('Item not found in cache, creating a new one.');
    recentItemsRepo.itemDB(listId, completedItem.id, firestore).set({
      id: completedItem.id,
      itemName: completedItem.name,
      name_idx: completedItem.name_idx,
      lastCompletedDate: completedItem.completedOn,
      completedOn: [completedItem.completedOn],
      addedOn: []
    });
    // Won't wait for the promise because it won't work offline.
    emitRecentItemEvent(listId, completedItem.id, firestore, 'Add');
  }
};

export const RecentItemsDomainApi = {
  subscribeToRecentItems,
  subscribeToRecentItemList,
  changeSortType,
  restoreRecentItem
};

export default {
  initialize
};
