import React, { useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import { makeStyles, Theme } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import { UserDetails } from '../../../domain/userdetals/Types';
import { TFunction } from 'i18next';
import Typography from '@material-ui/core/Typography';
import { SimpleConfirmation } from '../../../utils/components/dialogs/SimpleConfirmation';
import { CircularProgress } from '@material-ui/core';
import { SharingStatus, FetchUsersStatus } from './Reducers';
import WarningIcon from '@material-ui/icons/PriorityHigh';

const useStyles = makeStyles((theme: Theme) => {
    return {
        root: {},
        paper: {
            height: '85%',
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(),
            top: theme.spacing(),
            color: theme.palette.grey[500],
        },
        list: {
            maxHeight: '60%',
            overflow: 'auto'
        },
        listItem: {
            paddingLeft: 0
        },
        listItemAvatar: {
            maxWidth: '45px'
        },
        dialogContentUser: {
            display: 'flex',
            alignItems: 'center',
            padding: '10px 8px',
        },
        dialogContentAvatar: {
            margin: 10
        },
        resultsTitle: {
            marginTop: '20px'
        },
    };
});

const getUserListItem = (user: UserDetails, onClick: (user: UserDetails) => void, classes: any) => {
    const labelId = `checkbox-list-secondary-label-${user.userId}`;
    function shareList() {
        onClick(user);
    }
    return (
        <ListItem key={user.userId} className={classes.listItem} onClick={shareList}>
            <ListItemAvatar>
                <Avatar
                    src={user.photoURL}
                    imgProps={{
                        onError: (e) => {
                            e.currentTarget.src = `https://ui-avatars.com/api/?name=${user.email}`;
                        }
                    }}
                    className={classes.listItemAvatar}
                />
            </ListItemAvatar>
            <ListItemText id={labelId} primary={user.name || 'A user'} />
        </ListItem>
    );
};

export type SearchUsersDialogProps = {
    open: boolean;
    onClose: () => void;
    fetchUsers?: (searchTerm: string) => void;
    clearUserSuggestions: () => void;
    shareList: (toUserEmail: string) => void;
    filteredUsers: UserDetails[];
    fetchUsersStatus: FetchUsersStatus;
    sharingStatus: SharingStatus;
    t: TFunction;
};

export const SearchUsersDialog = (props: SearchUsersDialogProps) => {
    const classes = useStyles();
    const [searchTerm, setSearchTerm] = React.useState('');
    const [isConfirmOpen, setConfirmOpen] = React.useState(false);
    const [selectedUser, setSelectedUser] = React.useState<UserDetails>();
    const { sharingStatus, onClose, clearUserSuggestions } = props;

    useEffect(() => {
        if (sharingStatus === 'SUCCEEDED') {
            setConfirmOpen(false);
            // This is the same logic as 'OnClose' React complains about this.
            setSearchTerm('');
            clearUserSuggestions();
            onClose();
        }
    }, [sharingStatus, clearUserSuggestions, onClose]);

    function textChanged(e: React.ChangeEvent<HTMLInputElement>) {
        const txt = e.currentTarget.value;
        setSearchTerm(txt);
        props.clearUserSuggestions();
        if (txt.length >= 3) {
            props.fetchUsers!(txt);
        }
    }

    function closeShareDialog() {
        setSearchTerm('');
        props.clearUserSuggestions();
        props.onClose();
    }

    function onShareClick(user: UserDetails) {
        setSelectedUser(user);
        setConfirmOpen(true);
    }

    function onShareConfirmed() {
        if (selectedUser) {
            props.shareList(selectedUser.email);
        }
    }

    function onShareCanceled() {
        setConfirmOpen(false);
        setSelectedUser(undefined);
    }

    const users = props.filteredUsers.map(user => {
        return getUserListItem(user, onShareClick, classes);
    });
    const t = props.t;

    const usersListHeader = () => {
        if (users.length > 0) {
            return (
                <DialogContentText className={classes.resultsTitle}>{t('Select a user') + ':'}</DialogContentText>
            );
        } else if (props.fetchUsersStatus === 'DONE') {
            return (
                <List dense={false} className={classes.list}>
                    <ListItem className={classes.listItem}>
                        <ListItemAvatar>
                            <Avatar className={classes.listItemAvatar}>
                                <WarningIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={t('Couldn\'t find any user') + '.'} />
                    </ListItem>
                </List>
            );
        } else {
            return <></>;
        }
    };


    const confirmationPropmt = (
        <>
            {t("Do you want to share this list with") + ": "}
            {selectedUser && (
                <div className={classes.dialogContentUser}>
                    <Avatar
                        src={selectedUser.photoURL}
                        className={classes.dialogContentAvatar}
                        imgProps={{
                            onError: (e) => {
                                e.currentTarget.src = `https://ui-avatars.com/api/?name=${selectedUser.email}`;
                            }
                        }}
                    />
                    <Typography variant="body1" color="inherit">
                        {selectedUser.name}
                    </Typography>
                </div>
            )}
        </>
    );

    const sharingPrompt = <CircularProgress />;

    const confirmationContent = props.sharingStatus === 'IN_PROGRESS' ? sharingPrompt : confirmationPropmt;

    return (
        <>
            <Dialog
                open={props.open}
                onClose={closeShareDialog}
                aria-labelledby="search-dialog-title"
                fullWidth={true}
                className={classes.root}
                classes={{
                    paper: classes.paper
                }}
            >
                <DialogContent>
                    <DialogContentText>
                        {t('Search users by typing the first letters of their name') + '.'}
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        label={t('User name')}
                        type="search"
                        fullWidth
                        onChange={textChanged}
                        value={searchTerm}
                    />
                    {usersListHeader()}
                    <List dense={false} className={classes.list}>{users}</List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeShareDialog} color="primary">{t('Close')}</Button>
                </DialogActions>
            </Dialog>
            <SimpleConfirmation
                open={isConfirmOpen}
                ok={onShareConfirmed}
                cancel={onShareCanceled}
                content={confirmationContent}
                t={t}
                hideButtons={props.sharingStatus === 'IN_PROGRESS'}
            ></SimpleConfirmation>
        </>
    );
};
