import { SyncAction, SyncActionClass, AsyncActionClass } from '../../../utils/Actions';
import { domainApi } from '../../../domain/DomainApi';
import { effectsImpl } from '../../../utils/fp/effects/EffectsImpl';

export class NewListAction extends AsyncActionClass<{ userId: string; listName: string }, string> {
  constructor(listName: string, userId: string) {
    super('NewListAction', p => domainApi.createUserList(p.listName, p.userId).eval(effectsImpl), { listName, userId });
  }
}

export class AddListTextChanged extends SyncActionClass<string> {
  static TYPE = 'ADD_LIST_TEXT_CHANGED';
  constructor(txt: string) {
    super(AddListTextChanged.TYPE, txt);
  }
}

export class CleanNewListAction extends SyncActionClass<void> {
  static TYPE = 'CleanNewListAction';
  constructor() {
    super(CleanNewListAction.TYPE);
  }
}

export const addList: (listName: string, userId: string) => SyncAction = (listName, userId) =>
  new NewListAction(listName, userId).action();

const cleanNewListDialog = () => new CleanNewListAction().action();

export const onAddListTextChange: (txt: string) => SyncAction = txt => new AddListTextChanged(txt).action();

export const AddListDrawerActions = {
  addList,
  onAddListTextChange,
  cleanNewListDialog
}
