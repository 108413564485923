import { Action } from 'redux';
import { UserNotification } from '../../domain/notifications/Types';
import { NotificationsUpdatedAction } from '../shoppinglists/topbar/Actions';

export type NotificationsDrawerState = Readonly<{
  notifications: UserNotification[];
}>;

const emptyNotificationsState = {
  notifications: []
};

export const notificationsDrawerReducer: (
  state: NotificationsDrawerState | undefined,
  action: Action
) => NotificationsDrawerState = (state = emptyNotificationsState, action) => {
  switch (action.type) {
    case 'NotificationsUpdatedAction': {
      const notificationsAction = action as NotificationsUpdatedAction;
      return { ...state, notifications: notificationsAction.payload };
    }

    default:
      return state;
  }
};
