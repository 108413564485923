import * as React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { Theme, WithStyles, CardHeader, CardContent, CardActions, CardMedia } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import { StyleRules } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import versionUtils from '../../utils/VersionUtils';
import { logger } from '../../utils/LoggingUtils';
import { NavUtils } from '../../utils/NavigatorUtils';
import { TFunction } from 'i18next';

const TrolleyIcon = require('react-icons/lib/ti/shopping-cart');

const log = logger('VersionUpdateContainer');

const styles: (theme: Theme) => StyleRules = theme => ({
  card: {
    width: 250
  },
  media: {
    height: 0,
    paddingTop: '56.25%' // 16:9
  },
  actions: {
    display: 'flex'
  },
  icon: {
    fontSize: 40,
    color: 'black'
  },
  close: {
    marginLeft: 'auto',
    marginRight: '35px',
    paddingRight: 0
  }
});

export type VersionUpdateDialogProps = WithStyles & {
  open: boolean;
  closeClicked: () => void;
  t: TFunction;
};

class VersionUpdateDialogContainer extends React.Component<VersionUpdateDialogProps> {

  async updateClicked() {
    // IOS PWA refresh is shit. Check the ADR for details.
    // TODO: Add ADR.
    if (NavUtils.isIOS()) {
      window.location.reload(true);
    } else {
      await versionUtils.forceSWUpdate();
      window.location.reload(true);
    }
  }

  async componentDidMount() {
    // This happens after a hard reload. 
    // Hard reload happens after a version update. Check `updateClicked` method.
    // The component will mount opend because the url is pointing here.
    if (this.props.open) {
      log.debug('This component should never mount opened');
      window.location.replace('/');
    }
  }

  render() {
    const { t, classes } = this.props;
    return (
      <Dialog
        open={this.props.open}
        aria-labelledby="about-dialog-title"
        aria-describedby="about-dialog-description">
        <Card className={classes.card}>
          <CardHeader avatar={<TrolleyIcon className={classes.icon} />} title={t("New version available")+"!"} />
          <CardMedia className={classes.media} image="/bg-trolley-dark.jpg" title="Trolley" />
          <CardContent>
            <Typography component="span">{t('Do you want to update')}?</Typography>
          </CardContent>
          <CardActions className={classes.actions} disableSpacing={true}>
            <Button onClick={this.updateClicked} color="primary" className={classes.close}>
              {t('Yes')}
            </Button>
            <Button onClick={this.props.closeClicked} color="primary">
              No
            </Button>
          </CardActions>
        </Card>
      </Dialog>
    );
  }
}

export const VersionUpdateDialog = withStyles(styles)(VersionUpdateDialogContainer);
