import * as firebase from 'firebase/app';
import moment from 'moment';
import { logger } from '../../utils/LoggingUtils';
import { nameIdx } from '../../utils/IdUtils';
import { RecentItem } from '../../components/shoppinglists/recentitemsdrawer/Types';
import { SubscriptionCallback, Unsubscribe } from '../SubsciptionTypes';

type Timestamp = firebase.firestore.Timestamp;
type DocumentData = firebase.firestore.DocumentData;
type Firestore = firebase.firestore.Firestore;

const log = logger('RecentItemsRepo');

export type RecentItemDomain = {
  id: string;
  itemName: string;
  name_idx: string;
  completedOn: Timestamp[];
  addedOn: Timestamp[];
  lastCompletedDate?: Timestamp;
  lastAddedDate?: Timestamp;
};

const recentItemsCollectionDB: (
  listId: string,
  db: firebase.firestore.Firestore
) => firebase.firestore.DocumentReference = (listId, db) => {
  return db.collection('RecentItems').doc(listId);
};

const newRecentItemCollection: (listId: string, sortType: string, db: firebase.firestore.Firestore) => void = (
  listId,
  sortType,
  db
) => {
  db.collection('RecentItems')
    .doc(listId)
    .set({
      listId,
      sortType: 'OLDEST_FIRST'
    });
};

const recentItemsDB = (listId: string, firestore: Firestore) => {
  return firestore.collection('RecentItems').doc(listId);
};

const itemsDB = (listId: string, firestore: Firestore) => {
  return recentItemsDB(listId, firestore).collection('items');
};

const itemDB = (listId: string, itemId: string, firestore: firebase.firestore.Firestore) => {
  return itemsDB(listId, firestore).doc(itemId);
};

const getFSItemFromData: (data: DocumentData) => RecentItemDomain = data => {
  return data as RecentItemDomain;
};

const findItemByName: (
  listId: string,
  name: string,
  firestore: firebase.firestore.Firestore
) => Promise<RecentItemDomain | undefined> = (listId, name, firestore) => {
  log.debug(`Searching for item ${name} on recent items list ${listId}`);
  return itemsDB(listId, firestore)
    .where('name_idx', '==', nameIdx(name))
    .get({ source: 'cache' })
    .then(snapShot => {
      if (snapShot.empty) {
        log.debug(`Item not found: ${name}`);
        return undefined;
      }

      if (snapShot.size > 1) {
        log.warn(`Found more than one item for list ${listId} with name ${name}`);
      }

      log.debug(`SL: Item ${name} found...`);
      return getFSItemFromData(snapShot.docs[0].data());
    })
    .catch(() => {
      log.debug(`Recent item not found in cache list: ${listId}, item: ${name}`);
      return undefined;
    });
};

const queryToRecentItems: (snapshot: firebase.firestore.QuerySnapshot) => RecentItem[] = query => {
  return query.docs.map(doc => doc.data()).map(toRecentItem);
};

const subscribeToItems: (
  listId: string,
  cb: SubscriptionCallback<RecentItem[]>,
  db: firebase.firestore.Firestore
) => Unsubscribe = (listId, cb, db) => {
  if (!listId) {
    log.warn('Cant subscribe because there is not list.');
    return () => {
      log.warn('Cant subscribe because there is not list.');
    };
  }

  return recentItemsRepo.itemsDB(listId, db).onSnapshot(snapshot => {
    log.debug(`Recent items received: ${snapshot.docs.length} from cache: ${snapshot.metadata.fromCache}`);
    const recentItems = queryToRecentItems(snapshot);
    cb(recentItems);
  });
};

const deleteItem = (listId: string, itemId: string, firestore: firebase.firestore.Firestore) => {
  return itemsDB(listId, firestore)
    .doc(itemId)
    .delete();
};

const deleteRecentItems: (listId: string, firestore: Firestore) => Promise<void> 
  = async (listId, firestore) => {
  await recentItemsDB(listId, firestore).delete();
  log.debug(`Recent items ${listId} has been deleted`);
  return;
}

const findItemById: (
  listId: string,
  itemId: string,
  firestore: firebase.firestore.Firestore
) => Promise<RecentItem | undefined> = (listId, itemId, firestore) => {
  log.debug('Fetching item from cache');
  return itemDB(listId, itemId, firestore)
    .get({ source: 'cache' })
    .then(snapshot => {
      return snapshot.exists ? snapshot.data() : undefined;
    })
    .catch(() => {
      log.debug('Not found on cache, returning empty');
      return undefined;
    })
    .then(data => {
      if (data) {
        return toRecentItem(getFSItemFromData(data));
      }
      return undefined;
    });
};

const saveItems: (listId: string, items: RecentItem[], db: firebase.firestore.Firestore) => void = (
  listId,
  items,
  db
) => {
  log.debug(`Saving ${items.length} recent items`);
  const batch = db.batch();
  for (const item of items) {
    batch.set(itemDB(listId, item.id, db), toFirestoreFormat(item), { merge: true });
  }
  batch.commit();
  log.debug(`Saved ${items.length} items`);
};

const toRecentItem: (value: DocumentData) => RecentItem = value => {
  const data = value as RecentItemDomain;
  return {
    id: data.id,
    itemName: data.itemName,
    name_idx: data.name_idx,
    completedOn: data.completedOn.map((date: Timestamp) => date.toDate()),
    addedOn: data.addedOn ? data.addedOn.map((date: Timestamp) => date.toDate()) : [],
    lastCompletedDate: data.lastCompletedDate ? data.lastCompletedDate.toDate() : undefined,
    lastAddedDate: data.lastAddedDate ? data.lastAddedDate.toDate() : undefined
  };
};

const getOrNull: <R>(r?: R) => R | null = r => (r ? r : null);

const toFirestoreFormat = (data: RecentItem) => {
  return {
    ...data,
    name_idx: nameIdx(data.itemName),
    completedOn: data.completedOn.map(dateStr => moment(dateStr).toDate()),
    addedOn: data.addedOn.map(dateStr => moment(dateStr).toDate()),
    lastCompletedDate: getOrNull(data.lastCompletedDate),
    lastAddedDate: getOrNull(data.lastAddedDate)
  };
};

export const recentItemsRepo = {
  findItemByName,
  itemsDB,
  itemDB,
  recentItemsCollectionDB,
  newRecentItemCollection,
  subscribeToItems,
  deleteItem,
  findItemById,
  queryToRecentItems,
  saveItems,
  deleteRecentItems
};
