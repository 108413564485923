import * as React from 'react';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import { MouseEvent, ReactNode } from 'react';

interface VertMenuProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  icon?: ReactNode;
  id?: string;
  buttonClass?: string;
}

interface VertMenuState {
  open: boolean;
}

export class VertMenu extends React.Component<VertMenuProps, VertMenuState> {
  anchorEl?: HTMLElement;
  state = {
    open: false
  };

  handleMenu = (event: MouseEvent<HTMLElement>) => {
    this.anchorEl = event.currentTarget;
    this.setState({ open: true });
  };

  handleCloseMenu = () => {
    this.anchorEl = undefined;
    this.setState({ open: false });
  };

  render() {
    return (
      <div data-testid={this.props.id ? this.props.id + '-btn' : 'vert-menu-btn'}>
        <IconButton
          aria-owns={this.state.open ? 'menu-appbar' : undefined}
          aria-haspopup="true"
          onClick={this.handleMenu}
          color="inherit"
          className={this.props.buttonClass}>
          {this.props.icon ? this.props.icon : <MoreVertIcon />}
        </IconButton>
        <Menu
          id={this.props.id ? this.props.id : 'vert-menu'}
          open={this.state.open}
          anchorEl={this.anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          onClose={this.handleCloseMenu}
          onClick={this.handleCloseMenu}>
          {this.props.children}
        </Menu>
      </div>
    );
  }
}
