import * as React from 'react';
import { connect } from 'react-redux';
import { Component } from 'react';
import { recipesPageTheme, RecipesRouter } from '../components/recipes/router/RecipesRouter';
import { AuthState } from '../utils/components/auth/Reducers';
import { getNavState, State } from '../reducer';
import { NavState } from './NavReducer';
import { RouteComponentProps, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { ShoppingListRouter, shoppingListTheme } from '../components/shoppinglists/router/ShoppingListRouter';
import { NavActions } from './NavigationActions';
import { MenuDrawer } from '../components/menudrawer/Container';
import { ActionProps } from '../utils/Actions';
import { AdminPage } from '../components/admin/AdminPage';
import { I18nProps } from '../i18n';

export type AppProps = RouteComponentProps<{}> & AuthState & NavState & ActionProps<typeof NavActions> & I18nProps;

class PrivateAppContainer extends Component<AppProps> {
  getRouteTheme = () => (this.props.recipesPageShown ? recipesPageTheme : shoppingListTheme);

  hideMainMenu = () => this.props.openMenu(this.props.recipesPageShown, false);
  
  renderAdminPage = (props: RouteComponentProps<{}>) => <AdminPage {...props} {...this.props} />;
  render() {
    return (
      <ThemeProvider theme={this.getRouteTheme()}>
        <React.Fragment>
          <MenuDrawer open={this.props.menuOpen} closeClicked={this.hideMainMenu} {...this.props} />
          <ShoppingListRouter {...this.props} />
          <RecipesRouter {...this.props} />
          <Switch>
            <Route path={"/admin"} exact={true} render={this.renderAdminPage} />
          </Switch>
        </React.Fragment>
      </ThemeProvider>
    );
  }
}

const mapPrivateAppStateToProps = (state: State, props: RouteComponentProps<{}>) => {
  return {
    ...getNavState(state)
  };
};

const mapDispatchToProps = {
  ...NavActions
};

export const PrivateRouter = connect(mapPrivateAppStateToProps, mapDispatchToProps)(PrivateAppContainer);
