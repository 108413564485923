import { trimAndLower } from './StringUtils';
export const dedupe = (list: string[]) => {
  return dedupeBy(list, s => s);
};

const takeFirst: <R>(r1: R, r2: R) => R = r1 => r1;

export const dedupeBy: <R>(list: R[], getProp: (r: R) => string, merge?: (r1: R, r2: R) => R) => R[] = (
  list,
  getProp,
  merge = takeFirst
) => {
  const merged: typeof list = [];
  list.forEach((elem, currIdx, arr) => {
    const firstIdx = arr.findIndex(val => trimAndLower(getProp(val)) === trimAndLower(getProp(elem)));
    if (firstIdx === currIdx) {
      merged.push(elem);
    } else {
      const mergedIdx = merged.findIndex(val => trimAndLower(getProp(val)) === trimAndLower(getProp(elem)));
      merged[mergedIdx] = merge(merged[mergedIdx], elem);
    }
  });

  return merged;
};

export const containsAny: <R>(sourceList: R[], targetList: R[]) => boolean = (sourceList, targetList) => {
  return sourceList.some(sourceItem => targetList.indexOf(sourceItem) >= 0);
};

export const removeEmpty = (list: string[]) => {
  return list.filter(elem => elem && elem.toLowerCase() !== 'not set').filter(elem => elem && elem.trim() !== '');
};

const removeItem: <R>(list: R[], r: R) => R[] = (list, r) => {
  return list.filter(item => item !== r);
};

/**
 * Returns a new list with r inserted at the beginning and 
 * limiting the size to maxLength
 * @param list 
 * @param r 
 * @param maxLength 
 */
export const unshiftAndLimit: <R>(list: R[], r: R, maxLength: number) => R[] = (list, r, maxLength) => {
  const newList = [...list];
  newList.unshift(r);
  newList.splice(maxLength);
  return newList;
}

const isEmpty: <R>(list?: R[]) => boolean = list => {
  return !list || list.length === 0;
};

const includes: <R>(list?: R[], r?: R) => boolean = (list, r) => {
  if (!list) {
    return false;
  }

  if (!r) {
    return false;
  }

  return list.indexOf(r) >= 0;
};

/**
 * Sorts the date array.
 * @param dates to be sorted.
 * @param order 'ASC' (default) or 'DESC'
 */
export const sortDateArray: (dates: Date[], order?: 'ASC' | 'DESC') => Date[] = (dates, order = 'ASC') => {
  return dates.sort((d1, d2)=>{
    if (order === 'ASC') {
      return d1.getTime() - d2.getTime();
    }
    return d2.getTime() - d1.getTime();
  });
}

/**
 * Remove the time component of the dates and dedupe by the day of the year.
 * @param dates 
 */
export const dedupeDatesByDay: (dates: Date[]) => Date[] = (dates) => {
  const deduped = dedupeBy(dates, (date) => `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`);
  return deduped.map(date => new Date(date.getFullYear(), date.getMonth(), date.getDate()));
}

export const last: <R>(list?: R[]) => R | undefined = (list) => {
  const notEmpty = list && list.length;
  if (notEmpty) {
    return list![list!.length - 1];
  }

  return undefined;
}

export const listUtils = {
  removeItem,
  isEmpty,
  includes,
  dedupeBy,
  containsAny,
  sortDateArray,
  last,
  unshiftAndLimit,
  dedupeDatesByDay
};
