import { Action } from 'redux';
import { NavAction } from '../../../router/NavReducer';
import { LISTS_PATH } from './ShoppingListRouter';
import { logger } from '../../../utils/LoggingUtils';

const log = logger('shoppinglist.NavigationReducer');

export interface ShoppingListsNavigationState {
  itemHistoryOpen: boolean;
  itemsForLaterOpen: boolean;
  listCategoriesOpen: boolean;
  newCategoryDialogOpen: boolean;
  itemCategoriesOpen: boolean;
  renameItemOpen: boolean;
  shareListOpen: boolean;
  renameListOpen: boolean;
  menuOpen: boolean;
  addListFormOpen: boolean;
  aboutDialogOpen: boolean;
  notificationsDrawerOpen: boolean;
  versionUpdateDialogOpen: boolean;
  isRecipesFilterOpen: boolean;
  isAddListItemOpen: boolean;
}

const emptyState = {
  itemHistoryOpen: false,
  itemsForLaterOpen: false,
  listCategoriesOpen: false,
  newCategoryDialogOpen: false,
  itemCategoriesOpen: false,
  renameItemOpen: false,
  shareListOpen: false,
  renameListOpen: false,
  menuOpen: false,
  addListFormOpen: false,
  aboutDialogOpen: false,
  notificationsDrawerOpen: false,
  versionUpdateDialogOpen: false,
  isRecipesFilterOpen: false,
  isAddListItemOpen: false
};

export const isShoppingListsShown: (path: string) => boolean = path => path.startsWith(LISTS_PATH);

export const shoppingListsNavReducer: (
  state: ShoppingListsNavigationState | undefined,
  action: Action
) => ShoppingListsNavigationState = (state = emptyState, action) => {
  log.trace(`Checking action type ${action.type}`);
  switch (action.type) {
    case '@@router/LOCATION_CHANGE': {
      const navAction = action as NavAction;
      const path = navAction.payload.location.pathname;
      return {
        itemHistoryOpen: path === `${LISTS_PATH}/recent-items`,
        itemsForLaterOpen: path === `${LISTS_PATH}/items-for-later`,
        listCategoriesOpen: path.startsWith(`${LISTS_PATH}/list-categories`),
        newCategoryDialogOpen: path === `${LISTS_PATH}/list-categories/new-category`,
        itemCategoriesOpen: path.startsWith(`${LISTS_PATH}/item-categories/`),
        renameItemOpen: path.startsWith(`${LISTS_PATH}/rename-item/`),
        shareListOpen: path === `${LISTS_PATH}/share-list`,
        renameListOpen: path === `${LISTS_PATH}/rename-list`,
        notificationsDrawerOpen: path === `${LISTS_PATH}/notifications`,
        menuOpen: path.endsWith('/menu'),
        addListFormOpen: path === `${LISTS_PATH}/menu/add-list`,
        aboutDialogOpen: path === `${LISTS_PATH}/about`,
        versionUpdateDialogOpen: path === `${LISTS_PATH}/version-update`,
        isRecipesFilterOpen: path === `${LISTS_PATH}/filter-recipes`,
        isAddListItemOpen: path === `${LISTS_PATH}/add-list-item`
      };
    }
    default:
      return state;
  }
};
