import { combineReducers, Action } from 'redux';
import { getRecipesPageState, State } from '../../reducer';
import { recipesListReducer, RecipesListState } from './recipeslist/Reducer';
import { recipeDetailsReducer, RecipeDetailsState } from './recipedetails/Reducers';
import { recipesNavReducer, RecipesNavState } from './router/RecipesNavReducer';
import {
  addRecipeItemButtonReducer,
  AddRecipeItemButtonState,
  getAutoCompleteState
} from './recipedetails/additem/Reducers';
import { AutoCompleteState } from '../../utils/components/autocomplete/Reducers';
import { topBarReducer, TopBarState } from './topbar/Reducers';
import { logger } from '../../utils/LoggingUtils';
import { RecipeItemsHistoryUpdatedAction } from './RecipesPageActions';

const log = logger('RecipesPage.reducer')

export type AllItemsState = {
  allItems: string[];
}

const emptyAllItemsState = {
  allItems: [],
}

const allItemsReducer: (state: AllItemsState | undefined, action: Action) => AllItemsState
  = (state = emptyAllItemsState, action) => {
    switch (action.type) {
      case 'RecipeItemsHistoryUpdatedAction': {
        const myAction = action as RecipeItemsHistoryUpdatedAction;
        log.debug(`Recipe items history updated. Items: ${myAction.payload.items && myAction.payload.items.length}`)
        return {
          ...state,
          allItems: myAction.payload.items.map(item => item.name)
        };
      }
      default:
        return state;
    }
  }


export const getRecipesListSate: (state: State) => RecipesListState = state =>
  getRecipesPageState(state).recipesListState;
export const getRecipeDetailsState: (state: State) => RecipeDetailsState = state =>
  getRecipesPageState(state).recipeDetailsState;
export const getRecipesNavState: (state: State) => RecipesNavState = state =>
  getRecipesPageState(state).recipesNavState;

export const getAddRecipeItemState: (state: State) => AddRecipeItemButtonState = state =>
  getRecipesPageState(state).addRecipeItemState;

export const getAddRecipeItemAutocompleteState: (state: State) => AutoCompleteState = state =>
  getAutoCompleteState(getRecipesPageState(state).addRecipeItemState);

export const getTopBarState: (state: State) => TopBarState = state =>
  getRecipesPageState(state).topBarState;

export const getAllItemsState: (state: State) => AllItemsState = state =>
  getRecipesPageState(state).allItemsState;

/**
 * Root reducer for Recipes page.
 */
export const recipesPageReducer = combineReducers({
  allItemsState: allItemsReducer,
  recipesListState: recipesListReducer,
  recipeDetailsState: recipeDetailsReducer,
  recipesNavState: recipesNavReducer,
  addRecipeItemState: addRecipeItemButtonReducer,
  topBarState: topBarReducer,
});

export type RecipesPageState = {
  recipesListState: RecipesListState;
  recipeDetailsState: RecipeDetailsState;
  recipesNavState: RecipesNavState;
  addRecipeItemState: AddRecipeItemButtonState;
  topBarState: TopBarState;
  allItemsState: AllItemsState;
};