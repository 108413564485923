import * as React from 'react';
import { Component } from 'react';
import { AuthState } from '../../utils/components/auth/Reducers';
import AddItemButton from './additembutton/Container';
import { CurrentShoppingList } from './currentshoppinglist/Container';
import { TopBar } from './topbar/Container';
import { ShoppingListsPageActions } from './ShoppingListsPageActions';
import { connect } from 'react-redux';
import { domainApi } from '../../domain/DomainApi';
import { Unsubscribe } from '../../domain/SubsciptionTypes';
import { RouteComponentProps } from 'react-router-dom';
import { State } from '../../reducer';
import { ShoppingListsNavActions } from './router/NavigationActions';
import { ShoppingListsNavigationState } from './router/NavigationReducer';
import { getShoppingListNavState } from './ShoppingListsPageReducer';
import { logger } from '../../utils/LoggingUtils';
import { ActionProps } from '../../utils/Actions';
import { AppProps } from '../../router/PrivateRouter';

const log = logger('ShoppingListPage');

export type ShoppingListPageProps = AuthState &
  ActionProps<typeof ShoppingListsPageActions & typeof ShoppingListsNavActions> &
  AppProps &
  RouteComponentProps<{ [key: string]: string }> &
  ShoppingListsNavigationState;

class ShoppingListsPageContainer extends Component<ShoppingListPageProps> {
  unsubscribeListCategories?: Unsubscribe;

  componentDidMount() {
    log.debug('Mounting User App');
    this.unsubscribeListCategories = domainApi.subscribeToListCategories(
      this.props.selectedList,
      this.props.onListCategoriesUpdated
    );
  }

  componentWillUnmount() {
    log.debug('Unmounting shopping list page');
    this.unsubscribeListCategories && this.unsubscribeListCategories();
  }

  componentDidUpdate(prevProps: Readonly<ShoppingListPageProps>) {
    const newProps = this.props;
    if (newProps.selectedList !== prevProps.selectedList) {
      log.debug(`Selected list changed, updating list categories subscriptions to list ${newProps.selectedList}`);
      this.unsubscribeListCategories && this.unsubscribeListCategories();
      this.unsubscribeListCategories = domainApi.subscribeToListCategories(
        newProps.selectedList,
        newProps.onListCategoriesUpdated
      );
    }
  }

  render() {
    return (
      <React.Fragment>
        <TopBar {...this.props} />
        <CurrentShoppingList {...this.props} />
        <AddItemButton {...this.props} />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = {
  ...ShoppingListsPageActions,
  ...ShoppingListsNavActions
};

const mapStateToProps = (state: State) => {
  return {
    ...getShoppingListNavState(state)
  };
};

export const ShoppingListsPage = connect(mapStateToProps, mapDispatchToProps)(ShoppingListsPageContainer);
