import { SyncAction, SyncActionClass, ActionProps } from '../../Actions';
import * as firebase from 'firebase/app';
import { domainApi } from '../../../domain/DomainApi';
import * as ReactGA from 'react-ga';
import { UserDetails } from '../../../domain/userdetals/Types';
import { getUserId } from '../../firebase/FirestoreConfig';

export class AuthChangedAction extends SyncActionClass<firebase.User | null> {
  constructor(user: firebase.User | null) {
    super('AuthChangedAction', user);
  }
}

export class AuthenticatingAction extends SyncActionClass<{}> {
  constructor() {
    super('AuthenticatingAction', {});
  }
}

export class LogoutAction extends SyncActionClass<{}> {
  constructor() {
    super('LogoutAction', {});
  }
}

const onLogout: () => SyncAction = () => new LogoutAction().action();

const onAuthenticating = () => new AuthenticatingAction().action();

const onAuthChanged: (user: firebase.User | null) => SyncAction = (user: firebase.User | null) => {
  if (user) {
    const userId = getUserId(user);
    ReactGA.set({ userId: userId });
    ReactGA.event({
      category: 'User',
      action: 'Logged in',
      label: userId || undefined
    });
    domainApi.updateUserDetails(user);
  }
  return new AuthChangedAction(user).action();
};

const onUserDetailsChanged: (user: UserDetails) => SyncAction = user => new UserDetailsChanged(user).action();

export class UserDetailsChanged extends SyncActionClass<UserDetails> {
  constructor(userDetails: UserDetails) {
    super('UserDetailsChanged', userDetails);
  }
}

export const AuthActions = {
  onAuthChanged,
  onAuthenticating,
  onLogout,
  onUserDetailsChanged
};

export type AuthProps = ActionProps<typeof AuthActions>
