import * as React from 'react';
import { StyleRules, withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import {
  Collapse,
  LinearProgress,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Theme,
  WithStyles
} from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import UserListsIcon from '@material-ui/icons/LibraryAdd';
import ExitIcon from '@material-ui/icons/ExitToApp';
import ListIcon from '@material-ui/icons/Done';
import AboutIcon from '@material-ui/icons/HelpOutline';
import NotificationsIcon from '@material-ui/icons/Notifications';
import RecipesIcon from '@material-ui/icons/Fastfood';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import AddIcon from '@material-ui/icons/Add';
import MyListsIcon from '@material-ui/icons/FormatListBulleted';
import AddListDrawer from '../shoppinglists/addlistdrawer/Container';
import { MenuDrawerProps } from './Container';
import Grid from '@material-ui/core/Grid';
import { UserList } from './Types';
import { AboutDialog } from '../aboutdialog/Container';
import Badge from '@material-ui/core/Badge';
import { NotificationsDrawer } from '../notificationsdrawer/Container';
import { AuthState } from '../../utils/components/auth/Reducers';
import { logger } from '../../utils/LoggingUtils';

const log = logger('MenuDrawerComponent');

export type MenuDrawerComponentProps = MenuDrawerProps & WithStyles;

const style: (theme: Theme) => StyleRules = theme => ({
  list: {
    width: 'auto',
    paddingTop: 0
  },
  selectedItem: {
    backgroundColor: 'lightgray'
  },
  actionText: {
    color: theme.palette.secondary.light
  },
  fullList: {
    width: 'auto'
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    ...theme.mixins.toolbar
  },
  listIcon: {
    fontSize: '14px',
    color: theme.palette.grey.A700
  },
  listIconText: {
    fontSize: '13px',
    color: theme.palette.grey.A700
  },
  avatar: {
    margin: 10
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  drawerPaper: {},
  link: {
    color: theme.palette.secondary.main,
    textDecoration: 'none'
  }
});

class MenuDrawerComponentStyled extends React.Component<MenuDrawerComponentProps> {
  handleExpandMyListClick = () => {
    this.props.expandUserLists(!this.props.userListsExpanded);
  };

  showAddForm = () => this.props.showAddListForm(true);
  hideAddForm = (newListId?: string) => {
    if (newListId) {
      log.debug(`Selected user list ${this.props.userId} ${newListId}`);
      this.props.selectUserList(this.props.userId, newListId);
      this.props.closeClicked();
    } else {
      this.props.showAddListForm(false);
    }
    
  }
  showNotificationsDrawer = () => this.props.showNotificationListDrawer(true);
  hideNotificationsDrawer = () => this.props.showNotificationListDrawer(false);
  showAboutDialog = () => this.props.showAboutDialog(true);
  hideAboutDialog = () => this.props.showAboutDialog(false);
  navigateToRecipes = () => this.props.goToRecipesPage();
  navigateToLists = () => this.props.goToListsPage();

  render() {
    const props = this.props;
    const t = props.t;
    // Classes can not be passed down to children component due to warning:
    // https://stackoverflow.com/questions/48936467/
    // material-ui-the-key-provided-to-the-classes-property-is-not-implemented
    const { classes, open, closeClicked, ...inheritableProps } = props;
    const authState = inheritableProps as AuthState;

    const itemClicked = (listId: string) => {
      log.debug(`Selected user list ${props.userId} ${listId}`);
      props.selectUserList(props.userId, listId);
      props.closeClicked();
    };

    const itemsTotal = (items: number) => {
      return (
        <Grid container={true}>
          <Grid item={true}>
            <ListIcon className={classes.listIcon} />
          </Grid>
          <Grid item={true}>
            <div className={`${classes.listIconText}`}>{items}</div>
          </Grid>
        </Grid>
      );
    };

    const listComponents = props.userLists.lists.map((list: UserList) => {
      let classNames = `${classes.nested}`;

      if (list.id === props.userLists.selectedList) {
        classNames = classNames + ` ${classes.selectedItem}`;
      }

      return (
        <ListItem
          button={true}
          // tslint:disable-next-line jsx-no-lambda
          onClick={() => itemClicked(list.id)}
          className={classNames}
          key={list.id}>
          <ListItemText primary={list.name} />
          <ListItemSecondaryAction style={{ verticalAlign: 'Top' }}>
            {itemsTotal(list.totalItems)}
          </ListItemSecondaryAction>
        </ListItem>
      );
    });

    const loading = this.props.loading ? <LinearProgress /> : null;

    const naviageToListItem = (
      <ListItem button={true} onClick={this.navigateToLists} data-testid="navigate-to-lists">
        <ListItemIcon>
          <MyListsIcon />
        </ListItemIcon>
        <ListItemText primary={t('My lists')} />
      </ListItem>
    );

    let userLists = (
      <List className={classes.list}>
        <ListItem button={true} onClick={this.handleExpandMyListClick}>
          <ListItemIcon>
            <UserListsIcon />
          </ListItemIcon>
          <ListItemText primary={t('My lists')} />
          {this.props.userListsExpanded ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={this.props.userListsExpanded} timeout="auto" unmountOnExit={true}>
          <List component="span" disablePadding={true} data-testid="sl-menu-lists">
            {listComponents}
            <ListItem
              button={true}
              onClick={this.showAddForm}
              className={`${classes.nested} ${classes.actionText}`}
              data-testid="sl-new-list">
              <ListItemText primary={t('New list')} disableTypography={true} />
              <ListItemSecondaryAction>
                <AddIcon color={'secondary'} />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </Collapse>
      </List>
    );

    return (
      <div>
        <Drawer
          anchor="left"
          open={props.open}
          onClose={props.closeClicked}
          classes={{
            paper: classes.drawerPaper
          }}>
          <div className={classes.drawerHeader}>
            <Avatar className={classes.avatar}
              src={this.props.photoURL} 
              imgProps={{ onError: (e) => { 
                log.debug(`Error loading: ${e.currentTarget.src}`);
                e.currentTarget.src = `https://ui-avatars.com/api/?name=${this.props.email}`;
              } 
            }}
            />
            <Typography variant="body1" color="inherit">
              {this.props.name || this.props.email}
            </Typography>
          </div>
          <Divider />
          <List component="span" disablePadding={true}>
            {this.props.totalNotifications > 0 ? (
              <ListItem button={true} onClick={this.showNotificationsDrawer}>
                <ListItemIcon>
                  <Badge badgeContent={this.props.totalNotifications || 0} color="secondary">
                    <NotificationsIcon />
                  </Badge>
                </ListItemIcon>
                <ListItemText primary={t('Notifications')} />
              </ListItem>
            ) : (
              <ListItem button={false} disabled={true}>
                <ListItemIcon>
                  <NotificationsIcon />
                </ListItemIcon>
                <ListItemText primary={t('Notifications')} />
              </ListItem>
            )}
          </List>
          <Divider />
          <List component="span" disablePadding={true}>
            <ListItem button={true} onClick={this.navigateToRecipes} data-testid="navigate-to-recipes">
              <ListItemIcon>
                <RecipesIcon />
              </ListItemIcon>
              <ListItemText primary={t('Recipe ingredients')} />
            </ListItem>
          </List>
          <Divider />
          <div tabIndex={0} role="button">
            {loading}
            {this.props.listsPageShown ? userLists : naviageToListItem}
          </div>
          <Divider />
          <List component="span" disablePadding={true}>
            <ListItem button={true} onClick={this.showAboutDialog}>
              <ListItemIcon>
                <AboutIcon />
              </ListItemIcon>
              <ListItemText primary={t('About')} />
            </ListItem>
          </List>
          <Divider />
          <List component="span" disablePadding={true}>
            <ListItem id="LogOutMenuItem" button={true} onClick={props.logout}>
              <ListItemIcon>
                <ExitIcon />
              </ListItemIcon>
              <ListItemText primary={t('Log out')} />
            </ListItem>
          </List>
        </Drawer>
        <AboutDialog open={props.aboutDialogOpen} closeClicked={this.hideAboutDialog} t={t} />
        <AddListDrawer open={props.addListFormOpen} closeClicked={this.hideAddForm} {...authState} />
        <NotificationsDrawer open={props.notificationsDrawerOpen} closeClicked={this.hideAddForm} {...authState} />
      </div>
    );
  }
}

export const MenuDrawerComponent = withStyles(style)(MenuDrawerComponentStyled);
