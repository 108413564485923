import { safeEmpty } from '../../../utils/firebase/FirestoreConfig';
import { nameIdx } from '../../../utils/IdUtils';
import { logger } from '../../../utils/LoggingUtils';
import { Recipe, RecipeItem } from '../Types';
import * as firebase from 'firebase/app';

type Timestamp = firebase.firestore.Timestamp;
type Firestore= firebase.firestore.Firestore;

const log = logger('RecipesRepository');

const recipesListDB = (firestore: Firestore, listId: string) => {
  return firestore.collection('RecipesList').doc(listId);
}

const recipesDB = (firestore: Firestore, listId: string) => {
  return recipesListDB(firestore, listId).collection('Recipes');
};

const recipeDetailsDB = (firestore: firebase.firestore.Firestore, listId: string, recipeId: string) => {
  return recipesDB(firestore, listId).doc(recipeId);
};

const findRecipeById: (firestore: firebase.firestore.Firestore, listId: string, recipeId: string) => Promise<Recipe | undefined> = (
  firestore,
  listId,
  recipeId
) => {
  return recipeDetailsDB(firestore, listId, recipeId)
    .get({ source: 'cache' })
    .then(documentToRecipe);
};

const saveRecipe: (firestore: firebase.firestore.Firestore, recipe: Recipe) => Promise<Recipe> = (firestore, recipe) => {
  return recipesDB(firestore, recipe.listId)
    .doc(recipe.id)
    .set(safeRecipe(recipe), { merge: true })
    .then(() => recipe);
};

const documentToRecipe: (snapshot: firebase.firestore.DocumentSnapshot) => Recipe | undefined = snapshot => {
  const data = snapshot.data();
  if (data) {
    return fsToRecipe(data as RecipeFirebase);
  }

  return undefined;
};

const toRecipeItems: (data: RecipeItemFirebase) => RecipeItem = data => {
  return {
    id: data.id,
    itemName: data.itemName,
    name_idx: nameIdx(data.itemName),
    completedOn: data.completedOn ? data.completedOn.toDate() : undefined,
    addedOn: data.addedOn ? data.addedOn.toDate() : undefined
  };
};

const fsToRecipe: (recipeFs: RecipeFirebase) => Recipe = recipeFS => {
  return {
    ...recipeFS,
    items: recipeFS.items ? recipeFS.items.map(toRecipeItems) : []
  };
};

const queryToRecipeFsList = (snapshot: firebase.firestore.QuerySnapshot) => snapshot.docs.map(doc => doc.data() as RecipeFirebase);

const queryToRecipe: (snapshot: firebase.firestore.QuerySnapshot) => Recipe[] = snapshot => {
  return queryToRecipeFsList(snapshot).map(fsToRecipe);
};

const findRecipesByItemName: (firestore: firebase.firestore.Firestore, listId: string, itemName: string) => Promise<Recipe[]> = (
  firestore,
  listId,
  itemName
) => {
  log.debug(`Searching recipes with item name: ${itemName} in list ${listId}`);
  return recipesDB(firestore, listId)
    .get()
    .then(queryToRecipeFsList)
    .then(recipes => {
      return recipes.filter(recipe => recipe.items && recipe.items.some(item => item.name_idx === nameIdx(itemName)));
    })
    .then(fsRecipes => {
      log.debug(`Found ${fsRecipes.length} recipes`);
      return fsRecipes.map(fsToRecipe);
    });
};

const findAllRecipes: (firestore: firebase.firestore.Firestore, listId: string) => Promise<Recipe[]> = (firestore, listId) => {
  return recipesDB(firestore, listId)
      .get()
      .then(queryToRecipeFsList)
      .then(fsRecipes => {
          log.debug(`Found ${fsRecipes.length} recipes`);
          return fsRecipes.map(fsToRecipe);
      });
};

// TODO-AC RecipeFirebase has to be in sync with Recipes. Find a way to do it.
export interface RecipeFirebase {
  listId: string;
  id: string;
  name: string;
  name_idx: string;
  items: RecipeItemFirebase[];
}

export interface RecipeItemFirebase {
  id: string;
  itemName: string;
  name_idx: string;
  completedOn?: Timestamp;
  addedOn?: Timestamp;
}

const safeRecipe = (recipe: Recipe) => {
  return {
    ...recipe,
    items: recipe.items ? recipe.items.map(item => safeRecipeItem(item, true)) : [],
    name_idx: nameIdx(recipe.name)
  };
};

// TODO-AC: use nulls is a hack!!! for lists you can not do safe empty, for objects, you can.
const safeRecipeItem = (recipeItem: RecipeItem, useNulls: boolean = false) => {
  return {
    id: recipeItem.id,
    itemName: recipeItem.itemName,
    name_idx: nameIdx(recipeItem.itemName),
    addedOn: safeEmpty(recipeItem.addedOn, useNulls),
    completedOn: safeEmpty(recipeItem.completedOn, useNulls)
  };
};

const saveList: (listId: string, recipes: Recipe[], firestore: firebase.firestore.Firestore) => void = (
  listId,
  recipes,
  firestore
) => {
  log.debug(`Saving ${recipes.length} recipes`);
  const batch = firestore.batch();
  for (const recipe of recipes) {
    const recipeRef = recipeDetailsDB(firestore, listId, recipe.id);
    batch.set(recipeRef, safeRecipe(recipe), { merge: true });
  }
  batch.commit();
  log.debug(`Saved ${recipes.length} recipes`);
};

const deleteRecipesList: (listId: string, firestore: Firestore) => Promise<void> = async (listId, firestore) => {
  await recipesListDB(firestore, listId).delete();
  log.debug(`Recipes for list ${listId} have been deleted`);
  return;
}

export const RecipesRepo = {
  recipesDB,
  recipeDetailsDB,
  findRecipeById,
  saveRecipe,
  toRecipeItems,
  queryToRecipe,
  documentToRecipe,
  safeRecipeItem,
  findRecipesByItemName,
  findAllRecipes,
  saveList,
  deleteRecipesList
};
