import { Action } from 'redux';
import { AddRecipeItemTextChanged } from './Actions';
import {
  autoCompleteReducer,
  AutoCompleteState,
  emptyAutoCompleteState
} from '../../../../utils/components/autocomplete/Reducers';

export type AddRecipeItemButtonState = Readonly<{
  inputText: string;
  autoComplete: AutoCompleteState;
}>;

const emptySearchBarState = {
  inputText: '',
  autoComplete: emptyAutoCompleteState,
};

export const getAutoCompleteState = (state: AddRecipeItemButtonState) => state.autoComplete;

const resetDataInput = (state: AddRecipeItemButtonState) => {
  return {
    ...state,
    inputText: '',
    autoComplete: emptyAutoCompleteState
  };
};

export const addRecipeItemButtonReducer: (
  state: AddRecipeItemButtonState | undefined,
  action: Action
) => AddRecipeItemButtonState = (state = emptySearchBarState, action) => {
  switch (action.type) {
    case 'AddRecipeItemTextChanged': {
      const myAction = action as AddRecipeItemTextChanged;
      return {
        ...state,
        inputText: myAction.payload
      };
    }
    case 'AddRecipeItemAction': {
      return resetDataInput(state);
    }
    case 'AddMoreRecipeItemsAction': {
      return resetDataInput(state);
    }
    case 'CancelRecipeItemAction': {
      return resetDataInput(state);
    }
    default:
      return { ...state, autoComplete: autoCompleteReducer('addRecipeItem')(state.autoComplete, action) };
  }
};
