import * as React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { ShoppingListActions } from './Actions';
import { State } from '../../../reducer';
import { ShoppingListState } from './Reducers';
import { CurrentShoppingListComponent } from './Components';
import { domainApi } from '../../../domain/DomainApi';
import { Unsubscribe } from '../../../domain/SubsciptionTypes';
import { RouteComponentProps } from 'react-router-dom';
import { ShoppingListPageProps } from '../ShoppingListsPage';
import { getShoppingListState } from '../ShoppingListsPageReducer';
import { logger } from '../../../utils/LoggingUtils';
import { ActionProps } from '../../../utils/Actions';

const log = logger('ShoppingListContainer');

type ShoppingListContainerProps = 
  ActionProps<typeof ShoppingListActions> & 
  ShoppingListState & 
  ShoppingListPageProps;

const userHasLoaded = (props: ShoppingListContainerProps) => {
  return (props.loggedIn === 'true' || props.loggedIn === 'loading') && !!props.selectedList;
};

class CurrentShoppingListContainer extends Component<ShoppingListContainerProps> {
  unSubsListItems?: Unsubscribe;
  unSubsShoppingList?: Unsubscribe;

  componentDidMount() {
    if (userHasLoaded(this.props)) {
      log.debug('User logged in, on mount');
      this.subscribeToDomain();
    } else {
      log.debug('Waiting for user to login');
    }
  }

  componentWillUnmount() {
    if (this.unSubsListItems && this.unSubsShoppingList) {
      log.debug('Unsubscribing to ShoppingListItems');
      this.unSubsListItems();
      this.unSubsShoppingList();
    }
  }

  componentDidUpdate(prevProps: Readonly<ShoppingListContainerProps>) {
    const nextProps = this.props;
    if (!userHasLoaded(prevProps) && userHasLoaded(nextProps)) {
      log.debug('User logged in on update');
      this.subscribeToDomain();
    } else {
      this.resuscribeIfDifferentList(nextProps, prevProps);
    }
  }

  render() {
    const loading =
      !this.props.shoppingList.id || this.props.shoppingList.id !== this.props.selectedList;
    return <CurrentShoppingListComponent {...this.props} loading={loading} />;
  }

  private resuscribeIfDifferentList(
    nextProps: Readonly<{ children?: React.ReactNode }> & Readonly<ShoppingListContainerProps>,
    prevProps: Readonly<ShoppingListContainerProps>
  ) {
    if (nextProps.selectedList !== prevProps.selectedList) {
      log.debug(`Selected list changed, updating subscriptions to list ${nextProps.selectedList}`);
      if (this.unSubsListItems && this.unSubsShoppingList) {
        this.unSubsListItems();
        this.unSubsShoppingList();
      }
      this.subscribeToDomain();
    }
  }

  private subscribeToDomain() {
    if (this.props.selectedList) {
      this.unSubsListItems = domainApi.subscribeToShoppingListItems(
        this.props.selectedList,
        this.props.onListItemChanged
      );
      this.unSubsShoppingList = domainApi.subscribeToShoppingList(
        this.props.selectedList,
        this.props.onShoppingListChanged
      );
    }
  }
}

const mapDispatchToProps = {
  ...ShoppingListActions
};

const mapStateToProps = (state: State, props: RouteComponentProps<{ [key: string]: string }>) => {
  return {
    ...getShoppingListState(state)
  };
};

export const CurrentShoppingList = connect(mapStateToProps, mapDispatchToProps)(CurrentShoppingListContainer);
