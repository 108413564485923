import * as React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { State } from '../../../reducer';
import { ShoppingListsPage } from '../ShoppingListsPage';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import { AppProps } from '../../../router/PrivateRouter';

export const ITEM_CATEGORIES_PATH = '/lists/item-categories/:selectedItem';
export const RENAME_ITEM_PATH = '/lists/rename-item/:selectedItem';
export const LISTS_PATH = '/lists';

export const shoppingListTheme = createMuiTheme({
  palette: {
    primary: {
      main: 'rgba(0, 191, 165, 0.83)',
      contrastText: '#ffffff'
    },
    secondary: {
      main: 'rgba(255, 109, 0, 0.88)',
      contrastText: '#ffffff'
    }
  }
});

export class ShoppingListRouterContainer extends React.Component<AppProps> {
  renderShoppingListPage = (props: RouteComponentProps<{}>) => <ShoppingListsPage {...props} {...this.props} />;
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Redirect exact={true} from="/" to="/lists" />
          <Redirect exact={true} from="/version-update" to="/lists/version-update" />
          <Route
            path={
              '/lists:path(|/|/menu|/recent-items|/share-list|/rename-list|/items-for-later|' +
              '/list-categories|/about|/version-update|/menu/add-list|/list-categories/new-category|' +
              '/notifications|/filter-recipes|/add-list-item)'
            }
            exact={true}
            render={this.renderShoppingListPage}
          />
          <Route path={ITEM_CATEGORIES_PATH} exact={true} render={this.renderShoppingListPage} />
          <Route path={RENAME_ITEM_PATH} exact={true} render={this.renderShoppingListPage} />
        </Switch>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: State, props: RouteComponentProps<{}>) => {
  return {};
};

export const ShoppingListRouter = connect(mapStateToProps, {})(ShoppingListRouterContainer);
