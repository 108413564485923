import { combineReducers } from 'redux';
import { History } from 'history';
import { connectRouter } from 'connected-react-router'
import { authReducer, AuthState } from './utils/components/auth/Reducers';
import { menuDrawerReducer, MenuDrawerState } from './components/menudrawer/Reducers';
import { notificationsDrawerReducer, NotificationsDrawerState } from './components/notificationsdrawer/Reducers';
import { recipesPageReducer, RecipesPageState } from './components/recipes/RecipesPageReducer';
import { shoppingListsReducer, ShoppingListsState } from './components/shoppinglists/ShoppingListsPageReducer';
import { navReducer, NavState } from './router/NavReducer';
import { adminPageReducer, AdminPageState } from './components/admin/AdminPageReducer';


const createRootReducer = (history: History) => combineReducers({
  shoppingListsState: shoppingListsReducer,
  recipesPageState: recipesPageReducer,
  authState: authReducer,
  menuDrawerState: menuDrawerReducer,
  notificationsDrawerState: notificationsDrawerReducer,
  nav: navReducer,
  adminPageState: adminPageReducer,
  router: connectRouter(history)
});


export type State = Readonly<{
  authState: AuthState;
  menuDrawerState: MenuDrawerState;
  notificationsDrawerState: NotificationsDrawerState;
  recipesPageState: RecipesPageState;
  shoppingListsState: ShoppingListsState;
  adminPageState: AdminPageState;
  nav: NavState;
}>;

export const getMenuDrawerState: (state: State) => MenuDrawerState = state => state.menuDrawerState;

export const getAuthState: (state: State) => AuthState = state => state.authState;

export const getNotificationsDrawerState: (state: State) => NotificationsDrawerState = state =>
  state.notificationsDrawerState;

export const getRecipesPageState: (state: State) => RecipesPageState = state => state.recipesPageState;

export const getShoppingListsState: (state: State) => ShoppingListsState = state => state.shoppingListsState;

export const getAdminPageState: (state: State) => AdminPageState = state => state.adminPageState;

export const getNavState: (state: State) => NavState = state => state.nav;

export default createRootReducer;
