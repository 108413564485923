import { Theme, TableHead, TableRow, TableCell, TableSortLabel, Table, TableBody, Avatar, Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button } from '@material-ui/core';
import { StyleRules, WithStyles, withStyles } from '@material-ui/core/styles';
import { UsersContainerProps } from './Container';
import React from 'react';
import SettingsIcon from '@material-ui/icons/Settings';
import moment from 'moment';
import { DashboardUser } from './Types';
import { SortDirection } from '@material-ui/core/TableCell';

const style: (theme: Theme) => StyleRules = theme => ({
    table: {
        minWidth: 750,
    },
    nameCell: {
        display: 'inline-block'
    }
});

export type UsersComponentProps = UsersContainerProps & WithStyles;

const sortDasboardUsers: (users: DashboardUser[], sortDirection: SortDirection, sortColumn: string) => DashboardUser[] =
    (users, sortDirection, sortColumn) => {
        const sortedList: DashboardUser[] = [...users];

        const compareString = (a: string = '', b: string = '') => {
            return sortDirection === 'asc' ?
                (a || '').localeCompare(b || '') :
                (b || '').localeCompare(a || '');
        }

        const compareDates = (a?: Date, b?: Date) => {
            let res;

            if (!a) {
                res = -1;
            } else if (!b) {
                res = 1;
            } else {
                res = a > b ? 1 : -1;
            }

            return sortDirection === 'asc' ? res : -1 * res;
        }

        const compareNumbers = (a?: number, b?: number) => {
            let diff;

            if (!a) {
                diff = -1;
            } else if (!b) {
                diff = 1;
            } else {
                diff = a - b;
            }

            return sortDirection === 'asc' ? diff : -1 * diff;
        }

        sortedList.sort((a, b) => {
            switch (sortColumn) {
                case 'Name':
                    return compareString(a.name, b.name);
                case 'Email':
                    return compareString(a.email, b.email);
                case 'LastLogin':
                    return compareDates(a.lastLoginDate, b.lastLoginDate);
                case 'lastMonth':
                    return compareNumbers(a.dailyLoginsLastMonth, b.dailyLoginsLastMonth);
                case 'lastWeek':
                    return compareNumbers(a.dailyLoginsLastFortnight, b.dailyLoginsLastFortnight);
                default:
                    return compareString(a.name, b.name);
            }
        });

        return sortedList;
    }

interface UsersComponentStyledState {
    actionDialogOpen: boolean;
    removingUserId: string | null;
}

class UsersComponentStyled extends React.Component<UsersComponentProps, UsersComponentStyledState> {
    state: UsersComponentStyledState;

    constructor(props: UsersComponentProps) {
        super(props);
        this.state = { actionDialogOpen: false, removingUserId: null };
    }

    openActionDialog = (userId: string) => {
        this.setState({ actionDialogOpen: true, removingUserId: userId });
    };

    cancelRemove = () => {
        this.props.onActionTextChange('');
        this.setState({ actionDialogOpen: false, removingUserId: null });
    };

    execAction = () => {
        if (this.state.removingUserId) {
            this.props.executeAction(this.state.removingUserId, this.props.actionToExec);
        }

        this.cancelRemove();
    }

    onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.props.onActionTextChange(e.currentTarget.value);
    };

    render() {
        const props = this.props;
        const { classes } = props;
        const sortedList = sortDasboardUsers(props.usersDetails, props.sortDirection, props.sortColumn);
        return (
            <>
                <div>Total Users: {props.totalUsers}</div>
                <div>Total Monthly ( {'<'} 2 logins): {props.totalMonthlyActive}</div>
                <div>Total bi weekly: {props.totalFortnightlyActive}</div>
                <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size="small"
                    aria-label="enhanced table"
                >
                    <TableHead>
                        <TableRow>
                            <TableCell
                                key="Delete"
                                align="right"
                                padding="checkbox"
                            >
                                Action
                    </TableCell>
                            <TableCell
                                key="RowN"
                                align="center"
                                padding="checkbox"
                            >
                                Row
                    </TableCell>
                            <TableCell
                                key="avatar"
                                align="center"
                                padding="checkbox"
                            >
                                Avatar
                    </TableCell>
                            <TableCell
                                key="Name"
                                align="left"
                                padding="default"
                                sortDirection={props.sortColumn === 'Name' ? props.sortDirection : false}
                            >
                                <TableSortLabel
                                    active={props.sortColumn === 'Name'}
                                    direction={props.sortDirection === 'asc' ? 'asc' : 'desc'}
                                    onClick={() => {
                                        props.toggleSort('Name');
                                    }}
                                >
                                    Name
                        </TableSortLabel>
                            </TableCell>
                            <TableCell
                                key="LastLogin"
                                align="left"
                                padding="default"
                                sortDirection={props.sortColumn === 'LastLogin' ? props.sortDirection : false}
                            >
                                <TableSortLabel
                                    active={props.sortColumn === 'LastLogin'}
                                    direction={props.sortDirection === 'asc' ? 'asc' : 'desc'}
                                    onClick={() => {
                                        props.toggleSort('LastLogin');
                                    }}
                                >
                                    Last login
                        </TableSortLabel>
                            </TableCell>
                            <TableCell
                                key="lastWeek"
                                align="left"
                                padding="checkbox"
                            >
                                <TableSortLabel
                                    active={props.sortColumn === 'lastWeek'}
                                    direction={props.sortDirection === 'asc' ? 'asc' : 'desc'}
                                    onClick={() => {
                                        props.toggleSort('lastWeek');
                                    }}
                                >
                                    Last 2 weeks
                                </TableSortLabel>
                            </TableCell>
                            <TableCell
                                key="lastMonth"
                                align="left"
                                padding="checkbox"
                            >
                                <TableSortLabel
                                    active={props.sortColumn === 'lastMonth'}
                                    direction={props.sortDirection === 'asc' ? 'asc' : 'desc'}
                                    onClick={() => {
                                        props.toggleSort('lastMonth');
                                    }}
                                >
                                    Last month
                                </TableSortLabel>
                            </TableCell>
                            <TableCell
                                key="platform"
                                align="left"
                                padding="default"
                            >
                                Platform
                    </TableCell>
                            <TableCell
                                key="resolution"
                                align="left"
                                padding="default"
                            >
                                Resolution
                    </TableCell>
                            <TableCell
                                key="selectedList"
                                align="left"
                                padding="default"
                            >
                                Selected list
                    </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            sortedList.map((user, index) => {
                                return (
                                    <TableRow
                                        hover
                                        key={user.userId}
                                    >
                                        <TableCell align="center">
                                            <SettingsIcon onClick={() => {
                                                this.openActionDialog(user.userId);
                                            }} data-testid={user.email} />
                                        </TableCell>
                                        <TableCell align="right">
                                            {index + 1}
                                        </TableCell>
                                        <TableCell align="center">
                                            <Avatar
                                                src={user.photoURL}
                                                imgProps={
                                                    {
                                                        onError: (e) => {
                                                            e.currentTarget.src = `https://ui-avatars.com/api/?name=${user.email}`;
                                                        }
                                                    }
                                                }
                                            />
                                        </TableCell>
                                        <TableCell >
                                            {user.name || user.email}
                                        </TableCell>
                                        <TableCell>
                                            {user.lastLoginDate ? moment(user.lastLoginDate).format('MMMM Do YYYY') : ''}
                                        </TableCell>
                                        <TableCell>
                                            {user.dailyLoginsLastFortnight || 0}
                                        </TableCell>
                                        <TableCell>
                                            {user.dailyLoginsLastMonth || 0}
                                        </TableCell>
                                        <TableCell>
                                            {`${user.platform} ${user.appVersion === 'android' ? '' : user.appVersion} ${user.lang ? user.lang : ''}`}
                                        </TableCell>
                                        <TableCell>
                                            {user.resolution}
                                        </TableCell>
                                        <TableCell>
                                            {user.selectedList}
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        }
                    </TableBody>
                </Table>
                <Dialog open={this.state.actionDialogOpen} onClose={this.cancelRemove}>
                    <DialogTitle>Enter action to be executed</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Action"
                            type="text"
                            value={this.props.actionToExec}
                            onChange={this.onChange}
                            fullWidth={true}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.cancelRemove} color="primary">
                            Cancel
                    </Button>
                        <Button onClick={this.execAction} color="primary">
                            Ok
                    </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}

export const UsersComponent = withStyles(style)(UsersComponentStyled);