import {
  AsyncAction,
  AsyncActionClass,
  SyncAction,
  SyncActionClass
} from '../../../utils/Actions';
import { domainApi } from '../../../domain/DomainApi';
import { UserDetails } from '../../../domain/userdetals/Types';
import { effectsImpl } from '../../../utils/fp/effects/EffectsImpl';

export class ShareListAction extends AsyncActionClass<{ userId: string; toUserEmail: string; listId: string }, void> {
  constructor(userId: string, toUserEmail: string, listId: string) {
    super('ShareListAction', p => domainApi.shareListWithUser(p.userId, p.toUserEmail, p.listId), {
      userId,
      toUserEmail,
      listId
    });
  }
}

export class ClearUserSuggestionsAction extends SyncActionClass<{}> {
  static TYPE = 'ClearSuggestionsAction';
  constructor() {
    super(ClearUserSuggestionsAction.TYPE, {});
  }
}

export class FetchSuggestionsAction extends AsyncActionClass<string, UserDetails[]> {
  static TYPE = 'FetchSuggestionsAction';
  constructor(searchTerm: string) {
    super(FetchSuggestionsAction.TYPE, searchUsersByName, searchTerm)
  }
}

export class UsersSharingListUpdatedAction extends SyncActionClass<UserDetails[]> {
  static TYPE = 'UsersSharingListUpdatedAction';
  constructor(users: UserDetails[]) {
    super(UsersSharingListUpdatedAction.TYPE, users);
  }
}

const searchUsersByName: (searchTerm: string) => Promise<UserDetails[]>
  = async (searchTerm) => {
    return await domainApi.searchUsersByName(searchTerm).eval(effectsImpl);
  }

export class CloseUserMsg extends SyncActionClass<{}> {
  static TYPE = 'CLOSE_USER_MSG_SHARE_LIST';
  constructor() {
    super(CloseUserMsg.TYPE, {});
  }
}

export class RemoveUserFromList extends SyncActionClass<{user: UserDetails, list: string}> {
  static TYPE = 'RemoveUserFromList';
  constructor(user: UserDetails, list: string) {
    super(RemoveUserFromList.TYPE, {user, list});
  }
}

const clearUserSuggestions: () => SyncAction = () => new ClearUserSuggestionsAction().action();

const fetchSuggestions: (searchTerm: string) => AsyncAction<UserDetails[]> = (
  searchTerm
) => new FetchSuggestionsAction(searchTerm).action();

const shareListWithUser: (userId: string, toUserEmail: string, listId: string) => AsyncAction<void> = (
  userId,
  toUserEmail,
  listId
) => new ShareListAction(userId, toUserEmail, listId).action();

export const closeUserMsg: () => SyncAction = () => new CloseUserMsg().action();
export const onUsersSharingListUpdated: (users: UserDetails[]) => SyncAction = users =>
  new UsersSharingListUpdatedAction(users).action();

export const removeUser: (user: UserDetails, list: string, currentUserId: string) => SyncAction =
  (user, list, currentUserId) => {
    domainApi.removeUserFromList(user.userId, list, 'REMOVED_FROM_SHARED_LIST', currentUserId).eval(effectsImpl);
    return new RemoveUserFromList(user, list).action();
  }

export const ShareListDrawerActions = {
  shareListWithUser,
  closeUserMsg,
  fetchSuggestions,
  clearUserSuggestions,
  onUsersSharingListUpdated,
  removeUser
};
