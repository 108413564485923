import * as log from 'loglevel';

if (process.env.REACT_APP_ENV === 'dev' || process.env.REACT_APP_ENV === 'local') {
  log.info('Log level debug');
  log.setLevel('debug');
} else {
  log.info('Log level info');
  log.setLevel('info');
}


// TODO use this https://github.com/NatLibFi/loglevel-message-buffer
// and this https://github.com/kutuluk/loglevel-plugin-prefix

const logTime = () => {
  const date = new Date();
  return `${date.toLocaleString()}.${date.getMilliseconds()}`;
};

export const logger = (prefix: string) => {
  return {
    info: (msg: string) => log.debug(`${logTime()} - INFO: - ${prefix}:: ${msg}`),
    debug: (msg: string) => log.debug(`${logTime()} - DEBUG: - ${prefix}:: ${msg}`),
    trace: (msg: string) => log.trace(`${logTime()} - TRACE: - ${prefix}:: ${msg}`),
    warn: (msg: string) => log.warn(`${logTime()} - WARN: - ${prefix}:: ${msg}`),
    error: (msg: string) => log.error(`${logTime()} - ERROR: - ${prefix}:: ${msg}`)
  };
};
