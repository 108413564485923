import * as React from 'react';
import { State } from '../../../reducer';
import { connect } from 'react-redux';
import { TopBarComponent } from './Components';
import { TopBarState } from './Reducers';
import { TopBarActions } from './Actions';
import { CommonActions } from '../../../utils/actions/Actions';
import { RouteComponentProps } from 'react-router-dom';
import { Unsubscribe } from '../../../domain/SubsciptionTypes';
import { domainApi } from '../../../domain/DomainApi';
import { ShoppingListPageProps } from '../ShoppingListsPage';
import { getTopBarState } from '../ShoppingListsPageReducer';
import { ActionProps } from '../../../utils/Actions';
import { logger } from '../../../utils/LoggingUtils';

const log = logger('TopBarContainer');

export type TopBarProps = ShoppingListPageProps & TopBarState &
  ActionProps<typeof TopBarActions & typeof CommonActions>;

const mapDispatchToProps = {
  ...TopBarActions,
  ...CommonActions
};

const mapStateToProps = (state: State, props: RouteComponentProps<{}>) => {
  const topBarState = getTopBarState(state);
  return {
    ...topBarState
  };
};

class TopBarContainer extends React.Component<TopBarProps> {
  unsubscribe: Unsubscribe = () => { };

  componentDidMount() {
    if (this.props.userId) {
      this.unsubscribe = domainApi.subscribeToUserNotifications(
        this.props.userId,
        this.props.onNotificationsUpdatedAction
      );
    }
  }

  componentDidUpdate(prevProps: Readonly<TopBarProps>) {
    if (this.props.userId !== prevProps.userId) {
      log.debug(`User has changed. old: ${prevProps.userId} - new: ${this.props.userId}`);
      this.unsubscribe && this.unsubscribe();

      this.unsubscribe = domainApi.subscribeToUserNotifications(
        this.props.userId,
        this.props.onNotificationsUpdatedAction
      );
    }
  }

  componentWillUnmount() {
    this.unsubscribe && this.unsubscribe();
  }

  render() {
    const props = this.props;
    return <TopBarComponent {...props} />;
  }
}

export const TopBar = connect(mapStateToProps, mapDispatchToProps)(TopBarContainer);
