import { SyncAction, SyncActionClass } from '../../../utils/Actions';
import { domainApi } from '../../../domain/DomainApi';
import { UserNotification } from '../../../domain/notifications/Types';
import { effectsImpl } from '../../../utils/fp/effects/EffectsImpl';

export class RemoveListAction extends SyncActionClass<{ userId: string; listId: string }> {
  constructor(userId: string, listId: string) {
    super('RemoveListAction', { userId, listId });
  }
}

export class ShowCategoriesAction extends SyncActionClass<{ listId: string; show: boolean }> {
  constructor(listId: string, show: boolean) {
    super('ShowCategoriesAction', { listId, show });
  }
}

export class NotificationsUpdatedAction extends SyncActionClass<UserNotification[]> {
  constructor(notifications: UserNotification[]) {
    super('NotificationsUpdatedAction', notifications);
  }
}

export class ClearFiltersAction extends SyncActionClass<{}> {
  constructor() {
    super('ClearFiltersAction', {});
  }
}

export const showCategories: (listId: string, show: boolean) => SyncAction = (listId, show) => {
  domainApi.showCategories(listId, show);
  return new ShowCategoriesAction(listId, show).action();
};

export const removeListAction: (userId: string, listId: string) => SyncAction = (userId, listId) => {
  domainApi.removeUserFromList(userId, listId, 'LIST_DELETED').eval(effectsImpl);
  return new RemoveListAction(userId, listId).action();
};

export const onNotificationsUpdatedAction: (notifications: UserNotification[]) => SyncAction = notifications =>
  new NotificationsUpdatedAction(notifications).action();

export const clearFilters: () => SyncAction = () => new ClearFiltersAction().action();

export const TopBarActions = {
  removeListAction,
  showCategories,
  onNotificationsUpdatedAction,
  clearFilters
};
