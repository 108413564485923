import { IO, _do } from '../../../utils/fp/io';
import { logger } from '../../../utils/LoggingUtils';
import { UserDetailsRepositoryIO } from '../../userdetals/io/UserDetailsRepositoryIO';

const log = logger('UpdateNameIdx');

/******* SHELL */
const updateNameIdx: (userId: string) => IO<void> = _do(function* (userId) {
    log.debug(`Updating name for userId ${userId}`);
    const details = yield UserDetailsRepositoryIO.findUserDetailsById(userId);
    yield UserDetailsRepositoryIO.saveUserDetails(details);
});

export const NameIdxService = {
    updateNameIdx
};