import { Duration } from 'moment';
import { listUtils } from './ListUtils';
import { TFunction } from 'i18next';

const humanizeDuration = (d: Duration, t: TFunction) => {
  if (!d.asMinutes) {
    return d;
  }

  if (d.asMinutes() < 121) {
    return `${d.asMinutes().toFixed(0)} ${t('minutes')}`;
  }

  if (d.asHours() < 48) {
    return `${d.asHours().toFixed(0)} ${t('hours')}`;
  }

  if (d.asDays() < 366) {
    return `${d.asDays().toFixed(0)} ${t('days')}`;
  }

  return `${d.asYears().toFixed(0)} ${t('years')}`;
};

/**
 * Returns positive if a is bigger than b, negative if a is smaller than b, 0 if they are equal.
 * If any value is null, it is considered the biggest.
 */
const compareDuration = (a?: Duration, b?: Duration): number => {
  if (!a && !b) {
    return 0;
  }

  if (!a) {
    return 1;
  }

  if (!b) {
    return -1;
  }

  return a.asMilliseconds() - b.asMilliseconds();
}

/**
 * Finds the last date on a date array. Return undefined if array is empty.
 */
export const lastDate: (dates: Date[]) => Date | undefined = (dates) => {
  const sortedDates: Date[] = listUtils.sortDateArray(dates);
  return listUtils.last(sortedDates);
}


export default {
  humanizeDuration
};

export const timeUtils = {
  humanizeDuration,
  compareDuration,
  lastDate
}
