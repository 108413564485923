import * as React from 'react';
import { StyleRules, withStyles } from '@material-ui/core/styles';
import { Theme, WithStyles, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddToListIcon from '@material-ui/icons/SwapHoriz';
import { RecipesListContainerProps } from './Container';
import EmptyImg from '@material-ui/icons/Fastfood';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import Button from '@material-ui/core/Button';
import { SimpleConfirmation } from '../../../utils/components/dialogs/SimpleConfirmation';
import Chip from '@material-ui/core/Chip';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import { VertMenu } from '../../../utils/components/VertMenu';

const style: (theme: Theme) => StyleRules = theme => ({
  list: {
    width: '100%'
  },
  root: {
    flexGrow: 1,
    alignItems: 'center',
    paddingTop: '10px',
    paddingBottom: '10px'
  },
  emptyStateIcon: {
    color: 'rgba(97, 97, 97, 0.5)',
    width: '250px',
    height: '250px'
  },
  emptyState: {
    height: '100%',
    textAlign: 'center',
    paddingTop: '70px'
  },
  card: {
    paddingTop: '0',
    paddingBottom: '0'
  },
  cardHeader: {
    fontSize: '1.5rem'
  },
  cardActions: {
    display: 'flex',
    paddingTop: '2px'
  },
  cardContent: {
    paddingTop: '2px',
    paddingLeft: '10px',
    paddingBottom: '2px',
    paddingRight: '4px'
  },
  gridItem: {
    marginTop: '0',
    borderRadius: '5px',
    width: '96%'
  },
  addItemsButton: {
    marginLeft: 'auto'
  },
  cardTopBar: {
    display: 'flex',
    alignItems: 'center'
  },
  cardTitle: {
    marginBottom: 0
  },
  cardCompletedInfo: {
    fontSize: 'x-small',
    height: '20px',
    marginLeft: 0
  }
});

export type RecipesListProps = RecipesListContainerProps & WithStyles;

class RecipesItems extends React.Component<RecipesListProps> {
  render() {
    const { t, classes } = this.props;
    return (
      <Grid
        container={true}
        justify="flex-start"
        alignItems="stretch"
        spacing={2}
        direction="column"
        className={this.props.classes.root}>
        {this.props.recipesList.map(recipeUI => {
          const completedRecently =
            recipeUI.percentageCompletedRecently > 70 ? (
              <Chip
                label={`${recipeUI.percentageCompletedRecently}% ${t('completed recently')}`}
                color="secondary"
                variant="outlined"
                className={classes.cardCompletedInfo}
              />
            ) : (
              undefined
            );

          return (
            <Grid item={true} key={recipeUI.recipe.id} className={classes.gridItem}>
              <Card className={this.props.classes.card} elevation={1}>
                <CardHeader
                  titleTypographyProps={{ variant: 'h6', component: 'h2' }}
                  avatar={
                    <Avatar aria-label="recipe" className={classes.avatar}>
                      {recipeUI.recipe.name.substr(0, 1)}
                    </Avatar>
                  }
                  action={
                    <VertMenu>
                      <MenuItem
                        // tslint:disable-next-line jsx-no-lambda
                        onClick={() => this.props.openRecipeDetails(true, recipeUI.recipe.id)}>
                        <ListItemIcon>
                          <EditIcon />
                        </ListItemIcon>
                        <ListItemText primary={t('Edit')} disableTypography={true} />
                      </MenuItem>
                      <MenuItem
                        // tslint:disable-next-line jsx-no-lambda
                        onClick={() => this.props.confirmAddItemsToList(recipeUI.recipe.id)}>
                        <ListItemIcon>
                          <AddToListIcon />
                        </ListItemIcon>
                        <ListItemText primary={t('Add to list')} disableTypography={true} />
                      </MenuItem>
                      <Divider />
                      <MenuItem
                        // tslint:disable-next-line jsx-no-lambda
                        onClick={() => this.props.confirmDeleteRecipe(recipeUI.recipe.id)}>
                        <ListItemIcon>
                          <DeleteIcon />
                        </ListItemIcon>
                        <ListItemText primary={t('Delete recipe')} disableTypography={true} />
                      </MenuItem>
                    </VertMenu>
                  }
                  title={recipeUI.recipe.name}
                />
                <CardActionArea // tslint:disable-next-line jsx-no-lambda
                  onClick={() => this.props.openRecipeDetails(true, recipeUI.recipe.id)}>
                  <CardContent className={classes.cardContent}>
                    <Typography component="p">{recipeUI.recipe.items.map(item => item.itemName).join(', ')}</Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  {completedRecently}
                  <Button
                    size="small"
                    color="primary"
                    className={classes.addItemsButton}
                    aria-label={t("Add to list")} // tslint:disable-next-line jsx-no-lambda
                    onClick={() => this.props.confirmAddItemsToList(recipeUI.recipe.id)}>
                    {t('Add to list')}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    );
  }
}

class RecipesListStyled extends React.Component<RecipesListProps> {
  addItems = () => {
    if (this.props.confirmAddDialog.recipeId) {
      this.props.addRecipeItemsToList(this.props.selectedList, this.props.confirmAddDialog.recipeId);
    }
  };

  deleteRecipe = () => {
    if (this.props.confirmDeleteDialog.recipeId) {
      this.props.deleteRecipe(this.props.selectedList, this.props.confirmDeleteDialog.recipeId);
    }
  };

  render() {
    const props = this.props;
    const { t, classes } = props;

    let component = (
      <div className={classes.emptyState}>
        <EmptyImg className={classes.emptyStateIcon} />
        <Typography variant="h5">{t(`You don't have recipes for this list`)}.</Typography>
        <Typography variant="subtitle1">{t('Tap the + button on the corner to add a Recipe')}.</Typography>
      </div>
    );

    if (props.recipesList.length > 0) {
      component = <RecipesItems {...props} />;
    }

    return (
      <div className={classes.list}>
        {component}
        <SimpleConfirmation
          open={props.confirmAddDialog && props.confirmAddDialog.open}
          ok={this.addItems}
          cancel={props.cancelAddItemsToList}
          content={t("Do you want to add this recipe items to the current list") + "?"}
          t={t}
        />
        <SimpleConfirmation
          open={props.confirmDeleteDialog && props.confirmDeleteDialog.open}
          ok={this.deleteRecipe}
          cancel={props.cancelDeleteRecipe}
          content={t("Do you want delete this recipe") + "?"}
          t={t}
        />
      </div>
    );
  }
}

export const RecipesListComponent = withStyles(style)(RecipesListStyled);
