import * as React from 'react';
import { Collapse, Theme, WithStyles, Card, CardContent, Typography, CardActions } from '@material-ui/core';
import { StyleRules, withStyles } from '@material-ui/core/styles';
import FirebaseAuthComp from '../../utils/components/auth/FirebaseAuth';
import { firebaseService } from '../../utils/firebase/FirestoreConfig';
import * as firebase from 'firebase/app';
import firebaseui from 'firebaseui';
import { Unsubscribe } from '../../domain/SubsciptionTypes';
import { Redirect } from 'react-router-dom';
import Loading from '../../utils/components/loading/Loading';
import Button from '@material-ui/core/Button';
import ReactNativeBridge from '../../utils/ReactNativeBridge';
import { NavUtils } from '../../utils/NavigatorUtils';
import { UnsupportedOSContainer } from '../unsupported/UnsupportedContainer';
import { IosInstallScreen } from './IosInstallScreen';
import { logger } from '../../utils/LoggingUtils';
import VersionUtils from '../../utils/VersionUtils';
import { Alert, AlertTitle } from '@material-ui/lab';
import { I18nProps } from '../../i18n';

const log = logger('LoginContainer');
const TrolleyIcon = require('react-icons/lib/ti/shopping-cart');
const style: (theme: Theme) => StyleRules = theme => ({

  icon: {
    fontSize: 60,
    color: 'black'
  },
  title: {
    fontSize: 16,
    paddingBottom: '20px'
  },
  media: {
    maxWidth: '120%'
  },
  cardContent: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column"
  },
  pageWrapper: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  pageContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: '280px',
    width: '260px'
  },
  versionText: {
    position: 'fixed',
    top: 'auto',
    left: 'auto',
    bottom: '2px',
    right: '2px',
  },

  root: {
    height: '100%',
    backgroundImage: `linear-gradient(to bottom right, rgba(0, 191, 165, 0.4), rgba(0, 191, 165, 0.1));`
  },

  controls: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  },
  button: {
    width: 250,
    backgroundColor: 'white',
    color: 'black',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#909fd647',
    }
  },
  google: {
    width: '20px',
    marginRight: '20px'
  }
});

type LoginContainerProps = I18nProps;
type LoginComponentProps = LoginContainerProps & WithStyles;

class LoginStyled extends React.Component<LoginComponentProps> {
  unsubscribe: Unsubscribe = () => { };
  // Configure FirebaseUI.
  uiConfig: firebaseui.auth.Config = {
    // // Popup signin flow rather than redirect flow.
    signInFlow: 'redirect',
    // We will display Google and Facebook as auth providers.
    signInOptions: [
      {
        provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        scopes: ['profile']
      }
    ],
    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: () => false,
    },
    tosUrl: 'https://docs.google.com/document/d/11NrsHbKm7DOzuPriGTB2F_0yE1gkqoY5lhCai6CYpFU/edit?usp=sharing'
  };
  state: {
    loggedIn: 'yes' | 'no' | 'loading' | 'error';
    error?: firebase.auth.Error,
    auth: firebase.auth.Auth | undefined;
    showInstall: boolean;
  } = { loggedIn: 'loading', auth: undefined, showInstall: false };
  url = 'https://andrescastano.dev';
  constructor(props: LoginComponentProps) {
    super(props);
    log.debug(`ios ${NavUtils.isIOS()}`);
    log.debug(`validVersion ${NavUtils.isValidIOSVersion()}`);
    log.debug(`installed ${NavUtils.isPwaInstalled()}`);
    const shouldShowInstall = NavUtils.isIOS() && NavUtils.isValidIOSVersion() && !NavUtils.isPwaInstalled();
    log.debug(`shouldInstall ${shouldShowInstall}`);
    this.state = {
      ...this.state,
      showInstall: shouldShowInstall
    };
  }

  handleUserChanged(user: firebase.User | null) {
    if (user) {
      this.setState({
        loggedIn: 'yes',
        error: undefined
      });
    } else {
      this.setState({
        loggedIn: 'no',
      });
    }
  }

  handleAuthError(error: firebase.auth.Error) {
    this.setState({
      loggedIn: 'error',
      error
    });
  }

  componentDidMount() {
    this.unsubscribe = firebaseService.firebaseAuth.onAuthStateChanged(
      this.handleUserChanged.bind(this),
      this.handleAuthError.bind(this)
    );

    if (firebaseService.fromMobile && firebaseService.mobileLoginAction) {
      firebaseService.mobileLoginAction.catch(this.handleAuthError.bind(this));
    }
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  notifyLoginRequired = () => {
    return ReactNativeBridge.post(JSON.stringify({ type: 'LOGIN_REQUIRED' }));
  };

  openLinkNative = () => {
    ReactNativeBridge.post(JSON.stringify({ type: 'NAVIGATE_TO_URL', url: this.url }));
  };

  render() {
    const props = this.props;
    log.debug('LOGIN PAGE');
    const { classes } = props;

    if (NavUtils.isIOS()) {
      log.debug('IOS detected');
      if (!NavUtils.isValidIOSVersion() && NavUtils.isPwaInstalled()) {
        return <UnsupportedOSContainer />;
      }
    } else {
      log.debug('Android detected, going offline');
    }

    if (this.state.loggedIn === 'yes') {
      return <Redirect to="/" />;
    }

    if (this.state.loggedIn === 'loading') {
      return <Loading />;
    }

    let loginButton = (
      <Button variant="contained" color="primary" className={classes.button} onClick={this.notifyLoginRequired}>
        <img alt="Sign in with google" className={classes.google} src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg" /> {this.props.t('Sign in with Google')}
      </Button>
    );

    if (!firebaseService.fromMobile) {
      log.debug('Loggin in from web');
      loginButton = <FirebaseAuthComp uiConfig={this.uiConfig} firebaseAuth={firebaseService.firebaseAuth} />;
    }

    const afcastano = firebaseService.fromMobile ? (
      // eslint-disable-next-line
      <a href="/" onClick={this.openLinkNative}>
        {this.props.t('here')}
      </a>
    ) : (
        // eslint-disable-next-line
        <a href={this.url} target="_blank">
          {this.props.t('here')}
        </a>
      );

    const t = this.props.t;
    return (
      <div className={`${classes.root}`}>
        <IosInstallScreen open={this.state.showInstall} onClose={() => {
          this.setState({ showInstall: false });
        }} t={t}/>
        <Collapse in={this.state.loggedIn === 'error'}>
          <Alert severity="error">
            <AlertTitle>{t('Something went wrong')}</AlertTitle>
            <Typography variant="caption">
              {t('Please restart Trolley and try again')}.<br />
              {t('If the problem persist, please contact the author')} {afcastano}.
            </Typography><br/><br/>
            <strong>Error:</strong>{this.state.error ? this.state.error.code : ''}<br />
          </Alert>
        </Collapse>
        {!this.state.showInstall && (
          <div className={classes.pageWrapper}>
            <Card className={classes.pageContainer} elevation={5}>
              <CardContent className={classes.cardContent}>
                <TrolleyIcon className={classes.icon} />
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  {this.props.t('Welcome back')}!
                    </Typography>
                <img src="/bg-trolley-dark.jpg" alt="Trolley" className={classes.media} />
              </CardContent>
              <CardActions>
                {!firebaseService.mobileLoginInProgress && <div className={classes.controls}>{loginButton}</div>}
              </CardActions>
            </Card>
            <Typography className={classes.versionText} color="textSecondary" variant="body2">{NavUtils.getInstallType(firebaseService.fromMobile)} {VersionUtils.getClientVersion()}</Typography>
          </div >
        )}
      </div>
    );
  }
}

const LoginComponent = withStyles(style)(LoginStyled);

export class LoginContainer extends React.Component<LoginContainerProps> {
  render() {
    return <LoginComponent {...this.props} />;
  }
}
