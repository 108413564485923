export interface UserDetails {
    userId: string;
    selectedList: string;
    email: string;
    photoURL: string;
    name: string;
    loginHistory: Date[];
    platform: string;
    appVersion: string;
    resolution: string;
    lang: string;
    email_idx?: string;
    name_idx?: string;
}

export const emptyUserDetails: UserDetails = {
    userId: '',
    selectedList: '',
    email: '',
    photoURL: '',
    name: '',
    loginHistory: [],
    platform: '',
    appVersion: '',
    resolution: '',
    lang: ''
};