import * as React from 'react';
import { Component } from 'react';
import { AuthState } from '../../utils/components/auth/Reducers';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { TopBar } from './topbar/Container';
import { RecipesList } from './recipeslist/Container';
import { State } from '../../reducer';
import { RecipesPageActions } from './RecipesPageActions';
import { RecipeDetailsDrawer } from './recipedetails/Container';
import { RecipesNavState } from './router/RecipesNavReducer';
import { getRecipesNavState, AllItemsState, getAllItemsState } from './RecipesPageReducer';
import { RecipesNavigationActions } from './router/RecipeNavActions';
import { ActionProps } from '../../utils/Actions';
import { Unsubscribe } from '../../domain/SubsciptionTypes';
import { logger } from '../../utils/LoggingUtils';
import { domainApi } from '../../domain/DomainApi';
import { AppProps } from '../../router/PrivateRouter';

const log = logger('RecipesPage.container')

export type RecipesPageProps = AuthState &
  RouteComponentProps<{ [key: string]: string }> &
  RecipesNavState &
  AllItemsState &
  ActionProps<typeof RecipesNavigationActions &
  typeof RecipesPageActions> &
  AppProps;

class RecipesPageContainer extends Component<RecipesPageProps> {

  unsubs?: Unsubscribe;

  componentDidMount() {
    log.debug('Subscribing to all items');
    this.unsubs = domainApi.subscribeToAllItems(this.props.selectedList, items => {
      this.props.onRecipeItemsHistoryUpdatedAction(this.props.selectedList, items);
    });
  }

  componentWillUnmount() {
    if (this.unsubs) {
      log.debug('Unsubscribing to recipe items history');
      this.unsubs();
    }
  }

  render() {
    return (
      <React.Fragment>
        <TopBar {...this.props} />
        <RecipesList {...this.props} />
        <RecipeDetailsDrawer {...this.props}/>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: State) => {
  return {
    ...getRecipesNavState(state),
    ...getAllItemsState(state)
  }
};

const mapDispatchToProps = {
  ...RecipesPageActions,
  ...RecipesNavigationActions
};

export const RecipesPage = connect(mapStateToProps, mapDispatchToProps)(RecipesPageContainer);
