import * as React from 'react';
import { AddRecipeItemButtonActions } from './Actions';
import { connect } from 'react-redux';
import { Component } from 'react';
import { getAddRecipeItemState } from '../../RecipesPageReducer';
import { AddRecipeItemButtonState } from './Reducers';
import { State } from '../../../../reducer';
import { RecipesPageProps } from '../../RecipesPage';
import { AddRecipeItemButtonComponent } from './Component';
import { ActionProps } from '../../../../utils/Actions';

export type AddRecipeItemButtonProps = RecipesPageProps & AddRecipeItemButtonState
  & ActionProps<typeof AddRecipeItemButtonActions>;

class AddRecipeItemContainer extends Component<AddRecipeItemButtonProps> {
  render() {
    return <AddRecipeItemButtonComponent {...this.props} />;
  }
}

const mapDispatchToProps = {
  ...AddRecipeItemButtonActions
};

const mapStateToProps = (state: State) => {
  return getAddRecipeItemState(state);
};

export const AddRecipeItemButton = connect(mapStateToProps, mapDispatchToProps)(AddRecipeItemContainer);
