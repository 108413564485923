import { SyncAction, SyncActionClass } from '../../../utils/Actions';
import { domainApi } from '../../../domain/DomainApi';

export class RenameListAction extends SyncActionClass<{ listId: string; name: string }> {
  constructor(listId: string, name: string) {
    super('RenameListAction', { listId, name });
  }
}

export class RenameListTextChanged extends SyncActionClass<string> {
  constructor(txt: string) {
    super('RenameListTextChanged', txt);
  }
}

const renameList: (listId: string, name: string) => SyncAction = (listId, name) => {
  domainApi.renameList(listId, name);
  return new RenameListAction(listId, name).action();
};

const onRenameListTextChanged: (txt: string) => SyncAction = txt => new RenameListTextChanged(txt).action();

export const RenameListDrawerActions = {
  renameList,
  onRenameListTextChanged
};
