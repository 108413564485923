import { SubscriptionCallback, Unsubscribe } from '../SubsciptionTypes';
import { firebaseService } from '../../utils/firebase/FirestoreConfig';
import { ListCategoriesCreatedEvent, ListUserHardDeletedEventIO, UserListCreatedEventIO } from '../DomainEvents';
import { ListCategories } from './Types';
import { logger } from '../../utils/LoggingUtils';
import i18n from 'i18next';
import { PureListCategoriesService } from './fp/PureListCategoriesService';
import { effectsImpl } from '../../utils/fp/effects/EffectsImpl';
import { IO } from '../../utils/fp/io';

const log = logger('ListCategoriesService');

const initialize = () => {
  UserListCreatedEventIO.onEvent(event =>
    new IO(() => createNewListCategories(event.listId))
  ).eval(effectsImpl);

  ListUserHardDeletedEventIO.onEvent(event =>
    new IO(() => hardDeleteListCategories(event.listId))
  ).eval(effectsImpl);

  ListCategoriesCreatedEvent.onEvent(event =>
    createDefaultCategories(event.listId)
  );
};

const hardDeleteListCategories: (listId: string) => Promise<void> = async (listId) => {
  const firestore = await firebaseService.firestore;
  log.debug(`Deleting list categories for list ${listId}`);
  await firestore.collection('ListCategories').doc(listId).delete();
  log.debug(`List categories for list ${listId} have been deleted`);
  return;
};

const createDefaultCategories = async (listId: string) => {
  log.debug(`Creating default categories for list ${listId}`);
  const lang = i18n.languages[0];
  log.debug(`i18n language ${lang}`);
  await PureListCategoriesService.createDefaultCategories(listId, lang).eval(effectsImpl);
};

const createNewListCategories = async (listId: string) => {
  await PureListCategoriesService.createNewListCategories(listId).eval(effectsImpl);
  new ListCategoriesCreatedEvent(listId).emit();
};

const subscribeToListCategories: (listId: string, cb: SubscriptionCallback<ListCategories>) => Unsubscribe = (
  listId,
  cb
) => {
  return firebaseService.firestoreSubscription(firestore => {
    if (!listId) {
      log.warn('Cant subscribe to list categories because there is not list.');
      return () => {
        log.warn('There wasnt subscriptiopn');
      };
    }
    log.debug(`Susbscribing to list ${listId}`);
    return firestore
      .collection('ListCategories')
      .doc(listId)
      .onSnapshot(snapshot => {
        const listCategories: ListCategories = {
          listId: listId,
          categories: []
        };

        if (!snapshot.exists) {
          cb(listCategories);
          return;
        }

        const data = snapshot.data();

        if (data) {
          listCategories.categories = data.categories;
        }

        cb(listCategories);
      });
  });
};

const addCategoryToList: (listId: string, newCatName: string) => void = (listId, newCatName) => {
  if (!newCatName) {
    return;
  }

  firebaseService.firestore.then(firestore => {
    const listCategoriesRef = firestore.collection('ListCategories').doc(listId);
    return listCategoriesRef.get({ source: 'cache' }).then(docSnap => {
      if (!docSnap.exists) {
        return;
      }

      const data = docSnap.data();
      if (!data) {
        return;
      }

      const existingCategories: string[] = data.categories;

      let newCategories = existingCategories.filter(
        cat => cat.trim().toLowerCase() !== newCatName.trim().toLowerCase()
      );
      newCategories.unshift(newCatName);

      return listCategoriesRef.update({
        categories: newCategories
      });
    });
  });
};

const removeCategoryFromList: (listId: string, catName: string) => void = (listId, catName) => {
  if (!catName) {
    return;
  }

  firebaseService.firestore.then(firestore => {
    const listCategoriesRef = firestore.collection('ListCategories').doc(listId);
    return listCategoriesRef.get({ source: 'cache' }).then(docSnap => {
      if (!docSnap.exists) {
        return;
      }

      const data = docSnap.data();
      if (!data) {
        return;
      }

      const existingCategories: string[] = data.categories;

      let newCategories = existingCategories.filter(cat => cat.trim().toLowerCase() !== catName.trim().toLowerCase());
      return listCategoriesRef.update({
        categories: newCategories
      });
    });
  });
};

export default {
  initialize,
  createListDefaultListCategories: createNewListCategories
};

export const ListCategoriesDomainApi = {
  subscribeToListCategories,
  addCategoryToList,
  removeCategoryFromList
};
