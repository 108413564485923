import { IO, _do } from '../../../utils/fp/io';
import { DocumentData, CollectionFetchOptions } from '../../../utils/fp/effects/firestore/FirestoreEffects';
import { UserList } from '../../../components/menudrawer/Types';

const path = ['ListUsers'];

const getUserListFs: (listId: string) => IO<DocumentData | undefined> = (listId) =>
  new IO(effects => effects.firestore.findDocument(path, listId));


const getListUsersFs: (userId: string) => IO<DocumentData[]> = (userId) =>
  new IO(effects => {
    const options: CollectionFetchOptions = {
      where: [{
        field: `sharedWith.${userId}`,
        op: '==',
        value: true
      }]
    };

    return effects.firestore.fetchCollection(path, options);
  });

const dataToUserList: (data: DocumentData) => UserList = (data) => {
  return {
    id: data.id,
    name: data.name,
    totalItems: data.totalItems,
    sharedWith: data.sharedWith ? Object.keys(data.sharedWith) : []
  } as UserList;
};

const getListsWhereUserIsShared: (userId: string) => IO<UserList[]> = _do(function* (userId: string) {
  const snapshot: DocumentData[] = yield getListUsersFs(userId);

  return snapshot.map(dataToUserList);
});

const saveUserList: (userList: UserList) => IO<void>
  = (userList) => {

    const sharedWith: { [key: string]: boolean; } = {};
    userList.sharedWith.forEach(user => {
      sharedWith[user] = true;
    });
    const listUsersDb = {
      id: userList.id,
      name: userList.name,
      totalItems: userList.totalItems,
      sharedWith,
    };

    return new IO(effects => effects.firestore.saveDocument(path, listUsersDb.id, listUsersDb));
  };

const deleteUserList: (listId: string) => IO<void> = (listId) =>
  new IO(effects => effects.firestore.deleteDocument(path, listId));

const getUserList: (listId: string) => IO<UserList | undefined> = _do(function* (listId) {
  const usersOnlistData: DocumentData | undefined = yield getUserListFs(listId);

  if (!usersOnlistData) {
    return;
  }

  return dataToUserList(usersOnlistData);
});

export const UserListsRepo = {
  getListsWhereUserIsShared,
  saveUserList,
  getUserList,
  deleteUserList,
};