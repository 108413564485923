import { SyncActionClass, SyncAction } from '../../../utils/Actions';
import { domainApi } from '../../../domain/DomainApi';
import { logger } from '../../../utils/LoggingUtils';
import { effectsImpl } from '../../../utils/fp/effects/EffectsImpl';

const log = logger('AdminUsersActions');

export class ToggleUsersSort extends SyncActionClass<{ column: string }> {
    static TYPE = 'ToggleUsersSort';
    constructor(column: string) {
        super(ToggleUsersSort.TYPE, { column });
    }
}

export class AdminActionCommand extends SyncActionClass<{userId: string, action: string}> {
    static TYPE = 'AdminActionCommand';
    constructor(userId: string, action: string) {
        super(AdminActionCommand.TYPE, { userId, action });
    }
}

export class ActionTextChangedCommand extends SyncActionClass<string> {
    static TYPE = 'ActionTextChangedCommand';
    constructor(txt: string) {
      super(ActionTextChangedCommand.TYPE, txt);
    }
  }

export const executeAction: (userId: string, action: string) => SyncAction = (userId, action) => {
    switch(action) {
        case 'delete':
            domainApi.deleteUser(userId);
            break;
        case 'translate':
            domainApi.translateUserLists(userId).eval(effectsImpl);
            break;
        case 'nameIdx':
            domainApi.updateNameIdx(userId).eval(effectsImpl);
            break;    
        default:
            log.debug(`Action ${action} is not registered`);
            break;
    }
    return new AdminActionCommand(userId, action).action();
}

export const toggleSort: (column: string) => SyncAction = column =>
    new ToggleUsersSort(column).action();

const onActionTextChange: (txt: string) => SyncAction = (txt) => new ActionTextChangedCommand(txt).action();

export const UsersListActions = {
    toggleSort,
    onActionTextChange,
    executeAction
}