import * as React from 'react';
import { StyleRules, withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { MenuItem, Theme, WithStyles, ListItemIcon, ListItemText } from '@material-ui/core';
import FilterIcon from '@material-ui/icons/FilterList';
import MenuIcon from '@material-ui/icons/Menu';
import RecentItemsIcon from '@material-ui/icons/List';
import BuyLaterIcon from '@material-ui/icons/Alarm';
import ShareIcon from '@material-ui/icons/PersonAdd';
import RenameIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckBoxSelected from '@material-ui/icons/CheckBox';
import CheckBoxUnselected from '@material-ui/icons/CheckBoxOutlineBlank';
import Divider from '@material-ui/core/Divider';
import { RecentItemsDrawer } from '../recentitemsdrawer/Container';
import { ShareListDrawer } from '../sharelistdrawer/Container';
import { TopBarProps } from './Container';
import { VertMenu } from '../../../utils/components/VertMenu';
import { RenameListDrawer } from '../renamelistdrawer/Container';
import { ItemsForLaterDrawer } from '../itemsforlaterdrawer/Container';
import { VersionUpdateDialog } from '../../versionupdatedialog/Container';
import { waitMs } from '../../../utils/PromiseDelay';
import { ListCategoriesDrawer } from '../listcategoriesdrawer/Container';
import Badge from '@material-ui/core/Badge';
import { UserNotification } from '../../../domain/notifications/Types';
import { SimpleConfirmation } from '../../../utils/components/dialogs/SimpleConfirmation';
import { RecipesFilterDrawer } from '../recipesfilterdrawer/Container';
import { Recipe } from '../../../domain/recipes/Types';
import Button from '@material-ui/core/Button';

const CategoryIcon = require('react-icons/lib/fa/tag');

const style: (theme: Theme) => StyleRules = () => ({
  flex: {
    flex: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 5
  },
  recentItems: {
    paddingRight: 5
  },
  filterButton: {
    paddingRight: 0
  },
  clearFilterButton: {
    fontSize: 'x-small'
  }
});

const SmallBadge = withStyles({
  badge: {
    width: '12px',
    height: '12px',
    top: 0
  }
})(Badge);

const getMenuIcon = (notifications: UserNotification[]) => {
  return notifications && notifications.length > 0 ? (
    <Badge badgeContent={notifications.length} color="secondary">
      <MenuIcon />
    </Badge>
  ) : (
    <MenuIcon />
  );
};

const getFilterIcon = (filters: Recipe[]) => {
  return filters && filters.length > 0 ? (
    <SmallBadge badgeContent="" color="secondary">
      <FilterIcon />
    </SmallBadge>
  ) : (
    <FilterIcon />
  );
};

interface TopAppBarState {
  confirmRemoveOpen: boolean;
}

type TopBarComponentProps = TopBarProps & WithStyles;

class TopAppBarStyled extends React.Component<TopBarComponentProps, TopAppBarState> {
  state: TopAppBarState;

  constructor(props: TopBarComponentProps) {
    super(props);
    this.state = { confirmRemoveOpen: false };
  }

  removeList = () => {
    this.props.removeListAction(this.props.userId, this.props.selectedList);
    this.setState({ confirmRemoveOpen: false });
  };

  cancelRemove = () => {
    this.setState({ confirmRemoveOpen: false });
  };

  openMainMenu = () => this.props.openMenu(true);
  showRecentItems = () => this.props.showRecentItems(true);
  hideRecentItems = () => this.props.showRecentItems(false);
  showItemsForLater = () => this.props.showItemsForLater(true);
  hideItemsForLater = () => this.props.showItemsForLater(false);
  showListCategories = () => this.props.showListCategories(true);
  hideListCategories = () => this.props.showListCategories(false);
  showShareListDrawer = () => this.props.showShareListDrawer(true);
  hideShareListDrawer = () => this.props.showShareListDrawer(false);
  showRenameListDrawer = () => this.props.showRenameListDrawer(true);
  hideRenameListDrawer = () => this.props.showRenameListDrawer(false);
  hideVersionUpdateDialog = () => this.props.showVersionUpdateDialog(false);
  showRecipeFiltersDrawer = () => this.props.showRecipesFilter(true);
  hideRecipeFiltersDrawer = () => this.props.showRecipesFilter(false);

  confirmRemoveOpen = () => {
    this.setState({ confirmRemoveOpen: true });
  };

  showCategories = () => {
    // wait some time to avoid menu flickering
    waitMs(100).then(() => this.props.showCategories(this.props.selectedList, !this.props.showShoppingListCategories));
  };

  render() {
    const { t, shoppingListName, notifications } = this.props;

    // Classes can not be passed down to children component due to warning:
    // https://stackoverflow.com/questions/48936467/
    // material-ui-the-key-provided-to-the-classes-property-is-not-implemented
    const { classes, ...parentProps } = this.props;

    const clearButton =
      this.props.recipeFilters && this.props.recipeFilters.length > 0 ? (
        <Button
          variant="outlined"
          component="span"
          color="inherit"
          className={classes.clearFilterButton}
          onClick={this.props.clearFilters}>
          {t('Clear')}
        </Button>
      ) : (
        undefined
      );

    return (
      <AppBar position="sticky">
        <Toolbar>
          <IconButton
            className={classes.menuButton}
            id="MenuButtonSL"
            color="inherit"
            aria-label="Menu"
            onClick={this.openMainMenu}>
            {getMenuIcon(notifications)}
          </IconButton>
          <Typography variant="subtitle1" color="inherit" className={classes.flex} data-testid="shopping-list-name">
            {shoppingListName}
          </Typography>
          {clearButton}
          <IconButton color="inherit" className={classes.filterButton} onClick={this.showRecipeFiltersDrawer}>
            {getFilterIcon(this.props.recipeFilters)}
          </IconButton>
          <VertMenu id="sl-vert-menu">
            <MenuItem onClick={this.showRecentItems}>
              <ListItemIcon>
                <RecentItemsIcon />
              </ListItemIcon>
              <ListItemText primary={t('Recent items')} disableTypography={true} />
            </MenuItem>
            <MenuItem onClick={this.showItemsForLater}>
              <ListItemIcon>
                <BuyLaterIcon />
              </ListItemIcon>
              <ListItemText primary={t('Items for later')} disableTypography={true} />
            </MenuItem>
            <Divider />
            <MenuItem onClick={this.showListCategories}>
              <ListItemIcon>
                <CategoryIcon />
              </ListItemIcon>
              <ListItemText primary={t('List categories')} disableTypography={true} />
            </MenuItem>
            <MenuItem onClick={this.showCategories}>
              <ListItemIcon>
                {this.props.showShoppingListCategories ? <CheckBoxSelected /> : <CheckBoxUnselected />}
              </ListItemIcon>
              <ListItemText primary={t('Show categories')} disableTypography={true} />
            </MenuItem>
            <Divider />
            <MenuItem onClick={this.showShareListDrawer}>
              <ListItemIcon>
                <ShareIcon />
              </ListItemIcon>
              <ListItemText primary={t('Share list')} disableTypography={true} />
            </MenuItem>
            <MenuItem onClick={this.showRenameListDrawer} data-testid="sl-rename-list">
              <ListItemIcon>
                <RenameIcon />
              </ListItemIcon>
              <ListItemText primary={t('Rename list')} disableTypography={true} />
            </MenuItem>
            <Divider />
            <MenuItem
              className={classes.removeList}
              disabled={this.props.otherShoppingLists.length <= 1}
              onClick={this.confirmRemoveOpen}
              data-testid="sl-remove-list">
              <ListItemIcon>
                <DeleteIcon />
              </ListItemIcon>
              <ListItemText primary={t('Remove list')} disableTypography={true} />
            </MenuItem>
          </VertMenu>
        </Toolbar>
        <RecentItemsDrawer open={this.props.itemHistoryOpen} closeClicked={this.hideRecentItems} {...parentProps} />
        <ItemsForLaterDrawer
          open={this.props.itemsForLaterOpen}
          closeClicked={this.hideItemsForLater}
          {...parentProps}
        />
        <ListCategoriesDrawer
          open={this.props.listCategoriesOpen}
          closeClicked={this.hideListCategories}
          {...parentProps}
        />
        <ShareListDrawer open={this.props.shareListOpen} closeClicked={this.hideShareListDrawer} {...parentProps} />
        <RenameListDrawer
          open={this.props.renameListOpen}
          closeClicked={this.hideRenameListDrawer}
          listName={this.props.shoppingListName}
          {...parentProps}
        />
        <RecipesFilterDrawer
          open={this.props.isRecipesFilterOpen}
          closeClicked={this.hideRecipeFiltersDrawer}
          {...parentProps}
        />
        <VersionUpdateDialog open={this.props.versionUpdateDialogOpen} closeClicked={this.hideVersionUpdateDialog} t={t}/>
        <SimpleConfirmation
          open={this.state.confirmRemoveOpen}
          ok={this.removeList}
          cancel={this.cancelRemove}
          content={t("Do you want to remove this list")+'?'}
          t={t}
        />
      </AppBar>
    );
  }
}

export const TopBarComponent = withStyles(style)(TopAppBarStyled);
