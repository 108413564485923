import { SyncAction, SyncActionClass } from '../../../utils/Actions';
import { domainApi } from '../../../domain/DomainApi';

export class AddCategoryAction extends SyncActionClass<{ listId: string; categoryName: string }> {
  constructor(listId: string, categoryName: string) {
    super('AddCategoryAction', { listId, categoryName });
  }
}

export class RemoveCategoryAction extends SyncActionClass<{ listId: string; categoryName: string }> {
    constructor(listId: string, categoryName: string) {
        super('RemoveCategoryAction', { listId, categoryName });
    }
}

const addCategory: (listId: string, categoryName: string) => SyncAction = (listId, categoryName) => {
  domainApi.addCategoryToList(listId, categoryName);
  return new AddCategoryAction(listId, categoryName).action();
};

const removeCategory: (listId: string, categoryName: string) => SyncAction = (listId, categoryName) => {
    domainApi.removeCategoryFromList(listId, categoryName);
    return new RemoveCategoryAction(listId, categoryName).action();
};

export const ListCategoriesDrawerActions = {
  addCategory,
  removeCategory
};
