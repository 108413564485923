import { IO, _do } from '../../../utils/fp/io';
import { UserDetails } from '../Types';
import { DocumentData, CollectionFetchOptions, queryWhere } from '../../../utils/fp/effects/firestore/FirestoreEffects';
import { nameIdx } from '../../../utils/IdUtils';
import { logger } from '../../../utils/LoggingUtils';

const log = logger('UserDetailsRepo');
type Timestamp = firebase.firestore.Timestamp;
const userDetailsPath = ['UserDetails'];

const toUserDetails: (data: DocumentData) => UserDetails = data => {
    return {
        ...data,
        loginHistory: data.loginHistory
            ? data.loginHistory.map((fsDate: Timestamp) => fsDate.toDate ? fsDate.toDate() : new Date())
            : []
    } as UserDetails;
};

const searchUsersByName: (searchTerm: string) => IO<UserDetails[]> = _do(function* (searchTerm) {
    log.debug(`Searching users by ${searchTerm}`);
    const options: CollectionFetchOptions = {
        where: [
            queryWhere('name_idx', '>=', searchTerm.toLowerCase()),
            queryWhere('name_idx', '<', searchTerm.toLowerCase() + '\uf8ff')
        ],
        limit: 100
    };

    const documents: DocumentData[] = yield fetchUsersDetailsList(options);
    log.debug(`Found ${documents.length} documents`);
    return documents.map(toUserDetails);
});

const fetchUsersDetailsList: (options: CollectionFetchOptions) => IO<DocumentData[]> = options =>
    new IO(effects => effects.firestore.fetchCollection(userDetailsPath, options));

const fetchUserDetailsDocument: (userId: string) => IO<DocumentData | undefined>
    = userId => new IO(effects => effects.firestore.findDocument(userDetailsPath, userId));

const findUserDetailsById: (userId: string) => IO<UserDetails | undefined>
    = _do(function* (userId) {
        const docData: DocumentData = yield fetchUserDetailsDocument(userId);
        if (docData) {
            return toUserDetails(docData);
        }
    });

const updateSelectedList: (userId: string, selectedList: string) => IO<void> =
    (userId, selectedList) => new IO(effects =>
        effects.firestore.updateDocument(userDetailsPath, userId, { selectedList }));

const saveUserDetails: (userDetails: UserDetails) => IO<void>
    = userDetails => {
        const updatedDetails: UserDetails = {
            ...userDetails,
            email_idx: userDetails.email.toLowerCase(),
            name_idx: nameIdx(userDetails.name, true)
        };
        return new IO(
            effects =>
                effects.firestore.saveDocument(userDetailsPath, userDetails.userId, updatedDetails));
    };

export const UserDetailsRepositoryIO = {
    findUserDetailsById,
    toUserDetails,
    saveUserDetails,
    searchUsersByName,
    updateSelectedList
};
