import { ImportEffects } from './ImportEffects';
import { DefaultItems } from '../../../../domain/shoppinglist/fp/PureShoppingListService';
import { DefaultCategories } from '../../../../domain/listcategories/fp/PureListCategoriesService';

const importDefaultItemsEn: () => Promise<DefaultItems> = () => import('../../../../i18n/default-items-en.json');
const importDefaultItemsEs: () => Promise<DefaultItems> = () => import('../../../../i18n/default-items-es.json');
const importDefaultCategoriesEs: () => Promise<DefaultCategories> = () => import('../../../../i18n/categories-es.json');
const importDefaultCategoriesEn: () => Promise<DefaultCategories> = () => import('../../../../i18n/categories-en.json');

export const ImportEffectsImpl: ImportEffects = {
    importDefaultItemsEn,
    importDefaultItemsEs,
    importDefaultCategoriesEs,
    importDefaultCategoriesEn,
}