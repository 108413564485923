import { Action } from 'redux';
import { CloseUserMsg, FetchSuggestionsAction, UsersSharingListUpdatedAction, ClearUserSuggestionsAction, RemoveUserFromList } from './Actions';
import { actionFailedType, actionInProgressType, actionSuccessType } from '../../../utils/Actions';
import { UserDetails } from '../../../domain/userdetals/Types';

export type SharingStatus = 'IN_PROGRESS' | 'SUCCEEDED' | 'FAILED' | 'NOT_STARTED';
export type FetchUsersStatus = 'NOT_STARTED' | 'IN_PROGRESS' | 'DONE';

export type ShareListDrawerState = Readonly<{
  filteredUsers: UserDetails[];
  sharingStatus: SharingStatus;
  usersSharingList: UserDetails[];
  fetchUsersStatus: FetchUsersStatus;
}>;

const emptyShareListState = {
  filteredUsers: [],
  sharingStatus: 'NOT_STARTED',
  usersSharingList: [],
  fetchUsersStatus: 'NOT_STARTED'
} as ShareListDrawerState;

export const shareListDrawerReducer: (state: ShareListDrawerState | undefined, action: Action) => ShareListDrawerState = (
  state = emptyShareListState,
  action
) => {
  switch (action.type) {
    case CloseUserMsg.TYPE:
      return { ...state, sharingStatus: 'NOT_STARTED' };
    
    case UsersSharingListUpdatedAction.TYPE: 
      const sharingAction = action as UsersSharingListUpdatedAction;
      return {...state, usersSharingList: sharingAction.payload}
    
    case RemoveUserFromList.TYPE:
      const removeAction = action as RemoveUserFromList;
      const removedUserId = removeAction.payload.user.userId;
      return {...state, usersSharingList: state.usersSharingList.filter(user => user.userId !== removedUserId)}

    case actionInProgressType(FetchSuggestionsAction.TYPE): {
      return { ...state, fetchUsersStatus: 'IN_PROGRESS', filteredUsers: [] }
    }

    case actionSuccessType(FetchSuggestionsAction.TYPE): {
      const fetchAction = action as FetchSuggestionsAction;
      const filteredUsers = fetchAction.response ? fetchAction.response : []

      return { ...state, filteredUsers, fetchUsersStatus: 'DONE' }
    }

    case ClearUserSuggestionsAction.TYPE: {
      return { ...state, filteredUsers:[], fetchUsersStatus: 'NOT_STARTED' }
    }

    case actionInProgressType('ShareListAction'):
      return { ...state, sharingStatus: 'IN_PROGRESS' };
    case actionFailedType('ShareListAction'):
      return {
        ...state,
        sharingStatus: 'FAILED'
      };
    case actionSuccessType('ShareListAction'):
      return { ...state, sharingStatus: 'SUCCEEDED' };
    default:
      return state;
  }
};
