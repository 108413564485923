import { IO, _do } from '../../../utils/fp/io';
import { ListItem } from '../../../components/shoppinglists/currentshoppinglist/Types';
import { UserListsServiceIO } from '../../userlists/io/UserListsServiceIO';
import PureShoppingListService, { DefaultItems, DefaultItem } from '../../shoppinglist/fp/PureShoppingListService';
import { PureShoppingListRepo } from '../../shoppinglist/fp/PureShoppingListRepository';
import { logger } from '../../../utils/LoggingUtils';
import { ListCategoriesRepo } from '../../listcategories/fp/ListCategoriesRepo';
import { ListCategories } from '../../listcategories/Types';
import { DefaultCategories } from '../../listcategories/fp/PureListCategoriesService';

const log = logger('TranslateService');

/******* SHELL */
const translateUserLists: (userId: string) => IO<void> = _do(function* (userId) {
    log.debug(`Translating list for user ${userId}`);
    const listIds: string[] = yield UserListsServiceIO.getUserListsId(userId);
    log.debug(`${listIds.length} lists found`);
    //TODO for iteration instead of map, because we don't have a way yet to transform
    //[IO<A>, IO<B>] into IO<[A,B]>
    for (let i = 0; i < listIds.length; i++) {
        yield translateList(listIds[i]);
        yield translateCategories(listIds[i]);
    }
});

const translateCategories: (listId: string) => IO<void> = _do(function* (listId){
    log.debug(`Translating categories for list ${listId}`);
    const listCategories: ListCategories = yield ListCategoriesRepo.fetchListCategories(listId);
    const defaultCategories: DefaultCategories = yield new IO(effects => effects.imports.importDefaultCategoriesEn());
    const translated: DefaultCategories = yield new IO(effects => effects.imports.importDefaultCategoriesEs());
    const newCat = translateCategoriesForList(listCategories, defaultCategories, translated);
    yield ListCategoriesRepo.updateListCategories(listId, newCat);
    log.debug(`Categories translated`);
});
const translateList: (listId: string) => IO<void> = _do(function* (listId) {
    log.debug(`Translating list ${listId}`);
    const items: ListItem[] = yield PureShoppingListService.getShoppingListItems(listId);
    log.debug(`${items.length} items found...`);
    const translatedItems: DefaultItems = yield new IO(effects => effects.imports.importDefaultItemsEs());
    const defaultItems: DefaultItems = yield new IO(effects => effects.imports.importDefaultItemsEn())

    const result = translateItems(items, defaultItems, translatedItems);

    if (result.translated.length > 0) {
        yield PureShoppingListRepo.saveItems(listId, result.translated);
        log.debug(`Finished translating ${result.translated.length} items for list ${listId}`);
    } else {
        log.debug(`There were no items to translate`);
    }

    if (result.toRemove.length > 0) {
        for (let i = 0; i < result.toRemove.length; i++) {
            yield PureShoppingListRepo.deleteItem(listId, result.toRemove[i].id);
        }
    } else {
        log.debug(`There were no items to remove`);
    }

});

/******** CORE */
const translateCategoriesForList: (listCategories: ListCategories, defaultCategories: DefaultCategories, translatedCategories: DefaultCategories) => string[]
= (listCategories, defaultCategories, translatedCategories) => {
    const newCat: string[] = listCategories.categories.map(cat => {
        const index = defaultCategories.categories.indexOf(cat);
        if (index < 0) { // Category is not a default translatable cat.
            return cat;
        }

        return translatedCategories.categories[index];
    });

    return newCat;

}

const findById: (items: DefaultItems, item: ListItem) => DefaultItem | undefined =
    (items, item) => items.items.find(defaultItem => defaultItem.id === item.id);
const itemIdInList: (items: DefaultItems) => (item: ListItem) => boolean =
    (items) => (item) => !!findById(items, item);

const areTranslatableItemsUsed: (listItems: ListItem[], defaultItems: DefaultItems) => boolean =
    (listItems, defaultItems) => listItems.filter(itemIdInList(defaultItems))
        .some(item => !!item.addedOn || !!item.completedOn);

export const translateItems: (listItems: ListItem[], defaultItems: DefaultItems, translation: DefaultItems)
    => { translated: ListItem[], toRemove: ListItem[] } = (listItems, defaultItems, translation) => {

        const toRemove: ListItem[] = [];
        let translated: ListItem[] = [];
        if (areTranslatableItemsUsed(listItems, defaultItems)) {
            log.debug('Some items have already been used. Not translating this list.');
            return { translated, toRemove };
        }

        listItems.forEach(item => {
            const foundItem = findById(defaultItems, item);
            if (!foundItem) {
                return;
            }

            const translatedDefaultItem = findById(translation, item);

            //This should not happen, translatedItem must always exist. Think what to do here... exception?
            if (!translatedDefaultItem) {
                return;
            }

            const translatedItem = PureShoppingListRepo.newDefaultItem(
                translatedDefaultItem.id,
                translatedDefaultItem.name,
                translatedDefaultItem.category
            );

            //Try to see if the translated item already exist in the list. i.e. user manually created it.
            const foundTranslation = listItems.find(item => item.name_idx === translatedItem.name_idx && item.id !== translatedItem.id);
            if (foundTranslation) {
                log.debug(`There is already an item with name ${translatedItem.name_idx}, will remove the translation`);
                toRemove.push(item);
                return;
            }

            translated.push(translatedItem);
        });

        return { translated, toRemove }
    }

export const TranslateService = {
    translateUserLists
}