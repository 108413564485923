import { Action } from 'redux';
import { AuthChangedAction, UserDetailsChanged } from './Actions';
import { emptyUserDetails, UserDetails } from '../../../domain/userdetals/Types';
import { getUserId } from '../../firebase/FirestoreConfig';

export type AuthState = {
  loggedIn: string;
  loggedInUser: string;
} & UserDetails;

const emptyAuthState = { loggedIn: 'loading', loggedInUser: '', ...emptyUserDetails };

export const authReducer: (state: AuthState | undefined, action: Action) => AuthState = (state = emptyAuthState, action) => {
  switch (action.type) {
    case 'LogoutAction': {
      return {
        ...state,
        loggedIn: 'false',
        loggedInUser: ''
      };
    }
    case 'AuthenticatingAction': {
      return {
        ...state,
        loggedIn: 'loading'
      };
    }
    case 'AuthChangedAction':
      const autChanged = action as AuthChangedAction;
      const user = autChanged.payload;
      if (user) {
        const userId = getUserId(user);
        return {
          ...state,
          loggedInUser: userId,
          loggedIn: 'true'
        };
      } else {
        return { ...state, loggedInUser: '', loggedIn: 'false' };
      }
    case 'UserDetailsChanged':
      const detailsChanged = action as UserDetailsChanged;
      return {
        ...state,
        ...detailsChanged.payload
      };
    default:
      return state;
  }
};
