import * as React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { TFunction } from 'i18next';

export interface SimpleConfirmationProps {
  open: boolean;
  ok: () => void;
  cancel: () => void;
  content: React.ReactNode;
  t: TFunction;
  hideButtons?: boolean;
}

export const SimpleConfirmation = (props: SimpleConfirmationProps) => {
  const { t, open, ok, cancel, content, hideButtons } = props;
  return (
    <Dialog
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}>
      <DialogContent>{content}</DialogContent>
      {!hideButtons && (
        <DialogActions>
          <Button onClick={cancel} color="primary" data-testid="confirmation-cancel">
            {t('Cancel')}
          </Button>
          <Button onClick={ok} color="primary" data-testid="confirmation-ok">
            Ok
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};
