import { SubscriptionCallback, Unsubscribe } from '../SubsciptionTypes';
import { firebaseService } from '../../utils/firebase/FirestoreConfig';
import { UserList } from '../../components/menudrawer/Types';
import { eventBus } from '../../utils/eventbus/EventBus';
import DomainEvents,
{
  NewUserCreatedEvent,
  ShoppingListSharedEvent,
  UserDetailsDeletedEvent
} from '../DomainEvents';
import { ListItem, ShoppingList } from '../../components/shoppinglists/currentshoppinglist/Types';
import UserDetailsService from '../userdetals/UserDetailsService';
import * as firebase from 'firebase/app';
import { logger } from '../../utils/LoggingUtils';
import { UserDetails } from '../userdetals/Types';
import { UserListsServiceIO } from './io/UserListsServiceIO';
import { effectsImpl } from '../../utils/fp/effects/EffectsImpl';

const log = logger('UserListsService');

type DocumentData = firebase.firestore.DocumentData;

const initialize = () => {
  eventBus.on(DomainEvents.ShoppingListChanged, (sl: ShoppingList) => {
    updateListUsersName(sl);
  });

  eventBus.on(DomainEvents.ShoppingListItemsChanged, (lis: { listId: string; items: ListItem[]; }) => {
    updateListUsersItems(lis.listId, lis.items);
  });

  eventBus.on(DomainEvents.NewUserDetailsCreated, (param: NewUserCreatedEvent) => {
    UserListsServiceIO.createDefaultList(param.userId, param.selectedList).eval(effectsImpl);
  });

  UserDetailsDeletedEvent.onEvent(async (event) =>
    await UserListsServiceIO.onUserDetailsDeleted(event.userId).eval(effectsImpl)
  );

  log.debug('UserListService initialized.');
};

const updateListUsersItems = async (listId: string, items: ListItem[]) => {
  log.debug(`ShoppingListItemsChanged, updating items for ${listId}`);
  const firestore = await firebaseService.firestore;
  firestore
    .collection('ListUsers')
    .doc(listId)
    .set({
      totalItems: items.length
    }, { merge: true });
};

const updateListUsersName = async (sl: ShoppingList) => {
  log.debug(`ShoppingListChanged: Updating user lists name`);
  const firestore = await firebaseService.firestore;
  firestore
    .collection('ListUsers')
    .doc(sl.id)
    .set({
      name: sl.name
    }, { merge: true });
};

const shareListWithUser = async (fromUserId: string, toUserEmail: string, listId: string) => {
  const firestore = await firebaseService.firestore;
  const user = await UserDetailsService.findByEmail(toUserEmail);
  const ref = firestore.collection('ListUsers').doc(listId);
  const docSnap = await ref.get();
  if (!docSnap.exists) {
    return;
  }

  const data = docSnap.data();
  if (!data) {
    return;
  }

  let newSharedWith = { ...data.sharedWith };
  newSharedWith[user.userId] = true;

  await ref.set({ sharedWith: newSharedWith }, { merge: true });

  const event: ShoppingListSharedEvent = {
    sharedFromId: fromUserId,
    sharedToId: user.userId,
    shoppingListId: listId,
    shoppingListName: data.name
  };
  eventBus.emit(DomainEvents.ShoppingListShared, event);

};

const dataToUserList: (data: DocumentData) => UserList = (data) => {
  return {
    id: data.id,
    name: data.name,
    totalItems: data.totalItems,
    sharedWith: Object.keys(data.sharedWith)
  } as UserList;
};

const subscribeToUserLists: (userId: string, cb: SubscriptionCallback<UserList[]>) => Unsubscribe = (userId, cb) => {
  return firebaseService.firestoreSubscription(firestore => {
    if (!userId) {
      log.warn('Cant subscribe to user list items because there is not list.');
      return () => {
        log.warn('There wasnt subscriptiopn');
      };
    }
    log.debug(`Subscribing to user lists ${userId}`);
    return firestore
      .collection('ListUsers')
      .where(`sharedWith.${userId}`, '==', true)
      .onSnapshot(snapshot => {
        const userLists: UserList[] = snapshot.docs.map(doc => doc.data()).map(dataToUserList);
        log.debug(`Found lists ${userLists.map(list => list.id)}`);
        cb(userLists);
      });
  });
};

const getUsersIdFromList: (listId: string, db: firebase.firestore.Firestore) => Promise<string[]> = async (listId, db) => {
  log.debug(`Finding all users from list ${listId}`);
  const docSnap = await db.collection('ListUsers').doc(listId).get();
  if (!docSnap.exists) {
    return [];
  }

  const data = docSnap.data();
  if (!data) {
    return [];
  }

  let newSharedWith = { ...data.sharedWith };

  const users = Object.keys(newSharedWith);
  log.debug(`Found ${users.length} users`);
  return users;
};

const subscribeToUsersSharingList: (listId: string, cb: SubscriptionCallback<UserDetails[]>) => Unsubscribe = (listId, cb) => {
  return firebaseService.firestoreSubscription(db => {
    return db
      .collection('ListUsers')
      .doc(listId)
      .onSnapshot(docSnap => {
        if (!docSnap.exists) {
          return;
        }

        const data = docSnap.data();
        if (!data) {
          return;
        }

        let newSharedWith = { ...data.sharedWith };

        const users = Object.keys(newSharedWith);
        log.debug(`Found ${users.length} users`);
        UserDetailsService.getUsersDetails(users, db).then(cb);
      });
  });
};

export default {
  initialize,
  getUsersIdFromList
};

export const UserListsDomainApi = {
  subscribeToUserLists,
  createUserList: UserListsServiceIO.createUserList,
  removeUserFromList: UserListsServiceIO.removeUserFromList,
  shareListWithUser,
  subscribeToUsersSharingList
};
