/**
 * Trims a´ånd lower the string.
 */
export const trimAndLower = (s: string) => {
  if (s !== undefined && s !== null) {
    return s.trim().toLowerCase();
  }
  return s;
};

export const trimAndRemoveMultipleSpaces: (s: string) => string = s => {
  return s.replace(/\s+/g, ' ').trim();
}

/**
 * Remove multiple whitespaces and replace tildes with normal letters.
 * Also trims and lowercase.
 * @param s
 */
export const makeIndexable: (s: string, includeNTilde?: boolean) => string = (s, includeNTilde) => {
  //https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript
  const tildesRemoved = s.replace(/[^A-Za-z0-9]/g, a => {
    switch (a) {
      case 'á':
        return 'a';
      case 'Á':
        return 'A';
      case 'é':
        return 'e';
      case 'É':
        return 'E';
      case 'í':
        return 'i';
      case 'Í':
        return 'I';
      case 'ó':
        return 'o';
      case 'Ó':
        return 'O';
      case 'ú':
        return 'u';
      case 'Ú':
        return 'U';
      case 'ñ':
        return includeNTilde ? 'n' : 'ñ';
      case 'Ñ':
        return includeNTilde ? 'N' : 'Ñ';
      default:
        return a;
    }
  });

  /**
   * Inspired by: https://stackoverflow.com/questions/30895309/need-to-remove-emojis-and-simple-icons-from-input-with-javascript
   * Unicode characters allowed.
   * ñ: \u00F1
   * Ñ: \u00D1
   */
  const emojiRemoved = tildesRemoved.replace(/[^\w.,\s\u00F1\u00D1]/gu, '');

  const trimmedAndLowered = trimAndLower(emojiRemoved.replace(/\s+/g, ' '));

  return trimmedAndLowered;
};
