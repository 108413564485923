import { connect } from 'react-redux';
import { State } from '../../../reducer';
import * as React from 'react';
import { ListCategoriesDrawerState } from './Reducers';
import { ListCategoriesDrawerActions } from './Actions';
import { ListCategoriesDrawerComponent } from './Component';
import { RouteComponentProps } from 'react-router-dom';
import { ShoppingListPageProps } from '../ShoppingListsPage';
import { getListCategoriesDrawerState } from '../ShoppingListsPageReducer';
import { ActionProps } from '../../../utils/Actions';

export type ListCategoriesDrawerContainerProps = ShoppingListPageProps &
  ListCategoriesDrawerState & {
    open: boolean;
    closeClicked: () => void;
  } & ActionProps<typeof ListCategoriesDrawerActions>;

const mapDispatchToProps = {
  ...ListCategoriesDrawerActions
};

const mapStateToProps = (state: State, props: RouteComponentProps<{}>) => {
  const listCategoriesDrawerState = getListCategoriesDrawerState(state);
  return {
    ...listCategoriesDrawerState
  };
};

class ListCategoriesDrawerContainer extends React.Component<ListCategoriesDrawerContainerProps> {
  render() {
    return <ListCategoriesDrawerComponent {...this.props} />;
  }
}

export const ListCategoriesDrawer = connect(mapStateToProps, mapDispatchToProps)(ListCategoriesDrawerContainer);
