import * as React from 'react';
import { RecipesFilterDrawerState } from './Reducers';
import { RecipesFilterActions } from './Actions';
import { ShoppingListPageProps } from '../ShoppingListsPage';
import { State } from '../../../reducer';
import { getRecipesFilterDrawerState } from '../ShoppingListsPageReducer';
import { RecipesFilterDrawerComponent } from './Component';
import { connect } from 'react-redux';
import { ActionProps } from '../../../utils/Actions';

export type RecipesFilterDrawerProps = ShoppingListPageProps &
  RecipesFilterDrawerState & {
    open: boolean;
    closeClicked: () => void;
  } & ActionProps<typeof RecipesFilterActions>;

const mapDispatchToProps = {
  ...RecipesFilterActions
};

const mapStateToProps = (state: State) => {
  return getRecipesFilterDrawerState(state);
};

class RecipesFilterDrawerContainer extends React.Component<RecipesFilterDrawerProps> {
  render() {
    return <RecipesFilterDrawerComponent {...this.props} />;
  }
}

export const RecipesFilterDrawer = connect(mapStateToProps, mapDispatchToProps)(RecipesFilterDrawerContainer);
