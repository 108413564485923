import { Action } from 'redux';
import { isShoppingListsShown } from '../components/shoppinglists/router/NavigationReducer';
import { isRecipesPageShown } from '../components/recipes/router/RecipesNavReducer';

export interface NavState {
  recipesPageShown: boolean;
  listsPageShown: boolean;
  menuOpen: boolean;
}

const emptyState = {
  recipesPageShown: false,
  listsPageShown: false,
  menuOpen: false
};

export interface NavAction extends Action {
  payload: {
    location: {
      pathname: string;        
    }
  };
}

export const navReducer = (state: NavState = emptyState, action: Action) => {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE': {
      const navAction = action as NavAction;
      const path = navAction.payload.location.pathname;
      return {
        recipesPageShown: isRecipesPageShown(path),
        listsPageShown: isShoppingListsShown(path),
        menuOpen: path.endsWith('menu')
      };
    }
    default:
      return state;
  }
};
