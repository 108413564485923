import { connect } from 'react-redux';
import { State } from '../../../reducer';
import { RecentItemsDrawerState } from './Reducers';
import RecentItemsDrawerComponent from './Component';
import { domainApi } from '../../../domain/DomainApi';
import { RecentItemsActions } from './Actions';
import * as React from 'react';
import { AuthState } from '../../../utils/components/auth/Reducers';
import { getRecentItemsDrawerState } from '../ShoppingListsPageReducer';
import { ActionProps } from '../../../utils/Actions';
import { logger } from '../../../utils/LoggingUtils';
import { AppProps } from '../../../router/PrivateRouter';

const log = logger('RecentItems.container');

export type RecentItemsContainerProps = AuthState &
  RecentItemsDrawerState & {
    open: boolean;
    closeClicked: () => void;
  } & ActionProps<typeof RecentItemsActions>
  & AppProps;

const mapDispatchToProps = {
  ...RecentItemsActions
};

const mapStateToProps = (state: State) => {
  return getRecentItemsDrawerState(state);
};

class RecentItemsDrawerContainer extends React.Component<RecentItemsContainerProps> {
  unsuscribe?: () => void;

  componentDidMount() {
    const props = this.props;
    const unsubs1 = domainApi.subscribeToRecentItems(props.selectedList, props.recentItemsChanged);
    const unsubs2 = domainApi.subscribeToRecentItemList(props.selectedList, props.recentItemListChanged);
    log.debug(`mounted`);
    this.unsuscribe = () => {
      unsubs1();
      unsubs2();
    };
  }

  componentDidUpdate(prevProps: Readonly<RecentItemsContainerProps>) {
    const updatedProps = this.props;
    if (updatedProps.selectedList !== prevProps.selectedList) {
      log.debug(`selected list changed, updating subscriptions to list ${updatedProps.selectedList}`);
      this.unsuscribe && this.unsuscribe();
      const unsubs1 = domainApi.subscribeToRecentItems(updatedProps.selectedList, updatedProps.recentItemsChanged);
      const unsubs2 = domainApi.subscribeToRecentItemList(updatedProps.selectedList, updatedProps.recentItemListChanged);

      this.unsuscribe = () => {
        unsubs1();
        unsubs2();
      };
    }
  }

  componentWillUnmount() {
    log.debug(`Recent items unmount`);
    this.unsuscribe && this.unsuscribe();
  }

  render() {
    return <RecentItemsDrawerComponent {...this.props} />;
  }
}

export const RecentItemsDrawer = connect(mapStateToProps, mapDispatchToProps)(RecentItemsDrawerContainer);
