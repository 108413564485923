import * as React from 'react';
import { StyleRules, withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import { ListItemSecondaryAction, ListItemText, Theme, WithStyles, Link } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import ChevronIcon from '@material-ui/icons/ChevronLeft';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import { RecipesFilterDrawerProps } from './Container';
import Checkbox from '@material-ui/core/Checkbox';
import { Recipe } from '../../../domain/recipes/Types';
import ListItem from '@material-ui/core/ListItem';

const EmptyImg = require('react-icons/lib/fa/sun-o');

const style: (theme: Theme) => StyleRules = theme => ({
  list: {
    width: '100%'
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  drawerPaper: {
    width: '100%'
  },
  deleteIcon: {
    color: theme.palette.grey.A700
  },
  emptyState: {
    height: '100%',
    textAlign: 'center',
    paddingTop: '70px'
  }
});

export type RecipesFilterProps = RecipesFilterDrawerProps & WithStyles;

const RecipesFilterDrawerComponentStyled = (props: RecipesFilterProps) => {
  const { t, classes, open, closeClicked } = props;

  const handleCheckbox = (recipe: Recipe) => (evt: React.ChangeEvent, checked: boolean) => {
    props.updateRecipeFilterAction(props.selectedList, recipe, checked);
  };

  const goToRecipes = () => {
    props.goToRecipesPage();
  } 

  const handleRecipeClick = (recipe: Recipe) => () => {
    props.updateRecipeFilterAction(props.selectedList, recipe, !isRecipeChecked(recipe));
    closeClicked();
  };

  const isRecipeChecked = (recipe: Recipe) => {
      return props.filters.map(filter => filter.id).indexOf(recipe.id) >= 0;
  };

  const recipes = props.recipes.map(recipe => {
    return (
      <ListItem button={true} divider={true} key={recipe.id} onClick={handleRecipeClick(recipe)}>
        <ListItemText primary={recipe.name} />
        <ListItemSecondaryAction>
          <Checkbox
            checked={isRecipeChecked(recipe)}
            onChange={handleCheckbox(recipe)}
          />
        </ListItemSecondaryAction>
      </ListItem>
    );
  });

  let component;
  if (props.recipes.length > 0) {
    component = (
      <div className={classes.list}>
        <List>{recipes}</List>
      </div>
    );
  } else {
    component = (
      <div className={classes.emptyState}>
        <EmptyImg size={200} color="rgba(97, 97, 97, 0.5)" />
        <Typography variant="h5" style={{ paddingTop: '30px' }}>
          {t(`You don't have recipes`)}
        </Typography>
        <Typography variant="subtitle1">
          {t('Tap')} <strong><Link onClick={goToRecipes}>{t('here')}</Link></strong> {t('to start creating your recipes')}.
        </Typography>
      </div>
    );
  }

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={closeClicked}
      classes={{
        paper: classes.drawerPaper
      }}>
      <AppBar position="sticky">
        <Toolbar>
          <div className={classes.drawerHeader} onClick={closeClicked}>
            <ChevronIcon />
            {t('Filter list by recipe')}
          </div>
          <Typography variant="subtitle1" color="inherit" style={{ flex: 1 }} />
        </Toolbar>
      </AppBar>
      <Divider />
      <div tabIndex={0} role="button">
        {component}
      </div>
    </Drawer>
  );
};

export const RecipesFilterDrawerComponent = withStyles(style)(RecipesFilterDrawerComponentStyled);
