import { Action } from 'redux';
import { ToggleUsersSort, ActionTextChangedCommand } from './Actions';
import { SortDirection } from '@material-ui/core/TableCell';
import { logger } from '../../../utils/LoggingUtils';
import { UserDetails } from '../../../domain/userdetals/Types';
import { UsersDetailsUpdated } from '../AdminPageActions';
import { DashboardUser } from './Types';
import { dedupeDatesByDay } from '../../../utils/ListUtils';

const log = logger('UsersListReducer');


export type UsersListState = Readonly<{
  usersDetails: DashboardUser[];
  sortColumn: string;
  sortDirection: SortDirection;
  totalUsers: number;
  totalMonthlyActive: number;
  totalFortnightlyActive: number;
  actionToExec: string;
}>;

const emptyState = {
  usersDetails: [],
  sortColumn: 'Name',
  sortDirection: 'desc' as SortDirection,
  totalUsers: 0,
  totalMonthlyActive: 0,
  totalFortnightlyActive: 0,
  actionToExec: ''
};

const calculateDailyLoginsOnPeriod: (logins: Date[], period: number) => number = (logins, period) => {
  if (!logins || logins.length === 0) {
    return 0;
  }
  const lastPeriod = new Date();
  lastPeriod.setDate(lastPeriod.getDate() - period);
  const lastPeriodDates = [...logins]
    .filter(login => login >= lastPeriod);
  return dedupeDatesByDay(lastPeriodDates).length;
}

const toDashboardUsers: (details: UserDetails[]) => DashboardUser[] = (details = []) => {
  return details.map(userDetails => {
    return {
      ...userDetails,
      lastLoginDate: userDetails.loginHistory[0],
      dailyLoginsLastFortnight: calculateDailyLoginsOnPeriod(userDetails.loginHistory, 15),
      dailyLoginsLastMonth: calculateDailyLoginsOnPeriod(userDetails.loginHistory, 30)
    }
  });
}

export const usersListReducer: (state: UsersListState | undefined, action: Action) => UsersListState = (
  state = emptyState,
  action
) => {
  switch (action.type) {
    case UsersDetailsUpdated.TYPE: {
      const myAction = action as UsersDetailsUpdated;
      const usersDetails = toDashboardUsers(myAction.payload);
      const totalUsers = usersDetails.length;
      const totalMonthlyActive = usersDetails.filter(user => user.dailyLoginsLastMonth && user.dailyLoginsLastMonth >= 2).length
      const totalFortnightlyActive = usersDetails.filter(user => user.dailyLoginsLastFortnight && user.dailyLoginsLastFortnight >= 1).length
      return {
        ...state,
        usersDetails,
        totalUsers,
        totalMonthlyActive,
        totalFortnightlyActive
      };
    }

    case ToggleUsersSort.TYPE: {
      const myAction = action as ToggleUsersSort
      const isDesc = myAction.payload.column === state.sortColumn && state.sortDirection === 'desc';
      const newSort: SortDirection = isDesc ? 'asc' : 'desc';
      log.debug(`Sorting column ${myAction.payload.column} ${newSort}`)
      return {
        ...state,
        sortColumn: myAction.payload.column,
        sortDirection: newSort
      };
    }

    case ActionTextChangedCommand.TYPE: {
      const myAction = action as ActionTextChangedCommand
      return {
        ...state,
        actionToExec: myAction.payload
      }
    }
    
    default:
      return state;
  }
}