import { firebaseService } from '../../utils/firebase/FirestoreConfig';
import { RecipesList, Recipe, Recipes, RecipeItem } from './Types';
import { SubscriptionCallback, Unsubscribe } from '../SubsciptionTypes';

import {
  default as DomainEvents,
  ItemAddedToRecipeEvent,
  RecipeItemsAddedToList,
  RecipeDeletedEvent,
  ShoppingListItemAddedEvent,
  ShoppingListItemCompletedEvent,
  ItemRemovedFromRecipeEvent,
  ListUserHardDeletedEventIO,
  ShoppingListItemRenamedEvent
} from '../DomainEvents';
import { RecipesRepo } from './repo/RecipesRepository';
import { logger } from '../../utils/LoggingUtils';
import { listUtils } from '../../utils/ListUtils';
import { ListItem } from '../../components/shoppinglists/currentshoppinglist/Types';
import { ShoppingListRepo } from '../shoppinglist/ShoppingListRepository';
import { effectsImpl } from '../../utils/fp/effects/EffectsImpl';
import { IO } from '../../utils/fp/io';

const log = logger('RecipesService');

const initialize = () => {
  DomainEvents.onRecentItemUpdated(event => {
    switch (event.action) {
      case 'Restore':
        onRecentItemRestored(event.listId, event.item.itemName);
        break;
      default:
        return;
    }
  });

  ShoppingListItemAddedEvent.onEvent(event => {
    log.debug(`Item added to shoppinglist ${event.listId}`);
    onShoppingListUpdated(event.listId, ...event.items);
  });

  ShoppingListItemCompletedEvent.onEvent(event => {
    log.debug(`Item completed on shoppinglist ${event.listId}`);
    onShoppingListUpdated(event.listId, event.item);
  });

  ShoppingListItemRenamedEvent.onEvent(event => {
    log.debug(`Item renamed on shoppinglist ${event.listId}`);
    onShoppingListUpdated(event.listId, event.item);
  });

  ListUserHardDeletedEventIO.onEvent(event => 
    new IO(() => hardDeleteRecipesList(event.listId))
  ).eval(effectsImpl);

  log.debug('Recipes service initialized.');
};

const hardDeleteRecipesList: (listId: string) => Promise<void> = async (listId) => {
  log.debug(`Hard deleting recipes for list ${listId}`);
  const firestore = await firebaseService.firestore;
  await RecipesRepo.deleteRecipesList(listId, firestore);
  return;
}

const onRecentItemRestored = (listId: string, itemName: string) => {
  firebaseService.firestore.then(firestore => {
    RecipesRepo.findRecipesByItemName(firestore, listId, itemName).then(recipes => {
      if (listUtils.isEmpty(recipes)) {
        return;
      }

      const newRecipes = recipes.map(recipe => Recipes.updateItemDates(recipe, itemName, { addedOn: new Date() }));
      RecipesRepo.saveList(listId, newRecipes, firestore);
    });
  });
};

const onShoppingListUpdated = async (listId: string, ...updatedListItems: ListItem[]) => {
  if (!updatedListItems) {
    log.debug('No updated items');
    return;
  }

  const firestore = await firebaseService.firestore;
  const updatedItemIds = updatedListItems.map(item => item.id);
  const listRecipes = await RecipesRepo.findAllRecipes(firestore, listId);
  
  if (listUtils.isEmpty(listRecipes)) {
    return;
  }

  const recipesThatContainItems: (recipe: Recipe) => boolean = (recipe) => {
    return !!recipe.items.find(recipeItem => listUtils.includes(updatedItemIds, recipeItem.id));
  };

  const updateItemsOnRecipe: (recipe: Recipe) => Recipe = (recipe) => {
    const updatedRecipeItems = recipe.items.map(recipeItem => {
      const foundListItem = updatedListItems.find(item => recipeItem.id === item.id);
      if (foundListItem) {
        log.debug(`Updating recipe ${recipe.id}:${recipe.name} with item ${foundListItem.id}:${foundListItem.name}`);
        return Recipes.newRecipeItemFromListItem(foundListItem);
      }

      return recipeItem;
    });

    return {
      ...recipe,
      items: updatedRecipeItems
    };
  }

  let updatedRecipes = listRecipes
  .filter(recipesThatContainItems) //Filter to reduce the number of recipes that need to be saved.
  .map(updateItemsOnRecipe);
  RecipesRepo.saveList(listId, updatedRecipes, firestore);
};

const subscribeToRecipes: (listId: string, cb: SubscriptionCallback<RecipesList>) => Unsubscribe = (listId, cb) => {
  return firebaseService.firestoreSubscription(firestore => {
    return RecipesRepo.recipesDB(firestore, listId).onSnapshot(snapshot => {
      const recipes = RecipesRepo.queryToRecipe(snapshot);
      cb({
        listId,
        recipes
      });
    });
  });
};

const subscribeToRecipeDetails: (listId: string, recipeId: string, cb: SubscriptionCallback<Recipe>) => Unsubscribe = (
  listId,
  recipeId,
  cb
) => {
  return firebaseService.firestoreSubscription(firestore => {
    return RecipesRepo.recipeDetailsDB(firestore, listId, recipeId).onSnapshot(snapshot => {
      const recipe = RecipesRepo.documentToRecipe(snapshot);
      if (recipe) {
        cb(recipe);
      }
    });
  });
};

const createNewRecipe: (params: { name: string; listId: string }) => Promise<Recipe> = async params => {
  const firestore = await firebaseService.firestore;
  const { name, listId } = params;
  return RecipesRepo.saveRecipe(firestore, Recipes.newRecipe(listId, name));
};

const removeRecipeItem: (params: {
  itemId: string;
  listId: string;
  recipeId: string;
}) => Promise<void> = async params => {
  const firestore = await firebaseService.firestore;
  log.debug(`Deleting item ${params.itemId} from recipe ${params.recipeId}`);
  const foundRecipe = await RecipesRepo.findRecipeById(firestore, params.listId, params.recipeId);
  if (foundRecipe) {
    log.debug(`Deleting recipe item ${params.itemId} from recipe ${params.recipeId}`);
    Recipes.removeItem(foundRecipe, params.itemId);
    const savePromise = RecipesRepo.saveRecipe(firestore, foundRecipe);
    new ItemRemovedFromRecipeEvent(foundRecipe, params.itemId).emit();
    await savePromise;
    log.debug(`Operation completed: Remove recipe item ${JSON.stringify(params)}`);
    return;
  }
  log.debug(`Recipe ${params.recipeId} does not exist`);
  return;
};

const updateRecipeName = async (listId: string, recipeId: string, name: string) => {
  const firestore = await firebaseService.firestore;
  return RecipesRepo.recipeDetailsDB(firestore, listId, recipeId).update({ name });
};

const removeRecipe = (listId: string, recipeId: string) => {
  return firebaseService.firestore.then(firestore => {
    log.debug(`Deleting recipe ${listId} - ${recipeId}`);
    RecipesRepo.findRecipeById(firestore, listId, recipeId).then(recipe => {
      if (recipe) {
        RecipesRepo.recipeDetailsDB(firestore, listId, recipeId).delete();
        new RecipeDeletedEvent(recipe).emit();
      } else {
        log.warn(`Trying to delete a recipe that doesn't exist ${listId} - ${recipeId}`);
      }
    });
  });
};

const addNewRecipeItem = async (listId: string, recipeId: string, itemName: string) => {
  const firestore = await firebaseService.firestore;
  const foundRecipe = await RecipesRepo.findRecipeById(firestore, listId, recipeId);
  if (!foundRecipe) {
    log.warn(`Trying to add an item for a recipe that doesnt exist. Recipe ${recipeId} of list ${listId}`);
    return;
  }

  if (Recipes.containsItemName(foundRecipe, itemName)) {
    log.debug(`RecipeItem ${itemName} found, not adding it again`);
    return;
  }

  log.debug(`Recipe item ${itemName} not found in recipe, will search in recipe items history.`);
  const foundListItem = await ShoppingListRepo.findItemByName(listId, itemName, firestore);
  
  let addedRecipeItem: RecipeItem;
  if (!foundListItem) {
    log.debug(`Recipe item ${itemName} not found in history, creating a new one`);
    addedRecipeItem = Recipes.newRecipeItem(itemName);
  } else {
    log.debug(`Recipe item ${itemName} found in history.`);
    addedRecipeItem = Recipes.newRecipeItemFromListItem(foundListItem);
  }

  Recipes.addItem(foundRecipe, addedRecipeItem);
  await RecipesRepo.saveRecipe(firestore, foundRecipe);
  new ItemAddedToRecipeEvent(foundRecipe, addedRecipeItem).emit();
};

const addRecipeItemsToList = async (recipeId: string, listId: string, idsToAdd: string[] = []) => {
  const firestore = await firebaseService.firestore;
  log.debug(`Adding items to listId ${listId}, from recipeId ${recipeId}`);

  const recipe = await RecipesRepo.findRecipeById(firestore, listId, recipeId);
  if (!recipe) {
    log.warn(`Error adding recipe items to list: Recipe ${recipeId} not found for list ${listId}`);
    return;
  }

  log.debug(
    `Emitting RecipeItemsAddedToList with ${recipe.items.length} items. Adding ${idsToAdd.length} specific items`
  );
  new RecipeItemsAddedToList(recipe, idsToAdd).emit();
};

export const RecipesDomainApi = {
  subscribeToRecipes,
  createNewRecipe,
  subscribeToRecipeDetails,
  updateRecipeName,
  removeRecipe,
  addNewRecipeItem,
  addRecipeItemsToList,
  removeRecipeItem
};

export default {
  initialize
};
