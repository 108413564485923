import * as React from 'react';
import { ListItem } from './Types';
import Dialog from '@material-ui/core/Dialog';
import { DialogContent, DialogActions, DialogTitle, Theme, WithStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { StyleRules } from '@material-ui/core/styles';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import { TFunction } from 'i18next';

const style: (theme: Theme) => StyleRules = () => ({});

interface RenameItemDialogProps {
  item?: ListItem;
  open: boolean;
  onClose: () => void;
  onItemRenamed: (itemId: string, newName: string) => void;
  t: TFunction;
}

interface CategoriesDialogState {
  newNameTxt: string;
}

class StyledCategoriesDialog extends React.Component<RenameItemDialogProps & WithStyles, CategoriesDialogState> {
  state: CategoriesDialogState;
  inputRef?: HTMLElement;

  constructor(props: RenameItemDialogProps & WithStyles) {
    super(props);
    this.state = {
      newNameTxt: props.item ? props.item.name : ''
    };
  }

  componentDidMount() {
    this.setState({
      newNameTxt: this.props.item ? this.props.item.name : ''
    });
  }

  closeDialog = () => {
    this.setState({
      newNameTxt: ''
    });
    this.props.onClose();
  };

  onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      this.props.onItemRenamed(this.props.item ? this.props.item.id : '', this.state.newNameTxt);
      this.closeDialog();
  };

  onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ newNameTxt: e.currentTarget.value });
  };

  onInputFocused = (event: React.FocusEvent<HTMLInputElement>) => {
      // From: https://stackoverflow.com/questions
      // /36051883/how-to-select-all-text-in-input-with-reactjs-when-it-focused
      event.target.select();
  };

  onOkClicked = () => {
      this.props.onItemRenamed(this.props.item ? this.props.item.id : '', this.state.newNameTxt.trim());
      this.closeDialog();
  };

  onCancelClicked = () => {
      this.closeDialog();
  };

  render() {
    const props = this.props;
    const t = props.t;
    return (
      <Dialog
        maxWidth="xs"
        aria-labelledby="confirmation-dialog-title"
        open={props.open}
        onClose={this.closeDialog}>
        <DialogTitle id="confirmation-dialog-title">{props.item ? props.item.name : ''}</DialogTitle>
        <DialogContent>
          <form
            onSubmit={this.onSubmit}>
            <TextField
              value={this.state.newNameTxt}
              onChange={this.onInputChange}
              name="RenameItem"
              placeholder="New name"
              autoFocus={true}
              onFocus={this.onInputFocused}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.onCancelClicked}
            color="secondary">
            {t('Cancel')}
          </Button>
          <Button
            onClick={this.onOkClicked}
            color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export const RenameItemDialog = withStyles(style)(StyledCategoriesDialog);
