import { SyncAction, SyncActionClass } from '../../../utils/Actions';
import { domainApi } from '../../../domain/DomainApi';

export class ConfirmAddItemsToList extends SyncActionClass<string> {
  constructor(recipeId: string) {
    super('ConfirmAddItemsToList', recipeId);
  }
}

export class ConfirmDeleteRecipe extends SyncActionClass<string> {
  constructor(recipeId: string) {
    super('ConfirmDeleteRecipe', recipeId);
  }
}

export class AddRecipeItemsToList extends SyncActionClass<{ listId: string; recipeId: string }> {
  constructor(listId: string, recipeId: string) {
    super('AddRecipeItemsToList', { listId, recipeId });
  }
}

export class CancelAddItemsToList extends SyncActionClass<{}> {
  constructor() {
    super('CancelAddItemsToList', {});
  }
}

export class CancelDeleteRecipe extends SyncActionClass<{}> {
  constructor() {
    super('CancelDeleteRecipe', {});
  }
}

export class DeleteRecipe extends SyncActionClass<{ listId: string; recipeId: string }> {
  constructor(listId: string, recipeId: string) {
    super('DeleteRecipe', { listId, recipeId });
  }
}

const confirmAddItemsToList: (recipeId: string) => SyncAction = recipeId =>
  new ConfirmAddItemsToList(recipeId).action();

const confirmDeleteRecipe: (recipeId: string) => SyncAction = recipeId =>
  new ConfirmDeleteRecipe(recipeId).action();  

const addRecipeItemsToList: (listId: string, recipeId: string) => SyncAction = (listId, recipeId) => {
  domainApi.addRecipeItemsToList(recipeId, listId);
  return new AddRecipeItemsToList(listId, recipeId).action();
};

const deleteRecipe: (listId: string, recipeId: string) => SyncAction = (listId, recipeId) => {
  domainApi.removeRecipe(listId, recipeId);
  return new DeleteRecipe(listId, recipeId).action();
};

const cancelAddItemsToList: () => SyncAction = () => new CancelAddItemsToList().action();

const cancelDeleteRecipe: () => SyncAction = () => new CancelDeleteRecipe().action();

export const RecipesListActions = {
  confirmAddItemsToList,
  confirmDeleteRecipe,
  cancelAddItemsToList,
  addRecipeItemsToList,
  deleteRecipe,
  cancelDeleteRecipe
};
