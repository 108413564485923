import { Effect, Effects } from './effects/Effects';
import { effectsStub } from './effects/EffectsStub';

export const _do = (fn: (...args: any[]) => Generator<IO<any>, any, any>) => (...args: any[]) => {
    const gen = fn(...args);

    const next = (val?: any) => {
        const res: any = gen.next(val);
        if(!res.done) return res.value.flatMap(next);
        if(res.value && res.value.effect) return res.value;
        return IO.of(res.value);
    }

    return next();
}

/**
 * Same as do notation plus evaluation using stubs.
 */
export const _testDo: (fn: (...args: any[]) => Generator<IO<any>, any, any>) => () => Promise<any> =
    (fn) => {
        const io: IO<any> = _do(fn)();
        return () => io.eval(effectsStub);
    };

export class IO<A>{
    private effect: Effect<A>
    constructor(effect: Effect<A>) {
        this.effect = effect
    }
    static of<T>(val: T): IO<T> {
        return new IO(() => Promise.resolve(val))
    }

    static void(): IO<void> {
        return new IO(() => Promise.resolve())
    }
    
    map<B>(f: (val: A) => B): IO<B> {
        const mappedEffect: Effect<B> = async (effects: Effects) => {
            const unwrappedVal: A = await this.effect(effects);
            return f(unwrappedVal);
        };
        return new IO(mappedEffect);
    }

    flatMap<B>(f: (val: A) => IO<B>): IO<B> {
        const boundEffect: Effect<B> = async (effects: Effects) => {
            const unwrappedVal: A = await this.effect(effects);
            const mappedIO: IO<B> = f(unwrappedVal);
            return mappedIO.effect(effects);
        }

        return new IO(boundEffect);
    }

    eval(effects: Effects): Promise<A> {
        return this.effect(effects)
    }
}