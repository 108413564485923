import { Action } from 'redux';
import { ListItem } from '../currentshoppinglist/Types';
import { ItemsForLaterChanged } from './Actions';

export type ItemsForLaterDrawerState = Readonly<{
  listItems: ListItem[];
}>;

const emptyRecentItemsState = {
  listItems: []
};

export const itemsForLaterDrawerReducer: (
  state: ItemsForLaterDrawerState | undefined,
  action: Action
) => ItemsForLaterDrawerState = (state = emptyRecentItemsState, action) => {
  switch (action.type) {
    case 'ItemsForLaterChanged': {
      const itemsForLater = action as ItemsForLaterChanged;
      return { ...state, listItems: itemsForLater.payload };
    }

    default:
      return state;
  }
};
