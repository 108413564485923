import { Action } from 'redux';
import { emptyUserLists, UserList, UserLists } from './Types';
import { ExpandUserListAction, UserListsChangedAction, UserSelectedListChangedAction } from './Actions';
import { SelectUserListAction } from '../../utils/actions/Actions';
import { NotificationsUpdatedAction } from '../shoppinglists/topbar/Actions';

export type MenuDrawerState = Readonly<{
  userLists: UserLists;
  userListsExpanded: boolean;
  loading: boolean;
  reload: boolean;
  totalNotifications: number;
}>;

const emptyMenuDrawerState = {
  userLists: emptyUserLists,
  userListsExpanded: true,
  loading: false,
  reload: false,
  totalNotifications: 0
};

export const menuDrawerReducer: (state: MenuDrawerState | undefined, action: Action) => MenuDrawerState = (
  state = emptyMenuDrawerState,
  action
) => {
  switch (action.type) {
    case 'UserSelectedListChangedAction':
      const selectedListChangedAction = action as UserSelectedListChangedAction;
      return {
        ...state,
        userLists: {
          ...state.userLists,
          selectedList: selectedListChangedAction.payload.selectedList,
          userId: selectedListChangedAction.payload.userId
        },
        loading: false
      };
    case 'UserListsChangedAction':
      const listChangedAction = action as UserListsChangedAction;
      let lists: UserList[] = [];

      if (listChangedAction.payload.length) {
        lists = listChangedAction.payload;
      }

      return {
        ...state,
        userLists: {
          ...state.userLists,
          lists: lists
        }
      };

    case 'SelectUserListAction':
      const selectListInProgress = action as SelectUserListAction;
      const newUserLists = {
        ...state.userLists,
        selectedList: selectListInProgress.payload.listId
      };
      return {
        ...state,
        userLists: newUserLists
      };

    case ExpandUserListAction.TYPE:
      const expandUserList = action as ExpandUserListAction;
      return {
        ...state,
        userListsExpanded: expandUserList.payload
      };

    case 'NotificationsUpdatedAction': {
      const notificationsAction = action as NotificationsUpdatedAction;
      const total = !!notificationsAction.payload ? notificationsAction.payload.length : 0;
      return { ...state, totalNotifications: total };
    }

    default:
      return state;
  }
};
