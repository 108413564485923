import * as React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { StyleRules } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import SendIcon from '@material-ui/icons/Send';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';
import { Theme, WithStyles, Fab } from '@material-ui/core';
import { AddRecipeItemButtonProps } from './Container';
import { RECIPE_DETAILS_PATH } from '../../router/RecipesRouter';
import { getPathParam } from '../../../../utils/RouterUtils';
import AutoComplete from '../../../../utils/components/autocomplete/Container';
import { getAddRecipeItemAutocompleteState } from '../../RecipesPageReducer';

const style: (theme: Theme) => StyleRules = theme => ({
  fab: {
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    position: 'fixed',
    zIndex: 1500
  },
  drawerContent: {
    margin: theme.spacing(2)
  },
  drawerPaper: {
    overflowY: 'visible'
  },
  drawer: {
    overflowY: 'visible'
  },
  drawerToolbar: {
    justifyContent: 'space-between',
    paddingRight: 0,
    paddingLeft: 0
  },
  addItem: {
    color: theme.palette.secondary.main
  }
});

type AddItemComponentProps = AddRecipeItemButtonProps & WithStyles;

class StyledAddItemButton extends React.Component<AddItemComponentProps> {
  private textInput?: HTMLInputElement;

  submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.addMore(this.props.inputText);
  };

  addMore = (text: string) => {
    const id = this.getRecipeId();
    if (text && id) {
      this.props.addMoreItems(this.props.selectedList, id, text);
    }

    if (this.textInput) {
      this.textInput.focus();
    }
  };

  addItemClick = () => {
    const id = this.getRecipeId();
    if (!this.props.inputText || !id) {
      this.closeEditDialog();
      return;
    }

    this.props.addItem(this.props.selectedList, id, this.props.inputText);
    this.props.openAddRecipeItemDialog(false, id);
  };

  addItemFromList = (text: string) => {
    const id = this.getRecipeId();
    if (text && id) {
      this.props.addItem(this.props.selectedList, id, text);
    }
  };

  onSuggestionSelected = (suggestion: string) => this.addItemFromList(suggestion);

  focusTextInput = (inputRef?: HTMLInputElement) => {
    if (inputRef) {
      this.textInput = inputRef;
    }
  };
  render() {
    const props = this.props;
    const { t, classes } = props;

    return (
      <React.Fragment>
        {!this.props.isAddRecipeItemDialogOpen && (
          <Fab
            color="secondary"
            aria-label="add"
            className={classes.fab}
            onClick={this.openEditDialog}>
            <AddIcon />
          </Fab>
        )}
        <Drawer
          anchor="bottom"
          open={this.props.isAddRecipeItemDialogOpen}
          onClose={this.closeEditDialog}
          className={classes.drawer}
          classes={{
            paper: classes.drawerPaper
          }}>
          <div className={classes.drawerContent}>
            <form onSubmit={this.submitForm}>
              <AutoComplete
                name="addRecipeItem"
                onTextChange={props.onAddItemTextChange}
                searchText={props.inputText}
                getState={getAddRecipeItemAutocompleteState}
                suggestions={props.allItems}
                requestFocusWhenShown={true}
                onSuggestionSelected={this.onSuggestionSelected}
                inputProps={{
                  name: 'searchBoxText',
                  placeholder: t('Item name')
                }}
                inputRef={this.focusTextInput}
              />
              <Toolbar className={classes.drawerToolbar}>
                <IconButton onClick={this.closeEditDialog}>
                  <CancelIcon />
                </IconButton>
                <div>
                  <IconButton onClick={this.addItemClick}>
                    <SendIcon className={classes.addItem} />
                  </IconButton>
                </div>
              </Toolbar>
            </form>
          </div>
        </Drawer>
      </React.Fragment>
    );
  }

  private getRecipeId: () => string | undefined = () => {
    return getPathParam(this.props.location.pathname, RECIPE_DETAILS_PATH, 'selectedRecipe');
  };

  private openEditDialog = () => {
    const recipeId = this.getRecipeId();
    this.props.openAddRecipeItemDialog(true, recipeId);
  };

  private closeEditDialog = () => {
    const recipeId = this.getRecipeId();
    this.props.cancelAddingItem();
    this.props.openAddRecipeItemDialog(false, recipeId);
  };
}

export const AddRecipeItemButtonComponent = withStyles(style)(StyledAddItemButton);
