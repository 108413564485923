import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as ReactGA from 'react-ga';
import './index.css';
import { Provider } from 'react-redux';
import { Router } from './router/Router';
import { history, persistor, default as store } from './store';
import Loading from './utils/components/loading/Loading';
import { PersistGate } from 'redux-persist/integration/react';
import { register } from './serviceWorker';
import VersionUtils from './utils/VersionUtils';

import { logger } from './utils/LoggingUtils';
import { NavUtils } from './utils/NavigatorUtils';
import { backoff } from './utils/PromiseRetry';

import { useTranslation } from "react-i18next";
import { TrolleyI18n } from './i18n';

const log = logger('Index');

const props = {
  history
};

TrolleyI18n.init();

const Trolley = () => {
  const { t } = useTranslation();
  return (
      <Provider store={store}>
        <PersistGate loading={<Loading />} persistor={persistor}>
          <Router {...props} t={t} />
        </PersistGate>
      </Provider>
    );
  }

if (process.env.NODE_ENV === 'development' || process.env.REACT_APP_ENV === 'dev') {
  log.debug('Using development GA');
  ReactGA.initialize('UA-122457873-1');
} else {
  log.debug('Using prod GA');
  ReactGA.initialize('UA-122478757-1');
}

ReactDOM.render(<Trolley />, document.getElementById('root') as HTMLElement);

log.debug('Registering service worker');

register({
  onSuccess: (registration: ServiceWorkerRegistration) => {
    log.debug('Service worker registered!');
    if (registration.active) {
      log.debug('Service worker active!');
    }
  },
  onUpdate: (registration: ServiceWorkerRegistration) => {
    log.debug('Service worker updated');
    const waitingServiceWorker = registration.waiting

    if (waitingServiceWorker) {
      if (!NavUtils.isIOS()) {
        // Only check for updates here if not IOS.
        // This is because IOS updates are checked after a timeout.
        // Read below for more details.
        log.debug('Check for updates in the version');
        setTimeout(checkForUpdates, 1);
      }
    }
  }
});

const checkForUpdates = () => {
  log.debug('Checking for updates...');
  VersionUtils.checkForUpdates().then(version => {
    if (version) {
      if (version === 'error') {
        // TODO-AC: Hack use a more purposeful service to detect offline.
        log.warn('Offline detected');
      } else {
        log.debug(`New version detected ${version}`);
        persistor.purge();
        history.push('/lists/version-update');
      }
    }
  });
};

// On IOS the service worker lifecycle is different. The onUpdate handler defined above
// will never be called because the service worker is in waiting state even before 
// this code is run.
// That is why we manualy check if there are any waiting sw and if so, updates the
// app.
// It does so using a backoff strategy.
if (NavUtils.isIOS()) {

  window.addEventListener('focus', () => {
    checkForUpdates();
  });

  const skipWaitingSw = async () => {
    const waiting = await VersionUtils.getWaitingServiceWorkers();
    if (waiting.length > 0) {
      await VersionUtils.forceSWUpdate();
      window.location.reload(true);
    } else {
      throw new Error('There are no waiting service workers');
    }
  }

  backoff(5, skipWaitingSw);
}
