import * as React from 'react';
import { Route } from 'react-router-dom';
import { Component } from 'react';
import { History } from 'history';

import { LoginContainer } from '../components/login/LoginContainer';
import { firebaseService } from '../utils/firebase/FirestoreConfig';
import { Unsubscribe } from '../domain/SubsciptionTypes';
import { AuthState } from '../utils/components/auth/Reducers';
import { getAuthState, State } from '../reducer';
import { AuthActions } from '../utils/components/auth/Actions';
import { connect } from 'react-redux';
import { Logout } from '../components/login/LogoutContainer';
import { renderSecure } from '../utils/components/auth/PrivateRoute';
import { domainApi } from '../domain/DomainApi';
import { PrivateRouter } from './PrivateRouter';
import { CommonActions } from '../utils/actions/Actions';
import { UnsupportedOSContainer } from '../components/unsupported/UnsupportedContainer';
import ReactNativeBridge from '../utils/ReactNativeBridge';
import Snackbar from '@material-ui/core/Snackbar';
import { ActionProps } from '../utils/Actions';
import { ConnectedRouter } from 'connected-react-router'
import { logger } from '../utils/LoggingUtils';
import { SimpleDialog } from '../utils/components/dialogs/SimpleDialog';
import { Typography, CircularProgress } from '@material-ui/core';
import { I18nProps } from '../i18n';

const log = logger('Router');

type RouterContainerProps = ActionProps<typeof AuthActions & typeof CommonActions>
  & AuthState & { history: History } & I18nProps;

interface RouterLocalState {
  exitPrompt: 'NONE' | 'WAITING_SYNC' | 'CONFIRM_EXIT';
}

// Extract auth stuff from router container: https://trello.com/c/Q0iwevXX/260-extract-auth-stuff-from-router-container
class RouterContainer extends Component<RouterContainerProps> {
  state: RouterLocalState;
  authUnsubscribe: Unsubscribe = () => { };
  userDetailsUnsubscribe: Unsubscribe = () => { };
  recipesListUnsubscribe: Unsubscribe = () => { };

  constructor(props: RouterContainerProps) {
    super(props);
    this.state = { exitPrompt: 'NONE' };
  }

  componentDidMount() {
    this.props.onAuthenticating();
    this.authUnsubscribe = firebaseService.firebaseAuth.onAuthStateChanged(this.props.onAuthChanged);
    this.subscribeToUserDetailsIfNecessary('', this.props.loggedInUser);
    this.subscribeToRecipesListIfNeccessary('', this.props.selectedList);
    this.subscribeToMobileAppMessages();
  }

  componentDidUpdate(prevProps: Readonly<RouterContainerProps>) {
    this.subscribeToUserDetailsIfNecessary(prevProps.loggedInUser, this.props.loggedInUser);
    this.subscribeToRecipesListIfNeccessary(prevProps.selectedList, this.props.selectedList);
  }

  componentWillUnmount() {
    this.authUnsubscribe();
    this.userDetailsUnsubscribe();
    if (this.recipesListUnsubscribe) {
      this.recipesListUnsubscribe();
    }
  }

  renderLogin = () => {
    const i18n = this.props as I18nProps;
    return (<LoginContainer {...i18n}/>);
  };

  renderLogout = () => <Logout />;

  renderOffline = () => <UnsupportedOSContainer />;

  exitApp = () => {
    this.doExitApp();
    this.setState({ confirmExitOpen: false });
  };

  cancelExitApp = () => {
    this.setState({ confirmExitOpen: false });
  };

  render() {
    const authState = this.props as AuthState;
    const i18n = this.props as I18nProps;
    const t = this.props.t;

    // TODO-AC: Check what is the performance consideration for render method.
    return (
      <ConnectedRouter history={this.props.history}>
        <div style={{ height: '100%' }}>
          <Route path="/public/login" exact={true} render={this.renderLogin} />
          <Route path="/public/logout" exact={true} render={this.renderLogout} />
          <Route path="/public/offline" exact={true} render={this.renderOffline} />
          <Route path="/" render={renderSecure(authState, i18n, PrivateRouter)} />
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={this.state.exitPrompt === 'CONFIRM_EXIT'}
            onClose={this.cancelExitApp}
            ContentProps={{
              'aria-describedby': 'message-id',
            }}
            message={<span id="message-id">{t('Press back again to exit Trolley')}</span>}
          />
          <SimpleDialog
            open={this.state.exitPrompt === 'WAITING_SYNC'}
            title={(<><CircularProgress size={24}/>&nbsp; {t('Syncing with server') + '...'}</>)}
            content={(
              <>
                <Typography variant='body1'><br/><hr/></Typography>
                <Typography variant='caption'>({t('Press back to exit and sync later')})<br/></Typography>
              </>
          )}
          />
        </div>
      </ConnectedRouter>
    );
  }

  processEvent = (event: { type: string }) => {
    if (event.type === 'BACK_BTN_PRESSED') {
      log.debug('Back button pressed on mobile...');
      const path = this.props.history.location.pathname;
      if (path === '/public/login') {
        this.doExitApp();
        return;
      }
      if (path === '/lists' || path === '/recipes') {
        this.promptExitApp();
        return;
      }
      // Is in recipe details but not adding.
      if (path.indexOf('/recipe-details') >= 0 && path.indexOf('/add-recipe-item') < 0) {
        this.props.history.push('/recipes');
        return;
      }

      this.props.history.goBack();
    }
  };

  subscribeToMobileAppMessages = () => {
    document.addEventListener(
      'message',
      event => {
        const myEvent = event as MessageEvent;
        const parsedEvent = JSON.parse(myEvent.data);
        this.processEvent(parsedEvent as {type: string});
      },
      false
    );
  };

  private promptExitApp() {
    if (this.state.exitPrompt === 'CONFIRM_EXIT') {
      log.debug('Exiting... state: ' + this.state.exitPrompt);
      firebaseService.waitForPendingOperations((allCompleted) => {
        log.debug('Pending operations: ' + allCompleted);
        if (allCompleted) {
          this.doExitApp();
        } else {
          this.setState({ exitPrompt: 'WAITING_SYNC' });
        }
      });
      return;
    }

    if (this.state.exitPrompt === 'WAITING_SYNC') {
      this.doExitApp();
    }

    this.setState({ exitPrompt: 'CONFIRM_EXIT' });
    setTimeout(() => {
      if (this.state.exitPrompt === 'CONFIRM_EXIT') {
        this.setState({ exitPrompt: 'NONE' });
      }
    }, 2000);
  }

  private doExitApp() {
    log.debug('Exiting trolley');
    this.setState({ exitPrompt: 'NONE' });
    ReactNativeBridge.post(JSON.stringify({ type: 'EXIT_APP' }));
  }

  private subscribeToRecipesListIfNeccessary(prevSelectedList: string, currentSelectedList: string) {
    if (currentSelectedList !== prevSelectedList) {
      log.debug('User list changed, subscribing to new recipe list');
      if (this.recipesListUnsubscribe) {
        this.recipesListUnsubscribe();
      }

      this.recipesListUnsubscribe = domainApi.subscribeToRecipes(currentSelectedList, this.props.onRecipesListUpdated);
    }
  }

  private subscribeToUserDetailsIfNecessary(prevUserId: string, currentUserId: string) {
    if (!!currentUserId && currentUserId !== prevUserId) {
      log.debug('User authenticated... subscribing to user details collection...');
      if (this.userDetailsUnsubscribe) {
        this.userDetailsUnsubscribe();
      }

      this.userDetailsUnsubscribe = domainApi.subscribeToUserDetails(currentUserId, this.props.onUserDetailsChanged);
    }
  }
}

const mapDispatchToProps = {
  ...AuthActions,
  ...CommonActions
};

const mapStateToProps = (state: State) => {
  return getAuthState(state);
};

export const Router = connect(mapStateToProps, mapDispatchToProps)(RouterContainer);
