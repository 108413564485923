import * as React from 'react';
import { StyleRules, withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import { LinearProgress, MenuItem, Theme, WithStyles, ListItemText } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import ChevronIcon from '@material-ui/icons/ChevronLeft';
import { Virtuoso, TListContainer, TItemContainer } from 'react-virtuoso';


import { RecentItemsContainerProps } from './Container';
import AppBar from '@material-ui/core/AppBar';
import moment, { Duration } from 'moment';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import { VertMenu } from '../../../utils/components/VertMenu';
import { CollapsableItem } from '../../../utils/components/collapsableitem/CollapsableItem';
import TimeUtils from '../../../utils/TimeUtils';
import { TFunction } from 'i18next';

const EmptyImg = require('react-icons/lib/fa/sun-o');

const sortDesc = (sortType: string, t: TFunction) => {
  switch (sortType) {
    case 'SMART':
      return t('Smart sort');
    case 'OLDEST_FIRST':
      return t('Most recent last');
    case 'NEWEST_FIRST':
      return t('Most recent first');
    case 'A-Z':
      return 'A - Z';
    case 'Z-A':
      return 'Z - A';
    case 'FREQUENCY':
      return t('Most frequent first');
    default:
      return t('Smart sort');  
  }
}

const style: (theme: Theme) => StyleRules = theme => ({
  list: {
    width: '100%'
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  drawerPaper: {
    width: '100%'
  },
  emptyState: {
    height: '100%',
    textAlign: 'center',
    paddingTop: '70px'
  }
});

export type RecentItemsProps = RecentItemsContainerProps & WithStyles;

const ListContainer: TListContainer =
  (fc) => {
    return (
      <List ref={fc.listRef} style={fc.style}>
        {fc.children}
      </List>
    );
  };

const ItemContainer: TItemContainer = (ic) => {
  // remove the margins from the list item - 
  // they are not measured correctly by the component
  const { children, ...props } = ic;
  return (
    <div {...props} style={{ margin: 0 }}>
      {children}
    </div>
  );
};

const RecentItemsDrawerStyled = (props: RecentItemsProps) => {
  const { t, classes, open, closeClicked } = props;

  const addItem = (itemId: string) => {
    return () => props.addItemFromHistory(props.selectedList, itemId);
  };

  const items = props.recentItemsList.items;

  const item = (index: number) => {
    const item = props.recentItemsList.items[index];
    let secondaryText = '';
    if ((props.recentItemsList.sortType === 'FREQUENCY' || props.recentItemsList.sortType === 'SMART')) {
      const buyPeriod: Duration | undefined = item.frequency;

      if (!buyPeriod) {
        secondaryText = `${t('Hasn\'t been bought recently')}.`;

      } else {
        secondaryText = `${t('Bought every')} ${TimeUtils.humanizeDuration(buyPeriod, t)}.`;

      }
    } else {
      const timeAgo = moment.duration(moment().diff(item.lastCompletedDate));
      secondaryText = `${t('Bought')} ${TimeUtils.humanizeDuration(timeAgo, t)}${t(' ago')}.`;
    }
    return (
      <CollapsableItem
        onCollapsed={addItem(item.id)}
        key={item.id}>
        <ListItemText primary={item.itemName} secondary={secondaryText} />
      </CollapsableItem>
    );
  }

  let component;
  if (props.loading) {
    component = <LinearProgress />;
  } else if (props.recentItemsList.items && props.recentItemsList.items.length > 0) {
    component = (
      <Virtuoso
        ListContainer={ListContainer}
        ItemContainer={ItemContainer}
        style={{ width: '100%', height: '100%' }}
        totalCount={items.length}
        item={(index) => item(index)}
      />
    );
  } else {
    component = (
      <div className={classes.emptyState}>
        <EmptyImg size={200} color="rgba(97, 97, 97, 0.5)" />
        <Typography variant="h5" style={{ paddingTop: '30px' }}>
          {t('Yay, no recent items')}!
        </Typography>
        <Typography variant="subtitle1">{t('Buy some items from your list to see them here')}.</Typography>
      </div>
    );
  }

  return (
    <div>
      <Drawer
        anchor="right"
        open={open}
        onClose={closeClicked}
        classes={{
          paper: classes.drawerPaper
        }}>
        <AppBar position="sticky">
          <Toolbar>
            <div className={classes.drawerHeader} onClick={closeClicked}>
              <ChevronIcon />
              {t('Recent items')}
            </div>
            <Typography variant="subtitle1" color="inherit" style={{ flex: 1 }} />
            <Typography variant="subtitle2" color="inherit" >
                {sortDesc(props.recentItemsList.sortType, t)}
            </Typography>
            <VertMenu>
              <MenuItem
                selected={!props.recentItemsList.sortType || props.recentItemsList.sortType === 'SMART'}
                // tslint:disable-next-line jsx-no-lambda
                onClick={() => props.sortRecentItems('SMART', props.selectedList)}>
                {sortDesc('SMART', t)}
              </MenuItem>
              <MenuItem
                selected={props.recentItemsList.sortType === 'OLDEST_FIRST'}
                // tslint:disable-next-line jsx-no-lambda
                onClick={() => props.sortRecentItems('OLDEST_FIRST', props.selectedList)}>
                {sortDesc('OLDEST_FIRST', t)}
              </MenuItem>
              <MenuItem
                selected={props.recentItemsList.sortType === 'NEWEST_FIRST'}
                // tslint:disable-next-line jsx-no-lambda
                onClick={() => props.sortRecentItems('NEWEST_FIRST', props.selectedList)}>
                {sortDesc('NEWEST_FIRST', t)}
              </MenuItem>
              <MenuItem
                selected={props.recentItemsList.sortType === 'A-Z'}
                // tslint:disable-next-line jsx-no-lambda
                onClick={() => props.sortRecentItems('A-Z', props.selectedList)}>
                {sortDesc('A-Z', t)}
              </MenuItem>
              <MenuItem
                selected={props.recentItemsList.sortType === 'Z-A'}
                // tslint:disable-next-line jsx-no-lambda
                onClick={() => props.sortRecentItems('Z-A', props.selectedList)}>
                {sortDesc('Z-A', t)}
              </MenuItem>
              <MenuItem
                selected={props.recentItemsList.sortType === 'FREQUENCY'}
                // tslint:disable-next-line jsx-no-lambda
                onClick={() => props.sortRecentItems('FREQUENCY', props.selectedList)}>
                {sortDesc('FREQUENCY', t)}
              </MenuItem>
            </VertMenu>
          </Toolbar>
        </AppBar>
        <Divider />
        <div tabIndex={0} role="button" style={{ height: "100%" }}>
          {component}
        </div>
      </Drawer>
    </div>
  );
};

export default withStyles(style)(RecentItemsDrawerStyled);
