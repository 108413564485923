import { domainApi } from '../domain/DomainApi';
import { logger } from './LoggingUtils';

const log = logger('VersionUtils');

const getClientVersion: () => string = () => process.env.REACT_APP_VERSION || 'Dev environment';
const getServerVersion: () => Promise<string> = domainApi.getServerVersion;

const checkForUpdates: () => Promise<string | undefined> = () => {
  const promise = new Promise<string | undefined>(resolve => {
    log.debug(`Checking version...`);
    getServerVersion().then(serverVersion => {
      if (getClientVersion() !== serverVersion) {
        log.debug(`client version ${getClientVersion()}`);
        log.debug(`Server version ${serverVersion}`);
        resolve(serverVersion);
        return;
      }
      resolve(undefined);
    });
  });

  return promise;
};

const getWaitingServiceWorkers: () => Promise<ServiceWorker[]> = async () => {
  console.log('Finding waiting service workers...');

  const promise: Promise<ServiceWorker[]> = new Promise(async (resolve) => {

    if ('serviceWorker' in navigator) {
      const registrations = await navigator.serviceWorker.getRegistrations();
      console.log(`Found ${registrations.length} registrations`);
      
      const waitingSW: ServiceWorker[] = registrations
        .map(registration => registration.waiting)
        .filter(waiting => waiting)
        .map(sw => sw as ServiceWorker);
      
      resolve(waitingSW);
    } else {
      console.log('There is no waiting service worker...');
      resolve([]);
    }

  });

  return promise;
}

const skipWaitingSW: (sw: ServiceWorker) => Promise<void> = (sw) => {

  const promise: Promise<void> = new Promise(async resolve => {
    sw.addEventListener("statechange", event => {
      log.debug('State change event received...');
      const state = (event.target as ServiceWorker).state;
      if (state === "activated") {
        log.debug('Waiting service worker has been activated');
        resolve();
      } else {
        log.debug(`Waiting service worker not active ${state}`);
      }
    });

    log.debug('Posting skip waiting to service worker');
    sw.postMessage({ type: "SKIP_WAITING" });
  });

  return promise;
}

const forceSWUpdate: () => Promise<void[]> = async () => {
  const waitingSw: ServiceWorker[] = await getWaitingServiceWorkers();
  const activatedPromises: Promise<void>[] = waitingSw.map(skipWaitingSW);
  return Promise.all(activatedPromises);
}

export default {
  getClientVersion,
  checkForUpdates,
  forceSWUpdate,
  getWaitingServiceWorkers
};
