import { ListItem } from './Types';
import { Action } from 'redux';
import {
  DragEndedAction,
  DragStartedAction,
  ListItemChangedAction,
  ShoppingListChangedAction,
  ToggleExpandCategory
} from './Actions';
import { OnRecipesListUpdatedAction, SelectUserListAction } from '../../../utils/actions/Actions';
import { ListCategoriesUpdatedAction } from '../ShoppingListsPageActions';
import {
  autoCompleteReducer,
  AutoCompleteState,
  emptyAutoCompleteState
} from '../../../utils/components/autocomplete/Reducers';
import { UpdateRecipeFilterAction } from '../recipesfilterdrawer/Actions';
import { Recipe } from '../../../domain/recipes/Types';
import { containsAny } from '../../../utils/ListUtils';
import { removeRecipeFiltersNotInRecipeList, updateRecipeFilters } from '../ShoppingListsUtils';

export type ShoppingListState = Readonly<{
  selectedListId: string;
  shoppingList: {
    id: string;
    name: string;
    showCategories: boolean;
  };
  itemsByCategory: ItemsByCategory;
  collapsedCategories: string[];
  allItems: ListItem[];
  filteredItems: ListItem[];
  selectedItem?: string;
  draggingItemId?: string;
  listCategories: string[];
  categoriesAutoComplete: AutoCompleteState;
  recipeFilters: Recipe[];
}>;

export const getCategoriesAutoCompleteState = (state: ShoppingListState) => state.categoriesAutoComplete;

interface ItemsByCategory {
  [key: string]: ListItem[];
}

const emptyShoppingList = { id: '', name: '', showCategories: true };

const emptyShoppingListState = {
  selectedListId: '',
  shoppingList: emptyShoppingList,
  itemsByCategory: {},
  collapsedCategories: [],
  allItems: [],
  filteredItems: [],
  listCategories: [],
  categoriesAutoComplete: emptyAutoCompleteState,
  recipeFilters: []
};

const applyRecipeFilter = (list: ListItem[], filters: Recipe[]) => {
  if (!filters || filters.length === 0) {
    return list;
  }
  return list.filter(listItem => {
    const itemRecipes = listItem.tags.Recipe || [];
    return containsAny(itemRecipes, filters.map(filter => filter.name));
  });
};

const calculateListItemFields = (items: ListItem[], byCat: ItemsByCategory, filters: Recipe[]) => {
  const allItems = items.map(addDefaultValues);
  const filteredItems = applyRecipeFilter(allItems, filters);
  const itemsByCategory = groupByCategory(filteredItems, byCat);

  return {
    allItems,
    filteredItems,
    itemsByCategory
  };
};

// PrevItems is required because of drag and drop positioning. It should be removed once we
// are able to dnd to the category header.
const groupByCategory: (items: ListItem[], prevItemsByCategory: ItemsByCategory) => ItemsByCategory = (
  items,
  prevItemsByCat
) => {
  const accumulator: ItemsByCategory = {}
  const newItemsByCat: ItemsByCategory = items.reduce((acc, item) => {
    const unfilteredCat = (item.tags && item.tags.Category) || [];
    let categories = unfilteredCat.filter(cat => cat !== undefined && cat.trim() !== '');

    categories = categories.length === 0 ? ['Not set'] : categories;

    categories.forEach(cat => {
      if (!acc[cat]) {
        acc[cat] = [];
      }

      acc[cat].push(item);
    });

    return acc;
  }, accumulator);

  // keep the previous sorting
  Object.keys(newItemsByCat).forEach(cat => {
    // New items not existing in prev, will be last.
    const newCatItem = newItemsByCat[cat];
    const prevItems = prevItemsByCat[cat] || [];
    type ItemsByIdx = { [key: number]: ListItem }
    const itemsAccumulator: ItemsByIdx = {};
    let lastIdx = 10000;
    const sortedItemsObj: ItemsByIdx = newCatItem.reduce((acc, newItem) => {
      const idx = prevItems.findIndex(prev => prev.id === newItem.id);
      if (idx < 0) {
        acc[lastIdx] = newItem;
        lastIdx += 1;
      } else {
        acc[idx] = newItem;
      }
      return acc;
    }, itemsAccumulator);

    const compareNumber = (a: string, b: string) => +a - +b;

    newItemsByCat[cat] = Object.keys(sortedItemsObj)
      .sort(compareNumber)
      .map((key:string) => sortedItemsObj[+key]);
  });

  return newItemsByCat;
};

const calculateNewCollapsed = (collapsedCats: string[], cat: string, expand: boolean) => {
  const newCollapsed = collapsedCats ? collapsedCats.filter(collapsed => cat !== collapsed) : [];

  if (!expand) {
    newCollapsed.push(cat);
  }
  return newCollapsed;
};
const toggleExpand = (state: ShoppingListState, cat: string, expand: boolean) => {
  const newCollapsed = calculateNewCollapsed(state.collapsedCategories, cat, expand);

  return {
    ...state,
    collapsedCategories: newCollapsed
  };
};

const addDefaultValues = (item: ListItem) => {
  return {
    ...item,
    tags: {
      Category: item && item.tags && item.tags.Category ? item.tags.Category : [],
      Recipe: item && item.tags && item.tags.Recipe ? item.tags.Recipe : []
    }
  };
};

export const shoppingListReducer: (state: ShoppingListState | undefined, action: Action) => ShoppingListState = (
  state = emptyShoppingListState,
  action
) => {
  switch (action.type) {
    case 'ShoppingListChangedAction':
      const fetchAction = action as ShoppingListChangedAction;
      return {
        ...state,
        shoppingList: {
          id: fetchAction.payload.id,
          name: fetchAction.payload.name,
          showCategories: fetchAction.payload.showCategories
        },
        selectedListId: fetchAction.payload.id
      };

    case 'SelectUserListAction':
      const selectListAction = action as SelectUserListAction;
      if (selectListAction.payload.listId !== state.selectedListId) {
        return {
          ...state,
          selectedListId: selectListAction.payload.listId,
          shoppingList: emptyShoppingList,
          itemsByCategory: {}
        };
      }
      return state;

    case 'DragStartedAction': {
      const dragAction = action as DragStartedAction;
      return {
        ...state,
        draggingItemId: dragAction.payload
      };
    }

    case 'DragEndedAction': {
      const dragAction = action as DragEndedAction;
      const itemsByCategory = state.itemsByCategory;
      const payload = dragAction.payload;
      const prevItems = itemsByCategory[payload.prevCat];
      const prevIdx = prevItems.findIndex(itm => itm.id === payload.itemId);

      if (prevIdx < 0) {
        // This shouldn't happen.
        return {
          ...state,
          draggingItemId: undefined
        };
      }

      const removedItems = prevItems.splice(prevIdx, 1);

      itemsByCategory[payload.prevCat] = prevItems;

      // Don't add item to category if already exists.
      if (itemsByCategory[payload.newCat].findIndex(itm => itm.id === payload.itemId) < 0) {
        itemsByCategory[payload.newCat].splice(payload.idx, 0, removedItems[0]);
      }

      return {
        ...state,
        itemsByCategory,
        draggingItemId: undefined
      };
    }

    case 'ListItemChangedAction': {
      const fsItems = action as ListItemChangedAction;
      // When adding new items, check what is new and expand the category of new items.

      const nestedCat = fsItems.payload
        .filter(item => state.allItems.map(oldItem => oldItem.name).indexOf(item.name) < 0)
        .map(newItem => {
          return (newItem.tags && newItem.tags.Category) || [];
        });

      // Flatten cats.
      const newCats: string[] = ([] as string[]).concat(...nestedCat);

      // reduce the state to uncollapse cats
      const expandedState = newCats.reduce((currentState, cat) => toggleExpand(currentState, cat, true), { ...state });

      return {
        ...expandedState,
        ...calculateListItemFields(fsItems.payload, state.itemsByCategory, state.recipeFilters)
      };
    }

    case 'ToggleExpandCategory': {
      const expandCat = action as ToggleExpandCategory;
      const cat = expandCat.payload.cat;
      const expand = expandCat.payload.expand;
      return toggleExpand(state, cat, expand);
    }

    case 'ListCategoriesUpdatedAction': {
      const updateAction = action as ListCategoriesUpdatedAction;
      return {
        ...state,
        listCategories: updateAction.payload.categories,
        ...calculateListItemFields(state.allItems, state.itemsByCategory, state.recipeFilters)
      };
    }

    case 'UpdateItemCategoriesAction': {
      return {
        ...state,
        ...calculateListItemFields(state.allItems, state.itemsByCategory, state.recipeFilters)
      };
    }
    case 'OnRecipesListUpdatedAction': {
      const updateAction = action as OnRecipesListUpdatedAction;
      const filters = removeRecipeFiltersNotInRecipeList(state.recipeFilters, updateAction.payload.recipes);
      return {
        ...state,
        recipeFilters: filters,
        ...calculateListItemFields(state.allItems, state.itemsByCategory, filters)
      };
    }
    case 'UpdateRecipeFilterAction': {
      const filterAction = action as UpdateRecipeFilterAction;
      const filters = updateRecipeFilters(state.recipeFilters, filterAction.payload.recipe, filterAction.payload.add);
      return {
        ...state,
        recipeFilters: filters,
        ...calculateListItemFields(state.allItems, state.itemsByCategory, filters)
      };
    }

    case 'ClearFiltersAction': {
      return {
        ...state,
        recipeFilters: [],
        ...calculateListItemFields(state.allItems, state.itemsByCategory, [])
      };
    }

    default:
      return {
        ...state,
        categoriesAutoComplete: autoCompleteReducer('searchCategory')(state.categoriesAutoComplete, action)
      };
  }
};
