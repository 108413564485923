import { IO, _do } from '../../../utils/fp/io';
import { UserListsRepo } from './UserListsRepositoryIO';
import { UserList } from '../../../components/menudrawer/Types';
import { logger } from '../../../utils/LoggingUtils';
import { UserListCreatedEventIO, ListUserHardDeletedEventIO, UserRemovedFromListEventIO } from '../../DomainEvents';
import { randomId } from '../../../utils/IdUtils';
import { liftFromList } from '../../../utils/fp/ioutils';
import i18n from 'i18next';

const log = logger('PureUserListService');

/** Returns the id of the lists the user belongs to */
const getUserListsId: (userId: string) => IO<string[]> = _do(function* (userId) {
    const userLists: UserList[] = yield UserListsRepo.getListsWhereUserIsShared(userId);
    return userLists.map(userList => userList.id);
});

const createUserList: (listName: string, userId: string, listId?: string) => IO<string>
    = _do(function* (listName, userId, listId = randomId()) {
        log.debug(`NewUserDetailsCreated: Creating list users for id ${listId}`);
        const userList: UserList = {
            id: listId,
            name: listName,
            totalItems: 0,
            sharedWith: [userId]
        };
        yield UserListsRepo.saveUserList(userList);
        log.debug(`List users for id ${listId} created successfuly`);
        yield new UserListCreatedEventIO(userId, listId, listName).emit();
        return listId;
    });

const createDefaultList: (userId: string, listId: string) => IO<void> = _do(function* (userId, listId) {
    const listName = i18n.t('My first list');
    yield createUserList(listName, userId, listId);
});

export type RemoveFromListReason = 'USER_DELETED' | 'REMOVED_FROM_SHARED_LIST' | 'LIST_DELETED'

const removeUserFromList: (userToRemove: string, listId: string, reason: RemoveFromListReason, currentUserId?: string) => IO<void>
    = _do(function* (userToRemove, listId, reason, currentUserId) {
        const userList: UserList | undefined = yield UserListsRepo.getUserList(listId);
        if (!userList) {
            return;
        }

        log.debug(`Searching for user ${userToRemove} on shared list ${listId}`);
        const newSharedWith = _removeElementFromList(userToRemove, userList.sharedWith);
        userList.sharedWith = newSharedWith;

        if (newSharedWith.length === 0) {
            log.debug(`List id ${listId} is not shared with any user. ListUsers collection will be deleted`);
            yield UserListsRepo.deleteUserList(listId);

            if (reason === 'USER_DELETED') {
                log.debug(`This is part of a user delete ${userToRemove}, will propagate list delete event`);
                yield new ListUserHardDeletedEventIO(listId).emit();
            }

        } else {
            yield UserListsRepo.saveUserList(userList);
        }
        log.debug(`Publishing UserRemovedFromListEventIO`);
        yield new UserRemovedFromListEventIO(userList, userToRemove, reason, currentUserId).emit();
    });

const onUserDetailsDeleted: (userId: string) => IO<void> = _do(function* (userId) {
    log.debug(`Deleting user details for ${userId}`);
    const lists: string[] = yield getUserListsId(userId);
    log.debug(`Found ${lists.length} lists shared`);
    const removeActions: IO<void>[] = lists.map(listId => removeUserFromList(userId, listId, 'USER_DELETED'));
    return yield liftFromList(removeActions);
});


/**
 * Functional core ***********
 */

export const _removeElementFromList: (userId: string, sharedWith: string[]) => string[] =
    (userId, sharedWith) => {
        const newSharedWith = [...sharedWith];
        const userIdx = newSharedWith.indexOf(userId);
        if (userIdx >= 0) {
            log.debug(`Removing user sharing list ${userId}`);
            newSharedWith.splice(userIdx, 1);
        } else {
            log.debug(`User ${userId} does not exist on list`);
        }

        return newSharedWith;
    };

export const UserListsServiceIO = {
    getUserListsId,
    createUserList,
    removeUserFromList,
    onUserDetailsDeleted,
    createDefaultList
};
