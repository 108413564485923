import { match, matchPath } from 'react-router-dom';
import * as ReactGA from 'react-ga';
import { logger } from './LoggingUtils';
import { push } from 'connected-react-router';

const log = logger('RouterUtils');

export const getGlobalMatch: (currentPath: string, pathMask: string) => match<{ [key: string]: string }> | null = (
  currentPath,
  pathMask
) => {
  return matchPath(currentPath, {
    path: pathMask,
    exact: false,
    strict: false
  });
};

export const getPathParam = (currentPath: string, pathMask: string, paramName: string) => {
  const globalMatch = getGlobalMatch(currentPath, pathMask);

  return globalMatch ? globalMatch.params[paramName] : undefined;
};

// TODO: Explain what is remove id. Find a better way to do this.
export const navigate = (path: string, removeId: boolean = false) => {
  log.debug(`navigate to: ${path}`);
  const gaPath = removeId ? path.substring(0, path.lastIndexOf('/')) : path;
  ReactGA.pageview(gaPath ? gaPath : '/');
  const action = push(path);
  log.debug(`Action type: ${action.type}`);
  return action;
};

export const formatPath = (basePath: string, optionalPath: string, open: boolean) => {
  return `${basePath}${open ? `/${optionalPath}` : ''}`;
};
