import { IO, _do } from '../../../utils/fp/io';
import { ListItem } from '../../../components/shoppinglists/currentshoppinglist/Types';
import { PureShoppingListRepo } from './PureShoppingListRepository';
import { logger } from '../../../utils/LoggingUtils';

const log = logger('PureShoppingListService');

export type DefaultItem = {
    id: string;
    name: string;
    category: string;
}

export type DefaultItems = {
    items: DefaultItem[];
}

const getShoppingListItems: (listId: string) => IO<ListItem[]> = listId => {
    return PureShoppingListRepo.getListItems(listId);
}

// TODO: Add integration test for this using stubs.
const addDefaultItemsToShoppingList: (listId: string, lang: string) => IO<void>
    = _do(function* (listId, lang) {
        log.debug(`Creating default listItems for list ${listId}`);
        log.debug(`i18n language ${lang}`);
        const fileImported: DefaultItems = yield importDefaultItems(lang);

        log.debug(`File loaded for locale "${lang}". Items imported: ${fileImported.items.length}`);

        const items = fileImported.items.map(item => PureShoppingListRepo.newDefaultItem(item.id, item.name, item.category));
        yield PureShoppingListRepo.saveItems(listId, items);
    });


/**** CORE */
export const importDefaultItems: (lang: string) => IO<DefaultItems> =
    (lang) =>
        lang === 'es'
            ? new IO(effects => effects.imports.importDefaultItemsEs())
            : new IO(effects => effects.imports.importDefaultItemsEn())




export default {
    getShoppingListItems,
    addDefaultItemsToShoppingList
}