import { connect } from 'react-redux';
import { getNotificationsDrawerState, State } from '../../reducer';
import { NotificationsDrawerState } from './Reducers';
import { NotificationsDrawerComponent } from './Component';
import { NotificationsDrawerActions } from './Actions';
import { AuthState } from '../../utils/components/auth/Reducers';
import { ActionProps } from '../../utils/Actions';
import * as React from 'react';

export type NotificationsDrawerContainerProps = 
  ActionProps<typeof NotificationsDrawerActions> &
  NotificationsDrawerState &
  AuthState & {
    open: boolean;
    closeClicked: () => void;
  };

const mapDispatchToProps = {
  ...NotificationsDrawerActions
};

const mapStateToProps = (state: State) => {
  return getNotificationsDrawerState(state);
};

class NotificationsDrawerContainer extends React.Component<NotificationsDrawerContainerProps> {
  render() {
    return <NotificationsDrawerComponent {...this.props} />;
  }
}

export const NotificationsDrawer = connect(mapStateToProps, mapDispatchToProps)(NotificationsDrawerContainer);
