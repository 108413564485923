import * as React from 'react';
import { StyleRules, withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import { ListItemText, Theme, WithStyles } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import ChevronIcon from '@material-ui/icons/ChevronLeft';

import { ItemsForLaterContainerProps } from './Container';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import { CollapsableItem } from '../../../utils/components/collapsableitem/CollapsableItem';

const EmptyImg = require('react-icons/lib/fa/sun-o');

const style: (theme: Theme) => StyleRules = theme => ({
  list: {
    width: '100%'
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  drawerPaper: {
    width: '100%'
  },
  emptyState: {
    height: '100%',
    textAlign: 'center',
    paddingTop: '70px'
  }
});

export type ItemsForLaterProps = ItemsForLaterContainerProps & WithStyles;

const ItemsForLaterDrawerStyled = (props: ItemsForLaterProps) => {
  const { t, classes, open, closeClicked } = props;

  const items = props.listItems.map(item => {
    return (
      <CollapsableItem
        // tslint:disable-next-line jsx-no-lambda
        onCollapsed={() => props.addToList(props.selectedList, item.id)}
        key={item.id}>
        <ListItemText primary={item.name} />
      </CollapsableItem>
    );
  });

  let component;
  if (props.listItems && props.listItems.length > 0) {
    component = (
      <div className={classes.list}>
        <List>{items}</List>
      </div>
    );
  } else {
    component = (
      <div className={classes.emptyState}>
        <EmptyImg size={200} color="rgba(97, 97, 97, 0.5)" />
        <Typography variant="h5" style={{ paddingTop: '30px' }}>
          {t(`You don't have items for later`)}...
        </Typography>
        <Typography variant="subtitle1">
          {t('Swipe left some items in your list to see them here')}.
        </Typography>
      </div>
    );
  }

  return (
    <div>
      <Drawer
        anchor="right"
        open={open}
        onClose={closeClicked}
        classes={{
          paper: classes.drawerPaper
        }}>
        <AppBar position="sticky">
          <Toolbar>
            <div className={classes.drawerHeader} onClick={closeClicked}>
              <ChevronIcon />
              {t('Items for later')}
            </div>
            <Typography variant="subtitle1" color="inherit" style={{ flex: 1 }} />
          </Toolbar>
        </AppBar>
        <Divider />
        <div tabIndex={0} role="button">
          {component}
        </div>
      </Drawer>
    </div>
  );
};

export default withStyles(style)(ItemsForLaterDrawerStyled);
