import * as firebase from 'firebase/app';
import { logger } from '../../utils/LoggingUtils';
import { ListItem } from '../../components/shoppinglists/currentshoppinglist/Types';
import { nameIdx, randomId } from '../../utils/IdUtils';
import { getListItemFromFsData, queryToListItems, safeItem } from './fp/PureShoppingListRepository';

type DocumentData = firebase.firestore.DocumentData;
type Firestore = firebase.firestore.Firestore;


const log = logger('ShoppingListRepository');

const listDB = (listId: string, firestore: Firestore) => {
  return firestore.collection('ShoppingLists').doc(listId);
}

const itemsDB = (listId: string, firestore: Firestore) => {
  return listDB(listId, firestore).collection('items');
};

const itemDB = (listId: string, itemId: string, firestore: firebase.firestore.Firestore) => {
  return itemsDB(listId, firestore).doc(itemId);
};

const findItemByName = (listId: string, name: string, firestore: firebase.firestore.Firestore) => {
  log.debug(`SL: Searching for item ${name} on list ${listId}`);
  return itemsDB(listId, firestore)
    .where('name_idx', '==', nameIdx(name))
    .get({ source: 'cache' })
    .then(snapShot => {
      if (snapShot.empty) {
        log.debug(`SL: item not found: ${name}`);
        return undefined;
      }

      if (snapShot.size > 1) {
        log.warn(`SL: Found more than one item for list ${listId} with name ${name}`);
      }

      log.debug(`SL: Item ${name} found...`);
      return getListItemFromFsData(snapShot.docs[0].data());
    });
};

const deleteShoppingList: (listId: string, firestore: Firestore) => Promise<void> = async (listId, firestore) => {
  await listDB(listId, firestore).delete();
  log.debug(`Shoppinglist ${listId} has been deleted`);
  return;
}

const findItemById: (listId: string, itemId: string, firestore: firebase.firestore.Firestore) => Promise<ListItem | undefined> = (
  listId,
  itemId,
  firestore
) => {
  log.debug('Fetching shopping list item from cache');
  return itemsDB(listId, firestore)
    .doc(itemId)
    .get({ source: 'cache' })
    .then(snapshot => {
      return snapshot.exists ? snapshot.data() : undefined;
    })
    .catch(() => {
      log.debug('Not found on cache, returning empty');
      return undefined;
    })
    .then(data => {
      if (data) {
        return getListItemFromFsData(data);
      }
      return undefined;
    });
};

const findItemsByRecipeTag: (listId: string, recipeTag: string, firestore: firebase.firestore.Firestore) => Promise<ListItem[]> = (
  listId,
  recipeTag,
  firestore
) => {
  return itemsDB(listId, firestore)
    .get()
    .then(queryToListItems)
    .then(items => items.filter(item => item.tags.Recipe.indexOf(recipeTag) >= 0));
};

const saveItem: (listId: string, item: ListItem, db: firebase.firestore.Firestore) => void = (listId, item, db) => {
  log.debug(`Saving item ${item.name_idx} to list ${listId}`);
  itemDB(listId, item.id, db).set(safeItem(item), { merge: true });
  log.debug(`Item ${item.name_idx} saved`);
};

const newItem: (name: string, defaults: {id?: string; recipe?: string}) => ListItem = (name, defaults = {}) => {
  const id = defaults.id ? defaults.id : randomId();
  const tags = {
    Category: [],
    Recipe: defaults.recipe ? [defaults.recipe] : []
  };

  return {
    id: id,
    name: name,
    name_idx: nameIdx(name),
    addedOn: new Date(),
    hideUntil: undefined,
    tags
  };
};

export const ShoppingListRepo = {
  findItemByName,
  itemsDB,
  itemDB,
  queryToListItems,
  findItemById,
  findItemsByRecipeTag,
  saveItem,
  newItem,
  deleteShoppingList,
};
