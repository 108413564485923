import { default as Collapse, CollapseProps } from '@material-ui/core/Collapse';
import * as React from 'react';
import ListItem from '@material-ui/core/ListItem';
import { StyleRules } from '@material-ui/core/styles';
import { Theme, WithStyles } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';

const style: (theme: Theme) => StyleRules = theme => ({
  mainContainer: {
    '& li': {
      display: 'block'
    }
  }
});

interface CollapsableItemProps extends CollapseProps {
  onCollapsed: () => void;
  manualCollapse?: boolean;
}

interface CollapsableItemState {
  visible: boolean;
}

class CollapsableItemStyled extends React.Component<CollapsableItemProps & WithStyles, CollapsableItemState> {
  state = {
    visible: true
  };

  onClicked = () => {
    if (this.props.manualCollapse) {
      return;
    }
    this.setState({ visible: false });
  };

  render() {
    return (
      <Collapse
        in={this.state.visible}
        timeout={200}
        unmountOnExit={false}
        onExited={this.props.onCollapsed}
        className={this.props.classes.mainContainer}>
        <ListItem button={true} divider={true} onClick={this.onClicked}>
          {this.props.children}
        </ListItem>
      </Collapse>
    );
  }
}

export const CollapsableItem = withStyles(style)(CollapsableItemStyled);
