import { firebaseService } from '../../utils/firebase/FirestoreConfig';
import { eventBus } from '../../utils/eventbus/EventBus';
import DomainEvents, {
  NewUserCreatedEvent,
  ShoppingListSharedEvent,
  UserDetailsUpdatedEventIO,
  UserDetailsDeletedEvent,
  UserRemovedFromListEventIO
} from '../DomainEvents';
import { randomId } from '../../utils/IdUtils';
import { UserNotification, UserNotifications } from './Types';
import { SubscriptionCallback, Unsubscribe } from '../SubsciptionTypes';
import * as firebase from 'firebase/app';
import UserListsService from '../userlists/UserListsService';
import { logger } from '../../utils/LoggingUtils';
import { IO } from '../../utils/fp/io';
import { effectsImpl } from '../../utils/fp/effects/EffectsImpl';

const log = logger('UserNotificationService');

const initialize = () => {
  eventBus.on(DomainEvents.NewUserDetailsCreated, (event: NewUserCreatedEvent) => {
    updateUserInfo(event.userId, event.user.name, event.user.email);
  });

  eventBus.on(DomainEvents.ShoppingListShared, (props: ShoppingListSharedEvent) => {
    createListSharedNotification(props);
  });

  UserDetailsUpdatedEventIO.onEvent(event =>
    new IO(() => {
      log.debug(`User ${event.userDetails.userId} is being deleted, won't create notification`);
      updateUserInfo(event.userDetails.userId, event.userDetails.name, event.userDetails.email);
      return Promise.resolve();
    })).eval(effectsImpl);

  UserRemovedFromListEventIO.onEvent(event => {
    switch (event.reason) {
      case 'USER_DELETED':
        return IO.void();
      case 'LIST_DELETED':
        return new IO(() => userLeftTheListNotification(event.userList.id, event.userList.name, event.removedUserId));
      case 'REMOVED_FROM_SHARED_LIST':
        let removingUser = event.removingUserId;
        if (!!removingUser) {
          return new IO(() => userRemovedFromListNotification(event.userList.name, event.removedUserId, removingUser!!));
        } else {
          return IO.void();
        }

    }
  }
  ).eval(effectsImpl);

  UserDetailsDeletedEvent.onEvent(async (event) => {
    hardDeleteNotificaitonsForUser(event.userId);
  });

  log.debug('UserNotificationsService initialized.');
};

const hardDeleteNotificaitonsForUser: (userId: string) => Promise<void> = async (userId) => {
  const firestore = await firebaseService.firestore;
  log.debug(`Deleting notifications for user ${userId}`);
  // TODO: we have to delete the subcollection.
  // Use a cloud function for this.
  await firestore.collection('UserNotifications').doc(userId).delete();
  log.debug(`Notifications for user ${userId} have been deleted`);
  return;
};

const dataToUserNotifications: (data: firebase.firestore.DocumentData) => UserNotifications = data => {
  return {
    userId: data.userId,
    name: data.name,
    email: data.email
  };
};

const dataToUserNotification: (data: firebase.firestore.DocumentData) => UserNotification = data => {
  return {
    id: data.id,
    text: data.text,
    createdById: data.createdById,
    userId: data.userId,
    createdOn: data.createdOn.toDate(),
    type: data.type,
    viewedOn: data.viewedOn ? data.viewedOn.toDate() : undefined,
    viewed: data.viewed
  };
};

const parseQuerySnapshot: (snapshot: firebase.firestore.QuerySnapshot) => UserNotification[] | undefined = snapshot => {
  return snapshot.docs
    .map(doc => doc.data())
    .filter(data => data != null)
    .map(dataToUserNotification);
};

const getUserNotificationsRef = function (firestore: firebase.firestore.Firestore, userId: string) {
  return firestore
    .collection('UserNotifications')
    .doc(userId)
    .collection('Notifications');
};

const getUnreadRef = (firestore: firebase.firestore.Firestore, userId: string) => {
  return getUserNotificationsRef(firestore, userId).where('viewed', '==', false);
};

const updateUserInfo = (userId: string, userName: string | null, userEmail: string | null) => {
  firebaseService.firestore.then(firestore => {
    firestore
      .collection('UserNotifications')
      .doc(userId)
      .set({
        userId,
        email: userEmail,
        name: userName
      });
  });
};

const subscribeToUserNotifications: (userId: string, cb: SubscriptionCallback<UserNotification[]>) => Unsubscribe = (
  userId,
  cb
) => {
  log.debug(`Subscribing for user ${userId}`);
  return firebaseService.firestoreSubscription(firestore => {
    return getUnreadRef(firestore, userId).onSnapshot(snapShot => {
      const notifications = parseQuerySnapshot(snapShot);
      if (notifications) {
        cb(notifications);
      }
    });
  });
};

const clearNotifications = (userId: string) => {
  firebaseService.firestore.then(firestore => {
    return getUnreadRef(firestore, userId)
      .get()
      .then(snapshot => {
        const notifications = parseQuerySnapshot(snapshot);
        if (notifications) {
          const writeBatch = firestore.batch();
          const userRef = getUserNotificationsRef(firestore, userId);
          notifications.forEach(notification =>
            writeBatch.update(userRef.doc(notification.id), { viewed: true, viewedOn: new Date() })
          );
          writeBatch.commit();
        }
      });
  });
};

const userLeftTheListNotification = (listId: string, listName: string, createdById: string) => {
  return firebaseService.firestore.then(firestore => {
    // Find the name of the user and then issue notificaitons for all users.
    log.debug(`Find user notifications ${createdById}`);
    findUserNotifications(createdById, firestore).then(userNotification => {
      let userName = 'A user';

      if (userNotification) {
        userName = userNotification.name ? userNotification.name
          : userNotification.email ? userNotification.email
            : 'A user';
      }

      log.debug(`Found notifications for user "${userName}"...`);
      UserListsService.getUsersIdFromList(listId, firestore).then(usersId => {
        log.debug(`Creating notifications for ${usersId.length} users...`);
        usersId
          .map(userId => {
            return newNotification(`${userName} left the list ${listName}`, userId, createdById, 'UserLeftList');
          })
          .forEach(notification => saveNotification(notification, firestore));
      });
    });
  });
};

const userRemovedFromListNotification = (listName: string, removedUserId: string, removingUserId: string) => {
  return firebaseService.firestore.then(firestore => {
    // Find the name of the user and then issue notificaitons for all users.
    log.debug(`Find user notifications ${removingUserId}`);
    findUserNotifications(removingUserId, firestore).then(userNotification => {
      let userName = 'A user';

      if (userNotification) {
        userName = userNotification.name ? userNotification.name
          : userNotification.email ? userNotification.email
            : 'A user';
      }

      let notif = newNotification(`You were removed from the list "${listName}" by ${userName}`,
        removedUserId, removingUserId, 'UserLeftList');
      saveNotification(notif, firestore);
    });
  });
};


const findUserNotifications: (userId: string, db: firebase.firestore.Firestore) => Promise<UserNotifications | undefined> = async (
  userId,
  db
) => {
  const snapshot = await db.collection('UserNotifications').doc(userId).get();
  const data = snapshot.exists ? snapshot.data() : undefined;
  if (data) {
    return dataToUserNotifications(data);
  }
  return undefined;
};

const saveNotification = (notification: UserNotification, db: firebase.firestore.Firestore) => {
  log.debug(`Saving notification ${notification.id} for user ${notification.userId} of type ${notification.type}`);
  db
    .collection('UserNotifications')
    .doc(notification.userId)
    .collection('Notifications')
    .doc(notification.id)
    .set(notification);
};

const newNotification = (text: string, userId: string, createdById: string, type: string) => {
  const id = randomId();
  const notification: UserNotification = {
    id,
    text,
    userId,
    createdById,
    createdOn: new Date(),
    type,
    viewed: false
  };
  return notification;
};

const createListSharedNotification = (event: ShoppingListSharedEvent) => {
  firebaseService.firestore.then(firestore => {
    findUserNotifications(event.sharedFromId, firestore).then(data => {
      let fromName = 'A user';
      if (data) {
        fromName = data.name || data.email || 'A user';
      }
      const notification = newNotification(
        `${fromName} has shared the list "${event.shoppingListName}" with you.`,
        event.sharedToId,
        event.sharedFromId,
        'ShoppingListShared'
      );
      saveNotification(notification, firestore);
    });
  });
};

export const UserNotificationsDomainApi = {
  subscribeToUserNotifications,
  clearNotifications
};

export default {
  initialize
};
