import { State } from '../../../reducer';
import { connect } from 'react-redux';
import AddListDrawer from './Component';
import { getAddListDrawerState } from '../ShoppingListsPageReducer';
import { AddListDrawerActions } from './Actions';

const mapDispatchToProps = {
  ...AddListDrawerActions
};

const mapStateToProps = (state: State) => {
  return getAddListDrawerState(state);
};

export default connect(mapStateToProps, mapDispatchToProps)(AddListDrawer);
