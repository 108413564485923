import { Action } from 'redux';
import { RenameListAction } from '../renamelistdrawer/Actions';
import { ShoppingListChangedAction } from '../currentshoppinglist/Actions';
import { UserListsChangedAction } from '../../menudrawer/Actions';
import { UserNotification } from '../../../domain/notifications/Types';
import { NotificationsUpdatedAction } from './Actions';
import { UpdateRecipeFilterAction } from '../recipesfilterdrawer/Actions';
import { Recipe } from '../../../domain/recipes/Types';
import { removeRecipeFiltersNotInRecipeList, updateRecipeFilters } from '../ShoppingListsUtils';
import { OnRecipesListUpdatedAction } from '../../../utils/actions/Actions';

export type TopBarState = Readonly<{
  shoppingListName: string;
  otherShoppingLists: string[];
  showShoppingListCategories: boolean;
  notifications: UserNotification[];
  recipeFilters: Recipe[];
}>;

export const emptyTopBarState = {
  shoppingListName: '',
  otherShoppingLists: [],
  showShoppingListCategories: true,
  notifications: [],
  recipeFilters: []
};

export const topBarReducer: (state: TopBarState | undefined, action: Action) => TopBarState = (
  state = emptyTopBarState,
  action
) => {
  switch (action.type) {
    case 'ShoppingListChangedAction': {
      const fetchAction = action as ShoppingListChangedAction;
      const shoppingList = fetchAction.payload;
      return { ...state, shoppingListName: shoppingList.name, showShoppingListCategories: shoppingList.showCategories };
    }
    case 'UserListsChangedAction': {
      const listChangedAction = action as UserListsChangedAction;
      let lists: string[] = [];

      if (listChangedAction.payload.map) {
        lists = listChangedAction.payload.map(list => list.id);
      }

      return { ...state, otherShoppingLists: lists };
    }
    case 'RenameListAction': {
      const inProgress = action as RenameListAction;
      return { ...state, shoppingListName: inProgress.payload.name };
    }
    case 'NotificationsUpdatedAction': {
      const notificationsAction = action as NotificationsUpdatedAction;
      return { ...state, notifications: notificationsAction.payload };
    }
    case 'ClearFiltersAction': {
      return {
        ...state,
        recipeFilters: []
      };
    }
    case 'OnRecipesListUpdatedAction': {
      const updateAction = action as OnRecipesListUpdatedAction;
      const recipes = updateAction.payload.recipes;
      return {
        ...state,
        recipeFilters: removeRecipeFiltersNotInRecipeList(state.recipeFilters, recipes)
      };
    }
    case 'UpdateRecipeFilterAction': {
      const filterAction = action as UpdateRecipeFilterAction;
      return {
        ...state,
        recipeFilters: updateRecipeFilters(state.recipeFilters, filterAction.payload.recipe, filterAction.payload.add)
      };
    }
    default:
      return state;
  }
};
