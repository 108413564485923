import * as React from 'react';
import { Theme, WithStyles } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';

import ChevronIcon from '@material-ui/icons/ChevronLeft';
import AddIcon from '@material-ui/icons/Send';
import Drawer from '@material-ui/core/Drawer';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import IconButton from '@material-ui/core/IconButton';
import { RenameListDrawerActions } from './Actions';
import { RenameListDrawerState } from './Reducers';
import { AuthState } from '../../../utils/components/auth/Reducers';
import { logger } from '../../../utils/LoggingUtils';
import { ActionProps } from '../../../utils/Actions';
import { AppProps } from '../../../router/PrivateRouter';

const log = logger('RenameListDrawerComponent');

const style: (theme: Theme) => StyleRules = theme => ({
  flex: {
    flex: 1
  },
  drawerHeader: {
    alignItems: 'center',
    padding: '0 8px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    ...theme.mixins.toolbar
  },
  headerIcon: {
    color: theme.palette.primary.contrastText
  },
  drawerContent: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4)
  },
  drawerPaper: {
    width: '100%'
  }
});

type RenameListDrawerProps = ActionProps<typeof RenameListDrawerActions> &
  RenameListDrawerState &
  AuthState &
  WithStyles & { open: boolean; closeClicked: () => void; listName: string } &
  AppProps;

class RenameListDrawerStyled extends React.Component<RenameListDrawerProps> {
  inputRef?: HTMLElement;

  componentDidMount() {
    setTimeout(() => {
      if (this.inputRef) {
        this.inputRef.focus();
      }
    }, 200);
  }

  componentDidUpdate(prevProps: Readonly<RenameListDrawerProps>) {
    if (!prevProps.open && this.props.open) {
      setTimeout(() => {
        if (this.inputRef) {
          this.inputRef.focus();
        }
      }, 500);
      log.debug('Rename opened');
      this.props.onRenameListTextChanged(this.props.listName);
    }
  }

  onRenameClick = () => {
    this.props.renameList(this.props.selectedList, this.props.renameListBoxText);
    this.closeDrawer();
  };

  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.onRenameListTextChanged(e.currentTarget.value);
  };

  submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.props.renameList(this.props.selectedList, this.props.renameListBoxText);
    this.closeDrawer();
  };

  closeDrawer = () => {
    this.props.onRenameListTextChanged('');
    this.props.closeClicked();
  };

  setInputRef = (elem: HTMLElement) => {
    if (elem) {
      this.inputRef = elem;
    }
  };

  onFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    // From: https://stackoverflow.com/questions
    // /36051883/how-to-select-all-text-in-input-with-reactjs-when-it-focused
    event.target.select();
  };

  render() {
    const { t, classes, open } = this.props;

    return (
      <div>
        <Drawer
          anchor="right"
          open={open}
          onClose={this.closeDrawer}
          classes={{
            paper: classes.drawerPaper
          }}>
          <div className={classes.drawerHeader}>
            <Toolbar>
              <IconButton onClick={this.closeDrawer} data-testid="cancel-rename-list">
                <ChevronIcon className={classes.headerIcon} />
              </IconButton>
              <Typography variant="subtitle1" color="inherit" className={classes.flex} />
              <IconButton onClick={this.onRenameClick} data-testid="ok-rename-list">
                <AddIcon style={{ float: 'right' }} className={classes.headerIcon} />
              </IconButton>
            </Toolbar>
          </div>
          <div className={classes.drawerContent}>
            <form onSubmit={this.submitForm}>
              <TextField
                margin="normal"
                id="renameList"
                label={t("New list name")}
                type="text"
                fullWidth={true}
                value={this.props.renameListBoxText}
                onChange={this.onChange}
                inputRef={this.setInputRef}
                onFocus={this.onFocus}
                data-testid="rename-list-tf"
              />
            </form>
          </div>
        </Drawer>
      </div>
    );
  }
}

export const RenameListDrawerComponent = withStyles(style)(RenameListDrawerStyled);
