import { CollectionFetchOptions, DocumentData, FirestoreEffects, FirestoreDocument } from './FirestoreEffects';

interface DocStubType {
    id: string
    doc: DocumentData
}

interface FirestoreStubType {
    [path: string]: DocStubType[];
};

let firestoreStub: FirestoreStubType = {};

const newDocStub: (id: string, doc: any) => DocStubType = (id, doc) => { return { id, doc } }

const saveBatch: <T extends FirestoreDocument>(path: string[], elements: T[]) => Promise<void> =
    async (path, elements) => {
        for (const elm of elements) {
            saveDocument(path, elm.id, elm);
        }
    }

const deleteDocument: (path: string[], documentId: string) => Promise<void> =
    async (path, documentId) => {
        const key: string = path.join('/');
        let curr: any[] = firestoreStub[key];
        if (!curr) {
            curr = [];
        }

        const idx = curr.findIndex(it => it.id === documentId);
        if (idx !== -1) {
            curr.splice(idx, 1);
        }

        firestoreStub[key] = curr;
    }

const fetchCollection: (path: string[], options?: CollectionFetchOptions) => Promise<DocumentData[]>
    = async (path, options) => {
        const key: string = path.join('/');
        const retVal = firestoreStub[key];
        return (retVal || []).map(val => val.doc);
    }

const findDocument: (path: string[], docId: string) => Promise<DocumentData | undefined>
    = async (path, docId) => {
        const key: string = path.join('/');
        const col = firestoreStub[key];
        
        if(!col) {
            return;
        }

        const idx = col.findIndex(it => it.id === docId);
        if (idx < 0) {
            return;
        }
        return col[idx].doc;
    }

const saveDocument: (path: string[], docId: string, document: any) => Promise<void>
    = async (path, docId, document) => {
        addObjectToCollectionStub(path, docId, document);
    }

const updateDocument: <T>(path: string[], documentId: string, val: T) => Promise<void>
    = async (path, documentId, val) => {
        const key: string = path.join('/');
        let curr: DocStubType[] = firestoreStub[key];
        if (!curr) {
            curr = [];
        }

        const idx = curr.findIndex(it => it.id === documentId);
        if (idx !== -1) {
            const currDoc = curr[idx].doc;
            curr[idx] = newDocStub(documentId, {
                ...currDoc,
                ...val
            });
        } else {
            curr.push(newDocStub(documentId, val));
        }

        firestoreStub[key] = curr;
    }
export const addObjectToCollectionStub: (path: string[], docId: string, val: any) => void = (path, docId, val) => {
    const key: string = path.join('/');
    let curr: DocStubType[] = firestoreStub[key];
    if (!curr) {
        curr = [];
    }

    const idx = curr.findIndex(it => it.id === docId);
    const updatedDoc = newDocStub(docId, val);
    if (idx !== -1) {
        curr[idx] = updatedDoc;
    } else {
        curr.push(updatedDoc);
    }

    firestoreStub[key] = curr;
}

export const clearFirestoreStub = () => {
    firestoreStub = {};
}

export const FirestoreEffectsStub: FirestoreEffects = {
    fetchCollection,
    saveBatch,
    saveDocument,
    updateDocument,
    deleteDocument,
    findDocument
}