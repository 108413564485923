import { nameIdx, randomId } from '../../utils/IdUtils';
import { ListItem } from '../../components/shoppinglists/currentshoppinglist/Types';

export interface RecipesList {
  listId: string;
  recipes: Recipe[];
}

export interface Recipe {
  listId: string;
  id: string;
  name: string;
  name_idx: string;
  items: RecipeItem[];
}

export type RecipeItem = {
  id: string;
  name_idx: string;
  itemName: string;
  completedOn?: Date;
  addedOn?: Date;
};

const newRecipe: (listId: string, name: string) => Recipe = (listId, name) => {
  return {
    listId,
    name,
    name_idx: nameIdx(name),
    items: [],
    id: randomId()
  };
};

const newRecipeItem: (itemName: string) => RecipeItem = itemName => {
  return {
    id: randomId(),
    itemName: itemName,
    name_idx: nameIdx(itemName)
  };
};

const newRecipeItemFromListItem: (listItem: ListItem) => RecipeItem = listItem => {
  return {
    id: listItem.id,
    name_idx: listItem.name_idx,
    itemName: listItem.name,
    completedOn: listItem.completedOn,
    addedOn: listItem.addedOn
  };
};

const containsItemName: (recipe: Recipe, itemName: string) => boolean = (recipe, itemName) => {
  return recipe.items.map(item => item.itemName.toLowerCase().trim()).indexOf(itemName.toLowerCase().trim()) >= 0;
};

const addItem: (recipe: Recipe, recipeItem: RecipeItem) => void = (recipe, recipeItem) => {
  recipe.items.unshift(recipeItem);
};

const removeItem: (recipe: Recipe, itemId: string) => void = (recipe, itemId) => {
  if (recipe.items) {
    recipe.items = recipe.items.filter((item) => item.id !== itemId);
  }
}

const updateItemDates: (recipe: Recipe, itemName: string, dates: { addedOn?: Date; completedOn?: Date }) => Recipe = (
  recipe,
  itemName,
  dates
) => {
  recipe.items.forEach(item => {
    if (item.name_idx === nameIdx(itemName)) {
      item.addedOn = dates.addedOn ? dates.addedOn : item.addedOn;
      item.completedOn = dates.completedOn ? dates.completedOn : item.completedOn;
    }
  });

  return recipe;
};

export const Recipes = {
  containsItemName,
  newRecipe,
  newRecipeItem,
  addItem,
  newRecipeItemFromListItem,
  updateItemDates,
  removeItem
};
