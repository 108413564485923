import { Action } from 'redux';
import { Recipe, RecipeItem } from '../../../domain/recipes/Types';
import { NewRecipeTitleTextChanged, RecipeDetailsChanged } from './Actions';
import { Duration } from 'moment';
import moment from 'moment';
import { logger } from '../../../utils/LoggingUtils';

const log = logger('RecipeDetails.reducer')

export type RecipeDetailsState = Readonly<{
  recipe?: RecipeUI;
  newRecipeTitleText: string;
}>;

const emptyState = {
  newRecipeTitleText: '',
  recipe: undefined,
  recipeItems: []
};

export type RecipeUI = {
  id: string;
  name: string;
  items: RecipeItemUI[];
};

export type RecipeItemUI = {
  id: string;
  itemName: string;
  inList: boolean;
  boughtAgo?: Duration;
};

const toRecipeUI: (recipe: Recipe) => RecipeUI = recipe => {
  return {
    ...recipe,
    items: recipe.items.map(toRecipeItemUI)
  };
};

const toRecipeItemUI = (item: RecipeItem) => {
  const itemAddedAndNotCompleted = !!item.addedOn && !item.completedOn;
  const itemAddedAfterCompleted =
    !!item.addedOn && !!item.completedOn && moment(item.addedOn).isAfter(item.completedOn);

  const itemCompletedAndNotAdded = !!item.completedOn && !item.addedOn;
  const itemCompletedAfterAdded = !!item.completedOn && moment(item.completedOn).isAfter(item.addedOn);

  return {
    id: item.id,
    itemName: item.itemName,
    inList: itemAddedAndNotCompleted || itemAddedAfterCompleted,
    boughtAgo:
      itemCompletedAfterAdded || itemCompletedAndNotAdded
        ? moment.duration(moment(new Date()).diff(item.completedOn))
        : undefined
  };
};

export const recipeDetailsReducer: (state: RecipeDetailsState | undefined, action: Action) => RecipeDetailsState = (
  state = emptyState,
  action
) => {
  switch (action.type) {
    case 'RecipeDetailsChanged': {
      const recipeDetails = action as RecipeDetailsChanged;
      log.debug(`Recipe details received. Items: ${recipeDetails.payload.items && recipeDetails.payload.items.length}`);
      return { ...state, recipe: toRecipeUI(recipeDetails.payload) };
    }

    case 'NewRecipeTitleTextChanged':
      const searchBoxChanged = action as NewRecipeTitleTextChanged;
      return { ...state, newRecipeTitleText: searchBoxChanged.payload };

    case 'ResetRecipeDetailsData': {
      return {
        ...state,
        ...emptyState
      };
    }

    case 'ResetNewRecipeData': {
      return {
        ...state,
        newRecipeTitleText: ''
      };
    }
    default:
      return state;
  }
};
