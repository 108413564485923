import { SyncAction, SyncActionClass } from '../../utils/Actions';
import { domainApi } from '../../domain/DomainApi';

export class ClearNotificationsAction extends SyncActionClass<{ userId: string }> {
  constructor(userId: string) {
    super('ClearNotificationsAction', { userId });
  }
}

export const clearUserListNotification: (userId: string) => SyncAction = userId => {
  domainApi.clearNotifications(userId);
  return new ClearNotificationsAction(userId).action();
};

export const NotificationsDrawerActions = {
  clearUserListNotification
};
