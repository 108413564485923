import { SyncAction, SyncActionClass, voidAsyncAction } from '../../../../utils/Actions';
import { domainApi } from '../../../../domain/DomainApi';

export class AddRecipeItemAction extends SyncActionClass<{ itemName: string; listId: string; recipeId: string }> {
  constructor(itemName: string, listId: string, recipeId: string) {
    super('AddRecipeItemAction', { itemName, listId, recipeId });
  }
}

export class AddMoreRecipeItemsAction extends SyncActionClass<{ itemName: string; listId: string; recipeId: string }> {
  constructor(itemName: string, listId: string, recipeId: string) {
    super('AddMoreRecipeItemsAction', { itemName, listId, recipeId });
  }
}

export class CancelRecipeItemAction extends SyncActionClass<{}> {
  constructor() {
    super('CancelRecipeItemAction', {});
  }
}

export class AddRecipeItemTextChanged extends SyncActionClass<string> {
  constructor(txt: string) {
    super('AddRecipeItemTextChanged', txt);
  }
}

export const cancelAddingItem: () => SyncAction = () => new CancelRecipeItemAction().action();

export const addItem: (listId: string, recipeId: string, itemName: string) => SyncAction = (
  listId,
  recipeId,
  itemName
) => {
  if (!itemName) {
    return voidAsyncAction;
  }
  domainApi.addNewRecipeItem(listId, recipeId, itemName);
  return new AddRecipeItemAction(itemName, listId, recipeId).action();
};

export const addMoreItems: (listId: string, recipeId: string, itemName: string) => SyncAction = (
  listId,
  recipeId,
  itemName
) => {
  if (!itemName) {
    return voidAsyncAction;
  }
  domainApi.addNewRecipeItem(listId, recipeId, itemName);
  return new AddMoreRecipeItemsAction(itemName, listId, recipeId).action();
};

export const onAddItemTextChange: (txt: string) => SyncAction = txt => new AddRecipeItemTextChanged(txt).action();

export const AddRecipeItemButtonActions = {
  addItem,
  addMoreItems,
  onAddItemTextChange,
  cancelAddingItem
};
