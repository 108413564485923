import * as React from 'react';
import withStyles, { ClassNameMap } from '@material-ui/core/styles/withStyles';
import {
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  MenuItem,
  Theme,
  WithStyles
} from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import { ShoppingListState } from './Reducers';
import Typography from '@material-ui/core/Typography';
import SwipeableViews, { SwipeableViewsProps } from 'react-swipeable-views';
import SupportTouch from 'react-swipeable-views';
import Collapse from '@material-ui/core/Collapse';
import { waitMs } from '../../../utils/PromiseDelay';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import VertMenuIcon from '@material-ui/icons/MoreVert';
import BuyLater from '@material-ui/icons/Alarm';
import CancelIcon from '@material-ui/icons/HighlightOff';
import RemoveIcon from '@material-ui/icons/Delete';
import CompleteIcon from '@material-ui/icons/Done';
import RenameIcon from '@material-ui/icons/Edit';
import Menu from '@material-ui/core/Menu';
import { DragDropContext, Droppable, Draggable, DropResult, DragStart } from 'react-beautiful-dnd';
import { ShoppingListActions } from './Actions';
import Divider from '@material-ui/core/Divider';
import { ListItem as ShoppingListItem } from './Types';
import { RenameItemDialog } from './RenameItemDialog';
import { ItemCategories } from '../itemcategoriesdrawer/ItemCategoriesContainer';
import { VertMenu } from '../../../utils/components/VertMenu';
import { getPathParam } from '../../../utils/RouterUtils';
import { ITEM_CATEGORIES_PATH, RENAME_ITEM_PATH } from '../router/ShoppingListRouter';
import { ShoppingListPageProps } from '../ShoppingListsPage';
import { logger } from '../../../utils/LoggingUtils';
import { ActionProps } from '../../../utils/Actions';
import { AppProps } from '../../../router/PrivateRouter';

const EmptyImg = require('react-icons/lib/md/add-shopping-cart');
const CategoryIcon = require('react-icons/lib/fa/tag');

const droppablePrefix = 'dropId_';
const draggableSeparator = '--';

const log = logger('CurrentShoppingListComponent');

const style: (theme: Theme) => StyleRules = theme => ({
  root: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.paper
  },
  chooseAction: {
    backgroundColor: '#FFAB00',
    color: theme.palette.error.contrastText
  },
  itemAdded: {
    backgroundColor: '#43A047',
    color: theme.palette.primary.contrastText
  },
  listIcon: {
    color: theme.palette.grey.A700
  },
  category1Divider: {
    backgroundColor: theme.palette.secondary.main
  },
  category1HeaderIcon: {
    float: 'right',
    display: 'flex'
  },
  category1Header: {
    lineHeight: '30px',
    paddingLeft: '10px',
    paddingRight: '18px',
    color: theme.palette.secondary.main
  },
  listItem: {
  },
  listItemSecondaryAction: {
    right: '6px'
  },
  draggingItem: {
    opacity: 1,
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[5]
  },
  emptyState: {
    height: '100%',
    textAlign: 'center',
    paddingTop: '70px'
  }
});

type ShoppingListProps = ShoppingListState &
  ActionProps<typeof ShoppingListActions> & 
  { loading: boolean } & 
  ShoppingListPageProps &
  WithStyles;

interface SwipeableItemProps extends SwipeableViewsProps {
  removeItem: () => void;
  addItem: () => void;
  buyLater: () => void;
  item: ShoppingListItem;
  listCategories: string[];
  isDragging: boolean;
  showItemCategoriesDialog: (open: boolean, selectedItem: string) => void;
  showRenameItemDialog: (open: boolean, selectedItem: string) => void;
}

interface SwipeableItemState {
  swipeIdx: number;
  visible: boolean;
  itemMenuOpen: boolean;
  swipeMenuOpen: boolean;
}

class SwipeableItem extends React.Component<SwipeableItemProps & WithStyles & AppProps, SwipeableItemState> {
  swipeAnchorEl?: HTMLElement;
  state = {
    swipeIdx: 1,
    visible: true,
    itemMenuOpen: false,
    swipeMenuOpen: false
  };

  onSwitching = (idx: number, type: string) => {
    if (type === 'end') {
      this.setState({ swipeIdx: idx });
    }
  };

  onEndSwipping = () => {
    // Don't do anything if the item remains in the middle.
    if (this.state.swipeIdx === 1) {
      return;
    }

    if (this.state.swipeIdx === 0) {
      waitMs(400).then(() => {
        this.setState({ visible: false });
        this.props.addItem();
      });
    }

    if (this.state.swipeIdx === 2) {
      this.setState({ swipeMenuOpen: true });
    }
  };

  onCompleteItemContextMenu = () => {
    this.setState({ swipeIdx: 0, itemMenuOpen: false });
  };

  onBuyLaterContextMenu = () => {
    this.setState({ itemMenuOpen: false, visible: false });
    waitMs(400).then(this.props.buyLater);
  };

  onBuyLaterSwipeMenu = () => {
    this.setState({ swipeMenuOpen: false, visible: false });
    waitMs(400).then(this.props.buyLater);
  };

  onRemoveItemSwipeMenu = () => {
    this.setState({ swipeMenuOpen: false, visible: false });
    waitMs(400).then(this.props.removeItem);
  };

  onCancelSwipeMenu = () => {
    this.setState({ swipeMenuOpen: false, swipeIdx: 1 });
  };

  onRemoveItemContextMenu = () => {
    this.setState({ itemMenuOpen: false, visible: false });
    waitMs(400).then(this.props.removeItem);
  };

  handleMenu = (open: boolean) => {
    if (open) {
      this.setState({ itemMenuOpen: true });
    } else {
      this.setState({ itemMenuOpen: false });
    }
  };

  handleCategoriesClick = () => {
    this.props.showItemCategoriesDialog(true, this.props.item.id);
  };

  handleRenameClick = () => {
    this.props.showRenameItemDialog(true, this.props.item.id);
  };

  render() {
    const t = this.props.t;
    const itemMenu = (
      <VertMenu id={`itemMenu-${this.props.item.id}`} icon={<VertMenuIcon className={this.props.classes.listIcon} data-testid="itemMenu"/>}>
        <MenuItem onClick={this.handleCategoriesClick}>
          <ListItemIcon>
            <CategoryIcon />
          </ListItemIcon>
          <ListItemText primary={t('Item categories')} disableTypography={true} />
        </MenuItem>
        <Divider />
        <MenuItem onClick={this.onCompleteItemContextMenu}>
          <ListItemIcon>
            <CompleteIcon />
          </ListItemIcon>
          <ListItemText primary={t('Complete item')} disableTypography={true} />
        </MenuItem>
        <MenuItem onClick={this.onBuyLaterContextMenu}>
          <ListItemIcon>
            <BuyLater />
          </ListItemIcon>
          <ListItemText primary={t('Buy later')} disableTypography={true} />
        </MenuItem>
        <MenuItem onClick={this.onRemoveItemContextMenu}>
          <ListItemIcon>
            <RemoveIcon />
          </ListItemIcon>
          <ListItemText primary={t('Remove item')} disableTypography={true} />
        </MenuItem>
        <Divider />
        <MenuItem onClick={this.handleRenameClick}>
          <ListItemIcon>
            <RenameIcon />
          </ListItemIcon>
          <ListItemText primary={t('Rename item')} disableTypography={true} />
        </MenuItem>
      </VertMenu>
    );

    const swipeMenu = (
      <Menu
        id={`swipe-left-menu-${this.props.item.id}`}
        open={this.state.swipeMenuOpen}
        anchorEl={this.swipeAnchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        onClose={this.onCancelSwipeMenu}>
        <MenuItem onClick={this.onBuyLaterSwipeMenu}>
          <ListItemIcon>
            <BuyLater />
          </ListItemIcon>
          <ListItemText primary={t('Buy later')} disableTypography={true} />
        </MenuItem>
        <MenuItem onClick={this.onRemoveItemSwipeMenu}>
          <ListItemIcon>
            <RemoveIcon />
          </ListItemIcon>
          <ListItemText primary={t('Remove item')} disableTypography={true} />
        </MenuItem>
        <Divider />
        <MenuItem onClick={this.onCancelSwipeMenu}>
          <ListItemIcon>
            <CancelIcon />
          </ListItemIcon>
          <ListItemText primary={t('Cancel')} disableTypography={true} />
        </MenuItem>
      </Menu>
    );
    return (
      <SupportTouch>
        <Collapse in={this.state.visible} timeout={200} unmountOnExit={false}>
          <SwipeableViews
            index={this.state.swipeIdx}
            onSwitching={this.onSwitching}
            onTransitionEnd={this.onEndSwipping}
            disabled={this.props.isDragging}>
            <ListItem divider={true} className={this.props.classes.itemAdded}>
              <ListItemText primary={t('Completed')+'!'} disableTypography={true} />
            </ListItem>
            <ListItem divider={true} className={this.props.classes.listItem}>
              {this.props.children}
              <ListItemSecondaryAction className={this.props.classes.listItemSecondaryAction}>{itemMenu}</ListItemSecondaryAction>
            </ListItem>
            <div
              ref={node => {
                this.swipeAnchorEl = node ? node : undefined;
              }}>
              <ListItem divider={true} className={this.props.classes.chooseAction}>
                <ListItemText primary={t('Choose')} disableTypography={true} />
              </ListItem>
            </div>
            {swipeMenu}
          </SwipeableViews>
        </Collapse>
      </SupportTouch>
    );
  }
}

const getDragableListItems = (props: ShoppingListProps, items: ShoppingListItem[], cat: string) => {
  return items.map((listItem, idx) => {
    const draggingThisItem = !!props.draggingItemId && props.draggingItemId === listItem.id;
    return (
      <Draggable
        draggableId={`${cat}${draggableSeparator}${listItem.id}`}
        index={idx}
        key={listItem.id}
        isDragDisabled={props.itemCategoriesOpen || props.renameItemOpen}>
        {provided => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={draggingThisItem ? props.classes.draggingItem : props.classes.listItem}>
            {getSwipeableListItem(props, listItem)}
          </div>
        )}
      </Draggable>
    );
  });
};

const getSwipeableListItem = (props: ShoppingListProps, listItem: ShoppingListItem) => {
  const appProps = props as AppProps;
  return (
    <SwipeableItem
      // tslint:disable-next-line jsx-no-lambda
      removeItem={() => props.removeItemAction(props.selectedListId, listItem.id)}
      // tslint:disable-next-line jsx-no-lambda
      addItem={() => props.completeItem(props.selectedListId, listItem.id)}
      // tslint:disable-next-line jsx-no-lambda
      buyLater={() => props.buyItemLater(props.selectedListId, listItem.id)}
      item={listItem}
      key={listItem.id}
      classes={props.classes}
      listCategories={props.listCategories}
      isDragging={!!props.draggingItemId && props.draggingItemId === listItem.id}
      showItemCategoriesDialog={props.showItemCategoriesDialog}
      showRenameItemDialog={props.showRenameItemDialog}
      {...appProps}>
      <ListItemText primary={listItem.name} />
    </SwipeableItem>
  );
};

const getAllItems = (props: ShoppingListProps) => {
  return <List component="nav">{props.filteredItems.map(listItem => getSwipeableListItem(props, listItem))}</List>;
};

const getItemsByCategory = (props: ShoppingListProps, classes: ClassNameMap<string>) => {
  const emptyCategory = (cat: string) => {
    return cat.toLowerCase() === 'not set';
  };

  // Put empty categories first
  const categories = Object.keys(props.itemsByCategory).sort((a, b) => {
    return emptyCategory(a) && emptyCategory(b)
      ? 0
      : emptyCategory(a) && !emptyCategory(b) ? -1 : !emptyCategory(a) && emptyCategory(b) ? 1 : a.localeCompare(b);
  });

  return categories.map(cat => {
    const listItems = props.itemsByCategory[cat];
    const expanded = !props.collapsedCategories || props.collapsedCategories.indexOf(cat) < 0;
    return (
      <Droppable
        droppableId={`${droppablePrefix}${cat}`}
        key={cat}
        isDropDisabled={props.itemCategoriesOpen || props.renameItemOpen}>
        {provided => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            <List component="nav">
              {!emptyCategory(cat) && (
                <React.Fragment>
                  <ListSubheader
                    className={classes.category1Header}
                    disableSticky={true}
                    // tslint:disable-next-line jsx-no-lambda
                    onClick={() => (expanded ? props.collapseCategory(cat) : props.expandCategory(cat))}>
                    {cat}
                    <span className={classes.category1HeaderIcon}>{expanded ? <ExpandLess /> : <ExpandMore />}</span>
                  </ListSubheader>
                  <Divider className={classes.category1Divider} />
                </React.Fragment>
              )}
              <Collapse in={expanded} timeout={400} unmountOnExit={false}>
                {getDragableListItems(props, listItems, cat)}
              </Collapse>
              {provided.placeholder}
            </List>
          </div>
        )}
      </Droppable>
    );
  });
};
const toListItems = function(props: ShoppingListProps, classes: ClassNameMap<string>) {
  return props.shoppingList.showCategories ? getItemsByCategory(props, classes) : getAllItems(props);
};

const getItemIdFromDraggableId = (draggableId: string) => {
  return draggableId.substring(draggableId.indexOf(draggableSeparator) + draggableSeparator.length);
};

const ShoppingListStyled = (props: ShoppingListProps) => {
  const { t, classes } = props;

  let content;

  if (props.itemsByCategory && Object.keys(props.itemsByCategory).length > 0) {
    content = toListItems(props, classes);
  } else {
    content = (
      <div className={classes.emptyState}>
        <EmptyImg size={250} color="rgba(97, 97, 97, 0.5)" />
        <Typography variant="h5">{t('Your shopping list is empty')}.</Typography>
        <Typography variant="subtitle1">{t('Tap the button below to add an item')}.</Typography>
      </div>
    );
  }

  const handleDragStart = (initial: DragStart) => {
    props.onDragStarted(getItemIdFromDraggableId(initial.draggableId));
    if (window.navigator.vibrate) {
      window.navigator.vibrate(100);
    }
  };

  const handleDragEnd = (result: DropResult) => {
    const previosCat = result.source.droppableId.substring(droppablePrefix.length);

    if (!result.destination) {
      return;
    }

    const newCat = result.destination.droppableId.substring(droppablePrefix.length);

    const itemId = getItemIdFromDraggableId(result.draggableId);

    log.debug(`data: ${previosCat} - ${newCat}`);
    props.onDragEnded(previosCat, newCat, result.destination.index, itemId);

    const foundItem = props.filteredItems.find(item => item.id === itemId);
    const currentCategories = foundItem ? foundItem.tags.Category : [];
    let newCategories = currentCategories.filter(cat => cat !== previosCat);

    if (newCat.toLowerCase() !== 'not set') {
      newCategories = newCategories.concat(newCat);
    }

    if (newCat !== previosCat) {
      props.updateItemCategories(props.selectedListId, itemId, newCategories, '');
    }
  };

  const findListItemById = (itemId?: string) => {
    if (!itemId) {
      return undefined;
    }

    return props.filteredItems.find(item => item.id === itemId);
  };

  // There is an issue with global parameters. Selected item depends on the current path.
  // https://github.com/ReactTraining/react-router/issues/5870
  let selectedItem = getPathParam(props.location.pathname, ITEM_CATEGORIES_PATH, 'selectedItem');

  if (!selectedItem) {
    selectedItem = getPathParam(props.location.pathname, RENAME_ITEM_PATH, 'selectedItem');
  }

  return (
    <React.Fragment>
      <RenameItemDialog
        item={findListItemById(selectedItem)}
        open={props.renameItemOpen}
        // tslint:disable-next-line jsx-no-lambda
        onClose={() => props.showRenameItemDialog(false, '')}
        // tslint:disable-next-line jsx-no-lambda
        onItemRenamed={(itemId, newName) => props.renameItem(props.selectedListId, itemId, newName)}
        t={t}
      />
      <ItemCategories
        item={findListItemById(selectedItem)}
        open={props.itemCategoriesOpen}
        // tslint:disable-next-line jsx-no-lambda
        onClose={() => props.showItemCategoriesDialog(false, '')}
        listCategories={props.listCategories}
        // tslint:disable-next-line jsx-no-lambda
        onCategoriesUpdated={(itemId, categories, newCat) =>
          props.updateItemCategories(props.selectedListId, itemId, categories, newCat)
        }
      />

      <DragDropContext
        onDragStart={handleDragStart}
        // tslint:disable-next-line jsx-no-lambda
        onDragEnd={result => handleDragEnd(result)}>
        {props.loading && <LinearProgress />}
        <div className={classes.root}>{content}</div>
      </DragDropContext>
    </React.Fragment>
  );
};

export const CurrentShoppingListComponent = withStyles(style)(ShoppingListStyled);
