import { Action } from 'redux';
import { Recipe } from '../../../domain/recipes/Types';
import { OnRecipesListUpdatedAction } from '../../../utils/actions/Actions';
import { UpdateRecipeFilterAction } from './Actions';
import { removeRecipeFiltersNotInRecipeList, updateRecipeFilters } from '../ShoppingListsUtils';

export type RecipesFilterDrawerState = Readonly<{
  recipes: Recipe[];
  filters: Recipe[];
}>;

const emptyState = {
  recipes: [],
  filters: []
};

export const recipesFilterReducer: (state: RecipesFilterDrawerState | undefined, action: Action) => RecipesFilterDrawerState = (
  state = emptyState,
  action
) => {
  switch (action.type) {
    case 'OnRecipesListUpdatedAction': {
      const updateAction = action as OnRecipesListUpdatedAction;
      const recipes = updateAction.payload.recipes;
      return {
        ...state,
        recipes,
        filters: removeRecipeFiltersNotInRecipeList(state.filters, recipes)
      };
    }
    case 'UpdateRecipeFilterAction': {
      const filterAction = action as UpdateRecipeFilterAction;
      return {
        ...state,
        filters: updateRecipeFilters(state.filters, filterAction.payload.recipe, filterAction.payload.add)
      };
    }
    case 'ClearFiltersAction': {
      return {
        ...state,
        filters: []
      };
    }

    default:
      return state;
  }
};
