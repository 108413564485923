import { IO, _do } from '../../../utils/fp/io';
import { logger } from '../../../utils/LoggingUtils';
import { UserDetailsRepositoryIO } from './UserDetailsRepositoryIO';
import { UserDetails } from '../Types';
import { sortDateArray, dedupeDatesByDay } from '../../../utils/ListUtils';
import { randomId } from '../../../utils/IdUtils';
import { UserListsServiceIO } from '../../userlists/io/UserListsServiceIO';

const log = logger('PureUserDetailsService');

export type UpdateUserDetailsResponse = {
    newUser: boolean,
    user: UserDetails;
};

export type UpdateUserDetailsNavData = {
    platform: string,
    appVersion: string,
    resolution: string,
    lang: string;
};

/**
 * Updates the user details after succesfull auth
 * @param userId: the user Id to be updated
 * @param firebaseUser: The firebase auth user
 * 
 * @returns true if the user already exist.
 */
const updateUserDetails: (userId: string, user: firebase.UserInfo, navData: UpdateUserDetailsNavData, today: Date)
    => IO<UpdateUserDetailsResponse>
    = _do(function* (userId, user, navData, today = new Date()) {
        log.debug(`Updating user details: ${userId}`);
        const userDetails: UserDetails | undefined = yield UserDetailsRepositoryIO.findUserDetailsById(userId);
        const response = updateOrCreateUserDetails(userDetails, userId, user, navData, today);
        log.debug(`${response.newUser ? 'New' : 'Existing'} user detected: ${userId}`);
        yield UserDetailsRepositoryIO.saveUserDetails(response.user);
        return response;
    });

const searchUsersByName: (searchTerm: string) => IO<UserDetails[]> = UserDetailsRepositoryIO.searchUsersByName;

/****** Functional core */
export const getUserName: (userInfo: firebase.UserInfo) => string = (userInfo) => {
    if (userInfo.displayName) {
        return userInfo.displayName;
    }

    if (userInfo.email && userInfo.email.indexOf('@') >= 0) {
        return userInfo.email.substring(0, userInfo.email.indexOf('@'));
    }

    return "";
};

export const updateOrCreateUserDetails: (userDetails: UserDetails | undefined, userId: string, user: firebase.UserInfo, navData: UpdateUserDetailsNavData, today: Date) => UpdateUserDetailsResponse
    = (userDetails, userId, user, navData, today) => {
        if (userDetails) {
            const updatedUserDetails = updateExistingUserDetails(userDetails, user, navData, today);
            return { newUser: false, user: updatedUserDetails };
        } else {
            const newUserDetails = createNewUserDetails(userId, user, navData, today);
            return { newUser: true, user: newUserDetails };
        }
    };

export const updateExistingUserDetails: (existingUser: UserDetails, user: firebase.UserInfo, navData: UpdateUserDetailsNavData, today: Date) => UserDetails
    = (existingUser, user, navData, today) => {
        const loginHistory: Date[] = [...existingUser.loginHistory];
        loginHistory.push(today);
        const sortedLoginHistory: Date[] = sortDateArray(dedupeDatesByDay(loginHistory), 'DESC');
        sortedLoginHistory.splice(50);

        const updatedUserDetails: UserDetails =
        {
            ...existingUser,
            email: user.email || '',
            name: getUserName(user),
            photoURL: user.photoURL || '',
            loginHistory: sortedLoginHistory,
            platform: navData.platform,
            appVersion: navData.appVersion,
            resolution: navData.resolution,
            lang: navData.lang
        };
        return updatedUserDetails;
    };

export const createNewUserDetails: (userId: string, user: firebase.UserInfo, navData: UpdateUserDetailsNavData, today: Date) => UserDetails
    = (userId, user, navData, today) => {
        const defaultListId = randomId();
        today.setHours(0, 0, 0, 0);

        const newUserDetails: UserDetails =
        {
            selectedList: defaultListId,
            name: getUserName(user),
            email: user.email || '',
            photoURL: user.photoURL || '',
            loginHistory: [today],
            userId,
            platform: navData.platform,
            appVersion: navData.appVersion,
            resolution: navData.resolution,
            lang: navData.lang
        };

        return newUserDetails;
    };

export const selectDefaultList: (userId: string) => IO<void> = _do(function* (userId) {
    log.debug(`Selecting default list for user ${userId}`);
    let lists: string[] = yield UserListsServiceIO.getUserListsId(userId);
    let user: UserDetails | undefined = yield UserDetailsRepositoryIO.findUserDetailsById(userId);
    
    if (!user) {
        return;
    }

    if (lists.indexOf(user.selectedList) >= 0) {
        log.debug(`Current selected list is valid, won't do anything`);
        return;
    }

    if (lists.length > 0) {
        log.debug(`User ${userId} has ${lists.length} list left. Selecting the first one`);
        yield UserDetailsRepositoryIO.updateSelectedList(userId, lists[0]);
    } else {
        log.debug(`User ${userId} has 0 list left. Creating a new one`);
        let newListId = randomId();
        yield UserDetailsRepositoryIO.updateSelectedList(userId, newListId);
        yield UserListsServiceIO.createDefaultList(userId, newListId);
    }
});

export const UserDetailsServiceIO = {
    updateUserDetails,
    searchUsersByName,
    selectDefaultList
};

