import * as React from 'react';
import { connect } from 'react-redux';
import { RecipesListComponent } from './Component';
import { State } from '../../../reducer';
import { getRecipesListSate } from '../RecipesPageReducer';
import { RecipesListState } from './Reducer';
import { RecipesPageProps } from '../RecipesPage';
import { RecipesListActions } from './Actions';
import { ActionProps } from '../../../utils/Actions';
import { AppProps } from '../../../router/PrivateRouter';

export type RecipesListContainerProps = 
  RecipesListState & 
  RecipesPageProps & 
  ActionProps<typeof RecipesListActions> &
  AppProps;

const mapDispatchToProps = {
  ...RecipesListActions
};

const mapStateToProps = (state: State) => {
  return {
    ...getRecipesListSate(state)
  };
};

class RecipesListContainer extends React.Component<RecipesListContainerProps> {
  render() {
    const props = this.props;
    return <RecipesListComponent {...props} />;
  }
}

export const RecipesList = connect(mapStateToProps, mapDispatchToProps)(RecipesListContainer);
