import { connect } from 'react-redux';
import { State } from '../../../reducer';
import ItemsForLaterDrawerComponent from './Component';
import { domainApi } from '../../../domain/DomainApi';
import { ItemsForLaterActions } from './Actions';
import * as React from 'react';
import * as log from 'loglevel';
import { ItemsForLaterDrawerState } from './Reducers';
import { AuthState } from '../../../utils/components/auth/Reducers';
import { getItemsForLaterDrawerState } from '../ShoppingListsPageReducer';
import { ActionProps } from '../../../utils/Actions';
import { AppProps } from '../../../router/PrivateRouter';

export type ItemsForLaterContainerProps = AuthState &
  ItemsForLaterDrawerState & {
    open: boolean;
    closeClicked: () => void;
  } & ActionProps<typeof ItemsForLaterActions> &
  AppProps;

const mapDispatchToProps = {
  ...ItemsForLaterActions
};

const mapStateToProps = (state: State) => {
  return getItemsForLaterDrawerState(state);
};

class ItemsForLaterDrawerContainer extends React.Component<ItemsForLaterContainerProps> {
  unsuscribe?: () => void;

  componentDidMount() {
    const props = this.props;
    const unsubs1 = domainApi.subscribeToShoppingListItemsForLater(props.selectedList, props.onItemsForLaterChanged);

    this.unsuscribe = () => {
      unsubs1();
    };
  }

  componentDidUpdate(prevProps: Readonly<ItemsForLaterContainerProps>) {
    const updatedProps = this.props;
    if (updatedProps.selectedList !== prevProps.selectedList) {
      log.debug(`Items for later list changed, updating subscriptions to list ${updatedProps.selectedList}`);
      
      if (this.unsuscribe) {
        this.unsuscribe();
      }
      
      const unsubs1 = domainApi.subscribeToShoppingListItemsForLater(
        updatedProps.selectedList,
        updatedProps.onItemsForLaterChanged
      );

      this.unsuscribe = () => {
        unsubs1();
      };
    }
  }

  componentWillUnmount() {
    if (this.unsuscribe) {
      this.unsuscribe();
    }
    
  }

  render() {
    return <ItemsForLaterDrawerComponent {...this.props} />;
  }
}

export const ItemsForLaterDrawer = connect(mapStateToProps, mapDispatchToProps)(ItemsForLaterDrawerContainer);
