import { ListItem } from '../../../components/shoppinglists/currentshoppinglist/Types';
import { DocumentData, QuerySnapshot } from '../../../utils/fp/effects/firestore/FirestoreEffects';
import { IO, _do } from '../../../utils/fp/io';
import { logger } from '../../../utils/LoggingUtils';
import { nameIdx } from '../../../utils/IdUtils';

const log = logger('PureShoppingListRepo');

const itemsPath = (listId: string) => ['ShoppingLists', listId, 'items'];

export const queryToListItems: (snapshot: QuerySnapshot<DocumentData>) => ListItem[] = query => {
  return query.docs
    .map(doc => {
      return doc.data();
    })
    .map(data => {
      return getListItemFromFsData(data);
    });
};

export const getListItemFromFsData: (data: DocumentData) => ListItem = (data: DocumentData) => {
  return {
    id: data.id,
    name: data.name,
    name_idx: data.name_idx,
    hideUntil: data.hideUntil ? data.hideUntil.toDate() : undefined,
    addedOn: data.addedOn ? data.addedOn.toDate() : undefined,
    completedOn: data.completedOn ? data.completedOn.toDate() : undefined,
    tags: {
      Category: data.tags && data.tags.Category ? data.tags.Category : [],
      Recipe: data.tags && data.tags.Recipe ? data.tags.Recipe : []
    }
  };
};

const getShoppingListItemsFs: (listId: string) => IO<DocumentData[]> = (listId) =>
  new IO(effects => effects.firestore.fetchCollection(itemsPath(listId)));

const getListItems: (listId: string) => IO<ListItem[]> = _do(function* (listId) {
  const snapshot: DocumentData[] = yield getShoppingListItemsFs(listId);
  return snapshot.map(getListItemFromFsData);
});

const saveItems: (listId: string, items: ListItem[]) => IO<void> = _do(function* (listId, items) {
  const batchSave = new IO(effects =>
    effects.firestore.saveBatch(itemsPath(listId), items.map(safeItem)));

  log.debug(`Saving ${items.length} items for list ${listId}`);
  yield batchSave;
  log.debug(`Saved ${items.length} items`);
}
);

export const safeItem = (item: ListItem) => {
  return {
    ...item,
    name_idx: nameIdx(item.name),
    hideUntil: item.hideUntil ? item.hideUntil : null,
    addedOn: item.addedOn ? item.addedOn : null,
    completedOn: item.completedOn ? item.completedOn : null,
    tags: item.tags ? item.tags : { Category: [], Recipe: [] }
  };
};

const deleteItem: (listId: string, itemId: string) => IO<void> =
  (listId, itemId) =>
    new IO(effects => effects.firestore
      .deleteDocument(itemsPath(listId), itemId)
    );

/**
 * Default items are items that exist by default in every new shopping list. The way to 
 * identify default items atm is because it will have no added date nor completed date.
 * HACK! Not explicit enough.
 * @param id 
 * @param name 
 * @param category 
 */
const newDefaultItem: (id: string, name: string, category: string) => ListItem = (id, name, category) => {
  const tags = {
    Category: [category],
    Recipe: []
  };

  return {
    id: id,
    name: name,
    name_idx: nameIdx(name),
    tags
  };
};

export const PureShoppingListRepo = {
  getListItems,
  saveItems,
  newDefaultItem,
  deleteItem
};